import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './Payment.scss'
import PaymentFilter from './PaymentFilter';
import order from '../../data/Order.json'
import PaymentList from './PaymentList';
import payment from './../../data/Payment.json'
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { listPaymentsAPI } from './../../API/Payment'
import { eraseCookie } from './../../global/js/browserfunction'
import Update from './../../Store/Update'
class Payment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: '',
            filterVal: '',
            update: {},
            total: '',
            listpayment: [],
            newupdate: {},
            updateall: '',
            loading: false,
            Filterchange: false,

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.childCallback = this.childCallback.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.HandleFilter = this.HandleFilter.bind(this);
    }
    /**
* @description The function executed get the serach param and convert to object
*/
    async handleCheck() {
        var str = window.location.search;
        if (str != '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
        }
        Update.subscribe(() => {
            this._isMounted && this.setState({ updateall: Update.getState().AllUpdates })
        })
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        this._isMounted && store.dispatch({ type: 'URL', url: urls })

    }
    /**
* @description The function executed for redirect page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for get the change from child
*/
    childCallback(value) {
        this.setState({ filterVal: value });
    }
    /**
* @description The function executed for back to popup
*/
    HandleFilter(value) {
        this.setState({ Filterchange: value });
    }
    /**
* @description The function executed for exists the components
*/
    componentWillUnmount() {
        this._isMounted = false;
        var update = {}
        store.dispatch({ type: 'urlappend', Urlappend: update })
    }
    /**
    * @description The function executed for before the component call
    */
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();


    }
    /**
* @description The function executed conver the updates and change the keys as the same of the api keyword
*/
    Handlechange(value) {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        this.setState({ Status: value });
        // check thee filter are true
        if (value == true) {
            var update = {};
            console.log('second')
            update = store.getState().urlappend;
            console.log(update.page)
            //convert the value of the object in filter to compatible to api keywords
            if (update.email) {
                newupdate['CUSTOMER_EMAIL'] = update.email.trim()
            }
            if (update.paymentno) {
                newupdate['PAYMENT_No'] = update.paymentno.trim()
            }
            if (update.fromdate) {
                newupdate['DATE_FROM'] = update.fromdate
            }
            if (update.name) {
                newupdate['CUSTOMER_NAME'] = update.name.trim()
            }
            if (update.orderno) {
                newupdate['ORDER_NO'] = update.orderno
            }
            if (update.sort) {
                newupdate['SORT'] = update.sort
            }
            if (update.status) {
                newupdate['STATUS'] = update.status.toUpperCase()
            }
            if (update.paymentmethod) {
                newupdate['PAYMENT_METHOD'] = update.paymentmethod
            }
            if (update.todate) {
                newupdate['DATE_TO'] = update.todate
            }
            if (update.page) {
                newupdate['limit'] = 20
            }
            if (update.page) {
                if (parseInt(update.page) == 1) {
                    newupdate['offset'] = '0'
                } else {
                    newupdate['offset'] = ((parseInt(update.page)) - 1) * 20
                }

            }

            if (update.sync) {
                newupdate['SYNC_WITH_NETSUITE'] = Boolean(update.sync);
            }
            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                DATE_FROM: "DATE_FROM",
                CUSTOMER_NAME: "CUSTOMER_NAME",
                ORDER_NO: "ORDER_NO",
                SORT: "SORT",
                STATUS: "STATUS",
                DATE_TO: "DATE_TO",
                DATE_FROM: "DATE_FROM",
                PAYMENT_METHOD: "PAYMENT_METHOD",
                PAYMENT_No: "PAYMENT_No",

            };
            let booleanFields = { limit: "limit", SYNC_WITH_NETSUITE: "SYNC_WITH_NETSUITE", offset: "offset" };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
                else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
            }

            console.log(graphQLParam);
            this.HandleSubmit(graphQLParam)
        }
    }
    /**
    * @description The function executed pass the keyword to api and get the result and pass to components
    */
    HandleSubmit(query) {
        var self = this
        self.setState({ loading: true })
        var details = [
            "record_row_id",
            "transaction_number",
            "transaction_date",
            "payment_number",
            "order_transaction_number",
            "amount",
            "payment_method_name",
            "status"
        ];
        //api for list orders
        listPaymentsAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.listPayments?.summary?.reason;
                let status = res?.data?.listPayments?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listPayments.details.list;
                    let total = res.data.listPayments.details.totalCount;
                    console.log(data)
                    console.log(total)
                    self.setState({ loading: false })
                    self.setState({ listpayment: data });
                    self.setState({ total: total });

                } else {
                    self.setState({ loading: false })
                    let data = []
                    self.setState({ listpayment: data });
                    self.setState({ total: 0 });
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }

    render() {
        return (
            < div className='Payment-main h-full  ' >
                {/* Breadthcrum of the Dashboard */}
                < Breadthcrumbs Breadthcrumb={'Payment'} />
                <div className='Payment-content px-5 py-2'>
                    <PaymentFilter Paymentlist={order.Orderdata} Status={payment.Status} Sync={order.SyncNetsuite} PaymentMethod={payment.PaymentMethod} passToParent={this.Handlechange} history={this.props.history} Filter={this.state.Filterchange} />
                    <PaymentList PaymentList={order.OrderList} SortByFilter={payment.SortByFilter} Filterval={this.state.filterVal} history={this.props.history} passToParent={this.Handlechange} listPayments={this.state.listpayment} Total={this.state.total} Update={this.state.update} updateall={this.state.updateall} loading={this.state.loading} passToFilter={this.HandleFilter} />
                </div>
            </div >
        )
    }
};

export default Payment;