import React, { Component } from 'react';
import local from '../../global/js/locale';
import Select from 'react-select'
import store from './../../Store/Store'
import { getCookie, eraseCookie, convertDate, convertDatetime, capitalizeFirstLetter } from './../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import moment from 'moment';
class OrderInfo extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            Edit: store.getState().edit,
            Status: props.Status,
            selectedOption: { value: "Shipped", label: 'Shipped', id: 1 },
            CurrentOrder: '',
            orderlist: props.orderlist,
            itemlist: props.itemlist,
            id: props.Id,
            varchar: local.VARCHAR,
            Int: local.INT,
            FORMAT: local.locale.DATE.FORMAT,
            custid: '',

        }
        this.HandleEdit = this.HandleEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.Capitalize = this.Capitalize.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.orderlist !== this.state.orderlist) {
            this.setState({ orderlist: nextProps.orderlist })
            console.log(nextProps.orderlist)
            let obj = this.state.Status.find(o => o.value === nextProps.orderlist.status);
            this.setState({ selectedOption: obj });
            this.setState({ custid: nextProps.orderlist.customer_id })
        }
        if (nextProps.itemlist !== this.state.itemlist) {
            console.log(nextProps.itemlist)
            this.setState({ itemlist: nextProps.itemlist })

        }

    }
    /**
* @description The function executed for get the updated the value of edit
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }
    /**
* @description The function executed for capitalize the letter
*/
    Capitalize(s) {
        if (s != undefined) {
            return s.toLowerCase().replace(/\b./g, function (a) { return a.toUpperCase(); });
        }

    };
    /**
* @description The function executed for exists the component
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var id = this.state.id;
        console.log(this.state.Orderlist)
        var items = this.state.Status.filter(val => val.id !== 1);
        this.setState({ Status: items })
        // var obj = this.state.Orderlist.findIndex((el) => el.id === id);
        // var orderdetail = this.state.Orderlist[obj]
        // this.setState({ CurrentOrder: orderdetail });
        // var select = { value: orderdetail.Status, label: orderdetail.Status }
        // this.setState({ selectedOption: select });
    }
    /**
* @description The function executed for cancel the edit
*/
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
        var edit = !this.state.Edit;
        if (edit === false) {
            var update = {}
            store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
            this.props.passToParent(false)
        }

    }
    /**
* @description The function executed change the status
*/
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        var update = store.getState().orderupdate;
        update['STATUS'] = selectedOption.value;
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
    };
    /**
* @description The function executed changes in the input box updated to object
*/
    handleUpdate(e) {
        var key = e.target.name;
        var value = e.target.value;
        var update = store.getState().orderupdate
        update[key] = value;
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })

    }
    render() {
        return (
            < div className='paymentDetail-No h-full py-5 px-6 bg-white shadow-shadowblack' >
                <div className='w-full flex px-5'>
                    <div className='w-8/12 flex'>
                        <img src='/static/Icons/orderdetail.svg' alt='' className='w-9' />
                        <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-2xl text-xl my-auto ml-3 ' + (this.state.Edit === true ? 'block' : 'hidden')}>{this.state.orderlist.status === 'PENDING' || this.state.orderlist.status === 'CHECKOUT' ? 'Quote' : 'Editing'}</span>
                        <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-2xl text-xl my-auto ml-3 '>Order {this.state.orderlist.transaction_number}</h1>
                    </div>
                    <div className={'w-4/12 my-auto ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                        <span className='ml-auto w-max block ml-auto mr-4 cursor-pointer' onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>
                    </div>
                    <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                        <div className='buttons w-max flex ml-auto h-8'>
                            <div className='flex bg-bluecolor rounded-xl px-3 py-1'>
                                <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                    <div className='flex'>
                                        <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                        <span className='ml-2 mx-auto'>Save</span>
                                    </div>
                                </button>
                            </div>

                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => this.HandleEdit()}>Cancel</span>
                        </div>
                    </div>
                </div>
                <hr className='sortfilterborder my-4' />
                <div className='flex px-4'>
                    <div className='xl:flex xl:w-7/12 w-1/2 '>
                        <div className='xl:w-1/2 xl:my-auto mb-4'>
                            <div className='w-full flex'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor w-30 xl:w-32 xl:mr-0 mr-2 '>Transaction No  &nbsp;&nbsp; </span> <span className={'font-normal ont-OpenSans  text-sm text-bluecolor ml-2 '}>{this.state.orderlist.transaction_number}</span>
                                {/* <span className={this.state.Edit === true ? 'w-max ' : 'hidden'}><input type='text' name='TRANSACTION_NUMBER' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-11/12 ml-2' defaultValue={this.state.orderlist.transaction_number} onChange={this.handleUpdate} maxLength={this.state.Int['XL-US']} /></span> */}

                            </div>
                            <div className='mt-5 flex'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor w-32 xl:mr-2 '>Status   &nbsp;&nbsp; </span>
                                <span className={'font-normal font-OpenSans  text-sm text-bluecolor capitalize' + (this.state.Edit === true ? ' ' : '')}>{capitalizeFirstLetter(this.state.orderlist.status)}</span>
                                {/* <span className={'w-36 order-status status-select shipping-font ' + (this.state.Edit === true ? ' ' : 'hidden')}> <Select
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange}
                                    options={this.state.Status} isSearchable={false}
                                /></span> */}
                            </div>
                            <div className='mt-5 xl:block hidden'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max '>Order Resolved &nbsp;&nbsp; <span className='font-normal ml-4'><span className='cursor-pointer'><input type="checkbox" className={"form-checkbox sync-netsuite  h-4 w-4  text-white "} defaultChecked={this.state.orderlist.order_resolved} readOnly={true} /></span></span></span>
                            </div>
                        </div>
                        <div className='xl:w-1/2 xl:m-auto mb-4 xl:mt-0'>
                            <div className='xl:m-auto xl:w-max xl:block' >
                                <div>
                                    <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max xl:m-auto '>Transaction Date  &nbsp;&nbsp; <span className='font-normal'>{this.state.orderlist.transaction_date !== '' && this.state.orderlist.transaction_date !== null ? convertDatetime(this.state.orderlist.transaction_date) : ''}</span></span>
                                </div>
                                <div className='mt-4 xl:block hidden'>
                                    <span className='font-OpenSans font-semibold text-sm text-bluecolor mr-12 '> Customer  &nbsp;&nbsp;</span> <span className='font-semibold ont-OpenSans d text-sm text-bluecolor xl:w-max xl:m-auto '><Link to={'/customer/customerdetails/' + (this.state.custid)} className='text-redcolor underline'>{this.state.orderlist.customer_name}</Link></span>
                                </div>
                                <div className='mt-4 xl:hidden block'>
                                    <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max '>Order Resolved &nbsp;&nbsp; <span className='font-normal ml-4'><span className='cursor-pointer'><input type="checkbox" className={"form-checkbox sync-netsuite  h-4 w-4  text-white "} defaultChecked={this.state.orderlist.order_resolved} readOnly={true} /></span></span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' xl:w-4/12 w-1/2 block'>

                        <div className='xl:w-3/4 xl:ml-auto xl:mr-0 m-auto block w-max '>
                            <div>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor mr-2'>Date Created &nbsp;&nbsp;</span> <span className='font-normal text-sm text-bluecolor font-OpenSans'> {this.state.orderlist.date_created !== null && this.state.orderlist.date_created !== '' ? convertDatetime(this.state.orderlist.date_created) : ''}</span>
                            </div>
                            <div className='mt-4'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor '>Date Modified  &nbsp;&nbsp; </span> <span className='font-normal text-sm text-bluecolor font-OpenSans'> {this.state.orderlist.date_modified !== null && this.state.orderlist.date_modified !== '' ? convertDatetime(this.state.orderlist.date_modified) : ''}</span>
                            </div>
                            <div className='mt-4 xl:hidden block'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor mr-8  '> Customer  &nbsp;&nbsp;</span> <span className='font-semibold ont-OpenSans d text-sm text-bluecolor xl:w-max xl:m-auto '><Link to={'/customer/customerdetails/' + (this.state.custid)} className='text-redcolor underline'>{this.state.orderlist.customer_name}</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};

export default OrderInfo;