import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import store from './../../Store/Store'
class SimpleHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }
    /**
   * @description The function executed for before the component call
   */
    componentDidMount() {
        store.dispatch({ type: 'Header', header: true })
    }

    render() {
        return (
            < div className='log-header ' >
                <img src='/static/images/header/wineye_icon.png' alt='' className='w-52 m-auto pt-5' />

            </div >
        )
    }
};

export default SimpleHeader;