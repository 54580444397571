const locale = {
  WEIGHT: {
    NAME: "",
    SYMBOL: "cl"
  },
  CURRENCY: {
    NAME: "pound",
    SYMBOL: "£"
  },
  RECAPTCH: {
    KEY: '6Le9uqMaAAAAAFrpxyTOmp38O4WAR-u70i26HDRh',
  },
  PERCENT: {
    SYMBOL: "%"
  },
  SHIPPING: {
    METHOD: "FEDEX"
  },
  DATE: {
    FORMAT: "DD/MM/YYYY",
  }
}
const VARCHAR = {
  "XS": 10,
  "XM": 20,
  "SM": 30,
  "MD": 50,
  "LG": 100,
  "LG-1": 150,
  "XL": 300,
  "2X": 500,
  "3X": 1000,
  "4X": 2000,
  "5X": 4000,
  "6X": 8000,
  "7X": 16000,

}
const INT = {
  "XS-US": 1,
  "SM-US": 5,
  "MD-US": 8,
  "LG-US": 10,
  "XL-US": 20,
  "7X": 100000,


}
const FLOAT = {
  "XS-US": 5,
  "SM-US": 8,
  "MD-US": 10,
  "LG-US": 12,

}
const VAT = 20 / 100;
const DUTY = {
  "Still Wine": 27.68,
  "Sparkling Wine": 34.32,
  "Fortified Wine": 35.76
}
const FilterSymbol = "<-*>";
const local = {
  locale,
  VARCHAR,
  INT,
  VAT,
  DUTY,
  FLOAT,
  FilterSymbol,

};
export default local;