import React, { Component } from "react";
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: props.loading //To show loaded
        };
    }

    shouldComponentUpdate(nextProps, nextState) //To hide/show loader based on parent component request
    {

        if (this.state.loading !== nextProps.loading) {
            this.setState({ loading: nextProps.loading });
            return true;

        }
        return false;
    }


    render() {
        return (

            <div className=' relative top-4/12 w-max '>
                <ScaleLoader
                    // css={override}
                    sizeUnit={"px"}
                    size={25}
                    color={'#6E768E'}
                    loading={this.state.loading}
                />
            </div>

        );
    }
}

export default Loader;