import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { listCronjobsAPI } from './../../API/Cronjob'
import { eraseCookie } from './../../global/js/browserfunction'
import Update from './../../Store/Update'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
import Loader from '../../components/Loader/Loader'
import { convertDate, capitalizeFirstLetter, convertFilename, convertDatetime, convertDatetimesecond } from './../../global/js/browserfunction'
import { BASEURL } from './../../API/Baseurl'
import { Link } from 'react-router-dom';
class Cronjoblist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cronjoblist: [],
            Status: props.Status,
            loading: props.loading,
            Update: {
                status: '',
                page: ''
            },
            OrderLength: '',
            pageNumbers: [],
            Count: '',
            counts: 1,
            pageid: 1,
            PageStart: 0,
            PageEnd: 20,
            Size: 20,
            PageMin: 0,
            PageMax: 5,
            Cursur: 'none',
            updates: props.Update,
            Total: props.Total,
            updateall: props.updateall,
            listimports: props.listimports,
            cronjobs: props.cronjobs,
            cronstatus: props.cronstatus,
            TableView: 'grid',
            width: ''
        }



        this.handleStatus = this.handleStatus.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.handlePagecheck = this.handlePagecheck.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleView = this.handleView.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.loading !== this.state.loading) {
            this.setState({ loading: nextProps.loading });
        }
        if (nextProps.cronstatus !== this.state.cronstatus) {
            this.setState({ cronstatus: nextProps.cronstatus })
            console.log(this.state.cronstatus.count)
        }
        if (nextProps.updateall !== this.state.updateall) {
            if (nextProps.updateall == true) {
                var update = {
                    status: 'import',
                    page: 1
                }
                this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
                this._isMounted && Update.dispatch({ type: 'UpdatesAll', UpdatesAll: false })
                this.handlePage(1)
                var order = [];
                // this.setState({ listimports: order });
                this.setState({ PageMin: 0, PageMax: 5 });
                this.setState({ PageMin: 0, PageMax: 5 });
            }

        }
        if (nextProps.Status !== this.state.Status) {
            this.setState({ Status: nextProps.Status });
        }
        if (nextProps.listimports !== this.state.listimports) {
            var importlist;
            var update = store.getState().urlappend;
            if (nextProps.listimports.length !== 0 && update.status === 'import') {
                importlist = nextProps.listimports
                const mapObj = {
                    Items: "",
                    Failed: "Fail",
                    Successful: "Success"

                };
                importlist.forEach((element, key) => {
                    var summary = element.summary.replace(/\b(?:Items|Failed|Successful)\b/gi, matched => mapObj[matched]);
                    console.log(summary.indexOf(","), 1)
                    summary = summary.split(",").join("<br />")
                    importlist[key].summary = summary
                });
                this.setState({ listimports: importlist });
            } else {
                importlist = nextProps.listimports
                importlist.forEach((element, key) => {
                    var summary = capitalizeFirstLetter(element.summary)
                    importlist[key].summary = summary
                });
                this.setState({ listimports: nextProps.listimports });
            }

            var page = parseInt(update.page)
            this.setState({ pageid: page });
            this.setState({ PageStart: ((page - 1) * this.state.Size) });
            this.setState({ PageEnd: (this.state.Size * page) });
            var Count = Math.ceil(page / 5)
            this.setState({ counts: Count });
            var pagemax = 5 * Count
            var pagemin = pagemax - 5
            this.setState({ PageMin: pagemin, PageMax: pagemax });
            var length = nextProps.listimports.length;
            this.setState({ PageMin: pagemin, PageMax: pagemax });
            if (length < this.state.Size) {
                this.setState({ PageEnd: this.state.PageStart + length });
                this.setState({ Cursur: 'block' });
            } else {

            }
        }
        if (nextProps.Total !== this.state.Total) {
            this.setState({ Total: nextProps.Total });
            this.setState({ OrderLength: nextProps.Total });
            var length = nextProps.Total;
            var pagenum = [];
            for (let i = 1; i <= Math.ceil(length / this.state.Size); i++) {
                pagenum.push(i);
            }
            this.setState({ pageNumbers: pagenum });

            this.setState({ Count: Math.ceil(pagenum.length / 5) });
            // if (length < 10) {
            //     this.setState({ PageEnd: this.state.Total });
            //     this.setState({ Cursur: 'block' });
            // }

        }
    }
    /**
   * @description The function executed for grid and list view
   */
    handleView(view) {
        this.setState({ TableView: view });
    }
    /**
  * @description The function executed for when resize the page close the sidebar
 */
    handleResize() {
        let width = window.innerWidth
        console.log(width)
        if (width < 1280) {

            this.setState({ width: true })
        } else {
            this.setState({ width: false })
        }
    }
    /**
  * @description The function executed for change the status
  */
    handleStatus(id) {
        this.setState({ Status: id });
        var update = store.getState().urlappend;
        update['status'] = id.toLowerCase();
        update['page'] = 1;
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.history.push({
            pathname: '/cronjobs',
            search: "?" + str.toString()
        })
        this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.passToParent(id)
    }

    /**
* @description The function executed for exists the components
*/
    componentWillUnmount() {
        this._isMounted = false;

    }
    /**
    * @description The function executed for before the component call
    */
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
    }
    /**
   * @description The function executed for pagination when click the pagenumber
   */
    handlePagecheck(id) {
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        pageId = id;
        var pageStart = (pageId - 1) * this.state.Size;
        var pageEnd = pageId * this.state.Size;
        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        //pass the value to parent 
        this.props.passToParent(update.status)
        this.props.passToParent(update.status)
        this.setState({ loading: false });



    }

    /**
    * @description The function executed for pagination when click the pagenumber
    */
    handlePage(id) {
        window.scrollTo(0, 0)
        var length = this.state.Total;
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.history.push({
            pathname: '/cronjobs',
            search: "?" + str.toString()
        })
        pageId = id;
        var pageStart = (pageId - 1) * this.state.Size;
        var pageEnd = pageId * this.state.Size;
        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        console.log(update.status)
        //pass the value to parent 
        this.props.passToParent(update.status)
        this.props.passToParent(update.status)


    }
    /**
    * @description The function executed for when the next and prev button click
    */
    handlePagination(id) {
        var self = this.state;
        var count = this.state.counts;
        var pagemin = this.state.PageMin;
        var pagemax = this.state.PageMax;
        if (id == 'next') {
            if (self.counts < self.Count) {
                pagemin = pagemin + 5
                pagemax = pagemin + 5
                this.setState({ counts: count + 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                //the next lowest page number has selected
                this.handlePage(pagemin + 1)
            }
        }
        if (id == 'prev') {
            if (1 < self.counts) {
                pagemin = pagemin - 5
                pagemax = pagemax - 5
                this.setState({ counts: count - 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                //the prev lowest page has selected
                this.handlePage(pagemin + 1)

            }
        }

    }
    render() {
        return (
            <div className=' py-1 px-6'>
                <ReactNotification />
                < div className='CategorysInformation   bg-white shadow-shadowblack py-5 px-6' >
                    <div className='w-full flex'>
                        <div className='flex w-1/2'>
                            <img src='/static/Icons/cronjob.svg' alt='' className='xl:w-8 w-5' />
                            <span className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto'>Cron Jobs</span>
                        </div>

                    </div>
                </div >
                <div className='Categorys-details  py-5 px-6 bg-white shadow-shadowblack mt-8'>
                    <div className='pb-10'>
                        <h1 className='py-2 px-2 pl-4 xl:text-lg text-base text-bluecolor font-OpenSans font-semibold'>Cron Job Status</h1>
                        <div className='relative xl:pt-3'>
                            <div className='flex'>
                                <h2 className={' px-2 px-5  xl:py-2 my-2 xl:text-4xl text-redcolor font-NotoSans font-bold text-left  net-amount-price pl-4 xl:pl-4 '}>Total - {this.state.cronstatus === undefined ? 0 : this.state.cronstatus.count}</h2>
                                <span className=" font-OpenSans items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-redcolor rounded-full h-5 my-auto">Last 30 Days</span>
                            </div>
                            {/* The foreach of the jobstatus section */}
                            {this.state.cronjobs.map(list =>
                                <div className='flex xl:w-8/12 w-11/12 py-2' key={list.id}>
                                    <div className='w-4/7'>
                                        <div className='w-11/12 mr-auto ml-5'>
                                            <div className={list.class}>
                                                <span className='text-center font-OpenSans font-normal text-sm w-max m-auto block text-white py-1'>{list.text}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-2/8 mr-auto'>
                                        {list.text == 'In Progress' &&
                                            <span className='font-OpenSans font-normal text-bluecolor text-base '>{this.state.cronstatus === undefined ? 0 : this.state.cronstatus.processing}</span>
                                        }
                                        {list.text == 'Finished' &&
                                            <span className='font-OpenSans font-normal text-bluecolor text-base '>{this.state.cronstatus === undefined ? 0 : this.state.cronstatus.finished}</span>
                                        }
                                        {list.text == 'Queue' &&
                                            <span className='font-OpenSans font-normal text-bluecolor text-base '>{this.state.cronstatus === undefined ? 0 : this.state.cronstatus.pending}</span>
                                        }
                                        {list.text == 'Failed' &&
                                            <span className='font-OpenSans font-normal text-bluecolor text-base '>{this.state.cronstatus === undefined ? 0 : this.state.cronstatus.failed}</span>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='xl:grid xl:grid-cols-2 xl:gap-2 w-3/4'>
                        <div className='categorys grid grid-cols-2 '>
                            <div className={'category-list '}>
                                <div className={' px-2 border py-2 cursor-pointer ' + (this.state.Status === 'IMPORT' ? 'bg-bluecolor' : ' ')} onClick={() => this.handleStatus('IMPORT')}>
                                    <div className='cursor-pointer flex w-max m-auto' >
                                        {/* <img src='/static/Icons/category_blue_button.svg' alt='' className={'w-4 mr-3 ' + (this.state.Status === 'Menu' ? 'hidden' : 'block')} />
                                <img src='/static/Icons/category_red_button.svg' alt='' className={'w-4 mr-3 ' + (this.state.Status === 'Menu' ? 'block' : 'hidden')} /> */}
                                        <h2 className={' font-OpenSans text-base font-semibold my-auto text-center ' + (this.state.Status === 'IMPORT' ? 'text-white' : 'text-bluecolor')}  >Import</h2>
                                    </div>

                                </div>
                                <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 ml-auto relative bottom-0.5 ' + (this.state.Status === 'IMPORT' ? 'block' : 'hidden')} />
                            </div>
                            <div className={'category-list    '}>
                                <div className={' px-2 border py-2 px-2 cursor-pointer  ' + (this.state.Status === 'CACHE' ? 'bg-bluecolor' : ' ')} onClick={() => this.handleStatus('CACHE')}>
                                    <div className='cursor-pointer flex w-max m-auto'>
                                        {/* <img src='/static/Icons/category_blue_button.svg' alt='' className={'w-4 mr-3 ' + (this.state.Status === 'Home' ? 'hidden' : 'block')} />
                                <img src='/static/Icons/category_red_button.svg' alt='' className={'w-4 mr-3 ' + (this.state.Status === 'Home' ? 'block' : 'hidden')} /> */}
                                        <h2 className={' font-OpenSans text-base font-semibold my-auto text-center ' + (this.state.Status === 'CACHE' ? 'text-white' : 'text-bluecolor')}>Website Jobs</h2>
                                    </div>
                                </div>
                                <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 relative bottom-0.5 ml-auto ' + (this.state.Status === 'CACHE' ? 'block' : 'hidden')} />
                            </div>

                        </div>
                    </div>
                    <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                        <Loader loading={this.state.loading} />
                    </div>
                    <div className={'items-text mt-8   ' + (this.state.loading === true ? 'hidden ' : 'block ')}>
                        <div className='flex my-4'>
                            <div className={'item-show mt-3 w-5/12 ' + (this.state.listimports?.length === 0 ? 'hidden' : '')}>
                                <span className='font-OpenSans font-bold text-base text-bluecolor'>Showing {this.state.PageStart + 1} - {this.state.PageEnd} of {this.state.Total} Results</span>
                            </div>
                            <div className={'item-show mt-3 w-5/12 ' + (this.state.listimports?.length === 0 ? '' : 'hidden')}>
                                <span className='font-OpenSans font-bold text-base text-bluecolor'> {this.state.Total} Results</span>
                            </div>
                            <div className='mt-3 w-7/12 xl:hidden'>
                                <div className='w-max ml-auto flex mr-5'>
                                    <span className='text-xs font-OpenSans text-bluecolor opacity-80 my-auto'>View :</span>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/grid.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}> <img src='/static/Icons/gridred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}><img src='/static/Icons/list.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/listred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* The cronjoblist view desktop */}
                        <div className='w-full xl:block hidden'>
                            <table className="table-auto w-full mt-3  ">
                                <thead >
                                    <tr className='order-table'>
                                        <th className={' font-bold text-sm text-bluecolor font-OpenSans py-3  ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}>Date Created</th>
                                        <th className={' font-bold text-sm text-bluecolor font-OpenSans py-3  '}>{this.state.Status === 'CACHE' ? 'Date Executed' : 'Date Executed'}</th>
                                        <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 '>Type</th>
                                        <th className={' font-bold text-sm text-bluecolor font-OpenSans py-3 ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}>User Email</th>
                                        <th className={' font-bold text-sm text-bluecolor font-OpenSans py-3 ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}>File Name</th>
                                        <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 '>Status</th>
                                        <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 '>Summary</th>
                                        {/* <th className={' font-bold text-sm text-bluecolor font-OpenSans py-3  ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}>Result</th> */}
                                    </tr>
                                </thead>
                                <tbody className='mt-2 order-table-body'>
                                    {/* The Foreach of the added products */}
                                    {this.state.listimports.map(list =>
                                        <tr key={list.record_row_id}>
                                            <td className={'table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 ' + (this.state.Status === 'CACHE' ? 'hidden' : '')} > {convertDatetimesecond(list.date_created)}</td>
                                            <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3  ' >{convertDatetimesecond(list.date_modified)}</td>

                                            <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 capitalize px-2'>{list.type}</td>
                                            <td className={'table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 underline w-14 px-2 ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}><Link className='' to={'/user/userdetail/' + list.admin_record_row_id}>{list.admin_email}</Link></td>
                                            <td className={'table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 underline px-2 ' + (this.state.Status === 'CACHE' ? 'hidden' : '')} >  <a
                                                href={BASEURL + list.file_import}
                                                title="View Events"
                                                target="_blank">#{convertFilename(list.file_import)}</a></td>
                                            <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 px-2'>{capitalizeFirstLetter(list.status)}</td>
                                            <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 px-2' dangerouslySetInnerHTML={{ __html: list.summary }}></td>

                                            {/* <td className={'table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 underline  ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}>{convertFilename(list.file_result)}</td> */}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {/* The cronjoblist view Mobile */}
                        <div className={'items-text mt-16 pb-6  gap-2 xl:hidden block  ' + (this.state.TableView === 'list' ? ' grid grid-cols-1' : 'grid grid-cols-2')}>
                            {this.state.listimports.map(list =>
                                <div key={list.record_row_id} className='order-border-table rounded-xl px-4 py-3 mb-4'>
                                    <div className='grid grid-cols-2 gap-2 my-1'>
                                        <div className='w-max mr-auto pl-2 my-auto'>
                                            <span className='text-bluecolor font-NotoSans font-normal opacity-50 text-xs'>{convertDatetimesecond(list.date_modified)}</span>
                                        </div>
                                    </div>
                                    <hr className='order-view-table my-2'></hr>
                                    <table className="w-full table-order-mobile">
                                        <tbody>
                                            <tr>
                                                <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Type</td>
                                                <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{capitalizeFirstLetter(list.type)}</td>
                                            </tr>
                                            <tr>
                                                <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>User Email</td>
                                                <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl underline '><Link className='' to={'/user/userdetail/' + list.admin_record_row_id}>{list.admin_email}</Link></td>
                                            </tr>
                                            <tr>
                                                <td className={' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}>File Name</td>
                                                <td className={' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl capitalize ' + (this.state.Status === 'CACHE' ? 'hidden' : '')}> <a
                                                    href={BASEURL + list.file_import}
                                                    title="View Events"
                                                    target="_blank">#{convertFilename(list.file_import)}</a></td>
                                            </tr>
                                            <tr>
                                                <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Status</td>
                                                <td className='text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>  {capitalizeFirstLetter(list.status)}</td>
                                            </tr>
                                            <tr>

                                                <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Summary</td>

                                                <td className='text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl' dangerouslySetInnerHTML={{ __html: list.summary }}></td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'w-max m-auto py-3 ' + (this.state.listimports.length === 0 || this.state.pageNumbers.length === 0 ? '' : 'hidden')}>
                        <span className=' font-OpenSans text-bluecolor   text-sm font-semibold'>-- No Result Found --</span>
                    </div>
                </div >
                <div className={'w-max mt-5 m-auto px-5 flex ' + (this.state.listimports.length === 0 ? 'hidden' : '')}>
                    <span className={'font-OpenSans text-xs font-regular border border-bluecolor  px-1   flex cursor-pointer ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('prev')}> <img src='/static/Icons/arrowleft.svg' alt='' className='w-5' /> </span>
                    {this.state.pageNumbers.slice(this.state.PageMin, this.state.PageMax).map(list =>
                        <span key={list} className={'font-OpenSans text-xs font-regular border border-bluecolor  px-3 py-1  cursor-pointer  ' + (this.state.pageid === list ? 'text-white active-page ' : 'text-bluecolor ') + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePage(list)}> {list}</span>
                    )}
                    <span className={'font-OpenSans flex text-xs font-regular border border-bluecolor  px-1 cursor-pointer   ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('next')}> <img src='/static/Icons/arrowright.svg' alt='' className='w-5 flex' /> </span>
                </div>
            </div>

        )
    }
};

export default Cronjoblist;