import { BASEURL } from './Baseurl'
/**
     * @description  The function for list all the caetgory
     * @returns all the category
     */

function listCategoryAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listCategory(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                         child{
                            ${detail}
                         child{
                            ${detail}
                           }
                         }
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list all the caetgory
     * @returns all the category
     */

function validateConditionAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    validateCondition(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list all the caetgory
     * @returns all the category
     */

function addOrUpdateCategoryAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    addOrUpdateCategory(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for remove the category
     * @returns all the category
     */

function deleteCategoryAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    deleteCategory(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for update the cache and generate and update the header.js in webstore
     * @returns all the category
     */
function generateHeaderTreeAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    generateHeaderTree(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list all the list HomePage Category
     * @returns all the category
     */
function listHomePageCategoryAPI(query, details, bannerdetails, bottombannerdetails) {
    //The details are convert to string
    let detail = details.toString();
    let bannerdetail = bannerdetails.toString();
    let bottombannerdetail = bottombannerdetails.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listHomePageCategory(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                      bannerHeader{
                       ${bannerdetail}
                     }
                     featureProducts{
                       ${detail}
                     }
                      featureCategory{
                        ${detail}
                      }
                       bottomHeader{
                        ${bottombannerdetail}
                      }
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for add or update the category
     * @returns all the category
     */
function addNewFeaturedProductAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    addNewFeaturedProduct(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for delete category
     * @returns all the category
     */
function deleteHomePageItemAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    deleteHomePageItem(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       ${detail}
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list all the list HomePage Category
     * @returns all the category
     */
function UpdateHomePageItemAPI(query, details, bannerdetails, bottombannerdetails) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    UpdateHomePageItem(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                     featureProducts{
                       ${detail}
                     }
                       }
                       }
               
                }`,
            }
        ),
    })
}
/**
     * @description  The function update the cache
     * @returns all the category
     */
function updateHomePageCasheAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    updateHomePageCashe(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       ${detail}
                       }
                       }
               
                }`,
            }
        ),
    })
}
export {
    listCategoryAPI,
    validateConditionAPI,
    addOrUpdateCategoryAPI,
    deleteCategoryAPI,
    generateHeaderTreeAPI,
    listHomePageCategoryAPI,
    addNewFeaturedProductAPI,
    deleteHomePageItemAPI,
    UpdateHomePageItemAPI,
    updateHomePageCasheAPI




}