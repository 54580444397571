import React, { Component } from 'react'
import Chart from 'react-apexcharts'
class SalesReport extends Component {
    constructor(props) {
        super(props);
        this.handleSales = this.handleSales.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.state = {
            // Category: props.Categories,
            // Series: props.Series,
            Report: this.props.Report,
            orderid: 1,
            options: {
                colors: ['#6BB4C6'],
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },

                grid: {
                    show: false,
                    row: {
                        colors: ['transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: this.props.Categories,
                    axisBorder: {
                        show: true,
                        color: '#78909C',


                    },
                    labels: {
                        style: {
                            colors: ['#6E768E'],
                            fontSize: '12px',
                            fontFamily: 'Open Sans',
                            fontWeight: 600,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: true,
                        color: '#78909C',

                    },
                    labels: {
                        style: {
                            colors: ['#6E768E'],
                            fontSize: '12px',
                            fontFamily: 'Open Sans',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-label',
                        },
                    }

                },
                chart: {
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: false,
                        },
                    },
                }

            },
            series: [{
                name: "wineye",
                data: this.props.Series,
            }],

        }
    }
    handleSales(id) {
        this.setState({ orderid: id })
        this.props.passToParent(id)
    }
    componentDidMount() {
        console.log(this.props.Categories)
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        let oldseries = this.state.series
        console.log(nextProps.Categories)
        let oldoptions = this.state.options
        this.setState({ series: [{ name: "wineye", data: nextProps.Series }] })
        // this.setState({ options: { ...oldoptions, xaxis: Object.assign(oldoptions.xaxis, { categories: nextProps.Categories }) } })
        this.setState({
            options: {
                colors: ['#6BB4C6'],
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },

                grid: {
                    show: false,
                    row: {
                        colors: ['transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: nextProps.Categories,
                    axisBorder: {
                        show: true,
                        color: '#78909C',


                    },
                    labels: {
                        style: {
                            colors: ['#6E768E'],
                            fontSize: '12px',
                            fontFamily: 'Open Sans',
                            fontWeight: 600,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: true,
                        color: '#78909C',

                    },
                    labels: {
                        style: {
                            colors: ['#6E768E'],
                            fontSize: '12px',
                            fontFamily: 'Open Sans',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-label',
                        },
                    }

                },
                chart: {
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: false,
                        },
                    },
                }

            },
        })


    }
    /**
* @description The function executed for next of the saleorder category
*/
    handleNext(id) {
        var length = this.state.Report.length;
        if (id < length) {
            this.setState({ orderid: id + 1 })
            this.props.passToParent(id + 1)
        }

    }
    /**
* @description The function executed forprev of the saleorder
*/
    handlePrev(id) {
        var array = this.state.Report;
        var min;
        min = Math.min.apply(null, array.map(function (item) {
            return item.id;
        }))
        if (id > 1) {
            this.props.passToParent(id - 1)
            this.setState({ orderid: id - 1 })
        }
    }
    render() {
        return (
            <div>
                <div className='flex'>
                    <h3 className='py-2 px-2 pl-4 xl:text-lg text-base text-bluecolor font-OpenSans font-semibold'>Sales History</h3>
                    <div className='chart-comp  w-max ml-auto flex'>
                        <span className='my-auto cursor-pointer' onClick={() => this.handlePrev(this.state.orderid)}> <img src='/static/Icons/up-sales.svg' alt='' className='px-2' /></span>
                        <div className='sales-report-div border  rounded-lg flex my-2' >
                            {this.state.Report.map(list =>
                                <span key={list.id} className={'font-OpenSans font-normal px-4 sales-report-text py-1 cursor-pointer  ' + (list.id === this.state.orderid ? 'activeorder' : '') + (list.id === 1 ? ' rounded-l-lg' : '') + (list.id === 4 ? ' rounded-r-lg' : '')} onClick={() => this.handleSales(list.id)}>{list.text}</span>
                            )}
                        </div>
                        <span className='my-auto cursor-pointer' onClick={() => this.handleNext(this.state.orderid)}> <img src='/static/Icons/down-sales.svg' alt='' className='px-2' /></span>
                    </div>
                </div>
                {/* The Appexchart donut chart of wineye Dashboard */}
                <Chart options={this.state.options} series={this.state.series} type="line" height={300} />
            </div>

        )
    }
};

export default SalesReport;