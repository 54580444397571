
import { BASEURL } from './Baseurl'
/**
     * @description  The function for list the all products
     * @returns all the products
     */
function listProductsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/product', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listProducts(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       list{
                         ${detail}
                         
                       }
                         totalCount
                       }
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list the  products information
     * @returns  the products information
     */
function fetchProductDetailsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/product', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchProductDetails(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                         
                       }
                        
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for update the product details
     * @returns  the products information
     */
function updateProductAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/product', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    updateProduct(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                         
                       }
                        
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for update the product details
     * @returns  the products information
     */
function insertProductAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/product', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    insertProduct(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                         
                       }
                        
                         
                
                }
                }`,
            }
        ),
    })
}
export {
    listProductsAPI,
    fetchProductDetailsAPI,
    updateProductAPI,
    insertProductAPI


}