import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import paymentmethod from './../../data/PaymentMethod.json'
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './PaymentMethod.scss'
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { listAllPaymentMethodAPI } from '../../API/PaymentMethod';
import moment from 'moment';
class PaymentMethod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            paymentlist: [],
            paymentlength: '',
            pageNumbers: [],
            Count: '',
            counts: 1,
            pageid: 1,
            PageStart: 0,
            PageEnd: 20,
            loading: false,
            PageMin: 0,
            PageMax: 5,
            Cursur: 'none',
            Update: {},
            Total: '',

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handlePage = this.handlePage.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleData = this.handleData.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleGet = this.handleGet.bind(this);

    }

    /**
        * @description The function executed for redirect the page
        *@param path-location to redirect
    */
    GoToPage(path) {
        this.props.history.push(path);
    }

    /**
        * @description The function executed for when exists the component
    */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
        * @description The function executed for setting up the page numbers and page limits of the page from its initial state
    */
    handleLoad() {
        if (window.location.search === '') {

            this.handlePage(1)
            var order = [];
            this.setState({ PageMin: 0, PageMax: 5 });
            this.setState({ PageMin: 0, PageMax: 5 });
        }

        this.setState({ paymentlength: this.state.Total });
        var length = this.state.Total;
        var pagenum = [];
        for (let i = 1; i <= Math.ceil(length / 20); i++) {
            pagenum.push(i);
        }
        this.setState({ pageNumbers: pagenum });
        this.setState({ Count: Math.ceil(pagenum.length / 5) });
        if (length < 20) {
            this.setState({ PageEnd: this.state.Total });
            this.setState({ Cursur: 'block' });
        }
    }

    /**
        * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
        window.scrollTo(0, 0)

        if (this._isMounted) {
            this._isMounted && this.handleData()
        }
        else {
            this.props.history.push("/")
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps._isMounted !== this._isMounted) {
            this._isMounted = false
        }
    }

    /**
       * @description The function executed set the value of update from store and to set up its url and to set up data to be passed in API
       * @param {value}, a boolean value to decide whether to setup the data to be passed with API or not
       * @returns data to be passed with API
   */
    async handleData() {

        this.handleGet(true)
    }

    handleGet(value) {

        var str = window.location.search;
        if (str !== '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
        }
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        this._isMounted && store.dispatch({ type: 'URL', url: urls })



        var self = this;
        var token;
        var email;
        var newupdate = {};
        if (!getCookie("wn_log")) {//checks for empty cookies
            self.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {//checks for empty token
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            self.GoToPage("/");
        }

        //check thee filter are true
        if (value == true) {
            var update = {};
            update = store.getState().urlappend;

            if (update.page) {
                newupdate['limit'] = 20 //setting value of limit
            }
            {/* setting offset value */ }
            if (update.page) {
                if (parseInt(update.page) == 1) {
                    newupdate['offset'] = '0'
                } else {
                    newupdate['offset'] = ((parseInt(update.page)) - 1) * 20
                }

            }

            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                TOKEN: "TOKEN",
            };
            let booleanFields = { limit: "limit", offset: "offset" };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
            }
            this.HandleSubmit(graphQLParam)
        }

    }

    /**
        * @description  Function for getting Paymentlist
        * @param {query}, data to be passed with API to get details
        * @returns list of payment methods
    */
    HandleSubmit(query) {
        var self = this
        self.setState({ loading: true })
        var details = [
            "record_row_id",
            "date_created",
            "name",
            "display_in_website"
        ];

        listAllPaymentMethodAPI(query, details)//API call for listing Payment methods
            .then((res) => res.json())
            .then(function (res) {

                let reason = res?.data?.listAllPaymentMethods?.summary?.reason;
                let status = res?.data?.listAllPaymentMethods?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listAllPaymentMethods.details.list;
                    let total = res.data.listAllPaymentMethods.details.totalCount;
                    self.setState({ paymentlist: data });
                    self.setState({ Total: total });
                    self.handleLoad()
                    self.setState({ loading: false })
                }
            })

    }

    /**
        * @description The function executed for when page select change the list based on the page
        * @param {id}, a string value either to be 'prev' or 'next' to indicate the button click to whether moves next page section or previous page section
        * @returns value according to the param
    */
    handlePage(id) {
        window.scrollTo(0, 0)
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id

        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        store.dispatch({ type: 'urlappend', Urlappend: update })

        this.props.history.push({
            pathname: '/paymentmethod',
            search: "?" + str.toString()
        })

        pageId = id;
        var pageStart = (pageId - 1) * 20;
        var pageEnd = pageId * 20;

        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }

        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        this.handleGet(true);
        this.handleGet(false);
        this.setState({ loading: false });
    }

    /**
        * @description The function executed for next and prev page 
        *@param id-prev or next button
    */
    handlePagination(id) {
        var self = this.state;
        var count = this.state.counts;
        var pagemin = this.state.PageMin;
        var pagemax = this.state.PageMax;

        if (id == 'next') {
            if (self.counts < self.Count) {
                pagemin = pagemin + 5
                pagemax = pagemin + 5
                this.setState({ counts: count + 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)
            }
        }

        if (id == 'prev') {
            if (1 < self.counts) {
                pagemin = pagemin - 5
                pagemax = pagemax - 5
                this.setState({ counts: count - 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)
            }
        }

    }

    render() {
        return (
            <div className='paymentMethod-main xl:h-full h-screen'>

                < Breadthcrumbs Breadthcrumb={'Payment Method'} />

                <div className='payment-content px-5 py-2'>
                    < div className='paymentInformation  py-5 px-6 bg-white shadow-shadowblack' >
                        <div className='w-full flex'>
                            <img src='/static/Icons/payment.svg' alt='' className='w-10' />
                            <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 mt-3'>Payment Information</h1>
                        </div>
                    </div >
                </div>

                <div className='Order-Main px-5 py-2'>

                    < div className='Order-List h-full shadow-shadowblack mt-7 bg-white px-5' >
                        {/*Pagination section header */}
                        {this.state.paymentlist.length > 0 &&
                            <div className='items count flex'>
                                <div className='item-show mt-3 w-5/12 '>
                                    <span className='font-OpenSans font-bold text-base text-bluecolor'>Showing {this.state.PageStart + 1} - {this.state.PageEnd} of {this.state.Total} Results</span>
                                </div>
                            </div>
                        }

                        {this.state.loading ?

                            /* Loader */
                            <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                                <Loader loading={this.state.loading} />
                            </div> :

                            /* Payment method listing */
                            <div className={'items-text mt-12 pb-6  ' + (this.state.loading === true ? 'hidden' : 'block')}>

                                <table className="table-auto w-full mt-3 ">

                                    <thead >
                                        <tr className='payment-table'>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans  py-3 xl:w-40'>
                                            </th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 xl:w-64'>Date</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 xl:w-64'>Payment Method</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 text-center  xl:text-left xl:pl-md'>Show in Website</th>
                                        </tr>
                                    </thead>

                                    <tbody className='mt-2 payment-table-body'>
                                        {
                                            /* The Foreach of the added payment methods */
                                            this.state.paymentlist && this.state.paymentlist.length > 0 && this.state.paymentlist.map(list =>
                                                <tr key={list && list.record_row_id}>
                                                    <td className='table-payment font-normal text-sm text-bluecolor font-OpenSans  py-3'>
                                                        <Link to={'/paymentmethod/paymentdetails/' + list.record_row_id}>  <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' /></Link>
                                                    </td>
                                                    <td className='table-payment text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list && moment(list.date_created).format('DD/MM/YYYY')}</td>
                                                    <td className='table-payment text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list && list.name}</td>
                                                    <td className='table-payment text-center  text-sm  text-bluecolor font-NotoSans font-normal py-3 xl:text-left xl:pl-sm'>{list && list.display_in_website === true ? 'Yes' : 'No'}</td>

                                                </tr>
                                            )}
                                    </tbody>

                                </table>

                            </div>
                        }
                    </div >

                    {/* Pagination section Footer */}
                    <div className={'w-max mt-5 m-auto px-5 flex ' + (this.state.paymentlist.length === 0 ? 'hidden' : '')}>
                        <span className={'font-OpenSans text-xs font-regular border border-bluecolor  px-1   flex cursor-pointer ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('prev')}> <img src='/static/Icons/arrowleft.svg' alt='' className='w-5' /> </span>
                        {this.state.pageNumbers.slice(this.state.PageMin, this.state.PageMax).map(list =>
                            <span key={list} className={'font-OpenSans text-xs font-regular border border-bluecolor  px-3 py-1  cursor-pointer  ' + (parseInt(this.state.pageid) === list ? 'text-white active-page ' : 'text-bluecolor ') + (this.state.Cursur === 'none' ? 'cursor-pointer' : '')} onClick={() => this.handlePage(list)}> {list}</span>
                        )}
                        <span className={'font-OpenSans flex text-xs font-regular border border-bluecolor  px-1  cursor-pointer   ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('next')}> <img src='/static/Icons/arrowright.svg' alt='' className='w-5 flex' /> </span>
                    </div>

                </div >

            </div>
        )
    }
};

export default PaymentMethod;