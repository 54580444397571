import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './../PaymentMethod/PaymentMethod.scss'
import paymentmethod from './../../data/PaymentMethod.json'
import PaymentMethodinfo from './PaymentMethodinfo';
import PaymentmethodDetails from './PaymentmethodDetails';
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { listSinglePaymentDetailsAPI, updatePaymentDetailsAPI } from '../../API/PaymentMethod';
import moment from 'moment';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'


class PaymentMethodDetail extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            loading: false,
            Edit: store.getState().edit,
            Id: parseInt(props.match.params.id),
            Currentmethod: {},
            is_inactive: false,
            display_web: false,
            isCancel: false


        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleData = this.handleData.bind(this);
    }

    /**
        * @description The function executed for when exists component
    */
    componentWillUnmount() {
        this._isMounted = false
    }

    /**
        * @description The function executed for before the component call
    */
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();

    }

    /**
        * @description The function executed for before the component call
    */
    GoToPage(path) {
        this.props.history.push(path);
    }

    /**
        * @description The function executed set the value of edit from store
    */
    async handleCheck() {
        if (this._isMounted) {
            store.subscribe(() => {
                this._isMounted && this.setState({ Edit: store.getState().edit })
            })
            this.handleData()
        }
    }

    /**
        * @description The function for calling API to fetch a single payment method details
        * @returns Details of selected payment method
    */
    handleData() {
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        var self = this;
        var token;
        var email;
        var id;
        var newupdate = {};
        if (!getCookie("wn_log")) { //checking for empty cookies
            self.props.history.push("/paymentmethod");
        }
        if (localStorage.getItem("wn_log") !== null) { //checking  for empty token
            self.setState({ loading: true })
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            id = self.state.Id
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['RECORD_ROW_ID'] = parseInt(id)
        } else {
            self.props.history.push("/paymentmethod");
        }

        //convert the object to string also check the bolean and string
        newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
        };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }

        //Details to be return
        var details = [
            "record_row_id",
            "date_created",
            "date_modified",
            "is_inactive",
            "name",
            "image_icon",
            "display_in_website",
            "token_key",
            "token_secret",
            "api_url"
        ];

        listSinglePaymentDetailsAPI(graphQLParam, details)//API call for getting single Payment details
            .then((res) => res.json())
            .then(function (res) {
                let reason = res.data?.fetchPaymentmethodInformation?.summary?.reason;
                let status = res.data?.fetchPaymentmethodInformation?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.fetchPaymentmethodInformation.details;
                    self.setState({ Currentmethod: data }); // Setting value of selected Payment Details to the state Currentmethod       
                }
                self.setState({ loading: false })
            })
    }


    /**
        * @description The function executed when form submit and to call Updation API to update the details of a Payment method
        * @returns selected payment methods Details
    */
    handleSubmit(e) {
        e.preventDefault();
        var self = this;
        var token;
        var email;
        var newupdate = {};


        if (!getCookie("wn_log")) { //checking for empty cookies
            self.props.history.push("/paymentmethod");
        }

        if (localStorage.getItem("wn_log") !== null) { //checking for empty tokens
            self.setState({ loading: true })
            var update = store.getState().update
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['RECORD_ROW_ID'] = self.state.Currentmethod.record_row_id
            newupdate['NAME'] = update.name ? update.name : self.state.Currentmethod.name
            console.log(update)
            if (update.display_web) {

                newupdate['DISPLAY_IN_WEB'] = update.display_web

            }
            if (update.inactive) {
                newupdate['IS_INACTIVE'] = update.inactive
            }
            newupdate['IMAGE_ICON'] = update.image_1 ? update.image_1 : self.state.Currentmethod.image_icon
            newupdate['API_KEY'] = update.key ? update.key : self.state.Currentmethod.token_key
            newupdate['API_SECRET'] = update.secret ? update.secret : self.state.Currentmethod.token_secret
            newupdate['API_URL'] = update.url ? update.url : self.state.Currentmethod.api_url
        }

        else {
            self.props.history.push("/paymentmethod");
        }

        let stringFields = {//setting string fields 
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            NAME: "NAME",
            IMAGE_ICON: "IMAGE_ICON",
            API_KEY: "API_KEY",
            API_SECRET: "API_SECRET",
            API_URL: "API_URL"
        };
        let booleanFields = { DISPLAY_IN_WEB: "DISPLAY_IN_WEB", IS_INACTIVE: "IS_INACTIVE" };//setting boolean fields
        let integerFields = { RECORD_ROW_ID: "RECORD_ROW_ID" };//setting integer fields
        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        var details = ["record_row_id"]; //setting up what data should display  
        if (self.state.Edit) {
            console.log(graphQLParam, 'graphQLParam')
            updatePaymentDetailsAPI(graphQLParam, details)//API call for Upadting payment details
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res.data?.updatePaymentMethod.summary.reason;
                    let status = res.data?.updatePaymentMethod.summary.status;
                    if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                        // let data = res.data.updateAdmin.details;
                        self.handleData()
                        showNotification("Updated Successfully", "success");
                    } else {
                        showNotification("Sorry Cannot Update", "danger");
                    }

                })
        }
    }

    render() {
        return (
            <div className='paymentMethod-main h-screen'>
                <ReactNotification />
                {this.state.loading === true ?
                    <div className={this.state.loading ? 'parentDisable w-max m-auto block  ' : ''}>
                        <Loader loading={this.state.loading} />
                    </div> :
                    <div className=' '>

                        < Breadthcrumbs Breadthcrumb={'Details'} Count={2} level={'Payment Method'} />
                        <form onSubmit={this.handleSubmit}>
                            <PaymentMethodinfo MethodList={this.state.Currentmethod} Id={parseInt(this.state.Id)} isCancel={this.state.isCancel} handleData={this.handleData} />
                            <PaymentmethodDetails MethodList={this.state.Currentmethod} Id={parseInt(this.state.Id)} isCancel={this.state.isCancel} handleData={this.handleData} />
                        </form>
                    </div>
                }
            </div>
        )
    }
};

export default PaymentMethodDetail;