import React, { Component } from 'react';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './ProductDetail.scss'
import store from './../../Store/Store'
import Select from 'react-select'
import JoditEditor from "jodit-react";
import local from '../../global/js/locale';
import { showNotification } from './../../global/js/notification'
import { convertDate, convertDatetime } from './../../global/js/browserfunction'
import { fetchLeadTimeInformationAPI } from './../../API/Leadtime'
import { emailregex } from './../../global/js/validator'
import { CurrencyFormat } from './../../global/js/browserfunction'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import moment from 'moment';
class ProductDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Productinfo: props.ProductInfo,
            Tabs: 1,
            Products: props.Products,
            id: props.Id,
            currentProduct: '',
            Edit: store.getState().edit,
            currency: props.currency,
            weight: props.weight,
            Percent: props.Percent,
            ContractType: '',
            Contracttype: props.ContractType,
            DutyPaid: '',
            Dutypaid: props.DutyPaid,
            CountryList: props.Countrylist,
            Countrylist: [],
            Title: 1,
            Description: '',
            CountryOption: '',
            lasttradedate: '',
            varchar: local.VARCHAR,
            islivex: false,
            Int: local.INT,
            Float: local.FLOAT,
            ShortLimit: false,
            ShortLimitnumber: '',
            Descriptionlimit: false,
            Descriptionlimitnumber: '',
            ABV: '',
            Livex: '',
            FORMAT: local.locale.DATE.FORMAT,
            emailvalid: true,
            Type: '',
            SubType: '',
            Types: props.Types,
            Subtypes: props.Subtypes,
            Leadmin: 0,
            Leadmax: 0,
            SubTypelist: false,
            Leaddetails: [],
            Tightest: false,


        }
        this.Increment = this.Increment.bind(this);
        this.Decrement = this.Decrement.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.convertDateYear = this.convertDateYear.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.emailvalidator = this.emailvalidator.bind(this);
        this.LeadTimeInformation = this.LeadTimeInformation.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
    }
    /**
* @description The function executed for get the update value from store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
        // var id = parseInt(this.state.id);
        // var obj = this.state.Products.findIndex((el) => el.id === id);
        // var product = this.state.Products[obj];
        // //update the value 
        // this.setState({ currentProduct: product });
        // this.setState({ Description: product.Description });
        // this.setState({ lasttradedate: product.LastTradeDate.split('/').reverse().join("-") });
        // this.setState({ ContractType: { value: product.ContractType, label: product.ContractType } })
        // this.setState({ DutyPaid: { value: product.DutyPaid, label: product.DutyPaid === true ? 'YES' : 'NO' } })
        // var desc = product.Description
        // var results = document.getElementById("Description")
        // results.innerHTML = desc;
        // var short = product.ShortDescription
        // var shortdesc = document.getElementById("ShortDescription")
        // shortdesc.innerHTML = short;
        var countrylist = this.state.CountryList
        var array = [];
        //format the country list 
        Object.keys(countrylist).forEach(function (key) {
            array.push({
                value: countrylist[key].country,
                label: countrylist[key].country,
                id: countrylist[key].itemid,
                ISO: countrylist[key].countryISO
            });
            array.sort((a, b) =>
                a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0
            );
        });
        this.setState({ Countrylist: [...this.state.Countrylist, ...array] })
        this.forceUpdate();
    }
    /**
* @description The function executed for when the compoenent exists
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();

        var contract = this.state.Contracttype
        var duty = this.state.Dutypaid
        contract.forEach(e => {
            if (e.value === 'SIB') {
                let obj = duty.filter(x => x.value === false);
                e['Duty'] = obj
            } else if (e.value === 'X') {
                e['Duty'] = duty
            } else {
                let obj = duty.filter(x => x.value === false);
                e['Duty'] = obj
            }
        });

        this.setState({ Contracttype: contract })
    }
    /**
* @description The function executed for check the valid email
*/
    emailvalidator() {
        var update = store.getState().update
        var email = update['SUPPLIER_EMAIL']
        console.log(update['SUPPLIER_EMAIL'], emailregex.test(email))
        if (emailregex.test(email)) {
            this.setState({ emailvalid: true })
        } else {
            this.setState({ emailvalid: false })
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Products !== this.state.Products) {
            this.setState({ Products: nextProps.Products })

            var country = { label: nextProps.Products.wine_country, value: nextProps.Products.wine_country }
            this.setState({ CountryOption: country })
            this.LeadTimeInformation()
            var desc = nextProps.Products.sales_description
            var results = document.getElementById("Description")
            results.innerHTML = desc;
            var short = nextProps.Products.short_description
            var shortdesc = document.getElementById("ShortDescription")
            shortdesc.innerHTML = short;
            if (nextProps.Products.product_contract_type === null || nextProps.Products.product_contract_type === undefined) {
                let objcontract = this.state.Contracttype.find(o => o.value === false);
                this.setState({ ContractType: objcontract })
            } else {
                let objcontract = this.state.Contracttype.find(o => o.value === nextProps.Products.product_contract_type);
                this.setState({ ContractType: objcontract })
            }
            if (nextProps.Products.product_duty_paid === null || nextProps.Products.product_duty_paid === undefined) {
                let objduty = this.state.Dutypaid.find(o => o.value === false);
                this.setState({ DutyPaid: objduty })
            } else {
                let objduty = this.state.Dutypaid.find(o => o.value === nextProps.Products.product_duty_paid);
                this.setState({ DutyPaid: objduty })
            }

            if (nextProps.Products.abv_is_verified !== null) {
                let objabv = this.state.Dutypaid.find(o => o.value === nextProps.Products.abv_is_verified);
                this.setState({ ABV: objabv })
                console.log(objabv)
            }
            else {
                let objabv = this.state.Dutypaid.find(o => o.value === false);
                this.setState({ ABV: objabv })
                console.log(objabv)
            }

            if (nextProps.Products.tightest_spread !== null) {
                this.setState({ Tightest: nextProps.Products.tightest_spread })
            }
            else {
                this.setState({ Tightest: false })
            }
            if (nextProps.Products.wine_type !== null || nextProps.Products.wine_type === undefined) {
                let objabv = this.state.Types.find(o => o.value === nextProps.Products.wine_type.toLowerCase());
                if (objabv?.SubType?.length === 0) {
                    this.setState({ SubTypelist: true })
                } else {
                    this.setState({ SubTypelist: false })
                }
                this.setState({ Type: objabv })
            }
            if (nextProps.Products.wine_subtype !== null || nextProps.Products.wine_subtype === undefined) {
                let objabv = this.state.Subtypes.find(o => o.value === nextProps.Products.wine_subtype.toLowerCase());


                this.setState({ SubType: objabv })
            }
            if (nextProps.Products.is_livex === null) {
                this.setState({ islivex: false })
            } else {
                this.setState({ islivex: nextProps.Products.is_livex })
            }
            if (nextProps.Products.supplier_name === "Liv-ex" || nextProps.Products.supplier_name === 'livex' || nextProps.Products.supplier_name === 'LIVEX') {
                this.setState({ Livex: true })
            } else {
                var x = {}
                for (const [key, value] of Object.entries(nextProps.Products)) {
                    x[key.toUpperCase()] = value;
                }
                store.dispatch({ type: 'Update', Update: x })
                this.setState({ Livex: false })
            }


        }


    }
    /**
   * @description The function executed for convert the date with timezone to date only with dd/mm/yyyy fromat
   */
    convertDateYear(inputFormat) {

        if (inputFormat != undefined) {
            let input = inputFormat.split("T")[0];
            function pad(s) {
                return s < 10 ? "0" + s : s;
            }
            var d = new Date(input);
            return [pad(d.getFullYear()), pad(d.getMonth() + 1), d.getDate()].join(
                "-"
            );
        }
    }
    /**
* @description The function executed for update the contract type of the product
*/
    handleContract = selectedOption => {
        this.setState({ ContractType: selectedOption });
        var update = store.getState().update;
        update['PRODUCT_CONTRACT_TYPE'] = selectedOption.value;
        update['PRODUCT_DUTY_PAID'] = selectedOption['Duty'][0].value;
        this.setState({ DutyPaid: selectedOption['Duty'][0] });
        store.dispatch({ type: 'Update', Update: update })
    };
    /**
* @description The function executed for update the Type of the product
*/
    handleType = selectedOption => {
        this.setState({ Type: selectedOption });
        console.log(selectedOption)
        var update = store.getState().update;
        update['WINE_TYPE'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
        var product = this.state.Products
        product['wine_subtype'] = '';
        update['WINE_SUBTYPE'] = '';
        // if (selectedOption?.SubType[0]?.value != undefined) {
        //     product['WINE_SUBTYPE'] = selectedOption.SubType[0].value;
        //     update['WINE_SUBTYPE'] = selectedOption.SubType[0].value;
        //     this.setState({ SubType: selectedOption.SubType[0] });
        // } else {
        //     product['WINE_SUBTYPE'] = '';
        //     update['WINE_SUBTYPE'] = '';
        //     this.setState({ SubType: {} });
        // }
        if (selectedOption?.SubType?.length === 0) {
            this.setState({ SubTypelist: true })
        }
        else {
            document.getElementById('WINE_SUBTYPE').value = ''
            this.setState({ SubTypelist: false })
        }
        this.setState({ Products: product });
        console.log(store.getState().update)

    };
    /**
    * @description The function executed for update the Sub type of the product
    */
    handleSubype = selectedOption => {
        this.setState({ SubType: selectedOption });
        console.log(selectedOption)
        var update = store.getState().update;
        update['WINE_SUBTYPE'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
        console.log(update)
    };

    /**
     * 
* @description The function executed for update the ABV of the product
*/
    handleABV = selectedOption => {
        this.setState({ ABV: selectedOption });
        var update = store.getState().update;
        update['ABV_IS_VERIFIED'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
    };
    /**
* @description The function executed for update the dutypaid
*/
    handleDutypaid = selectedOption => {
        this.setState({ DutyPaid: selectedOption });
        var update = store.getState().update;
        update['PRODUCT_DUTY_PAID'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
    };
    /**
* @description The function executed for increment the count
*/
    Increment(e) {
        var Quantity = e.target.parentNode.parentNode.parentElement.querySelector('#QUANTITY_AVAILABLE')
        var val = Quantity.value;
        val++
        Quantity.value = val;
        var update = store.getState().update;
        update['QUANTITY_AVAILABLE'] = val;
        store.dispatch({ type: 'Update', Update: update })

    }
    /**
* @description The function executed for decrement the count
*/
    Decrement(e) {
        var Quantity = e.target.parentNode.parentNode.parentElement.querySelector('#QUANTITY_AVAILABLE')
        var val = Quantity.value;
        if (val > 0) {
            val--
        }
        Quantity.value = val;
        var update = store.getState().update;
        update['QUANTITY_AVAILABLE'] = val;
        store.dispatch({ type: 'Update', Update: update })
    }
    /**
* @description The function executed for set the title of the product
*/
    handleTitle(id) {
        this.setState({ Title: id });
    }
    /**
* @description The function executed for update the country
*/
    handleCountry = selectedOption => {
        this.setState({ CountryOption: selectedOption });
        var update = store.getState().update;
        update['WINE_COUNTRY'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
        // var Leaddetails = this.state.Leaddetails
        // let uk = Leaddetails.find(o => o.COUNTRY === 'UK');
        // let outside = Leaddetails.find(o => o.COUNTRY === 'International');
        // if (selectedOption.value === "United Kingdom") {
        //     this.setState({ Leadmin: uk.Minimum })
        //     this.setState({ Leadmax: uk.Maximum })
        //     document.getElementById('LEAD_TIME_MIN').value = uk.Minimum
        //     document.getElementById('LEAD_TIME_MAX').value = uk.Maximum
        //     var update = store.getState().update;
        //     update['LEAD_TIME_MIN'] = uk.Minimum;
        //     update['LEAD_TIME_MAX'] = uk.Maximum;
        //     store.dispatch({ type: 'Update', Update: update })
        // } else {
        //     this.setState({ Leadmin: outside.Minimum })
        //     this.setState({ Leadmax: outside.Maximum })
        //     update['LEAD_TIME_MIN'] = outside.Minimum;
        //     update['LEAD_TIME_MAX'] = outside.Maximum;
        //     document.getElementById('LEAD_TIME_MIN').value = outside.Minimum
        //     document.getElementById('LEAD_TIME_MAX').value = outside.Maximum
        // }
    };
    /**
* @description The function executed for change the state of active and inactive
*/
    handleToggle(e) {
        var self = this;
        var update = store.getState().update
        if (e.target.children.Tightest != undefined) {
            var Tightest = !e.target.children.Tightest.checked
            self.setState({ Tightest: Tightest })
            update['TIGHTEST_SPREAD'] = !e.target.children.Tightest.checked;
            store.dispatch({ type: 'Update', Update: update })
        }


    }
    /**
* @description The function executed for update the sort
*/
    updateshort = (value) => {
        // console.log(this.state.varchar['2X'])
        var maxlength = this.state.varchar['2X'];
        var length = value.length;
        console.log(value.replace(/"/g, "'"))
        if (maxlength >= length) {
            this.setState({ ShortLimit: false });
            var update = store.getState().update
            update['SHORT_DESCRIPTION'] = value.replace(/"/g, "'");
            store.dispatch({ type: 'Update', Update: update })
        } else {
            console.log(length - maxlength)
            this.setState({ ShortLimitnumber: (length - maxlength) })
            this.setState({ ShortLimit: true });
        }

    }
    setRef = jodit => this.jodit = jodit;
    config = {
        readonly: false,
    }
    /**
* @description The function executed for update the description
*/
    updateDescription = (value) => {
        var maxlength = this.state.varchar['4X'];
        var length = value.length;
        if (maxlength > length) {
            this.setState({ Descriptionlimit: false });
            var update = store.getState().update
            update['SALES_DESCRIPTION'] = value.replace(/"/g, "'");
            store.dispatch({ type: 'Update', Update: update })
        } else {
            console.log(length - maxlength)
            this.setState({ Descriptionlimitnumber: (length - maxlength) })
            this.setState({ Descriptionlimit: true });
        }
    }
    setRef = jodit => this.jodit = jodit;
    config = {
        readonly: false,
    }
    /**
* @description The function executed for conavert the object to string
*/
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { RECORD_ID: "RECORD_ID", MARKUP: "MARKUP" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function for fetch the leadtime and markup percent
*/
    LeadTimeInformation() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        self.setState({ loading: true })
        var details = [
            "Minimum",
            "Maximum",
            "COUNTRY"

        ];
        var dutydetails = [
            "record_row_id",
            "duty_rate",
            "wine_type",
            "wine_volume"
        ]
        //api for list orders
        fetchLeadTimeInformationAPI(query, details, dutydetails)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.fetchLeadTimeInformation?.summary?.reason;
                let status = res?.data?.fetchLeadTimeInformation?.summary?.status;
                console.log(reason, status)
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    self.setState({ loading: false })
                    var Leaddetails = res.data.fetchLeadTimeInformation.details.leadtime;
                    self.setState({ Leaddetails: Leaddetails })
                    let uk = Leaddetails.find(o => o.COUNTRY === 'UK');
                    let outside = Leaddetails.find(o => o.COUNTRY === 'International');
                    console.log(self.state.CountryOption)
                    if (self.state.CountryOption.value === "United Kingdom") {
                        self.setState({ Leadmin: uk.Minimum })
                        self.setState({ Leadmax: uk.Maximum })
                    } else {
                        self.setState({ Leadmin: outside.Minimum })
                        self.setState({ Leadmax: outside.Maximum })
                    }

                } else {
                    self.setState({ loading: false })
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
* @description The function executed for updated values are converted to object the id as key
*/
    handleUpdate(e) {
        var self = this;
        var key = e.target.id;
        var value = e.target.value;
        var update = store.getState().update
        var products = this.state.Products
        var priceonline;
        update['PRICE_OFFER'] = products.price_offer
        update['BUFFER_MARKUP_PERCENTAGE'] = products.buffer_markup_percentage
        if (key === 'PRICE_LAST_TRADE_DATE') {
            e.target.setAttribute(
                "data-date",
                moment(e.target.value, "YYYY-MM-DD")
                    .format(e.target.getAttribute("data-date-format"))
            )
        }
        if (key === 'SUPPLIER_EMAIL') {
            update[key] = value;
            self.setState({ update: update }, function () {
                this.emailvalidator();
            })
        }
        if (key === 'PRICE_MARKUP_PERCENTAGE') {
            console.log(value)
            if (value === '') {
                priceonline = products.price_offer
            } else if (parseFloat(value) < 99) {
                priceonline = (products.price_offer * parseInt(value)) / 100 + products.price_offer;
            }
            products['price_online'] = parseFloat(priceonline).toFixed(2)
            this.setState({ Products: products });
            update['PRICE_ONLINE'] = parseFloat(priceonline).toFixed(2);
            console.log(priceonline)
            update[key] = value;
        } else if (key === 'PRICE_DISCOUNT_PERCENTAGE') {
            console.log(value)
            if (value === '') {
                priceonline = products.price_offer
                products['price_online'] = parseFloat(priceonline).toFixed(2)
                update['PRICE_ONLINE'] = parseFloat(priceonline).toFixed(2);
            } else if (value < 100) {
                priceonline = products.price_offer - (products.price_offer * parseInt(value)) / 100;
                products['price_online'] = parseFloat(priceonline).toFixed(2)
                update['PRICE_ONLINE'] = parseFloat(priceonline).toFixed(2);
            }

            this.setState({ Products: products });

            console.log(priceonline)
            update[key] = value;
        }
        else if (key === 'PRICE_OFFER') {
            if (products.price_markup_percentage != '' && products.price_markup_percentage != null && value != '') {
                let markup = products.price_markup_percentage
                let priceoffer = parseFloat(value)
                let price_offer = parseFloat(value) + parseFloat(priceoffer * parseInt(markup) / 100);
                products['price_online'] = parseFloat(price_offer).toFixed(2)
                update['PRICE_ONLINE'] = parseFloat(price_offer).toFixed(2);

            } else {

                console.log(update)
                if (value === '') {
                    update['PRICE_ONLINE'] = 0
                    products['price_online'] = 0
                } else {
                    update['PRICE_ONLINE'] = value
                    products['price_online'] = 0
                }

            }
            update[key] = value;

        } else if (key === 'PRICE_ONLINE') {
            products['price_discount_percentage'] = 0
            products['price_markup_percentage'] = 0
            update['PRICE_MARKUP_PERCENTAGE'] = 0
            update['PRICE_DISCOUNT_PERCENTAGE'] = 0
        }
        else if (key === 'PRODUCT_DELIVERY_PERIOD' && value !== '') {
            products['lead_time_min'] = parseInt(value) + this.state.Leadmin
            products['lead_time_max'] = parseInt(value) + this.state.Leadmax
            update['LEAD_TIME_MIN'] = parseInt(value) + this.state.Leadmin
            update['LEAD_TIME_MAX'] = parseInt(value) + this.state.Leadmax
            update[key] = value;
        }
        else {
            update[key] = value;
        }
        console.log(update)
        store.dispatch({ type: 'Update', Update: update })

    }
    render() {
        return (
            < div className={'Product-information h-full pt-4 py-8 px-5 bg-white mt-6 shadow-shadowblack mb-10'}>
                <div className='product-head flex'>
                    {this.state.Productinfo.map(list =>
                        <div className='mr-2 flex' key={list.id}>
                            <span className={'OrderItem font-OpenSans font-semibold   text-base my-auto cursor-pointer ' + (this.state.Title !== list.id ? 'text-bluecolor ' : 'text-redcolor ') + (list.id !== 1 ? 'xl:ml-3' : '')} onClick={() => this.handleTitle(list.id)}>{list.name}  &nbsp;</span>
                            <img src='/static/Icons/Line.svg' alt='' className={'h-8 w-2 ' + (list.id === 4 ? 'hidden' : '')} />
                        </div>
                    )}
                </div>
                <hr className='sortfilterborder mb-8 mt-3' />
                <div className={'mt-10 px-10 ' + (this.state.Title === 1 ? 'block' : 'hidden')}>
                    <div className='title-description'>
                        <table className="w-full">
                            <tbody>
                                <tr>
                                    <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>LWIN 7</span></td>
                                    <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor '} >{this.state.Products.lwin_7}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>LWIN 11</span></td>
                                    <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor '} >{this.state.Products.lwin_11}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>LWIN 18 *</span></td>
                                    <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor '} >{this.state.Products.lwin_18}</p>
                                    </td>
                                </tr>
                                <tr >
                                    <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Short <br></br>description</span></td>
                                    <td className='py-2'> <div id='ShortDescription' className={'font-OpenSans font-semibold  text-sm text-bluecolor w-9/12 whitespace-pre-line ' + (this.state.Edit === true ? 'hidden ' : 'block')}>   </div>
                                        <span className={this.state.Edit === true ? ' w-1/2 ' : 'hidden'}>
                                            <JoditEditor
                                                editorRef={this.setRef}
                                                value={this.state.Products.short_description}
                                                config={this.config}
                                                tabIndex={1}
                                                maxLength="10"
                                                onChange={this.updateshort}
                                            />
                                        </span>
                                        <span className={'font-OpenSans font-semibold  text-sm text-redcolor ' + (this.state.ShortLimit === true ? '' : 'hidden')}>Limit exceeded Chars {this.state.ShortLimitnumber}</span>
                                    </td>
                                </tr>
                                <tr >
                                    <td className='w-48 py-2 align-top'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Description</span></td>
                                    <td className='py-2'> <div className={'font-OpenSans font-semibold  text-sm text-bluecolor w-9/12 whitespace-pre-line ' + (this.state.Edit === true ? 'hidden ' : 'block')} ><div id="Description"></div></div>
                                        <span className={this.state.Edit === true ? ' w-10/12' : 'hidden'}>
                                            <JoditEditor
                                                editorRef={this.setRef}
                                                value={this.state.Products.sales_description}
                                                config={this.config}
                                                tabIndex={1}
                                                onChange={this.updateDescription}
                                            />
                                        </span>
                                        <span className={'font-OpenSans font-semibold  text-sm text-redcolor ' + (this.state.Descriptionlimit === true ? '' : 'hidden')}>Limit exceeded Chars {this.state.Descriptionlimitnumber}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={'wine-deatils xl:w-11/12 w-10/12 m-auto  ' + (this.state.Title === 2 ? 'block' : 'hidden')}>
                    <div className='grid xl:grid-cols-3 grid-cols-1 xl:gap-4'>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className='w-48 py-2'>  <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Color *</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_color}  </p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='text' id='WINE_COLOR' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_color} onInput={this.handleUpdate} maxLength={this.state.varchar['SM']} pattern={this.state.Title === 2 ? '^[a-zA-Z_ ]*$' : undefined} title="character only allowed" /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Vintage *</span></td>
                                        <td className='py-2'>  <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_vintage}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='number' id='WINE_VINTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_vintage} onInput={this.handleUpdate} maxLength={this.state.varchar['XS']} pattern="\s*\S+.*" title="space only is not allowed" required={this.state.Title === 2 ? true : false} /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Grower *</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_producer_name}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='text' id='WINE_PRODUCER' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_producer_name} onInput={this.handleUpdate} maxLength={this.state.varchar['SM']} pattern="\s*\S+.*" title="space only is not allowed" required={this.state.Title === 2 ? true : false} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className={'w-48 py-2 ' + (this.state.Livex)}><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Grower Title</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.title}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='text' id='TITLE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor  w-52' defaultValue={this.state.Products.title} onInput={this.handleUpdate} maxLength={this.state.varchar['SM']} /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Country *</span></td>
                                        <td className='py-2'>  <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_country}</p>
                                            <span className={' shipping-country w-52 wine-country status-select shipping-font ' + (this.state.Edit === true && this.state.Livex === false ? ' ' : 'hidden')}><Select
                                                value={this.state.CountryOption}
                                                onChange={this.handleCountry}
                                                options={this.state.Countrylist} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'>  <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Region </span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_region}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='text' id='WINE_REGION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_region} onInput={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>SubRegion </span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_subregion}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='text' id='WINE_SUBREGION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_subregion} onInput={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr >
                                        <td className='w-48 py-2'>   <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Site</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_site}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='text' id='WINE_SITE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_site} onInput={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Parcel</span></td>
                                        <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_parcel}</p>
                                            <span className={this.state.Edit && this.state.Livex === false === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_PARCEL' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_parcel} onInput={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Type *</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor capitalize ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_type}</p>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font  Shipping-size  ' + (this.state.Edit === true && this.state.Livex === false ? 'block' : 'hidden')}> <Select
                                                value={this.state.Type}
                                                onChange={this.handleType}
                                                options={this.state.Types} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Sub Type</span></td>
                                        <td className='py-2'>  <p className={'font-OpenSans font-semibold  text-sm text-bluecolor capitalize ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_subtype}</p>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font Shipping-size   ' + (this.state.Edit === true && this.state.Livex === false && this.state.SubTypelist === false ? 'block' : 'hidden')}> <Select
                                                value={this.state.SubType}
                                                onChange={this.handleSubype}
                                                options={this.state.Type?.SubType} isSearchable={false}
                                            /></span>
                                            <span className={this.state.Edit === true && this.state.Livex === false && this.state.SubTypelist === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_SUBTYPE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onInput={this.handleUpdate} maxLength={this.state.varchar['LG']} defaultValue={this.state.Products.wine_subtype} /></span>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'>   <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Is Liv-ex</span></td>
                                        <td className='py-2'> <span className='cursor-pointer '><input type="checkbox" id={(this.state.Edit === true && this.state.Livex === false ? 'islivex' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white  " + (this.state.Edit === true && this.state.Livex === false ? '' : '')} defaultValue={this.state.islivex} checked={this.state.islivex} readOnly={true} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'>   <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Supplier Name *</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.supplier_name}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='text' id='SUPPLIER_NAME' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.supplier_name} onInput={this.handleUpdate} maxLength={this.state.varchar['MD']} pattern="\s*\S+.*" title="space only is not allowed" required={this.state.Title === 2 ? true : false} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Supplier Email *</span></td>
                                        <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.supplier_email}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full block' : 'hidden'}><input type='text' id='SUPPLIER_EMAIL' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.supplier_email} onInput={this.handleUpdate} maxLength={this.state.varchar['MD']} required={this.state.Title === 2 ? true : false} /></span>
                                            <span className={'font-OpenSans font-normal text-redcolor text-sm  ' + (this.state.emailvalid === false ? 'mb-6' : 'hidden')}>Invalid Email</span></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Pack Size *</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.wine_packsize}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='number' id='WINE_PACKSIZE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_packsize} onInput={this.handleUpdate} max='1000' min='1' required={this.state.Title === 2 ? true : false} /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Bottle Size *</span></td>
                                        <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit && this.state.Livex === false === true ? 'hidden ' : 'block')}>{this.state.Products.wine_bottlesize} ml</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full font-OpenSans font-normal text-sm text-bluecolor ' : 'hidden'}><input type='number' id='WINE_BOTTLESIZE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.wine_bottlesize} onInput={this.handleUpdate} max='65535' min='0' required={this.state.Title === 2 ? true : false} /> ml</span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Alcohol Value</span></td>
                                        <td className='py-2 font-OpenSans font-semibold  text-sm text-bluecolor'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.abv_percentage}&nbsp;{this.state.Products.abv_percentage === null ? '' : " %"}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}><input type='number' id='ABV_PERCENTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.abv_percentage} onInput={this.handleUpdate} min='0' max='100' /> %</span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Alcohol Value Verified </span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit && this.state.Livex === false === true ? 'hidden ' : 'block')}>&nbsp;{this.state.Products.abv_is_verified
                                            === null || this.state.Products.abv_is_verified === false ? 'No' : 'Yes'}</p>
                                            <span className={' w-52  block shipping-country status-select  ' + (this.state.Edit === true && this.state.Livex === false ? 'block' : 'hidden')}><Select
                                                value={this.state.ABV}
                                                onChange={this.handleABV}
                                                options={this.state.Dutypaid} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Livex Index</span></td>
                                        <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor '} >{this.state.Products.livex_index}</p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={'Price_detail w-11/12 m-auto  ' + (this.state.Title === 4 ? 'flex' : 'hidden')}>
                    <div className='w-1/2 '>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Market price</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.price_market === null || this.state.Products.price_market === '' ? '' : CurrencyFormat(this.state.Products.price_market)}</p>
                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_MARKET' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.price_market} onInput={this.handleUpdate} maxLength={this.state.Float['SM-US']} /></span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Offer price *</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{CurrencyFormat(this.state.Products.price_offer)}</p>
                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_OFFER' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.price_offer} onInput={this.handleUpdate} maxLength={this.state.Float['SM-US']} required={this.state.Title === 4 ? true : false} /></span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Price Online *</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'block ' : 'block')}>{CurrencyFormat(this.state.Products.price_online)}</p>
                            {/* <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_ONLINE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.price_online} onInput={this.handleUpdate} maxLength={this.state.Float['SM-US']} required={this.state.Title === 4 ? true : false} />
                            </span> */}
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Last trade price</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{CurrencyFormat(this.state.Products.price_last_trade)}</p>
                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_LAST_TRADE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.price_last_trade} onInput={this.handleUpdate} maxLength={this.state.Float['SM-US']} /></span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Last trade date</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.last_trade_date !== null && this.state.Products.last_trade_date !== '' ? convertDatetime(this.state.Products.last_trade_date) : ''}</p>
                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='date' id='PRICE_LAST_TRADE_DATE' data-date="mm/dd/yyyy" data-date-format={this.state.FORMAT} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40 active' defaultValue={this.convertDateYear(this.state.Products.date_modified)} onInput={this.handleUpdate} /></span>
                        </div>

                    </div>
                    <div className='w-1/2'>
                        {/* <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Bid</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.price_bid}</p>
                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='text' id='PRICE_BID' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.price_bid} onInput={this.handleUpdate} maxLength={this.state.varchar['MD']} /></span>
                        </div> */}
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Markup *</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.price_markup_percentage}{this.state.Percent}</p>
                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full text-bluecolor' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='number' id='PRICE_MARKUP_PERCENTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.price_markup_percentage} onInput={this.handleUpdate} min='0' max='100' pattern={this.state.Title === 4 ? '[+-]?([0-9]*[.])?[0-9]+' : undefined} required={this.state.Title === 4 ? true : false} /> &nbsp; &nbsp;{this.state.Percent}</span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Estimate Duty *</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'block ' : 'block')}>{CurrencyFormat(this.state.Products.cart_duty)}</p>

                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Buffer Value</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true ? 'hidden ' : 'block')}>{this.state.Products.buffer_item_value}</p>
                            <span className={this.state.Edit === true ? ' w-full text-bluecolor' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='number' id='BUFFER_ITEM_VALUE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.buffer_item_value} onInput={this.handleUpdate} min='0' max='100' pattern={this.state.Title === 4 ? '[+-]?([0-9]*[.])?[0-9]+' : undefined} /> &nbsp; &nbsp;</span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Buffer Percentage</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true ? 'hidden ' : 'block')}>{this.state.Products.buffer_markup_percentage}&nbsp;{this.state.Products.buffer_markup_percentage === null ? '' : " %"}</p>
                            <span className={this.state.Edit === true ? ' w-full text-bluecolor' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='number' id='BUFFER_MARKUP_PERCENTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.buffer_markup_percentage} onInput={this.handleUpdate} min='0' max='100' pattern={this.state.Title === 4 ? '[+-]?([0-9]*[.])?[0-9]+' : undefined} /> &nbsp; &nbsp;{this.state.Percent}</span>
                        </div>
                        {/* <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Discount</span>
                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true ? 'hidden ' : 'block')}>{this.state.Products.price_discount_percentage}{this.state.Percent}</p>
                            <span className={this.state.Edit === true ? ' w-full text-bluecolor' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='number' id='PRICE_DISCOUNT_PERCENTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' defaultValue={this.state.Products.price_discount_percentage} onInput={this.handleUpdate} maxLength={this.state.Float['XS-US']}  /> &nbsp; &nbsp;{this.state.Percent}</span>
                        </div> */}
                    </div>
                </div>
                <div className={'Product-condition ' + (this.state.Title === 3 ? 'block' : 'hidden')}>
                    <div className='grid xl:grid-cols-2 grid-cols-1 xl:gap-4 w-11/12 m-auto'>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor  '>Contract Type *</span></td>
                                        <td className='py-2'>
                                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Products.product_contract_type === 'X' ? '' : 'hidden ') + (this.state.Edit === true && this.state.Livex === false ? ' hidden ' : '  ')}>&nbsp;Special {this.state.Products.product_duty_paid === true ? '(DP)' : '(SIB)'}</p>
                                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Products.product_contract_type === 'SIB' ? '' : 'hidden ') + (this.state.Edit === true && this.state.Livex === false ? ' hidden ' : '  ')}>&nbsp;In-Bond</p>
                                            <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Products.product_contract_type === 'SEP' ? '' : 'hidden ') + (this.state.Edit === true ? ' hidden ' : '  ')}>&nbsp;En-Premier</p>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font   ' + (this.state.Edit === true && this.state.Livex === false ? 'block' : 'hidden')}> <Select
                                                value={this.state.ContractType}
                                                onChange={this.handleContract}
                                                options={this.state.Contracttype} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor '>Duty Paid *</span></td>
                                        <td className='py-2'> <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>&nbsp;{this.state.Products.product_duty_paid === true ? "Yes" : 'No'}</p>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font   ' + (this.state.Edit === true && this.state.Livex === false ? 'block' : 'hidden')}><Select
                                                value={this.state.DutyPaid}
                                                onChange={this.handleDutypaid}
                                                options={this.state.ContractType?.Duty} isSearchable={false}
                                            /></span></td>
                                    </tr>

                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor  '>Available Quantity</span></td>
                                        <td className='py-2 flex'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>&nbsp;{this.state.Products.quantity_available}</p>
                                            <div className='flex right-2 relative'>
                                                <span className='my-auto relative left-4 cursor-pointer ' onClick={this.Decrement}>  <img src='/static/Icons/minus.svg' alt='' className={(this.state.Edit === true && this.state.Livex === false ? 'block ' : 'hidden')} /></span>
                                                <span className={this.state.Edit === true && this.state.Livex === false ? ' w-max ' : 'hidden'}><input type='number' id='QUANTITY_AVAILABLE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md text-center focus:border-redcolor w-16 px-4' defaultValue={this.state.Products.quantity_available} onChange={this.handleUpdate} maxLength={this.state.Int['SM-US']} /></span>
                                                <span className='my-auto relative right-4 cursor-pointer' onClick={this.Increment}><img src='/static/Icons/plus.svg' alt='' className={(this.state.Edit === true && this.state.Livex === false ? 'block ' : 'hidden')} /></span></div></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'>Product Condition</span></td>
                                        <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.product_condition}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full relative  ' : 'hidden'}><input type='text' id='PRODUCT_CONDITION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.product_condition} onChange={this.handleUpdate} maxLength={this.state.varchar['2X']} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'>   <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Tightest Spread</span></td>
                                        <td className='py-2'> <span className='cursor-pointer ' onClick={(e) => this.handleToggle(e)}><input type="checkbox" id={(this.state.Edit === true && this.state.Livex === false ? 'Tightest' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white  " + (this.state.Edit === true && this.state.Livex === false ? 'cursor-pointer' : '')} defaultValue={this.state.Tightest} checked={this.state.Tightest} readOnly={!this.state.Edit} /></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-full">
                                <tbody>

                                    <tr>
                                        <td className='w-48 py-2'>  <span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'>Delivery Period *</span></td>
                                        <td className='py-2'>  <p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden ' : 'block')}>{this.state.Products.product_delivery_period}&nbsp;&nbsp;{this.state.Products.product_delivery_period === null || this.state.Products.product_delivery_period === '' ? '' : 'days'}</p>
                                            <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full font-OpenSans   text-bluecolor  ' : 'hidden'}><input type='number' id='PRODUCT_DELIVERY_PERIOD' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' required={this.state.Title === 3 ? true : false} defaultValue={this.state.Products.product_delivery_period} onChange={this.handleUpdate} min='0' />&nbsp;&nbsp;days</span></td>

                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'> Lead Time Minimum *</span></td>
                                        <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'block ' : 'block')}>{this.state.Products.lead_time_min}</p>
                                            {/* <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full relative  ' : 'hidden'}><input type='number' id='LEAD_TIME_MIN' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.lead_time_min} required={this.state.Title === 3 ? true : false} onChange={this.handleUpdate} max={this.state.Products.lead_time_max} min='0' /></span> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'> Lead Time Maximum *</span></td>
                                        <td className='py-2'><p className={'font-OpenSans font-semibold  text-sm text-bluecolor ' + (this.state.Edit === true && this.state.Livex === false ? 'block ' : 'block')}>{this.state.Products.lead_time_max}</p>
                                            {/* <span className={this.state.Edit === true && this.state.Livex === false ? ' w-full relative  ' : 'hidden'}><input type='number' id='LEAD_TIME_MAX' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' defaultValue={this.state.Products.lead_time_max} required={this.state.Title === 3 ? true : false} onChange={this.handleUpdate} min='0' /></span> */}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
};

export default ProductDetails;