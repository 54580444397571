import React, { Component } from 'react';
import local from '../../global/js/locale';
import Select from 'react-select'
import { CgSearch } from 'react-icons/cg';
import store from './../../Store/Store'
import { Link } from 'react-router-dom';
class ProductFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Record: '',
            inactive: props.Inactive,
            color: props.Color,
            vintage: props.Vintage,
            stock: props.Stock,
            contract: props.Contract,
            supplier: props.Supplier,
            CountryList: props.Countrylist,
            Countrylist: [],
            LWIN18: '',
            name: '',
            filter: false,
            selectedOption: { value: "", id: 3, label: "Any" },
            StockOption: { value: "", id: 3, label: "Any" },
            selectedweb: { value: "", id: 3, label: "Any" },
            selectedContract: { value: "", id: 3, label: "Any" },
            varchar: local.VARCHAR,
            Int: local.INT,
            Updates: props.Update,
            update: store.getState().urlappend
        }
        this.handleForm = this.handleForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.HandleLoad = this.HandleLoad.bind(this);
        this.handleContract = this.handleContract.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Update !== this.state.Updates) {
            if (window.location.search === '') {

                var active = { value: "false", id: 3, label: "No" }
                var stock = { "value": "InStock", "id": 2, "label": "In Stock" }
                this.setState({ selectedOption: active });
                this.setState({ StockOption: stock });
            }
        }
        if (nextProps.Filter !== this.state.Filtervalue) {
            console.log('filter', nextProps.Filter)
            if (nextProps.Filter == true) {
                this._isMounted && this.handleCheck();
                this._isMounted && this.HandleLoad();
            }
        }

    }
    /**
* @description The function executed for before the component call
*/
    HandleLoad() {
        var update = this.state.update;
        let params = new URLSearchParams(document.location.search)
        // The search param not empty convert the serach parammeter to object
        if (window.location.search != null && window.location.search != '') {
            var search = window.location.search.substring(1);
            update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            if (update.dutypaid != '' && update.dutypaid != null) {
                var obj = this.state.inactive.find(item => item.value === update.dutypaid)
                console.log(update.dutypaid)
                this._isMounted && this.setState({ selectedOption: obj });
            }
            if (update.contract != '' && update.contract != null) {
                var obj = this.state.contract.find(item => item.value === update.contract)
                console.log(update.contract)
                this._isMounted && this.setState({ selectedContract: obj });
            }
            if (update.stock != '' && update.stock != null) {
                var obj = this.state.stock.find(item => item.value === update.stock)
                this._isMounted && this.setState({ StockOption: obj });
            }
            if (update.display_in_web != '' && update.display_in_web != null) {
                var obj = this.state.inactive.find(item => item.value === update.display_in_web)
                this._isMounted && this.setState({ selectedweb: obj });
            }
        }
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {

        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.HandleLoad();

    }
    /**
* @description The function executed for change the inactive state
*/
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        var update = store.getState().urlappend;
        update['dutypaid'] = selectedOption.value
    };
    /**
* @description The function executed for change the Display in website state
*/
    handleWeb = selectedweb => {
        this.setState({ selectedweb });
        console.log(`Option selected:`, selectedweb);
        var update = store.getState().urlappend;
        update['display_in_web'] = selectedweb.value
    };
    /**
* @description The function executed for change the contract of website
*/
    handleContract = selectedContract => {
        this.setState({ selectedContract });
        console.log(`Option selected:`, selectedContract);
        var update = store.getState().urlappend;
        update['contract'] = selectedContract.value
    };
    /**
* @description The function executed for exists the components
*/
    componentWillUnmount() {
        this._isMounted = false;
    }
    /**
* @description The function executed for change the stock of product
*/
    handleStock = StockOption => {
        this.setState({ StockOption });
        console.log(`Option selected:`, StockOption);
        var update = store.getState().urlappend;
        update['stock'] = StockOption.value
    };
    /**
* @description The function executed for format the country from json
*/
    async handleCheck() {
        var countrylist = this.state.CountryList
        var array = [];
        Object.keys(countrylist).forEach(function (key) {
            array.push({
                value: countrylist[key].country,
                label: countrylist[key].country,
                id: countrylist[key].itemid,
            });
            array.sort((a, b) =>
                a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0
            );
        });
        this._isMounted && this.setState({ Countrylist: [...this.state.Countrylist, ...array] })
        this._isMounted && store.subscribe(() => {
            this._isMounted && this.setState({ update: store.getState().urlappend })
            if (store.getState().urlappend) {
                //the sync with netsute is not empty set the select box value
                if (store.getState().urlappend.dutypaid == '' || store.getState().urlappend.dutypaid == undefined) {
                    var selectedOption = { value: "", id: 3, label: "Any" }
                    this._isMounted && this.setState({ selectedOption: selectedOption });
                }
            }
            // set the value of the contract from the store
            if (store.getState().urlappend) {
                if (store.getState().urlappend.contract == '' || store.getState().urlappend.contract == undefined) {
                    var selectedContract = { value: "", id: 3, label: "Any" }
                    this._isMounted && this.setState({ selectedContract: selectedContract });
                }
            }
            // set the value of the status from the store
            if (store.getState().urlappend) {
                if (store.getState().urlappend.stock == '' || store.getState().urlappend.stock == undefined) {
                    var StockOption = { value: "", id: 3, label: "Any" }
                    this._isMounted && this.setState({ StockOption: StockOption });
                }
            }
            if (store.getState().urlappend) {
                if (store.getState().urlappend.display_in_web == '' || store.getState().urlappend.display_in_web == undefined) {
                    var selectedweb = { value: "", id: 3, label: "Any" }
                    this._isMounted && this.setState({ selectedweb: selectedweb });
                }
            }
        })

    }
    /**
* @description The function executed get the changed value and store as object with id as key
*/
    handleForm(e) {
        var update = store.getState().urlappend;
        var key = e.target.name;
        var value = e.target.value;
        update[key] = value
        this.setState({ update: update });
        store.dispatch({ type: 'urlappend', Urlappend: update })
    }
    /**
* @description The function executed for submit the form
*/
    handleSubmit() {
        var update = store.getState().urlappend;
        update['page'] = 1;
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.history.push({
            pathname: '/product',
            search: "?" + str.toString()
        })
        this.props.passToParent(true)
        setTimeout(
            function () {
                this.props.passToParent(false)
            }
                .bind(this),
            3000
        );

    }
    /**
* @description The function executed for filters of the section
*/
    handleFilter() {
        this.setState({ filter: !this.state.filter });
        // this.props.passToParent(this.state.filter)
    }

    render() {
        return (
            <div className='filter-order'>
                <div className='flex '>
                    <div className='w-full'>
                        <button className='filter-btn bg-white   py-1 w-32 px-4  shadow-shadowblack  flex' onClick={() => this.handleFilter()}>
                            <span className='font-OpenSans font-semibold text-left text-base text-bluecolor '>Filters</span>
                            <img src='/static/Icons/filter-icon.svg' alt='' className={'my-auto ml-auto transform  ' + (this.state.filter === true ? '' : ' rotate-180')} />
                        </button>
                    </div>
                    <div className='my-auto relative'>
                        {/* <Link to='/addproduct' className='flex bg-white w-44 rounded-md  pl-3 pr-3 h-7 bg-redcolor '><img src='/static/Icons/productadd.svg' alt='add-product' className='w-3 h-3  my-auto' /> <span className=' font-OpenSans font-normal  text-sm my-auto ml-1 text-white '>&nbsp;&nbsp;Add New Product</span>
                        </Link> */}
                    </div>
                </div>
                {/* <button className='filter-btn bg-white   py-1 w-32 px-4  shadow-shadowblack  flex' onClick={() => this.handleFilter()}>
                    <span className='font-OpenSans font-semibold text-left text-base text-bluecolor '>Filters</span>
                    <img src='/static/Icons/filter-icon.svg' alt='' className={'my-auto ml-auto transform  ' + (this.state.filter === true ? '' : ' rotate-180')} />
                </button> */}
                <img src='/static/Icons/filter.svg' alt='' className={'' + (this.state.filter === true ? 'block' : 'hidden')} />
                <div className={'bg-white bottom-3 relative shadow-shadowblack filter-main ' + (this.state.filter === true ? 'block' : 'hidden')}>
                    <div className='xl:px-10 px-5 py-7'>
                        <div className='flex '>

                            {/* The Name field */}
                            <div className='xl:w-3/12 w-1/2'>
                                <div className='xl:w-10/12 w-10/12'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Keyword</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm     font-semibold" name='name' id="name"
                                            placeholder="Name" onChange={this.handleForm} defaultValue={this.state.update.name} maxLength={this.state.varchar['SM']}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* The LWIN 18 field */}
                            <div className='xl:w-3/12 w-1/2'>
                                <div className='xl:w-10/12 w-10/12'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>SKU</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold" name='SKU' id='SKU'
                                            placeholder="SKU" onChange={this.handleForm} defaultValue={this.state.update.SKU} maxLength={this.state.varchar['SM']}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* The desktop Inactive field */}
                            <div className=' xl:w-3/12 w-4/12 xl:block'>
                                <div className='xl:w-11/12 w-10/12 '>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Vintage</span>
                                    <div className=' w-full'>
                                        <input type='number' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold" name='vintage' id='vintage'
                                            placeholder="Vintage" onChange={this.handleForm} defaultValue={this.state.update.vintage} maxLength="4"
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className=' xl:w-2/12 w-4/12 xl:block xl:block hidden'>
                                <div className='xl:w-11/12 w-10/12 '>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Geography</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold" name='geograph' id='geograph'
                                            placeholder="Geography" onChange={this.handleForm} defaultValue={this.state.update.geograph} maxLength={this.state.varchar['SM']}
                                        />
                                    </div>
                                </div>

                            </div>
                            {/* The desktop view of Supplier */}
                            <div className=' w-3/12 ml-auto xl:block hidden'>
                                <div className='w-11/12 ml-auto status-select'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Supplier</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold" name='supplier' id="supplier"
                                            placeholder="Supplier" onChange={this.handleForm} defaultValue={this.state.update.supplier} maxLength={this.state.varchar['SM']}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tab view of the Geograph,Inactive and color field */}
                        <div className='w-full py-2 pt-8 flex xl:hidden'>
                            <div className=' w-4/12 '>
                                <div className='xl:w-11/12 w-10/12 xl:hidden block'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Geography</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold" name='geograph' id='geograph'
                                            placeholder="Geography" onChange={this.handleForm} defaultValue={this.state.update.geograph} maxLength={this.state.varchar['SM']}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='w-4/12   xl:hidden block mr-1 '>
                                <div className=' w-11/12  mr-auto'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Supplier</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold" name='supplier' id="supplier"
                                            placeholder="Supplier" onChange={this.handleForm} defaultValue={this.state.update.supplier} maxLength={this.state.varchar['SM']}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='w-4/12 xl:hidden block'>
                                <div className=' w-10/12  mr-auto'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Contract Type</span>
                                    <div className=' w-full status-select'>
                                        <Select
                                            value={this.state.selectedContract}
                                            onChange={this.handleContract}
                                            options={this.state.contract} isSearchable={false}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='w-full py-7 xl:flex'>
                            <div className='xl:w-full w-full flex'>
                                {/* The desktop view of the Color field */}
                                <div className='w-4/12 xl:block hidden'>
                                    <div className='xl:w-9/12 w-10/12'>
                                        <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Contract Type</span>
                                        <div className=' w-full status-select'>
                                            <Select
                                                value={this.state.selectedContract}
                                                onChange={this.handleContract}
                                                options={this.state.contract} isSearchable={false}
                                            />
                                        </div>


                                    </div>
                                </div>
                                {/* The Vintage field */}
                                <div className='w-4/12'>
                                    <div className='xl:w-9/12 w-10/12'>
                                        <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>DutyPaid</span>
                                        <div className=' w-full status-select'>
                                            <Select
                                                value={this.state.selectedOption}
                                                onChange={this.handleChange}
                                                options={this.state.inactive} isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='w-4/12'>
                                    <div className='xl:w-9/12 w-10/12'>
                                        <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Stock</span>
                                        <div className=' w-full status-select'>
                                            <Select
                                                value={this.state.StockOption}
                                                onChange={this.handleStock}
                                                options={this.state.stock} isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* The Show in website field */}
                                <div className='w-4/12'>
                                    <div className='xl:w-9/12 w-10/12'>
                                        <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Show in website</span>
                                        <div className=' w-full status-select'>
                                            <Select
                                                value={this.state.selectedweb}
                                                onChange={this.handleWeb}
                                                options={this.state.inactive} isSearchable={false}
                                            />
                                        </div>


                                    </div>
                                </div>

                            </div>
                            <div className=' xl:w-2/7 w-4/12 mt-5  ml-auto '>
                                <div className='ml-auto xl:ml-auto my-auto w-max'>
                                    <button className='text-white bg-redcolor flex mx-auto px-6 py-1 rounded-lg ' onClick={() => this.handleSubmit()}><span className='text-base font-OpenSans font-normal'>Apply Filter</span><span className='text-white my-auto ml-3'><CgSearch /></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};

export default ProductFilter;