import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import Customerdata from '../../data/Customer.json'
import store from '../../Store/Store';
import Country from './../../data/Country.json'
import { Link } from 'react-router-dom';
import Select from 'react-select'
import CountryCode from './../../data/countrycode.json'
import { getCookie } from './../../global/js/browserfunction'
import { fetchCustomerInformationAPI, updateCustomerAddressAPI } from './../../API/Customer'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
import Loader from './../../components/Loader/FullLoader'
class CustomerEditAddress extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            Edit: store.getState().edit,
            CurrentCustomer: '',
            Id: props.match.params.id,
            varchar: local.VARCHAR,
            Customerlist: Customerdata.CusomerList,
            Int: local.INT,
            customer: null,
            selectedOption: { value: "Active", label: 'Active', id: 1 },
            Currency: local.locale.CURRENCY.SYMBOL,
            CurrentAddress: {},
            CountryList: Country.CountryList,
            Countrylist: [],
            Country: {},
            Codes: [],
            code: '',
            phonenumber: '',
            defualt_billing: '',
            defualt_shipping: '',
            is_residential: '',
            update: {},
            customerdetails: '',
            orderlist: [],
            cartlist: [],
            customeraddresslist: '',
            loading: false,
        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.HandleEdit = this.HandleEdit.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleItem = this.handleItem.bind(this);
        this.Handleaddress = this.Handleaddress.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.handleListcustomer = this.handleListcustomer.bind(this);
        this.HandleString = this.HandleString.bind(this);
        this.HandleChecking = this.HandleChecking.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.HandleconvertCode = this.HandleconvertCode.bind(this);
    }
    GoToPage(path) {
        this.props.history.push(path);
    }
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
        var countrylist = this.state.CountryList
        var array = [];
        Object.keys(countrylist).forEach(function (key) {
            array.push({
                value: countrylist[key].country,
                label: countrylist[key].country,
                id: countrylist[key].itemid,
                ISO: countrylist[key].countryISO,
            });
            array.sort((a, b) =>
                a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0
            );
        });
        this.setState({ Countrylist: [...this.state.Countrylist, ...array] })
    }
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        let params = new URLSearchParams(document.location.search)
        let customer = parseInt(params.get("customer"));
        this.setState({ Codes: CountryCode.CountryCode });
        var update = {}
        store.dispatch({ type: 'Update', Update: update })
        //to get the id of the customer
        if (params.get("customer")) {
            this.setState({ customer: customer });
            this.handleListcustomer(customer)
        }
        if (params.get("edit")) {
            var edit;
            if (params.get("edit") === 'true') {
                store.dispatch({ type: 'EditOrder', edits: true })
            } else {
                store.dispatch({ type: 'EditOrder', edits: false })
            }
        }
        var id = this.state.Id
        console.log(id)

        //get the customer from the list

    }
    /**
* @description The function executed get the customer details based on the customer id
*@param id-order id
*/
    handleListcustomer(id) {
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "RECORD_ROW_ID": id
            }
            query = this.HandleString(query)
            var details = [
                "record_row_id",
            ];
            var orderdetails = [
                "record_row_id",
            ]
            var cartdetails = [
                "product_row_id",
            ];
            var customerdetails = [
                "record_row_id",
                "customer_row_id",
                "label",
                "default_billing",
                "default_shipping",
                "is_residential",
                "attention",
                "addressee",
                "landmark",
                "address_line_1",
                "address_line_2",
                "address_line_3",
                "city",
                "state",
                "zip",
                "phone",
                "country_code",
                "country_name",

            ];
            //api for order information
            self.setState({ loading: true })
            console.log(query)
            fetchCustomerInformationAPI(query, details, orderdetails, cartdetails, customerdetails)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.fetchCustomerInformation?.summary?.reason;
                    let status = res?.data?.fetchCustomerInformation?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var customerdetails = res.data.fetchCustomerInformation.details;
                        var orderlist = res.data.fetchCustomerInformation.details.order_history;
                        var cartlist = res.data.fetchCustomerInformation.details.cart;
                        var customeraddresslist = res.data.fetchCustomerInformation.details.customer_address;
                        customeraddresslist = customeraddresslist.find(item => item.record_row_id === parseInt(self.state.Id))
                        self.setState({ customerdetails: customerdetails })
                        self.setState({ orderlist: orderlist })
                        self.setState({ cartlist: cartlist })
                        self.setState({ customeraddresslist: customeraddresslist })
                        self.setState({ loading: false })
                        self.HandleChecking()
                    } else {

                        // self.props.history.push("/customer");
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            this.GoToPage("/");
        }

    }
    /**
* @description The function executed for convert phone number
*@param input-phone number
*/
    Handleconvert(phone) {
        if (phone != undefined) {
            return phone.substr(phone.indexOf(" ") + 1)
        }

    }
    /** 
     * * @description The function executed for convert phone number to code
    *@param input-phone number
    */
    HandleconvertCode(phone) {
        if (phone != undefined) {
            return phone.substr(0, phone.indexOf(" "));
        }

    }
    /**
        /**
    * @description The function executed for check the address have selected and also check the country and phone number
    */
    HandleChecking() {
        var customeraddress = this.state.customeraddresslist;
        var Country = this.state.Countrylist.find(item => item.value === customeraddress.country_name);
        this.setState({ Country: Country });
        var codes = this.HandleconvertCode(customeraddress.phone)
        var code = this.state.Codes.find(item => item.code === codes);
        this.setState({ code: code });
        console.log(customeraddress)
        this.setState({ defualt_billing: customeraddress.default_billing });
        this.setState({ defualt_shipping: customeraddress.default_shipping });
        this.setState({ is_residential: customeraddress.is_residential });
        console.log(customeraddress.default_billing)

    }
    /**
* @description The function executed for cancel the edit
*/
    HandleString(newupdate) {

        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            PHONE: "PHONE",
            LABEL: "LABEL",
            ATTENTION: "ATTENTION",
            LANDMARK: "LANDMARK",
            ADDRESS_1: "ADDRESS_1",
            ADDRESS_2: "ADDRESS_2",
            ADDRESS_3: "ADDRESS_3",
            CITY: "CITY",
            STATE: "STATE",
            ZIP: "ZIP",
            COUNTRY_CODE: "COUNTRY_CODE",
            COUNTRY_NAME: "COUNTRY_NAME",

        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", DEFAULT_SHIPPING: "DEFAULT_SHIPPING", RECORD_ID: "RECORD_ID", ADDRESS_ID: "ADDRESS_ID", DEFAULT_BILLING: "DEFAULT_BILLING", IS_RESIDENTIAL: "IS_RESIDENTIAL" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    /**
* @description The function executed for cancel the edit of the page
*/
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
        var edit = !this.state.Edit;
        if (edit === false) {
            var update = {}
            store.dispatch({ type: 'Update', Update: update })
            var customer = this.state.customer
            this.handleListcustomer(customer)

        }

    }
    /**
* @description The function executed for get the updates of the input box
*@param e-event of the input box
*/
    handleUpdate(e) {
        var key = e.target.id;
        var value = e.target.value;
        var update = store.getState().update
        if (key == 'PHONE') {
            update['PHONE'] = this.state.code.code + ' ' + value
        } else {
            update[key] = value;
        }
        store.dispatch({ type: 'Update', Update: update })
        console.log(update)


    }
    /**
* @description The function executed for set the country from select box
*/
    handleCountry = selectedOption => {
        this.setState({ Country: selectedOption });
        var update = store.getState().update;
        update['COUNTRY_NAME'] = selectedOption.value;
        update['COUNTRY_CODE'] = selectedOption.ISO;
        console.log(selectedOption)
        this.setState({ update: update });
        store.dispatch({ type: 'Update', Update: update })
        console.log(update)

    };
    /**
* @description The function executed for set the mobile number in select box
*/
    handleCode = selectedOption => {
        this.setState({ code: selectedOption });
        var update = store.getState().update;
        let phone = document.getElementById("PHONE").value
        let phonenumber = selectedOption.code + ' ' + phone
        update['PHONE'] = phonenumber;
        this.setState({ update: update });
        store.dispatch({ type: 'Update', Update: update })
        console.log(update)

    };
    handleItem(id) {
        this.setState({ Item: id })
    }
    /**
* @description The function executed for change the state of the defualt billing and shipping of the toggle button
*/
    Handleaddress(index) {
        var update = store.getState().update;
        if (index === 'billing') {
            this.setState({ defualt_billing: !this.state.defualt_billing })
            update['DEFAULT_BILLING'] = JSON.stringify(!this.state.defualt_billing)
            update['DEFAULT_SHIPPING'] = JSON.stringify(this.state.defualt_shipping)
        } else if (index === 'shipping') {
            this.setState({ defualt_shipping: !this.state.defualt_shipping })
            update['DEFAULT_BILLING'] = JSON.stringify(this.state.defualt_billing)
            update['DEFAULT_SHIPPING'] = JSON.stringify(!this.state.defualt_shipping)
        }
        else if (index == 'Residential') {
            this.setState({ is_residential: !this.state.is_residential })
            update['IS_RESIDENTIAL'] = JSON.stringify(!this.state.is_residential)
        }
        console.log(update)
        store.dispatch({ type: 'Update', Update: update })
    }
    /**
* @description The function executed for submit change from form
*/
    HandleSubmit(e) {
        e.preventDefault();
        var id = parseInt(this.state.Id);
        let params = new URLSearchParams(document.location.search)
        var update = store.getState().update;
        console.log(update)
        let customer = parseInt(params.get("customer"));
        this.setState({ customer: customer });
        update['RECORD_ID'] = customer
        update['ADDRESS_ID'] = this.state.Id
        var self = this;
        var email;
        var token;
        var query
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            update['EMAIL'] = email
            update['TOKEN'] = token
            query = this.HandleString(update)
            var details = [
                "email",

            ];
            console.log(query)
            //api for order information
            self.setState({ loading: true })

            updateCustomerAddressAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.updateCustomerAddress?.summary?.reason;
                    let status = res?.data?.updateCustomerAddress?.summary?.status;
                    if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                        let params = new URLSearchParams(document.location.search)
                        if (params.get("customer")) {
                            let customer = parseInt(params.get("customer"));
                            self.setState({ customer: customer });
                            self.handleListcustomer(customer)
                        }
                        showNotification("Updated Address", "success");
                        self.setState({ loading: false })
                    } else {
                        showNotification("Cannot Update Address", "danger");
                        // self.props.history.push("/product?page=1");
                        self.setState({ loading: false })
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            this.GoToPage("/");
        }
    }
    render() {
        return (
            < div className={'Customer-main xl:h-full h-screen '} >
                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    <ReactNotification />
                    <div className='w-3/4'>
                        < Breadthcrumbs Breadthcrumb={'Address'} />
                    </div>
                    <Link to={'/customer/customerdetails/' + (this.state.customer)} className='flex bg-white w-60 rounded-xl mt-2 mr-5 ml-auto border pl-2 h-8 my-auto'><img src='/static/Icons/target.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Customer details</span>
                    </Link>
                </div>
                {this.state.loading === true ?
                    <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                        <Loader loading={this.state.loading} />
                    </div> :
                    <div className={'order-content px-5 py-2 ' + (this.state.loading === true ? 'hidden' : '')}>
                        <form onSubmit={this.HandleSubmit}>
                            <div className='bg-white py-6 px-5 shadow-shadowblack mb-10'>
                                <div className='flex '>
                                    <div className='my-auto'>
                                        <h1 className='font-OpenSans  font-semibold text-bluecolor xl:text-2xl text-xl  ml-4'>Edit Address</h1>
                                    </div>
                                    <div className='action w-max ml-auto my-auto'>
                                        <div className={'buttons w-max flex ml-auto h-8 ' + (this.state.Edit == true ? '' : 'hidden')}>
                                            <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                                <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                                    <div className='flex'>
                                                        <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                                        <span className='ml-2 mx-auto'>Save</span>
                                                    </div>
                                                </button>
                                            </div>
                                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer ' onClick={() => this.HandleEdit()}> Cancel</span>
                                        </div>
                                        <div className={'buttons w-max flex ml-auto mr-5 ' + (this.state.Edit == true ? 'hidden' : '')}>
                                            <img src='/static/Icons/edit.svg' alt='' className='w-5 cursor-pointer ' onClick={() => this.HandleEdit()} />
                                        </div>
                                    </div>
                                </div>
                                <hr className='my-5 border '></hr>
                                <div className='customer-details'>
                                    <table className="xl:w-1/2 w-full">
                                        <tbody>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right py-2'>Default Billing Address</td>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm pl-20 py-2'> <label
                                                    className={"flex items-center  w-max " + (this.state.Edit == true ? 'cursor-pointer' : 'pointer-events-none')}
                                                >
                                                    <div className="relative">
                                                        <input id="Billing" type="checkbox" className="sr-only" checked={this.state.defualt_billing === undefined || this.state.defualt_billing === null ? false : this.state.defualt_billing} readOnly />
                                                        <div className="w-10 h-4 bg-lightgrey rounded-full shadow-inner slider " onClick={() => this.Handleaddress('billing')}></div>
                                                        <div className="dot absolute w-4 h-4 bg-white rounded-full shadow left-1.5 top-0 transition"></div>
                                                    </div>
                                                </label></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Default Shipping Address</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><label
                                                    className={"flex items-center w-max cursor-pointer " + (this.state.Edit == true ? 'cursor-pointer' : 'pointer-events-none')}
                                                >
                                                    <div className="relative">
                                                        <input id="Shipping" type="checkbox" className="sr-only" checked={this.state.defualt_shipping === undefined || this.state.defualt_shipping === null ? false : this.state.defualt_shipping} readOnly />
                                                        <div className="w-10 h-4 bg-lightgrey rounded-full shadow-inner slider" onClick={() => this.Handleaddress('shipping')}></div>
                                                        <div className="dot absolute w-4 h-4 bg-white rounded-full shadow left-1.5 top-0 transition"></div>
                                                    </div>
                                                </label></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Default Residential Address</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><label
                                                    className={"flex items-center cursor-pointer w-max " + (this.state.Edit == true ? 'cursor-pointer' : 'pointer-events-none')}
                                                >
                                                    <div className="relative">
                                                        <input id="Residential" type="checkbox" className="sr-only" checked={this.state.is_residential === undefined || this.state.is_residential === null ? false : this.state.is_residential} readOnly />
                                                        <div className="w-10 h-4 bg-lightgrey rounded-full shadow-inner slider" onClick={() => this.Handleaddress('Residential')}></div>
                                                        <div className="dot absolute w-4 h-4 bg-white rounded-full shadow left-1.5 top-0 transition"></div>
                                                    </div>
                                                </label></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Label *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.label}</span> <input type='text' id='LABEL' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 py-1 focus:border-redcolor  w-64  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.label} required maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" title="space only is not allowed" /></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Attention *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.attention}</span> <input type='text' id='ATTENTION' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor  w-64  py-1 ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.attention} required maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" title="space only is not allowed" /></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Addressee</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.addressee}</span> <input type='text' id='ADDRESSEE' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor  w-64  py-1 ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.addressee} maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" title="space only is not allowed" /></td>

                                            </tr>
                                            {/* <tr className={'' + (this.state.CurrentAddress.street === null && this.state.Edit !== true ? 'hidden' : '')}>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right py-2'>Street</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2 '><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.landmark}</span> <input type='text' id='LANDMARK' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor  w-64 py-1  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.landmark} maxLength={this.state.varchar['LG-1']} /></td>

                                            </tr> */}
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Address Line 1 *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.address_line_1}</span> <input type='text' id='ADDRESS_1' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-64 py-1  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.address_line_1} required maxLength={this.state.varchar['LG-1']} pattern="\s*\S+.*" title="space only is not allowed" /></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Address Line 2 </td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.address_line_2}</span> <input type='text' id='ADDRESS_2' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-64 py-1  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.address_line_2} maxLength={this.state.varchar['LG-1']} pattern="\s*\S+.*" title="space only is not allowed" /></td>

                                            </tr>
                                            {/* <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Address Line 3</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.address_line_3}</span> <input type='text' id='ADDRESS_3' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor  w-64 py-1 ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.address_line_3} maxLength={this.state.varchar['LG']} /></td>

                                            </tr> */}
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>City *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.city}</span> <input type='text' id='CITY' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-64 py-1  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.city} required maxLength={this.state.varchar['MD']} /></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>State *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.state}</span> <input type='text' id='STATE' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-64 py-1  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.state} required maxLength={this.state.varchar['MD']} /></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Country *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'> <span className={'font-OpenSans font-semibold  text-bluecolor text-sm  ' + (this.state.Edit === true ? 'hidden' : 'block')}>{this.state.customeraddresslist.country_name}</span>
                                                    <span className={'xl:w-11/12 w-52  py-2 block shipping-country status-select address-select ' + (this.state.Edit === true ? 'block' : 'hidden')}> <Select
                                                        value={this.state.Country}
                                                        onChange={this.handleCountry}
                                                        options={this.state.Countrylist} isSearchable={false}
                                                    /></span></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Zip/Postal Code *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.zip}</span> <input type='text' id='ZIP' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor  w-64  py-1 ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customeraddresslist.zip} required maxLength={this.state.varchar['LG']} /></td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans  font-bold text-bluecolor text-sm w-52 text-right'>Phone *</td>
                                                <td className='font-OpenSans  font-semibold text-bluecolor text-sm pl-20 py-2'><span className={'' + (this.state.Edit === true ? 'hidden ' : '')}>{this.state.customeraddresslist.phone}</span><span className={'flex ' + (this.state.Edit === true ? ' ' : 'hidden')}><span className='phone-select'><Select
                                                    value={this.state.code}
                                                    onChange={this.handleCode}
                                                    options={this.state.Codes} isSearchable={false}
                                                /> </span><input type='text' id='PHONE' className={'font-OpenSans font-semibold text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-36  '} onInput={this.handleUpdate} defaultValue={this.Handleconvert(this.state.customeraddresslist.phone)} maxLength={this.state.varchar['LG']} minLength="8" maxLength="15" pattern="^(?!0*$)[0-9-]+" /></span></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </div >
        )
    }
};

export default CustomerEditAddress;