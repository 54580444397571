import React, { Component } from 'react';
import local from '../../../global/js/locale';
import Breadthcrumbs from '../../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../../Store/Store'
import { getCookie } from './../../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import '../PaymentDetail.scss'
import Loader from './../../../components/Loader/FullLoader'
import locale from '../../../global/js/locale';
class RefundAmount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Countinue: "Continue >>",
            Back: "<< Back",
            Int: locale.INT,
            Id: props.Id,
            Amounts: {},
            paymentdetails: props.paymentdetails,
            Transaction: '',
            carttotal: 0,
            Shiptotal: 0,
            Total: 0,
            RefundAmount: 0


        }
        this.handleContinue = this.handleContinue.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        console.log(this.state.paymentdetails)
        if (this.state.paymentdetails === '') {
            this.props.history.push("/payment/paymentdetails/" + this.state.Id);
        } else {
            var paymentdetail = this.state.paymentdetails;
            var transaction = paymentdetail.order_header_subtotal + paymentdetail.order_header_duty_total + paymentdetail.order_header_tax_total;
            var transactions = transaction.toFixed(2)
            var ship = paymentdetail.order_header_shipping_total.toFixed(2)
            this.setState({ Shiptotal: parseFloat(ship).toFixed(2) })
            this.setState({ carttotal: parseFloat(transactions).toFixed(2) })
            this.setState({ Total: transaction })
            this.setState({ Transaction: paymentdetail.order_header_transaction_total })
            var Amount = { TRANSACTION_TOTAL: transactions, SHIPPING_TOTAL: ship }
            this.setState({ Amounts: Amount })
        }

    }

    /**
  * @description The function executed continue the page to next
  */
    handleContinue(e) {
        e.preventDefault();
        var self = this
        let params = new URLSearchParams(document.location.search)
        if (params.has("status")) {
            let status = params.get("status")
            self.props.history.push({
                pathname: window.location.pathname,
                search: "?status=Confirm"
            })
            this.props.passToRefund()
            console.log(this.state.Amounts)
            this.props.passToAmount(this.state.Amounts)

        }
    }
    /**
* @description The function executed back to previous page
*/
    handleBack() {
        var self = this
        self.props.history.push({
            pathname: window.location.pathname

        })
        this.props.passToRefund()
    }
    /**
* @description The function executed for get the shipping and transaction total
*/
    handleChange(e) {
        var Amount = this.state.Amounts;
        var paymentdetail = this.state.paymentdetails;
        var transaction = paymentdetail.order_header_transaction_total;
        var trans = transaction;
        var key = e.target.id;
        var value = e.target.value;
        var carttotal = this.state.carttotal;
        var Shiptotal = this.state.Shiptotal;
        if (value == '') {
            value = 0;
        }
        Amount[key] = parseFloat(value).toFixed(2);
        if (key === 'TRANSACTION_TOTAL') {
            carttotal = parseFloat(value).toFixed(2)
            this.setState({ carttotal: carttotal })
        } else if (key === 'SHIPPING_TOTAL') {
            Shiptotal = parseFloat(value).toFixed(2)
            this.setState({ Shiptotal: Shiptotal })
        }
        var total = parseFloat(carttotal) + parseFloat(Shiptotal)
        trans = total;
        this.setState({ Transaction: trans })
        this.setState({ Amounts: Amount })
        var refund = transaction - trans;
        this.setState({ RefundAmount: refund })


    }
    render() {
        return (
            <div>
                <form onSubmit={this.handleContinue}>
                    <div
                        className=" grid xl:grid-cols-1 md:grid-cols-1 "
                    >
                        <h2 className='font-OpenSans font-semibold  text-redcolor xl:text-base text-base my-2 w-11/12 m-auto opacity-90'>Note that refund can only be processed once. </h2>
                        <h2 className='font-OpenSans font-semibold  text-bluecolor xl:text-base text-base my-1 w-11/12 m-auto'> Please provide revised the subtotal and shipping total amount.   </h2>
                    </div>
                    <div
                        className=" w-11/12 m-auto mt-3"
                    >
                        <div className='xl:w-1/2'>
                            <table className="w-full table-auto cart-table md:pl-5 pl-6">
                                <tbody>
                                    <tr className="border-b-2 ">
                                        <td className='   font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm mb-1 py-2'>Transaction Total</td>
                                        <td className='   font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm mb-1 w-52 py-2'>{parseFloat(this.state.Transaction).toFixed(2)}</td>
                                    </tr>
                                    {this.state.Transaction !== '' &&
                                        <tr className="">
                                            <td className=" font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm">Cart Total</td>
                                            <td className="font-semibold break-all result-text md:text-base  text-sm md:text-sm py-2 text-right pr-5 "><input type="number" id="TRANSACTION_TOTAL" className="font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52" max={this.state.Total.toFixed(2)} min='0' defaultValue={this.state.Total.toFixed(2)} required onInput={this.handleChange} /></td>

                                        </tr>
                                    }
                                    <tr className="">
                                        <td className=" font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm">Shipping Total</td>
                                        <td className="font-semibold break-all result-text md:text-base  text-sm md:text-sm py-2 text-right pr-5 "><input type="number" id="SHIPPING_TOTAL" className="font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52" max={this.state.paymentdetails.order_header_shipping_total} min='0' defaultValue={this.state.paymentdetails.order_header_shipping_total} required onInput={this.handleChange} /></td>
                                    </tr>
                                    <tr className="border-b-2 ">
                                        <td className='   font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm mb-1 py-2'>Refund Amount</td>
                                        <td className='   font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm mb-1 w-52 py-2 pl-3 '>{parseFloat(this.state.RefundAmount).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div>
                        <div className='w-full flex my-10'>
                            <div className=" my-auto">
                                <div className='w-max  ml-5'>
                                    <div className='flex items-end'>
                                        <span className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer W-1/6 flex items-end' onClick={this.handleBack}>
                                            <span className="xl:w-full w-full text-white text-md mx-4 whitespace-nowrap"> {this.state.Back} </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='w-max ml-auto mr-5'>
                                <div className='flex items-end'>
                                    <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer W-1/6 flex items-end' >
                                        <span className="xl:w-full w-full text-white text-md mx-4 whitespace-nowrap"> {this.state.Countinue} </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        )
    }
};

export default RefundAmount;