import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { listCronjobsAPI, cronJobStatusAPI } from './../../API/Cronjob'
import { eraseCookie } from './../../global/js/browserfunction'
import Update from './../../Store/Update'
import Cronjoblist from './Cronjoblist';
import dashboard from './../../data/Dashboard.json'
import Loader from './../../components/Loader/FullLoader'
class Cronjob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cronjoblist: [],
            Status: 'IMPORT',
            loading: false,
            listimport: [],
            updateall: '',
            totalcronjob: {
                "pending": 0,
                "finished": 0,
                "processing": 0,
                "failed": 0,
                "count": 0
            }

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }

        this.GoToPage = this.GoToPage.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.HandleCronJob = this.HandleCronJob.bind(this);
        this.HandleString = this.HandleString.bind(this);

    }
    /**
* @description The function executed get the serach param and convert to object
*/
    async handleCheck() {
        var str = window.location.search;
        if (str != '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            this._isMounted && this.Handlechange(update.status);
        } else {
            var state = 'import'
            var update = store.getState().urlappend;
            update['status'] = state
            update['page'] = 1
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            this._isMounted && this.Handlechange(state);
        }
        Update.subscribe(() => {
            this._isMounted && this.setState({ updateall: Update.getState().AllUpdates })
        })
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        this._isMounted && store.dispatch({ type: 'URL', url: urls })

    }
    /**
  * @description The function executed for exists the components
  */
    componentWillUnmount() {
        this._isMounted = false;
        var update = {}
        store.dispatch({ type: 'urlappend', Urlappend: update })
    }
    /**
    * @description The function executed for before the component call
    */
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.HandleCronJob();
        window.onpopstate = e => {
            this._isMounted && this.handleCheck();

        }


    }
    /**
* @description The function executed for redirect page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed change in import and  website category
*/
    Handlechange(value) {
        console.log(value)
        this.setState({ Status: value.toUpperCase() });
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        //check thee filter are true

        var update = {};

        update = store.getState().urlappend;

        //convert the value of the object in filter to compatible to api keywords
        if (update.status) {
            newupdate['TYPE'] = update.status.toUpperCase()
        }
        if (update.page) {
            newupdate['limit'] = 20
        }
        if (update.page) {
            if (parseInt(update.page) == 1) {
                newupdate['offset'] = '0'
            } else {
                newupdate['offset'] = ((parseInt(update.page)) - 1) * 20
            }

        }
        if (update.sync) {
            newupdate['SYNC_WITH_NETSUITE'] = Boolean(update.sync);
        }

        //convert the object to string also check the bolean and string
        newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});

        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
        };
        let booleanFields = { limit: "limit" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }


        this.HandleSubmit(graphQLParam)

    }
    /**
* @description The function executed for exists the components
*/
    componentWillUnmount() {
        this._isMounted = false;

    }
    /**
     * @description The function executed pass the keyword to api and get the result and pass to components
     */
    HandleSubmit(query) {

        var self = this
        self.setState({ loading: true })
        var details = [
            "record_row_id",
            "date_created",
            "date_modified",
            "is_inactive",
            "status",
            "type",
            "summary",
            "file_import",
            "admin_email",
            "admin_record_row_id"
        ];
        //api for list orders
        listCronjobsAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.listCronjobs?.summary?.reason;
                let status = res?.data?.listCronjobs?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listCronjobs.details.list;
                    let total = res.data.listCronjobs.details.totalCount;

                    self.setState({ loading: false })
                    self.setState({ listimport: data });
                    self.setState({ total: total });

                } else {
                    self.setState({ loading: false })
                    let data = []
                    self.setState({ listcustomer: data });
                    self.setState({ total: 0 });
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
* @description The function executed fetch the sales history
*/
    HandleCronJob() {
        var self = this
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
            }
            query = this.HandleString(query)
            self.setState({ loading: true })
            var details = [
                "pending",
                "finished",
                "processing",
                "failed",
                "count",
            ];
            //api for list orders
            cronJobStatusAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {

                    let reason = res?.data?.cronJobStatus?.summary?.reason;
                    let status = res?.data?.cronJobStatus?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        let totalcronjob = res.data.cronJobStatus.details;
                        self.setState({ totalcronjob: totalcronjob })
                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        }

    }
    /**
  * @description The function executed for cancel the edit
  */
    HandleString(newupdate) {
        var newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            SORT: "SORT",

        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", limit: "limit", offset: "offset" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    render() {
        return (
            < div className='Payment-main xl:h-full h-screen  ' >
                {/* Breadthcrum of the Dashboard */}
                < Breadthcrumbs Breadthcrumb={'Cron jobs'} />
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'Payment-content  py-2 ' + (this.state.loading === true ? 'hidden' : '')}>
                    <Cronjoblist cronjobs={dashboard.Cronjobs} cronstatus={this.state.totalcronjob} passToParent={this.Handlechange} loading={this.state.loading} listimports={this.state.listimport} Total={this.state.total} Update={this.state.update} updateall={this.state.updateall} history={this.props.history} Status={this.state.Status} />
                </div>
            </div >
        )
    }
};

export default Cronjob;