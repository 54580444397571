import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import DeliveryFilter from './DeliveryFilter';
import Deliverylist from './Deliverylist';
import Country from './../../data/Country.json'
import store from './../../Store/Store'
import delivery from '../../data/DeliveryCharge.json'
import Loader from '../../components/Loader/Loader'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { deliveryChargeListAPI, updateWebsiteCacheAPI } from '../../API/Delivery';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
class Delivery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CountryList: [],
            StateOption: '',
            CountryOption: '',
            StateList: [],
            Edit: store.getState().edit,
            loading: false,
            DeliveryList: [],
            DeliveryCharge: []

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleCache = this.handleCache.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
    }

    /**
        * @description The function executed for navigatinon
        * @param {path}, path to navigate
    */
    GoToPage(path) {
        this.props.history.push(path);
    }

    /**
        * @description The function executed for when component enter get the state of the value from store
    */
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })

    }

    /**
        * @description The function executed for when component changed
    */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
        * @description The function executed for before the component call
    */
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        this.Handlechange(true)
    }

    /**
        * @description  Function for API callinglisting 
        * @param {value}, true.false value for applying filter
        * @returns user list    
    */
    Handlechange(value) {
        var self = this;
        var token;
        var email;
        var newupdate = {};
        if (!getCookie("wn_log")) {//checks for empty cookies
            self.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {//checks for empty token
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token

        } else {
            self.GoToPage("/");
        }
        this.setState({ Status: value });

        //check thee filter are true
        if (value == true) {

            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                TOKEN: "TOKEN",
            };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
            }
            this.HandleSubmit(graphQLParam)
        }
    }

    /**
        * @description  Function for getting Userlist
        * @param {query}, data to be passed with API to get details
    */
    HandleSubmit(query) {
        var self = this
        var details1 = [

            "record_id",
            "min_weight",
            "max_weight",
            "price"

        ];

        var details2 = [

            "record_id",
            "min_weight",
            "max_weight",
            "price"

        ];


        deliveryChargeListAPI(query, details1, details2)//API call for User listing Delivery charges
            .then((res) => res.json())
            .then(function (res) {

                var dataArray = []
                var countries = []
                let reason = res.data.fetchDeliveryCharge.summary.reason;
                let status = res.data.fetchDeliveryCharge.summary.status;

                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.fetchDeliveryCharge.details.country_list;
                    if (data && data.length > 0) {
                        data.forEach((item, index) => {
                            dataArray.push({
                                country: item.country,
                                id: item.id,
                                states: item.states,
                                standard: item.standard_charges,
                                specialCharges: item.special_charges
                            })

                            countries.push({
                                id: item.country,
                                label: item.country,
                                value: item.country,
                                states: item.states,
                                stateslist: [],
                                standard: item.standard_charges,
                                specialCharges: item.special_charges
                            })
                        })

                        countries.forEach((item, index) => {
                            if (countries[index].states.length != 0) {
                                console.log(countries[index].specialCharges)
                                if (countries[index].specialCharges.length != 0) {
                                    countries[index].specialCharges.forEach((state, key) => {
                                        countries[index].stateslist.push({
                                            id: state.state,
                                            label: state.state,
                                            value: state.state,
                                        })
                                    })
                                    countries[index].stateslist.push({
                                        id: "Others",
                                        label: "Others",
                                        value: "Others",
                                    })
                                }

                            }
                        })
                    }

                    countries.sort((a, b) =>
                        a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0
                    );

                    self.setState({ CountryList: countries })
                    self.setState({ DeliveryList: dataArray })
                    self.setState({ loading: false })

                }
                else if (reason == "RECORD_NOT_FOUND" && status == "SUCCESS") {
                    showNotification("No data Available", "info");
                    self.setState({ loading: false })
                }
                else {
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            })
    }

    /**
        * @description  Function for updating Cache
        * @returns updates Cache
    */
    handleCache() {
        var self = this;
        var token;
        var email;
        var newupdate = {};
        if (!getCookie("wn_log")) {//checking for empty cookies
            self.props.history.push("/delivery");
        }
        if (localStorage.getItem("wn_log") !== null) {//checking  for empty token
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token

            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                TOKEN: "TOKEN",
            };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
                var details = [
                    "country"
                ];
            }
            if (!self.state.Edit) {
                updateWebsiteCacheAPI(graphQLParam, details)//API call for updating Cache
                    .then((res) => res.json())
                    .then(function (res) {
                        let reason = res.data.updateDeliveryCashe.summary.reason;
                        let status = res.data.updateDeliveryCashe.summary.status;
                        if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                            showNotification("Cache updated Successfully", "success");
                        } else {
                            showNotification("Sorry update Failed", "danger");
                        }

                    })
            }
            self.setState({ loading: false })
        } else {
            self.props.history.push("/delivery");
        }
    }

    render() {
        return (
            < div className='Payment-main xl:h-full h-screen' >
                <ReactNotification />
                {/* Breadthcrum of the Dashboard */}
                < Breadthcrumbs Breadthcrumb={'Delivery Charge'} />

                <div className={'Payment-content px-5 py-2 '}>
                    <DeliveryFilter Countrylist={this.state.CountryList} handleCache={(e) => this.handleCache(e)} Edit={this.state.Edit} />
                    <Deliverylist Countrylist={this.state.CountryList} Deliverycharge={this.state.DeliveryList} passToParent={(e) => this.Handlechange(e)} loading={this.state.loading} />
                </div>

            </div >
        )
    }
};

export default Delivery;