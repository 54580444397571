import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './User.scss'
import user from '../../data/User.json'
import order from '../../data/Order.json'
import UserFilter from './UserFilter';
import UserList from './UserList';
import { userListAPI } from '../../API/Users';
import Loader from '../../components/Loader/Loader';
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { eraseCookie } from './../../global/js/browserfunction'
import Update from './../../Store/Update'
class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterVal: '',
            userData:[],
            Status: '',
            update: {
                username: '',
                email: '',
                phone: '',
                fromdate: '',
                todate: '',
                sort: '',
                page: ''
            },
            updateall: '',
            total:'',
            loading: false,
            Filterchange: false,
        }
        this.Handlechange = this.Handlechange.bind(this);
        this.handleData = this.handleData.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.HandleFilter = this.HandleFilter.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted &&this.handleData()
    }

    
    async handleData(){
        
        Update.subscribe(() => {
            this._isMounted && this.setState({ updateall: Update.getState().AllUpdates })
        })
        
        
    }

    GoToPage(path) {
        this.props.history.push(path);
    }

    /**
        * @description The function executed for back to popup
    */
    HandleFilter(value) {
        this.setState({ Filterchange: value });
    }

    /**
        * @description  Function for API callinglisting 
        * @param {value}, true.false value for applying filter
        * @returns user list    
    */
    Handlechange(value){
        var str = window.location.search;
        if (str !== '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
        }

        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        this._isMounted && store.dispatch({ type: 'URL', url: urls })
        
        var self = this;
        var token;
        var email;
        var newupdate = {};
        if (!getCookie("wn_log")) {//checks for empty cookies
            self.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {//checks for empty token
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['SORT'] = 'Date_Created_Desc'
        } else {
            self.GoToPage("/");
        }
        this.setState({ Status: value }); 

        //check thee filter are true
        if (value == true) {
            var update = {};
            update = store.getState().urlappend;

            //convert the value of the object in filter to compatible to api keywords
            if (update.Email) {
                newupdate['USER_EMAIL'] = update.Email
            }
            if (update.UserName) {
                newupdate['USER_NAME'] = update.UserName
            }
            if (update.PhoneNumber) {
                newupdate['PHONE'] = update.PhoneNumber
            }
            if (update.sort) {
                newupdate['SORT'] = update.sort
            }
            if (update.page) {
                newupdate['limit'] = 20
            }
            if (update.page) {
                if (parseInt(update.page) == 1) {
                    newupdate['offset'] = '0'
                } else {
                    newupdate['offset'] = ((parseInt(update.page)) - 1) * 20
                }

            }

            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                TOKEN:"TOKEN",
                USER_EMAIL: "USER_EMAIL",
                USER_NAME:"USER_NAME",
                PHONE:"PHONE",
                SORT:"SORT",
            };
            let booleanFields = { limit: "limit", offset: "offset" };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
                else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
            }

            this.HandleSubmit(graphQLParam)
        }
    }

    /**
        * @description  Function for getting Userlist
        * @param {query}, data to be passed with API to get details
    */
    HandleSubmit(query){
        var self = this
        var details = [
            "record_row_id",
            "email",
            "first_name",
            "last_name",
            "date_created",
            "date_modified",
            "password",
            "have_access",
            "is_inactive",
            "last_login_date",
            "last_password_reset_date",
            "phone"
        ];
        self.setState({loading:true})
        userListAPI(query, details)//API call for User listing
        .then((res)=> res.json())
        .then(function (res) {
            
            let reason = res.data.listAllAdmin.summary.reason;
            let status = res.data.listAllAdmin.summary.status;
            if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                let data = res.data.listAllAdmin.details.list;
                let total = res.data.listAllAdmin.details.totalCount;
                self.setState({ userData: data });
                self.setState({ total: total });
                store.dispatch({ type: 'userData', userData: data })
            }
            else {
                eraseCookie('wn_log');
                localStorage.removeItem("wn_log");
                self.props.history.push("/");
            }
            self.setState({loading:false})
        })
    }

    /**
        * @description  To check whether the section for
        * @returns login success or fails
    */
        childCallback(value) {
        this.setState({ filterVal: value });
    }

    /**
        * @description The function executed for exists the components
    */
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
        return (
            < div className={'Order-main h-full'} >
                
                {/* Breadthcrum of the Dashboard */}
                < Breadthcrumbs Breadthcrumb={'User'} />
                
                <div className='order-content px-5 py-2'>
                    <UserFilter UserData={this.state.Userdata} passToParent={this.Handlechange} history={this.props.history} Filter={this.state.Filterchange}/>
                    <UserList UserList={this.state.userData} SortByFilter={user.SortByFilter} Filterval={this.state.filterVal} Total={this.state.total} history={this.props.history} passToParent={this.Handlechange} Update={this.state.update} updateall={this.state.updateall} loading={this.state.loading} passToFilter={this.HandleFilter}/>
           
                </div>
            </div >
        )
    }
};

export default User;