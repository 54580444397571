import React, { Component } from 'react';
import local from '../../../global/js/locale';
import Breadthcrumbs from '../../../components/Breadthcrumbs/Breadcrumbs'
import { getCookie } from './../../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import '../PaymentDetail.scss'
import Loader from './../../../components/Loader/FullLoader'
import RefundDetails from './RefundDetails';
import store from './../../../Store/Store'
class Refund extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Id: props.match.params.id,
        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.HandleString = this.HandleString.bind(this);
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        var url = '/payment'
        store.dispatch({ type: 'URL', url: url })

    }


    /**
    * @description The function executed for cancel the edit
    */
    HandleString(newupdate) {
        var newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            BILLING_PHONE: "BILLING_PHONE",
            BILLING_PHONE: "SHIPPING_PHONE",
        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", ORDER_HEADER_ID: "ORDER_HEADER_ID" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    render() {
        return (
            < div className='paymentDetail-main xl:h-full h-full  ' >
                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    < Breadthcrumbs Breadthcrumb={'Refund'} Count={4} level_0={'Payment'} level_1={'Payment Details'} level_2={this.state.Id} levels={true} />
                    <Link to={'/payment/paymentdetails/' + this.state.Id} className='flex bg-white w-72 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/income.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Payment Details</span>
                    </Link>
                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'payment-content px-5 py-2 ' + (this.state.loading === true ? ' ' : 'xl:block')}>
                    <RefundDetails history={this.props.history} Id={this.state.Id} />
                </div>
            </div >
        )
    }
};

export default Refund;