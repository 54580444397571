import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Login.scss'
import store from './../../Store/Store'
import { emailregex } from './../../global/js/validator'
import local from '../../global/js/locale';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Loader from "../../components/Loader/FullLoader";
import { resetPasswordAdminAPI } from './../../API/Admin'
import { setCookie, getCookie, eraseCookie } from './../../global/js/browserfunction'
class Reset extends Component {

    constructor(props) {
        super(props);
        store.dispatch({ type: 'Header', header: true })
        this.state = {
            email: '',
            valid: false,
            show: [],
            newpassword: '',
            confpassword: '',
            passwordvalid: true,
            varchar: local.VARCHAR,
            Int: local.INT,
            matchpassword: true,
            passcode: '',
            loading: false,
            errorlist: []
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.PasswordValidator = this.PasswordValidator.bind(this);
        this.Passwordmatch = this.Passwordmatch.bind(this);
        this.Gotopage = this.Gotopage.bind(this);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        eraseCookie('wn_log');
        localStorage.removeItem("wn_log");
        window.scrollTo(0, 0)
        store.dispatch({ type: 'Header', header: true })
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        let params = new URLSearchParams(document.location.search)
        if (params.get("passcode") && params.get("email")) {
            this.setState({ email: params.get("email") });
            this.setState({ passcode: params.get("passcode") });
        } else {
            var self = this;
            self.Gotopage('/login')
        }
    }
    /**
* @description The function executed for get the value password input box changes
*/
    handleChange = (e) => {
        if (e.target.id == 'new password') {
            this.setState({ newpassword: e.target.value }, function () {
                this.PasswordValidator()
            });
        }
        else {
            this.setState({ confpassword: e.target.value }, function () {
                this.Passwordmatch()
            });
        }

    };
    /**
* @description The function executed for view the passowrd
*/
    handleShow(id) {
        if (this.state.show.includes(id)) {
            let someArray = this.state.show;
            someArray = someArray.filter(person => person != id);
            this.setState({ show: someArray })
        }
        else {
            this.setState({ show: [...this.state.show, id] });
        }


    }
    /**
* @description The function executed for validate the passowrd requirements
*/
    PasswordValidator() {
        let passlen = this.state.newpassword.length;
        let p = this.state.newpassword;
        let errors = [];
        console.log(this.state.password)
        this.setState({ newpassword: this.state.newpassword.trim() })
        if (p.length < 8 && p.length < 15) {
            errors.push("Password must be between 8-15 characters");
            this.setState({ passwordvalid: false })
        }
        else if (p.search(/[a-z]/i) < 0) {
            errors.push("Your password must contain at least one letter.");
            this.setState({ passwordvalid: false })
        }
        else if (p.search(/[0-9]/) < 0) {
            errors.push("Your password must contain at least one digit.");
            this.setState({ passwordvalid: false })
        } else {
            errors = []
            this.setState({ passwordvalid: true })
        }
        console.log(errors)
        this.setState({ errorlist: errors })
        // if (passlen > 5 && passlen < 15) {
        //     this.setState({ passwordvalid: true })
        //     return false;
        // } else {
        //     this.setState({ passwordvalid: false })
        // }
    }
    /**
* @description The function executed for matching the password and confirm password
*/
    Passwordmatch() {
        if (this.state.newpassword == this.state.confpassword) {
            this.setState({ matchpassword: true })
            return false;
        } else {
            this.setState({ matchpassword: false })
        }
    }
    /**
* @description The function executed form submit
*/
    handleSubmit(e) {
        e.preventDefault();
        var self = this;
        this.Passwordmatch()
        this.PasswordValidator()
        let details = [
            "first_name"
        ];
        if (this.state.passwordvalid == true && this.state.matchpassword == true) {
            var newpassword = self.state.newpassword;
            var email = self.state.email;
            var passcode = self.state.passcode
            // api for email validate
            resetPasswordAdminAPI(email, newpassword, passcode, details)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.resetPassword?.summary?.reason;
                    let status = res?.data?.resetPassword?.summary?.status;
                    if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                        let data = res.data.resetPassword.details;
                        //when login sucess email,name,accesstoken are stored in local storage
                        localStorage.setItem("wn_log", JSON.stringify(data));
                        self.setState({ loading: false });
                        self.Gotopage('/login')
                        showNotification("Password Reseted", "success");
                    } else {
                        self.setState({ loading: false });
                        showNotification(
                            "Token Expired",
                            "danger",
                        );
                    }
                });
        }
    }
    /**
* @description The function executed for redirect the page
*/
    Gotopage(path) {
        this.props.history.push(path);
    }
    render() {
        return (
            < div className=' mt-5 Reset' >
                <ReactNotification />
                <Loader loading={this.state.loading} />
                <div className='xl:w-1/3 w-2/3 m-auto bg-white shadow-loginshadow py-20 mt-16'>
                    <h1 className='login-head xl:text-4xl text-2xl font-OpenSans font-normal text-bluecolor text-center pb-6'>Change Password</h1>

                    <form action="" className="mt-2 flex flex-col lg:w-2/3 w-8/12 m-auto" onSubmit={this.handleSubmit} autoComplete="off">
                        <div className="flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded mb-4">
                            <div className="flex -mr-px justify-center w-15 p-4 pr-2 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white   px-3 pl-0 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/password_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type={this.state.show.includes(1) === true ? 'text' : 'password'} id='new password' autoComplete="off" maxLength={this.state.varchar['2X']}
                                className="flex-shrink flex-grow input-box flex-auto text-bluecolor leading-normal w-px flex-1 border-0 h-10 px-3 relative self-center  outline-none border-b font-OpenSans text-sm"
                                placeholder="New Password" onChange={this.handleChange}
                            />
                            <div className="flex -mr-px border-b font-OpenSans h-10 my-auto">
                                <span
                                    className="flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/show_password.png' alt='' className='cursor-pointer' onClick={() => this.handleShow(1)} />
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded mb-4">
                            <div className="flex -mr-px justify-center w-15 p-4 pr-2 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white   px-3 pl-0 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/password_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type={this.state.show.includes(2) === true ? 'text' : 'password'} id='confirm password' autoComplete="off" maxLength={this.state.varchar['2X']}
                                className="flex-shrink flex-grow input-box flex-auto text-bluecolor leading-normal w-px flex-1 border-0 h-10 px-3 relative self-center  outline-none border-b font-OpenSans text-sm"
                                placeholder="Confirm Password" onChange={this.handleChange}
                            />
                            <div className="flex -mr-px border-b font-OpenSans h-10 my-auto">
                                <span
                                    className="flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/show_password.png' alt='' className='cursor-pointer' onClick={() => this.handleShow(2)} />
                                </span>
                            </div>
                        </div>
                        {this.state.errorlist.map(list =>
                            <span key={list} className={'font-OpenSans xl:text-sm text-xs text-redcolor w-max ml-auto ' + (this.state.passwordvalid === true ? 'hidden' : 'block')}>{list}</span>
                        )}
                        <span className={'font-OpenSans xl:text-sm text-xs text-redcolor w-max ml-auto ' + (this.state.matchpassword === true ? 'hidden' : 'block')}>Password Mismatch</span>
                        <button
                            type='submit'
                            className="bg-redcolor font-semibold py-4  text-center w-full text-white rounded leading-tight text-xl xl:text-lg font-OpenSans font-sans my-4 mt-12 mb-2 m-auto"
                        >
                            Change Password
                        </button>

                    </form>
                </div>
            </div >
        )
    }
};

export default Reset;