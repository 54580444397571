import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import store from './../../Store/Store'
import Update from './../../Store/Update'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import './Header.scss'

class Sidebar extends Component {

    constructor(props) {
        super(props);
        // store.dispatch({ type: 'URL', url: window.location.pathname })
        this.handleClick = this.handleClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleUrl = this.handleUrl.bind(this);
        this.dropdown = this.dropdown.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            Navlist: props.Navlist,
            menuid: store.getState().todos,
            iconmap: props.Iconmap,
            tag: '',
            categorymenuid: [],
            urlchange: store.getState().urlchange,
            locationurl: '',
            str: window.location.pathname,
            popmenu: false
        }
    }
    /**
    * @description The function executed for before the component call
    */
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        store.subscribe(() => {
            this.setState({ menuid: store.getState().todos })
            this.setState({ urlchange: store.getState().urlchange })

        })
        var self = this
        window.addEventListener('click', function (e) {
            if (document.getElementById('sidebar-main-outside')?.contains(e.target)) {
                // Clicked in box
            } else {
                if (store.getState().todos === true) {
                    if (self.state.popmenu === true) {
                        self.setState({ menuid: false })
                        store.dispatch({ type: 'ADD_TODO', todo: false })
                        var tag = document.querySelector("html");
                        tag.classList.remove("nav-navbar-open");
                        self.setState({ popmenu: false })
                    } else {
                        self.setState({ popmenu: true })
                    }

                }

            }
        });
    }
    /**
         * @description The function executed for open the sidebar when the button click
         */
    handleClick() {
        this.setState({ menuid: !this.state.menuid })
        store.dispatch({ type: 'ADD_TODO', todo: !this.state.menuid })
        var tag = document.querySelector("html");
        if (this.state.menuid == false) {
            tag.classList.add("nav-navbar-open");
        }
        else {
            tag.classList.remove("nav-navbar-open");
        }
        this.setState({ popmenu: false })
    }
    /**
     * @description The function executed for when resize the sidebar closes
     */
    handleResize() {
        this.setState({ menuid: store.getState().todos })
    }
    /**
     * @description The function executed for when the url changes store the redux store and change the color of icon
     * @param {object} url-the component link
     */
    handleUrl(url) {
        var update = store.getState().urlappend;
        if (url == '/order' || url == 'customer') {
            update['page'] = 1

        } else if (url == '/payment') {
            update['page'] = 1
            update['sort'] = 'Date_Desc'

        }
        else if (url == '/product') {
            update['page'] = 1
            update['inactive'] = "false"
            update['stock'] = "instock"
        }
        store.dispatch({ type: 'urlappend', Urlappend: update })
        Update.dispatch({ type: 'UpdatesAll', UpdatesAll: true })
        // setTimeout(
        //     function () {
        //         Update.dispatch({ type: 'UpdatesAll', UpdatesAll: false })
        //     }
        //         .bind(this),
        //     1000
        // );
        // this.setState({ menuid: false })
        // store.dispatch({ type: 'ADD_TODO', todo: false })
        store.dispatch({ type: 'URL', url: url })
        // var tag = document.querySelector("html");
        // tag.classList.remove("nav-navbar-open");
    }
    /**
    * @description The function executed dropdown the sidebar cateogries
    */
    dropdown(id) {
        var array = [...this.state.categorymenuid];
        var index = array.indexOf(id)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ categorymenuid: array });
        } else {
            this.setState({
                categorymenuid: [...this.state.categorymenuid, id]
            })
        }
    }
    handleLogout() {
        eraseCookie('wn_log');
        localStorage.removeItem("wn_log");
        this.props.history.push('/')
    }
    render() {
        return (
            <div className='xl:w-20 flex '>
                {/* The view of without sidebar */}
                <div className={'  shadow-shadowsidebar xl:block hidden ' + (this.state.menuid === true ? '' : '')}>
                    <div className={'flex'}>
                        <div className={' xl:relative xl:block min-h-screen w-20 animate  bg-white ' + (this.state.menuid === true ? '  animated ' : '')}>
                            <div className={' px-2  ' + (this.state.menuid === true ? ' py-6' : 'py-6')}>
                                <div >
                                    {this.state.iconmap.map((icons, index) =>
                                        <div key={icons.id} className={this.state.menuid === true ? 'pb-0' : ''}>
                                            {icons.child &&
                                                icons.child.map(subicons =>
                                                    <div key={subicons.id} className={'flex  ' + (this.state.menuid === true ? 'py-3 ' : 'py-3 ') + (this.state.categorymenuid.includes(icons.id) === true && this.state.menuid === true ? 'hidden' : 'block')}>
                                                        <Link key={subicons.id} to={subicons.link} onClick={() => this.handleUrl(subicons.link)} className={'w-max flex xl:m-auto' + (this.state.menuid === true ? '' : ' ')}>
                                                            <div className={'w-10'}>
                                                                <img src={subicons.icon} alt='' className={' icon-img  m-auto w-7 ' + (this.state.menuid === true ? ' ' : 'xl:m-auto  ') + (this.state.urlchange === subicons.link ? ' hidden ' : ' block')} />
                                                                <img src={subicons.icon_active} alt='' className={'  icon-img m-auto w-7 ' + (this.state.menuid === true ? '' : 'xl:m-auto ') + (this.state.urlchange === subicons.link ? ' block ' : ' hidden')} />
                                                            </div>
                                                        </Link>

                                                    </div>

                                                )
                                            }
                                            <hr className={'border-gray-400 mx-2 ' + (this.state.iconmap.length - 1 === index ? 'hidden' : '')}></hr>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* The view with sidebar open */}
                <div className={'w-max sidebar-main-lg shadow-shadowsidebar z-30 ' + (this.state.menuid === true ? 'visible' : 'invisible h-0')}>
                    <div className={'flex ' + (this.state.menuid === true ? 'visible min-h-screen  ' : 'invisible h-0')}>
                        <div id='sidebar-main-outside' className={' xl:relative   sidebar bg-white side-main  animated sidebar-lg fixed ' + (this.state.menuid === true ? 'visible min-h-screen  ' : 'invisible h-0')}>
                            <Link to='/home' onClick={() => this.handleUrl('/')} className={'xl:py-2 py-3 text-base font-OpenSans text-white bg-redcolor text-center font-semibold wineye-dash-text xl:block '}>Wineye Dashboard</Link >
                            <div className={' px-2 px-4 py-6 '}>
                                <div >
                                    {this.state.iconmap.map(icons =>
                                        <div key={icons.id} className='pb-0'>
                                            <div className={'flex   py-2'}>
                                                <span key={icons.id} className={' ' + (this.state.menuid === true ? 'w-full flex' : ' ')}>
                                                    <span className={'xl:text-base w-full text-bluecolor font-OpenSans font-semibold xl:flex pl-3 '}>{icons.text} </span><span onClick={() => this.dropdown(icons.id)} className={'' + (this.state.categorymenuid.includes(icons.id) === true ? 'transform rotate-180' : '')}><img className={'right-0  float-right relative top-2 w-3 cursor-pointer ' + (this.state.menuid === true ? 'xl:flex' : 'xl:hidden')} src='/static/Icons/down-chevron-cat.svg' alt='' /></span>
                                                </span>
                                            </div>
                                            {
                                                icons.child &&
                                                icons.child.map(subicons =>
                                                    <div key={subicons.id} className={'flex py-2 ' + (this.state.categorymenuid.includes(icons.id) === true ? 'hidden' : 'block')}>
                                                        <Link key={subicons.id} to={subicons.link} onClick={() => this.handleUrl(subicons.link)} className={'w-max flex '}>
                                                            <div className={'w-20 '}>
                                                                <img src={subicons.icon} alt='' className={' xl:icon-img  m-auto xl:w-7 ' + (this.state.urlchange === subicons.link ? ' hidden ' : ' block')} />
                                                                <img src={subicons.icon_active} alt='' className={'  xl:icon-img m-auto xl:w-7 ' + (this.state.urlchange === subicons.link ? ' block ' : ' hidden')} />
                                                            </div>
                                                            <span className={'xl:text-base text-bluecolor font-OpenSans my-auto  xl:block ' + (this.state.urlchange === subicons.link ? 'activelink ' : '')}>{subicons.text}</span>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className={'logout-sec m-auto w-max py-28 '}>
                                    <img src='/static/images/header/Nickolls-and-Perks-Logo.png' alt='' />
                                    <button className='bg-redcolor px-2 text-white font-NotoSans m-auto block shadow-shadowblack mt-10 py-1 flex' onClick={this.handleLogout}><img className='top-1 relative' src='/static/Icons/logout.svg' /><span className='font-NotoSans'> Logout</span></button>
                                </div>
                            </div>
                        </div>

                        <div className={' fixed  top-3  animated block  close-btn ' + (this.state.menuid === true ? ' ' : ' animate left-0  hidden')}>
                            <span className='cursor-pointer' onClick={this.handleClick}>
                                <img src='/static/Icons/closebar.svg' alt='' className={' w-max ' + (this.state.menuid === true ? 'ml-3' : 'm-auto ')} /></span>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};

export default withRouter(Sidebar);