import React, { Component } from 'react';
import local from '../../global/js/locale';
import Select from 'react-select'
import { CgSearch } from 'react-icons/cg';
import { withRouter } from 'react-router-dom';
import './User.scss'
import store from './../../Store/Store'

class UserFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userdata: props.UserData,
            filter: false,
            Filtervalue: props.Filter,
            addNew: false,
            varchar: local.VARCHAR,
            Int: local.INT,
            update: store.getState().urlappend
        }
        this.handleForm = this.handleForm.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.HandleLoad = this.HandleLoad.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.HandleLoad();

    }

    /**
        * @description The function executed for before the component call
    */
    HandleLoad() {
        var update = this.state.update;
        let params = new URLSearchParams(document.location.search)
        //The search param not empty convert the serach parammeter to object
        if (window.location.search != null && window.location.search != '') {
            var search = window.location.search.substring(1);
            update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })

        }
    }

    async handleCheck() {
        // store.dispatch({ type: 'urlappend', Urlappend: this.state.update })
        store.subscribe(() => {
            this._isMounted && this.setState({ update: store.getState().urlappend })
        })
    }

    /**
    * @description The function executed for exists the components
    */
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Filter !== this.state.Filtervalue) {
            console.log('filter', nextProps.Filter)
            if (nextProps.Filter == true) {
                this._isMounted && this.handleCheck();
                this._isMounted && this.HandleLoad();
            }
        }

    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };

    handleForm(e) {
        var update = store.getState().urlappend;
        var key = e.target.id;
        var value = e.target.value;
        update[key] = value
        this.setState({ update: update });
        store.dispatch({ type: 'urlappend', Urlappend: update })
    }

    handleSubmit() {
        //get the value for store
        var update = store.getState().urlappend;
        update['page'] = 1;
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.history.push({
            pathname: '/user',
            search: "?" + str.toString()
        })
        this.props.passToParent(true)
        this.props.passToParent(false)
    }


    /**
        * @description  To handle filtering
        * @returns Filtered data if it any
    */
    handleFilter() {
        this.setState({ filter: !this.state.filter });
        // this.props.passToParent(this.state.filter)
    }

    /**
        * @description  To Navigate for the New User Adding page
        * @returns Navigates to add new user pagey
    */
    handleAddUser() {
        this.props.history.push('/User/NewUser')
    }

    render() {
        return (
            /* User Filter Section */
            <div className='filter-order'>
                <div className="flex">
                    <div className="w-full flex">
                        <button className='filter-btn bg-white py-1 w-32 grid-columns-1 px-4 shadow-shadowblack flex' onClick={() => this.handleFilter()}>
                            <span className='font-OpenSans font-semibold text-left text-base text-bluecolor '>Filters</span>
                            <img src='/static/Icons/filter-icon.svg' alt='' className={'my-auto ml-auto transform  ' + (this.state.filter === true ? '' : ' rotate-180')} />
                        </button>
                    </div>
                    <div className='w-52 my-auto ml-20'>
                        <button type='submit' className='text-white flex text-sm bg-redcolor xl:ml-auto mr-auto mr-2 xl: my-auto px-2 py-2 rounded-md' onClick={() => this.handleAddUser()}>
                            <img src='/static/Icons/productadd.svg' alt='add-product' className='w-3 my-auto' />
                            <span className='text-sm font-OpenSans font-normal my-auto'>&nbsp;&nbsp; Add New User</span>
                        </button>
                    </div>
                </div>
                <img src='/static/Icons/filter.svg' alt='' className={'' + (this.state.filter === true ? 'block' : 'hidden')} />

                <div className={'bg-white bottom-3 relative shadow-shadowblack filter-main ' + (this.state.filter === true ? 'block' : 'hidden')}>
                    <div className='xl:px-10 px-5 py-7'>
                        <div className='flex '>

                            <div className='w-1/2'>
                                <div className='xl:w-9/12 w-10/12'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>User Name</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-3/4 border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm font-semibold" id='UserName'
                                            placeholder="User Name" onChange={this.handleForm} defaultValue={this.state.update.username} maxLength={this.state.varchar["MD"]}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='w-1/2'>
                                <div className='xl:w-9/12 w-10/12'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>User Email</span>
                                    <div className=' w-full'>
                                        <input type='email' className="w-3/4 border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm font-semibold" id='Email'
                                            placeholder="Email" onChange={this.handleForm} defaultValue={this.state.update.email} maxLength={this.state.varchar["MD"]}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='w-1/2 my-auto'>
                                <div className='xl:w-full w-full xl:ml-6'>
                                    <button className='text-white bg-redcolor flex ml-auto px-6 py-1 rounded-lg ' onClick={() => this.handleSubmit()}><span className='text-base font-OpenSans font-normal'>Apply Filter</span><span className='text-white my-auto ml-3'><CgSearch /></span></button>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>

            </div >
        )
    }
};

export default withRouter(UserFilter);