import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import './ProductCategory.scss'
import Progress from '../../components/ProgressBar/Progress';
import Select from 'react-select'
import ImportProduct from '../../data/ImportProduct.json'
import { withRouter } from 'react-router-dom';
import ImportHeader from './ImportHeader';
import Multiselect from '../../components/MultiSelect/MultiSelect';
import { exportProductsAPI } from './../../API/Cronjob'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import Loader from './../../components/Loader/FullLoader'
import CSV from './../../Store/Csv'
class Export extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            varchar: local.VARCHAR,
            Int: local.INT,
            isLoading: false,
            Category: props.Category,
            Homepage: props.Homepage,
            Status: 'Home',
            level0: '',
            level1: '',
            level2: '',
            level_0: '',
            home_level_0: '',
            parent: 0,
            delimiter: '',
            loading: false


        }

        this.handleStatus = this.handleStatus.bind(this);
        this.handleLevel0 = this.handleLevel0.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.dataURLtoFile = this.dataURLtoFile.bind(this);
    }
    /**
   * @description The function executed for before the component call
   */
    componentDidMount() {

    }
    /**
        * @description The function executed for change the status
    */
    handleStatus(id) {
        this.setState({ Status: id });
    }

    /**
        * @description The function executed for set the levels for dropdown
    */
    handleLevel0(id) {
        if (this.state.level0 == id) {
            this.setState({ level0: null })
        } else {
            this.setState({ level0: id })
        }
    }

    /**
        * @description The function executed for navigating to next page
    */
    handleContinue() {
        var self = this;
        self.setState({ loading: true });
        let details = [
            "file"
        ]
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            var token = login.access_token;
            var email = login.email;

            exportProductsAPI(email, token, details)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.exportProducts?.summary?.reason;
                    let status = res?.data?.exportProducts?.summary?.status;
                    if (reason == "FILE_EXPORTED" && status == "SUCCESS") {
                        let data = res?.data?.exportProducts?.details.file;
                        var timestamp = new Date().valueOf()
                        var fileName = "product_" + timestamp + ".csv";
                        var file = self.dataURLtoFile('data:text/plain;base64,' + data, 'product.csv');
                        self.setState({ loading: false });
                        var saveData = (function () {
                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            return function (data, fileName) {
                                var blob = data,
                                    url = window.URL.createObjectURL(blob);
                                a.href = url;
                                a.download = fileName;
                                a.click();
                                window.URL.revokeObjectURL(url);
                            };
                        }());
                        saveData(file, fileName);
                    } else {

                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        }
        else {
            this.GoToPage("/");
        }

        // this.props.history.push('/importproducts/mapping')



    }
    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    render() {
        return (
            <div className={'category-down pt-6 ' + (this.state.Status === 'Home' ? '' : 'hidden')}>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'parent-item  py-2 flex my-auto w-full mb-2 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                    <div className='w-full  my-auto' onClick={() => this.handleLevel0(63)}>
                        <div className='my-auto'>

                            <div className='w-full border-solid border-2 border-gray-200 my-auto border-b mt-2'>
                                <div className="xl:mb-12 mb-6 ml-4">
                                    <div className="w-full">

                                        <div className='mt-8 mb-2 ml-8'>
                                            <span className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl mt-4'>Exporting Product</span>
                                        </div>

                                        <div className='xl: w-1/2 w-9/10 flex'>
                                            <div className='w-full mt-5 px-5 m-auto'>
                                                <table className="xl:w-1/2 w-full">
                                                    <tbody>

                                                        {/* <tr>
                                                            <td className='text-base text-bluecolor font-OpenSans my-auto w-72  py-4 pl-4 text-left'><span>Exporting Product Type</span></td>
                                                            <td className='text-base text-bluecolor font-OpenSans my-auto    py-4 text-left'>
                                                                <div className="">
                                                                    <input type="text" className="font-OpenSans font-normal text-sm text-bluecolor rounded-lg focus:border-bluecolor xl:w-full w-full  py-1 border" />
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='text-base text-bluecolor font-OpenSans my-auto  w-72  py-4 pl-4 text-left'><span>Exporting Product Category</span></td>
                                                            <td className='text-base text-bluecolor font-OpenSans my-auto    py-4 text-left'>
                                                                <div className="">
                                                                    <input type="text" className="font-OpenSans font-normal text-sm text-bluecolor rounded-lg focus:border-bluecolor xl:w-full w-full  py-1 border" />
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='text-base text-bluecolor font-OpenSans my-auto  w-72  py-4 pl-4 text-left'><span>Choose CSV File</span></td>
                                                            <td className='text-base text-bluecolor font-OpenSans my-auto   py-4 text-left'>
                                                                <div className="">
                                                                    <input type="text" className="font-OpenSans font-normal text-sm text-bluecolor rounded-lg focus:border-bluecolor xl:w-full w-full   py-1 border" />
                                                                </div>
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className=' w-72  py-4 pl-4 text-left'></td>
                                                            <td className='text-base text-bluecolor font-OpenSans my-auto   py-4 text-left'>
                                                                <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg my-auto py-1 cursor-pointer w-60 ml-auto flex items-end' onClick={this.handleContinue}>
                                                                    <span className="xl:w-full w-full text-white text-md"> Generating CSV </span>
                                                                </button>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                {/* <div className="w-1/4   my-auto  mt-8">
                                                    <div className='w-52 ml-auto items-end'>
                                                        <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg my-auto py-1 cursor-pointer w-full flex items-end' onClick={this.handleContinue}>
                                                            <span className="xl:w-full w-full text-white text-md"> Generating CSV </span>
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
export default withRouter(Export);