import React, { Component } from 'react';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './ProductDetail.scss'
import ProductDetails from './ProductDetails';
import ProductInfo from './ProductInfo';
import product from './../../data/Product.json'
import local from '../../global/js/locale';
import Country from './../../data/Country.json'
import ProductImage from './ProductImage';
import store from './../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import { fetchProductDetailsAPI, updateProductAPI } from './../../API/Product'
import Loader from './../../components/Loader/FullLoader'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
import { emailregex } from './../../global/js/validator'
class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Weight: local.locale.WEIGHT.SYMBOL,
            Percent: local.locale.PERCENT.SYMBOL,
            Duty: local.DUTY,
            Id: props.match.params.id,
            Edit: '',
            product: product.ProductList,
            productdetails: {},
            loading: false,
            Livex: '',

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Hanldeconditionsubmit = this.Hanldeconditionsubmit.bind(this);
        this.cleanobject = this.cleanobject.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        this.HandleDuty = this.HandleDuty.bind(this);
    }
    /**
* @description The function executed for get the updated value from store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for exists the component
*/
    componentWillUnmount() {
        this._isMounted = false;
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        var id = this.state.Id
        this._isMounted && this.handleListorder(id)
    }
    /**
* @description The function executed conver the updates and change the keys as the same of the api keyword
*/
    Handlechange(value) {
        var id = this.state.Id
        this.handleListorder(id)
    }

    /**
* @description The function executed get the orderdetails based on the orderid
*@param id-order id
*/
    handleListorder(id) {
        document.getElementById("Product-form").reset();
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "PRODUCT_ID": id
            }
            query = this.HandleString(query)
            var details = [
                "record_row_id",
                "lwin_7",
                "lwin_11",
                "lwin_18",
                "name",
                "date_modified",
                "date_created",
                "image_1",
                "image_2",
                "image_3",
                "condition_image_1",
                "condition_image_2",
                "condition_image_3",
                "condition_image_4",
                "condition_image_5",
                "title",
                "sku",
                "short_description",
                "sales_description",
                "tightest_spread",
                "wine_color",
                "wine_vintage",
                "wine_country",
                "wine_region",
                "wine_subregion",
                "wine_producer_name",
                "wine_site",
                "wine_parcel",
                "wine_type",
                "wine_subtype",
                "wine_bottlesize",
                "wine_packsize",
                "price_market",
                "price_offer",
                "price_online",
                "price_discount_percentage",
                "quantity_available",
                "product_condition",
                "product_contract_type",
                "product_delivery_period",
                "supplier_name",
                "supplier_email",
                "is_inactive",
                "display_in_website",
                "price_markup_percentage",
                "last_trade_date",
                "price_last_trade",
                "product_duty_paid",
                "abv_percentage",
                "abv_is_verified",
                "is_livex",
                "lead_time_min",
                "lead_time_max",
                "livex_index",
                "buffer_item_value",
                "buffer_markup_percentage"

            ];
            //api for order information
            self.setState({ loading: true })
            console.log(query)
            fetchProductDetailsAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.fetchProductDetails?.summary?.reason;
                    let status = res?.data?.fetchProductDetails?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var productlist = res.data.fetchProductDetails.details;
                        productlist = self.HandleDuty(productlist)
                        self.setState({ productdetails: productlist })
                        self.setState({ loading: false })
                        var supplier;
                        if (productlist?.supplier_name !== undefined) {
                            supplier = productlist.supplier_name.toLowerCase();
                        }
                        if (productlist.supplier_name === "Liv-ex" || productlist.supplier_name === 'livex' || productlist.supplier_name === 'LIVEX' || productlist.supplier_name === 'Live-Ex' || supplier === 'live-ex') {
                            self.setState({ Livex: true })
                        } else {
                            self.setState({ Livex: false })
                        }
                    } else {
                        self.setState({ loading: false })
                        self.props.history.push("/product?page=1");
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            self.setState({ loading: false })
            eraseCookie('wn_log');
            localStorage.removeItem("wn_log");
            this.GoToPage("/");
        }

    }
    /**
* @description The function executed calculate the estimate duty
*/
    HandleDuty(Products) {
        let product = Products;
        let Duty;
        var dutyarray = window.public_dataset.Leadtime.Dutydetails
        let Still_Wine = dutyarray.find(x => x.wine_type === "Still");
        let Sparkling_Wine = dutyarray.find(x => x.wine_type === "Sparkling");
        let Fortified_Wine = dutyarray.find(x => x.wine_type === "Fortified");

        if (
            product.wine_type === "Wine" ||
            product.wine_type.toUpperCase() === "WINE"
        ) {
            if (
                product.wine_subtype != null &&
                product.wine_subtype != ""
            ) {
                if (
                    product.wine_subtype === "Still" ||
                    product.wine_subtype === "Trotanoy" ||
                    product.wine_subtype.toUpperCase() === "STILL" ||
                    product.wine_subtype.toUpperCase() === "TROTANOY"
                ) {
                    let weight =
                        (((product.wine_bottlesize *
                            product.wine_packsize) /
                            10)) /
                        Still_Wine['wine_volume'];
                    Duty = weight * Still_Wine['duty_rate'];
                    if (
                        (product.product_contract_type === "X" &&
                            product.product_duty_paid === false) ||
                        (product.product_contract_type === "SIB" &&
                            product.product_duty_paid === false)
                    ) {
                        product["cart_duty"] = Duty;

                    } else {
                        if (product.product_contract_type === "SEP") {

                            product["cart_duty"] = 0;
                        } else if (
                            (product.product_contract_type == "SIB" &&
                                product.product_duty_paid === true) ||
                            (product.product_contract_type == "X" &&
                                product.product_duty_paid === true)
                        ) {
                            product["cart_duty"] = 0;
                        } else {

                            product["cart_duty"] = 0;
                        }
                    }
                } else if (
                    product.wine_subtype == "Sparkling" ||
                    product.wine_subtype.toUpperCase() == "SPARKLING"
                ) {
                    let weight =
                        (((product.wine_bottlesize *
                            product.wine_packsize) /
                            10)) /
                        Sparkling_Wine['wine_volume'];
                    Duty = weight * Sparkling_Wine['duty_rate'];

                    if (
                        (product.product_contract_type === "X" &&
                            product.product_duty_paid === false) ||
                        (product.product_contract_type == "SIB" &&
                            product.product_duty_paid == false)
                    ) {
                        product["cart_duty"] = Duty;

                    } else {
                        if (product.product_contract_type === "SEP") {

                            product["cart_duty"] = 0;
                        } else if (
                            (product.product_contract_type == "SIB" &&
                                product.product_duty_paid === true) ||
                            (product.product_contract_type == "X" &&
                                product.product_duty_paid === true)
                        ) {

                            product["cart_duty"] = 0;
                        } else {

                            product["cart_duty"] = 0;
                        }
                    }
                } else {

                    product["cart_duty"] = 0;
                }
            } else {

                product["cart_duty"] = 0;
            }
        } else if (
            product.wine_type == "Fortified" ||
            product.wine_type.toUpperCase() == "FORTIFIED"
        ) {
            let weight =
                (((product.wine_bottlesize * product.wine_packsize) /
                    10)) /
                Fortified_Wine['wine_volume'];
            Duty = weight * Fortified_Wine['duty_rate'];

            if (
                (product.product_contract_type == "X" &&
                    product.product_duty_paid == false) ||
                (product.product_contract_type == "SIB" &&
                    product.product_duty_paid == false)
            ) {
                product["cart_duty"] = Duty;

            } else {
                if (product.product_contract_type === "SEP") {

                    product["cart_duty"] = 0;
                } else if (
                    (product.product_contract_type == "SIB" &&
                        product.product_duty_paid === true) ||
                    (product.product_contract_type == "X" &&
                        product.product_duty_paid === true)
                ) {

                    product["cart_duty"] = 0;
                } else {

                    product["cart_duty"] = 0;
                }
            }
        } else {

            product["cart_duty"] = 0;
        }

        return product;
    }
    /**
* @description The function executed for cancel the edit
*/
    HandleString(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            BILLING_PHONE: "BILLING_PHONE",
            BILLING_PHONE: "SHIPPING_PHONE",
            CONDITION_IMAGE_1: "CONDITION_IMAGE_1",
            CONDITION_IMAGE_2: "CONDITION_IMAGE_2",
            CONDITION_IMAGE_3: "CONDITION_IMAGE_3",
            CONDITION_IMAGE_4: "CONDITION_IMAGE_4",
            CONDITION_IMAGE_5: "CONDITION_IMAGE_5",
            IMAGE_1: "IMAGE_1",
            IMAGE_2: "IMAGE_2",
            IMAGE_3: "IMAGE_3",
            SALES_DESCRIPTION: "SALES_DESCRIPTION",
            SHORT_DESCRIPTION: "SHORT_DESCRIPTION",
            NAME: "NAME",
            TITLE: "TITLE",
            SKU: "SKU",
            WINE_COLOR: "WINE_COLOR",
            WINE_PRODUCER: "WINE_PRODUCER",
            WINE_COUNTRY: "WINE_COUNTRY",
            WINE_REGION: "WINE_REGION",
            WINE_SUBREGION: "WINE_SUBREGION",
            WINE_SITE: "WINE_SITE",
            WINE_PARCEL: "WINE_PARCEL",
            WINE_TYPE: "WINE_TYPE",
            WINE_SUBTYPE: "WINE_SUBTYPE",
            WINE_PRODUCER: "WINE_PRODUCER",
            PRICE_LAST_TRADE_DATE: "PRICE_LAST_TRADE_DATE",
            PRODUCT_CONTRACT_TYPE: "PRODUCT_CONTRACT_TYPE",
            PRODUCT_CONDITION: "PRODUCT_CONDITION",
            LWIN_18: "LWIN_18"



        };
        let booleanFields = { PRODUCT_ID: "PRODUCT_ID", TIGHTEST_SPREAD: "TIGHTEST_SPREAD", ORDER_HEADER_ID: "ORDER_HEADER_ID", PRODUCT_DUTY_PAID: "PRODUCT_DUTY_PAID", IS_INACTIVE: "IS_INACTIVE", DISPLAY_IN_WEBSITE: "DISPLAY_IN_WEBSITE", ABV_IS_VERIFIED: "ABV_IS_VERIFIED", WINE_BOTTLESIZE: "WINE_BOTTLESIZE", WINE_PACKSIZE: "WINE_PACKSIZE", PRICE_MARKET: "PRICE_MARKET", PRICE_OFFER: "PRICE_OFFER", PRICE_LAST_TRADE: "PRICE_LAST_TRADE", PRICE_BID: "PRICE_BID", PRICE_MARKUP_PERCENTAGE: "PRICE_MARKUP_PERCENTAGE", PRICE_DISCOUNT_PERCENTAGE: "PRICE_DISCOUNT_PERCENTAGE", PRICE_ONLINE: "PRICE_ONLINE", PRODUCT_DELIVERY_PERIOD: "PRODUCT_DELIVERY_PERIOD", QUANTITY_AVAILABLE: "QUANTITY_AVAILABLE", ABV_PERCENTAGE: "ABV_PERCENTAGE", WINE_VINTAGE: "WINE_VINTAGE", };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    /**
* @description The function executed for cancel the edit
*/
    handleCancel() {

        store.dispatch({ type: 'EditOrder', edits: false })
    }
    cleanobject(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "undefined") {
                delete obj[propName];
            }
            delete obj["RECORD_ROW_ID"];
            delete obj["RECORD_ID"];
            delete obj["DATE_MODIFIED"];
            delete obj["DATE_CREATED"];
            delete obj["LWIN_18"];
            delete obj["LWIN_7"];
            delete obj["LWIN_11"];
            delete obj["IS_LIVEX"];
            delete obj["CART_DUTY"];
            delete obj["SKU"];
            delete obj["LIVEX_INDEX"];
            if (propName === "WINE_PRODUCER_NAME") {
                if (obj.WINE_PRODUCER) {
                    delete obj["WINE_PRODUCER_NAME"];
                } else {
                    obj['WINE_PRODUCER'] = obj["WINE_PRODUCER_NAME"]
                    delete obj["WINE_PRODUCER_NAME"];
                }
            }
            if (propName === "LAST_TRADE_DATE" && obj['PRICE_LAST_TRADE_DATE'] === undefined) {
                if (obj["LAST_TRADE_DATE"] !== undefined || obj["LAST_TRADE_DATE"] !== "undefined") {
                    obj['PRICE_LAST_TRADE_DATE'] = obj["LAST_TRADE_DATE"]
                    delete obj["LAST_TRADE_DATE"];
                }

            }
            else {
                delete obj["LAST_TRADE_DATE"];
            }
        }
        return obj
    }
    /**
* @description The function executed for submit the form
*/
    handleSubmit(e) {
        e.preventDefault();
        var update = store.getState().update;
        if (livex === false) {
            update = this.cleanobject(update);
        }
        console.log(store.getState().update)
        var livex = this.state.Livex
        var query = this.HandleString(update)
        console.log(query)
        if (localStorage.getItem("wn_log") !== null) {
            if (livex === true) {
                if ((update['NAME'] != '') && (update['WINE_COLOR'] != '') && (update['WINE_VINTAGE'] != '') && (update['WINE_PRODUCER'] != '') && (update['WINE_TYPE'] != '') && (update['SUPPLIER_NAME'] != '') && (update['WINE_PACKSIZE'] != '') && (update['WINE_BOTTLESIZE'] != '') && (update['WINE_BOTTLESIZE'] != '') && (update['QUANTITY_AVAILABLE'] != '') && (update['PRICE_OFFER'] != '') && (update['PRICE_ONLINE'] != '') && (update['PRICE_MARKUP_PERCENTAGE'] != '') && (update['PRODUCT_DELIVERY_PERIOD'] != '') && (update['LEAD_TIME_MIN'] != '') && (update['LEAD_TIME_MAX'] != '')) {
                    if (update['SUPPLIER_EMAIL'] != null && update['SUPPLIER_EMAIL'] != '') {
                        if (emailregex.test(update['SUPPLIER_EMAIL'])) {
                            this.Hanldeconditionsubmit()
                        } else {
                            showNotification("invalid Supplier Email", "danger");
                        }
                    } else {
                        this.Hanldeconditionsubmit()
                    }

                }
                else {
                    showNotification("Must Fill Mandatory Fields", "danger");
                }
            } else {
                this.Hanldeconditionsubmit()
            }


        } else {
            this.GoToPage("/");
        }

        // cancel edit when submit form



    }
    /**
    * @description The function executed for submit the form
    */
    Hanldeconditionsubmit() {
        var id = parseInt(this.state.Id);
        var update = store.getState().update;
        update = this.cleanobject(update)
        update['PRODUCT_ID'] = this.state.Id
        var self = this;
        var email;
        var token;
        var query
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            console.log(update)
            update['EMAIL'] = email
            update['TOKEN'] = token
            query = this.HandleString(update)
            var details = [
                "title",

            ];
            console.log(query)
            //api for order information
            self.setState({ loading: true })

            updateProductAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.updateProduct?.summary?.reason;
                    let status = res?.data?.updateProduct?.summary?.status;
                    if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                        var id = parseInt(self.state.Id)
                        self.handleListorder(id)
                        showNotification("Updated Product", "success");
                        self.setState({ loading: false })
                    } else if (reason == "RECORD_NOT_UPDATED" && status == "SUCCESS") {
                        self.setState({ loading: false })
                        var id = parseInt(self.state.Id)
                        self.handleListorder(id)
                        showNotification("Order Has Not Update", "danger");
                    } else {
                        var id = parseInt(self.state.Id)
                        self.handleListorder(id)
                        showNotification("Cannot Update Product", "danger");
                        // self.props.history.push("/product?page=1");
                        self.setState({ loading: false })
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            this.GoToPage("/");
        }

        // cancel edit when submit form
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    render() {
        return (
            < div className='Product-Detail h-full  ' >

                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    <ReactNotification />
                    < Breadthcrumbs Breadthcrumb={'Product Details'} Count={2} level={'Product'} />
                    <Link to='/product' className='flex bg-white w-48 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/packing.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Product</span>
                    </Link>
                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'Product-Details px-5 py-2 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>

                    <form onSubmit={this.handleSubmit} id='Product-form'>
                        <ProductInfo Id={parseInt(this.state.Id)} Products={this.state.productdetails} passToParent={this.Handlechange} />
                        <ProductImage Products={this.state.productdetails} Id={parseInt(this.state.Id)} />
                        <ProductDetails Countrylist={Country.CountryList} ProductInfo={product.ProductInfo} Products={this.state.productdetails} currency={this.state.Currency} weight={this.state.Weight} Percent={this.state.Percent} ContractType={product.ContractType} DutyPaid={product.DutyPaid} Id={parseInt(this.state.Id)} Types={product.Type} Subtypes={product.Subtype} />

                    </form>
                </div>

            </div >
        )
    }
};

export default ProductDetail;