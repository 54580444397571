import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './ProductCategory.scss'
import Select from 'react-select'
import store from './../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import Condition from './../../data/Condition.json';
import { validateConditionAPI, addOrUpdateCategoryAPI } from './../../API/Category'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
class AddCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Id: props.match.params.id,
            varchar: local.VARCHAR,
            Int: local.INT,
            menubar: '',
            homepage: '',
            orderlevel: '',
            orderpresedence: '',
            Between: false,
            conditions: 'AND',
            NewCondition: [],
            ConditionList: [],
            SelectColumn: '',
            SelectCondition: '',
            SelectValue: '',
            SelectValues: '',
            ConditionsList: [],
            ConditionDetails: [],
            ConditionDetailslist: [],
            inactive: false,
            levels: '',
            update: {}


        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleMenubar = this.handleMenubar.bind(this);
        this.handleCondition = this.handleCondition.bind(this);
        this.AddnewCondition = this.AddnewCondition.bind(this);
        this.HandleClear = this.HandleClear.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.HandleRemove = this.HandleRemove.bind(this);
        this.Handlechecked = this.Handlechecked.bind(this);
        this.Handlevalues = this.Handlevalues.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.HandleValidate = this.HandleValidate.bind(this);
        this.HandleNewCategory = this.HandleNewCategory.bind(this);
    }
    /**
* @description The function executed for redirect the page when the cookie are not available
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        let params = new URLSearchParams(document.location.search)
        //check the category of the product and levels
        if ((params.get("is_menu_category") == null || params.get("is_menu_category") == '') || (params.get("level") == null || params.get("level") == '') || (params.get("order_precedence") == null || params.get("order_precedence") == '')) {
            this.props.history.push('/category')
        } else {
            let menubar = params.get("is_menu_category")
            this.setState({ menubar: JSON.parse(menubar) });
            this.setState({ homepage: !JSON.parse(menubar) });
            this.setState({ orderlevel: params.get("level") });
            this.setState({ orderpresedence: params.get("order_precedence") });
            var update = this.state.update
            update['ORDER_PRECEDENCE'] = params.get("order_precedence")

        }
        if (params.get("level0") && !params.get("level1")) {
            this.setState({ levels: params.get("level0") });
        } else if (params.get("level0") && params.get("level1")) {
            this.setState({ levels: params.get("level1") });
        }
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        //formated the condition list
        var operator = Condition.operator
        operator.forEach((value, index, self) => {
            if (value.type == "Number") {
                value['values'] = Condition.number_operation
            }
            if (value.type == "String") {
                value['values'] = Condition.string_operation
            }
            if (value.type == "Boolean") {
                value['values'] = Condition.boolean_operation
            }
            if (value.type == "Date") {
                value['values'] = Condition.date_operation
            }
        })
        this.setState({ ConditionsList: operator });

    }
    /**
* @description The function executed for change the inactive state
*/
    Handlechecked() {
        this.setState({ inactive: !this.state.inactive });
    }
    /**
* @description The function executed for when the category as menubar or homepage category
*@param id-menu or homepage category
*/
    handleMenubar(id) {
        if (id == 'Menu') {
            this.setState({ menubar: true });
            this.setState({ homepage: false });
        } else {
            this.setState({ menubar: false });
            this.setState({ homepage: true });
        }
    }
    /**
* @description The function executed for change value
*/
    Handlevalues(e) {
        var update = this.state.update
        var key = e.target.id;
        var value = e.target.value
        update[key] = value
        this.setState({ update: update });
        console.log(update)
    }
    /**
* @description The function executed for change the condition if AND or OR
*@param id-AND or OR
*/
    handleCondition(id) {
        if (id == 'AND') {
            this.setState({ conditions: 'AND' });
        } else {
            this.setState({ conditions: 'OR' });
        }
    }
    /**
* @description The function executed set the condition based on the values
*@param item-the column,condition and value
*/
    handleChange(e, item) {
        let Newarray = this.state.NewCondition;
        let detailsarray = this.state.ConditionDetails;
        if (item == 'Columns') {
            this.setState({ SelectColumn: e });
            this.setState({ SelectCondition: null });
            this.setState({ SelectValue: null });
            this.setState({ SelectValues: null });
            Newarray[0] = e.value
            detailsarray[0] = e
        } else if (item == 'Condition') {
            this.setState({ SelectCondition: e });
            this.setState({ SelectValue: null });
            this.setState({ SelectValues: null });
            Newarray[1] = e.value
            detailsarray[1] = e
            if (Newarray[1] == 'BETWEEN' || Newarray[1] == 'NOTBETWEEN' || Newarray[1] == 'IN' || Newarray[1] == 'NOTIN') {
                this.setState({ Between: true });
            } else {
                Newarray.splice(3, 1);
                this.setState({ Between: false });
            }
        } else if (item == 'Value') {
            this.setState({ SelectValue: e.target.value });
            this.setState({ SelectValues: null });
            Newarray[2] = e.target.value
            detailsarray[2] = e.target.value
        } else if ((Newarray[1] == 'BETWEEN' || Newarray[1] == 'NOTBETWEEN' || Newarray[1] == 'IN' || Newarray[1] == 'NOTIN') && item == 'Values') {
            this.setState({ SelectValues: e.target.value });
            Newarray[3] = e.target.value
            detailsarray[3] = e.target.value
        }
        console.log(Newarray)
        this.setState({ NewCondition: Newarray });
        this.setState({ ConditionDetails: detailsarray });
    };
    /**
* @description The function executed for add new condition 
*/
    AddnewCondition() {
        this.setState({ Between: false });
        let condition = this.state.NewCondition;
        let conditiondetails = this.state.ConditionDetails;
        console.log(condition.length)
        let conditionlist = this.state.ConditionList;
        let conditiondetailslist = this.state.ConditionDetailslist;
        if (condition.length != 0) {
            if (condition[1] == 'BETWEEN' || condition[1] == 'NOTBETWEEN' || condition[1] == 'IN' || condition[1] == 'NOTIN') {
                if (condition.length == 4) {
                    var keys = ["column", "condition", "value", "values"];
                    var array = keys.map((el, i) => {
                        return [keys[i], condition[i]];
                    });
                    var output = Object.fromEntries(array);
                    conditionlist.push(output)
                    this.setState({ NewCondition: [] });
                    this.setState({ ConditionList: conditionlist });

                }
            } else {
                if (condition[1] == 'ISTRUE' || condition[1] == 'ISNOTTRUE' || condition[1] == 'ISEMPTY' || condition[1] == 'ISNOTEMPTY') {
                    var keys = ["column", "condition",];
                    var array = keys.map((el, i) => {
                        return [keys[i], condition[i]];
                    });
                    var output = Object.fromEntries(array);
                    conditionlist.push(output)
                    this.setState({ NewCondition: [] });
                    this.setState({ ConditionList: conditionlist });
                    console.log(this.state.ConditionList)
                }
                else if (condition.length >= 3) {
                    var keys = ["column", "condition", "value",];
                    var array = keys.map((el, i) => {
                        return [keys[i], condition[i]];
                    });
                    var output = Object.fromEntries(array);
                    conditionlist.push(output)
                    this.setState({ NewCondition: [] });
                    this.setState({ ConditionList: conditionlist });
                    console.log(this.state.ConditionList)
                }
            }
            if (conditiondetails[1].value == 'BETWEEN' || conditiondetails[1].value == 'NOTBETWEEN' || conditiondetails[1].value == 'IN' || conditiondetails[1].value == 'NOTIN') {
                if (conditiondetails.length == 4) {
                    var keys = ["column", "condition", "value", "values"];
                    var array = keys.map((el, i) => {
                        return [keys[i], conditiondetails[i]];
                    });
                    var output = Object.fromEntries(array);
                    conditiondetailslist.push(output)
                    console.log(conditiondetailslist)
                    this.setState({ ConditionDetails: [] });
                    this.setState({ ConditionDetailslist: conditiondetailslist });

                }
            } else {
                if (conditiondetails[1].value == 'ISTRUE' || conditiondetails[1].value == 'ISNOTTRUE' || conditiondetails[1].value == 'ISEMPTY' || conditiondetails[1].value == 'ISNOTEMPTY') {
                    var keys = ["column", "condition",];
                    var array = keys.map((el, i) => {
                        return [keys[i], conditiondetails[i]];
                    });
                    var output = Object.fromEntries(array);
                    conditiondetailslist.push(output)
                    this.setState({ ConditionDetails: [] });
                    this.setState({ ConditionDetailslist: conditiondetailslist });
                    console.log(conditiondetailslist)
                }
                if (conditiondetails.length >= 3) {
                    var keys = ["column", "condition", "value",];
                    var array = keys.map((el, i) => {
                        return [keys[i], conditiondetails[i]];
                    });
                    var output = Object.fromEntries(array);
                    conditiondetailslist.push(output)
                    this.setState({ ConditionDetails: [] });
                    this.setState({ ConditionDetailslist: conditiondetailslist });
                    console.log(conditiondetailslist)
                }
            }
        }

        this.HandleClear()
    }
    /**
* @description The function executed update when the values in condition
*/
    handleUpdate(e, item, index) {
        let value = item
        let conditionlist = this.state.ConditionList;
        let ConditionDetailslist = this.state.ConditionDetailslist;
        if (value != 'value' && value != 'values') {
            conditionlist[index][value] = e.value
            ConditionDetailslist[index][value] = e
        } else {
            console.log(value, e.target.value)
            conditionlist[index][value] = e.target.value
            ConditionDetailslist[index][value] = e.target.value
        }

        if (value == 'column') {
            conditionlist[index]["condition"] = ConditionDetailslist[index].column.values[0].value
            ConditionDetailslist[index]["condition"] = ConditionDetailslist[index].column.values[0]
            console.log(ConditionDetailslist[index].column.values[0])
            if (conditionlist[index]['value']) {
                conditionlist[index]["value"] = '';
                ConditionDetailslist[index]["value"] = '';
                document.getElementById("value" + index).value = "";
            }
        }
        else if (value == 'conditon') {
            if (conditionlist[index]['value']) {
                conditionlist[index]["value"] = ''
                ConditionDetailslist[index]["value"] = ''
                document.getElementById("value" + index).value = "";
            }
        }

        if (conditionlist[index]['condition'] !== 'BETWEEN' && conditionlist[index]['condition'] !== 'NOTBETWEEN' && conditionlist[index]['condition'] !== 'IN' && conditionlist[index]['condition'] !== 'NOTIN') {
            if (conditionlist[index]['value'] === '' || conditionlist[index]['value'] === undefined) {
                delete conditionlist[index].values;
                delete ConditionDetailslist[index].values;
                document.getElementById("values" + index).value = "";
            }
        }
        if (conditionlist[index]['condition'] === 'ISTRUE' || conditionlist[index]['condition'] === 'ISNOTTRUE' || conditionlist[index]['condition'] === 'ISEMPTY' || conditionlist[index]['condition'] === 'ISNOTEMPTY') {
            if (conditionlist[index]['value'] === '' || conditionlist[index]['value'] === undefined) {

                document.getElementById("value" + index).value = "";
                document.getElementById("values" + index).value = "";
                delete conditionlist[index].value;
                delete ConditionDetailslist[index].value;
            }
        }
        console.log(conditionlist)
        console.log(ConditionDetailslist)
        this.setState({ ConditionList: conditionlist });
        this.setState({ ConditionDetailslist: ConditionDetailslist });
    }
    /**
* @description The function executed for clear the condition
*/
    HandleClear() {
        this.setState({ SelectColumn: '' });
        this.setState({ SelectCondition: '' });
        this.setState({ SelectValue: '' });
        this.setState({ SelectValues: '' });
    }
    /**
* @description The function executed for submit the condition and changes
*/
    HandleSubmit(e) {
        e.preventDefault();
        var update = this.state.update;
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            var token = login.access_token;
            var email = login.email;
            update['EMAIL'] = email
            update['TOKEN'] = token
        }
        update['HOME_PAGE'] = this.state.homepage
        update['MENU_BAR'] = this.state.menubar;
        update['LEVEL'] = this.state.orderlevel
        update['IS_INACTIVE'] = JSON.stringify(this.state.inactive)
        if (this.state.Id != 0 && this.state.Id != undefined) {
            update['PARENT_CATEGORY'] = this.state.Id
        }
        if (this.state.ConditionList.length != 0) {
            let conditionlist = this.state.ConditionList;
            let oddnumber = []

            var output = conditionlist.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            for (var i = 1; i <= ((conditionlist.length) * 2) - 2; i++)
                if (i % 2 !== 0) {
                    oddnumber.push(i)
                }
            console.log(oddnumber)
            oddnumber.forEach(num => {
                output.splice(num, 0, this.state.conditions);
            });
            output = JSON.stringify(output)
            var newTemp = output.replace(/"/g, "'");
            this.HandleValidate(newTemp)
            update['SQL_PRODUCTS_ON_CATEGORY'] = newTemp
            this.setState({ update: update });

        } else {
            if (!update['ORDER_PRECEDENCE']) {
                update['ORDER_PRECEDENCE'] = this.state.orderpresedence
            }
            var query = this.Handleconvert(update)
            console.log(query)
            this.HandleNewCategory(query)
        }


    }
    /**
* @description The function executed for conavert the object to string
*/
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            SQL_PRODUCTS_ON_CATEGORY: "SQL_PRODUCTS_ON_CATEGORY",
            CATEGORY_NAME: "CATEGORY_NAME"
        };
        let booleanFields = { MENU_BAR: "MENU_BAR", HOME_PAGE: "HOME_PAGE", LEVEL: "LEVEL", IS_INACTIVE: "IS_INACTIVE", ORDER_PRECEDENCE: "ORDER_PRECEDENCE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function executed validate condition
*/
    HandleValidate(condition) {
        var self = this
        var conditions = {}
        let login = JSON.parse(localStorage.getItem("wn_log"));
        var token = login.access_token;
        var email = login.email;
        conditions['EMAIL'] = email
        conditions['TOKEN'] = token
        conditions['CONDITION'] = condition
        var query = this.Handleconvert(conditions)
        console.log(query)
        var details = [
            "level",
        ];
        validateConditionAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.validateCondition?.summary?.reason;
                let status = res?.data?.validateCondition?.summary?.status;
                if (reason == "CONDITION_RIGHT" && status == "SUCCESS") {
                    // showNotification("Condition  Valid", "success");
                    var update = self.state.update
                    if (!update['ORDER_PRECEDENCE']) {
                        update['ORDER_PRECEDENCE'] = self.state.orderpresedence
                    }
                    var query = self.Handleconvert(update)
                    self.HandleNewCategory(query)
                    update = {}
                    self.setState({ update: update });

                } else {
                    showNotification("Condition Not Valid", "danger");
                    var update = {}
                    self.setState({ update: update });
                }
            });
    }
    /**
* @description The function executed create or update new category
*/
    HandleNewCategory(query) {
        var self = this
        var details = [
            "level",
            "sql_products_on_category",
            "parent_category_id",
        ];
        console.log(query)
        addOrUpdateCategoryAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.addOrUpdateCategory?.summary?.reason;
                let status = res?.data?.addOrUpdateCategory?.summary?.status;
                if (reason == "RECORD_CREATED" && status == "SUCCESS") {
                    // showNotification("Category Created", "success");
                    console.log(self.state.update)
                    self.props.history.push("/category");
                } else if (reason == 'MENU_CATEGORY_LEVEL_3_CONDITON_MISSING') {
                    showNotification("Condition is Required", "danger");
                }
                else if (reason == 'CONDITION_WRONG_ONLY_ONE_VALUE_REQIUIRED') {
                    showNotification("Condition is Wrong", "danger");
                }
                else {
                    showNotification("Category Not Created", "danger");
                }
            });
    }
    /**
* @description The function executed remove the condition
*/
    HandleRemove(key) {
        console.log(key)
        let conditionlist = this.state.ConditionList;
        conditionlist.splice(key, 1);
        this.setState({ ConditionList: conditionlist });
        let conditionlistobject = this.state.ConditionDetailslist;
        conditionlistobject.splice(key, 1);
        this.setState({ ConditionDetailslist: conditionlistobject });
        console.log(conditionlist, conditionlistobject)
    }
    render() {
        return (
            <div className='Category-Add h-screen xl:h-full '>
                <ReactNotification />
                <div className='flex'>
                    < Breadthcrumbs Breadthcrumb={'Add new Category'} Count={4} />
                    <Link to='/category' className='flex bg-white w-64 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/categories_page.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Category page</span>
                    </Link>
                </div>
                <div className='category-content mx-5 '>
                    <form onSubmit={this.HandleSubmit}>
                        <div className='bg-white py-6 px-5 shadow-shadowblack mb-10'>
                            <div className='flex '>
                                <div className='my-auto'>
                                    <h1 className='font-OpenSans  font-semibold text-bluecolor xl:text-2xl text-xl  ml-4'>Add New Category</h1>
                                </div>
                                <div className='action w-max ml-auto my-auto'>
                                    <div className='buttons w-max flex ml-auto h-8'>
                                        <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                            <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                                <div className='flex'>
                                                    <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                                    <span className='ml-2 mx-auto'>Save</span>
                                                </div>
                                            </button>
                                        </div>
                                        <Link to='/category'>
                                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer'>Cancel</span></Link>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-5 border-bluecolor '></hr>

                            <div className='mt-5 px-5 m-auto'>
                                <table className="xl:w-1/2 w-9/12">
                                    <tbody>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-left'><span>Enable Category in</span></td>
                                            <td className=' pl-20'><span className='' >
                                                <input type="checkbox" className={"form-checkbox     h-4 w-4  text-white  " + (this.state.menubar === true ? 'order-sync-netsuite' : 'order-sync')} readOnly />
                                                {/* <input type="checkbox" className={"form-checkbox category-check  h-4 w-4  text-white cursor-pointer"} checked={this.state.menubar} readOnly /> */}
                                                <span className='font-OpenSans  font-normal text-bluecolor text-sm relative ml-2 '>Is Menubar</span></span><span className='cursor-pointer' >
                                                    <input type="checkbox" className={"form-checkbox     h-4 w-4  text-white  ml-3 " + (this.state.homepage === true ? 'order-sync-netsuite' : 'order-sync')} readOnly />
                                                    {/* <input type="checkbox" className={"form-checkbox category-check ml-2  h-4 w-4  text-white cursor-pointer"} checked={this.state.homepage} readOnly /> */}
                                                    <span className='font-OpenSans  font-normal text-bluecolor text-sm relative ml-2 '>Is Home page</span></span> </td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-left'><span>Category Name *</span></td>
                                            <td className=' pl-20'>  <input type='text' id='CATEGORY_NAME' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor xl:w-52 pl-2' maxLength={this.state.Int['MD']} required onChange={(e) => this.Handlevalues(e)} pattern="\s*\S+.*" title="space only is not allowed" /></td>
                                        </tr>
                                        <tr className={'' + this.state.homepage === "true" ? 'hidden' : ''}>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-left '><span>Order Precedence {this.state.homepage === "true" ? '' : '*'}</span></td>
                                            <td className=' pl-20'>  <input type='text' id='ORDER_PRECEDENCE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor xl:w-52 px-2' maxLength={this.state.Int['XS-US']} defaultValue={this.state.orderpresedence} required={this.state.homepage === "true" ? false : true} onChange={(e) => this.Handlevalues(e)} /></td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-left'><span>Level</span></td>
                                            <td className=' pl-20 font-OpenSans font-normal text-sm text-bluecolor  px-2'>{this.state.orderlevel}</td>
                                        </tr>
                                        <tr className={this.state.Id == 0 ? 'hidden' : ''}>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-left '><span>Parent Category</span></td>
                                            <td className=' pl-20 font-OpenSans font-normal text-sm text-bluecolor  px-2'>{this.state.levels} (ID : {this.state.Id})</td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-left'><span>Inactive</span></td>
                                            <td className=' pl-20'><span className='cursor-pointer' onClick={() => this.Handlechecked()}>
                                                <input type="checkbox" className={"form-checkbox    h-4 w-4  text-white cursor-pointer " + (this.state.inactive === true ? 'order-sync-netsuite' : 'order-sync')} />
                                                {/* <input type="checkbox" className={"form-checkbox category-check  h-4 w-4  text-white  " + (this.state.inactive === true ? 'checked' : '')} /> */}
                                            </span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={'conditions-div border mt-5 ' + (this.state.orderlevel === '1' && this.state.menubar === true ? 'hidden' : '')}>
                                    <div className='condition-content px-4 py-2'>
                                        <h2 className='font-OpenSans  font-semibold text-bluecolor text-xl'>Conditions</h2>
                                        <hr className='border-t  my-2'></hr>
                                        <div className='px-3'>
                                            <p className='font-OpenSans  font-normal text-bluecolor text-sm pb-2' >Automatically select products based on conditions</p>
                                        </div>
                                        <div className='conditions-column xl:w-1/2 w-full flex px-3 mt-3'>
                                            <div className='w-max pr-10'>
                                                <p className='font-OpenSans  font-semibold text-bluecolor text-sm ' >Product must match</p>
                                            </div>
                                            <div className='w-1/2 flex'>
                                                <div className='flex cursor-pointer' onClick={() => this.handleCondition('AND')}>
                                                    <img src='/static/Icons/radio1.svg ' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'AND' ? 'hidden' : 'block')} />
                                                    <img src='/static/Icons/radio2.svg' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'AND' ? 'block' : 'hidden')} />
                                                    <span className='font-OpenSans font-semibold text-sm text-bluecolor'>All Conditions</span>
                                                </div>
                                                <div className='flex ml-2 cursor-pointer' onClick={() => this.handleCondition('OR')}>
                                                    <img src='/static/Icons/radio1.svg ' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'OR' ? 'hidden' : 'block')} />
                                                    <img src='/static/Icons/radio2.svg' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'OR' ? 'block' : 'hidden')} />
                                                    <span className='font-OpenSans font-semibold text-sm text-bluecolor'>Any Conditions</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className='text-bluecolor font-NotoSans font-semibold  py-1 text-sm py-2 mt-5 px-3'>Product Criteria</h2>
                                        <div className='main-conditions grid xl:grid-cols-2 grid-cols-1 px-3  gap-3'>
                                            {
                                                this.state.ConditionDetailslist.length !== 0 &&
                                                this.state.ConditionDetailslist.map((list, key) =>
                                                    // <div className={'main-condition-view flex px-3 mt-5 ' + (key)} key={key}>
                                                    //     <div className='flex w-full'>
                                                    //         <div className='column-div condition-div w-60'>
                                                    //             <Select
                                                    //                 onChange={(e) => this.handleUpdate(e, 'column', key)}
                                                    //                 options={this.state.Column}
                                                    //                 placeholder='Column'
                                                    //                 value={{ "label": list.column, "value": list.column }}

                                                    //             />
                                                    //         </div>
                                                    //         <div className='column-div condition-div w-60 xl:ml-10 ml-3'>
                                                    //             <Select
                                                    //                 onChange={(e) => this.handleUpdate(e, 'condition', key)}
                                                    //                 options={this.state.Condition}
                                                    //                 placeholder='Condition'
                                                    //                 value={{ "label": list.condition, "value": list.condition }}
                                                    //             />
                                                    //         </div>
                                                    //         <div className='column-div condition-div w-60  xl:ml-10 ml-3'>
                                                    //             <Select
                                                    //                 onChange={(e) => this.handleUpdate(e, 'value', key)}
                                                    //                 options={this.state.Value}
                                                    //                 placeholder='Value'
                                                    //                 value={{ "label": list.value, "value": list.value }}
                                                    //             />
                                                    //         </div>
                                                    //         <div className={'column-div condition-div w-60  xl:ml-10 ml-3 ' + (list.condition == 'between' ? '' : 'hidden')}>
                                                    //             <Select
                                                    //                 onChange={(e) => this.handleUpdate(e, 'values', key)}
                                                    //                 options={this.state.Value}
                                                    //                 placeholder='Value'
                                                    //                 value={{ "label": list.values, "value": list.values }}
                                                    //             />
                                                    //         </div>
                                                    //         <div className='delete-div xl:ml-10 ml-3 xl:w-8 w-10 border border-bluecolor rounded-lg cursor-pointer' onClick={() => this.HandleRemove(key)}>
                                                    //             <div className='m-auto  py-1.5'>
                                                    //                 <img src='/static/Icons/delete.svg' alt='' className='w-3 m-auto' />
                                                    //             </div>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>
                                                    <div className={'order-border-table rounded-xl px-4 py-3 my-4 ' + (key)} key={key}>
                                                        <table className="w-full table-order-mobile" >
                                                            <tbody>
                                                                <tr className=''>
                                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 '>Column</td>
                                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl column-div condition-div '> <Select
                                                                        onChange={(e) => this.handleUpdate(e, 'column', key)}
                                                                        options={this.state.ConditionsList}
                                                                        placeholder='--Choose Column--'
                                                                        value={list.column}

                                                                    /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Condition</td>
                                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl column-div condition-div'>  <Select
                                                                        onChange={(e) => this.handleUpdate(e, 'condition', key)}
                                                                        options={list.column.values}
                                                                        placeholder='--Choose Condition--'
                                                                        value={list.condition}
                                                                    /></td>
                                                                </tr>
                                                                <tr className={'' + (list.condition.value === 'ISTRUE' || list.condition.value === 'ISNOTTRUE' || list.condition.value === 'ISEMPTY' || list.condition.value === 'ISNOTEMPTY' ? 'hidden' : '')}>
                                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Value</td>
                                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl column-div condition-div'>
                                                                        <span className={' bg-bluetablebg ' + (list.value)}>
                                                                            {list.column.type === 'Number' &&
                                                                                <input type='number' onChange={(e) => this.handleUpdate(e, 'value', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.value} id={'value' + (key)} />
                                                                            }
                                                                            {list.column.type === 'String' &&
                                                                                <input type='text' onChange={(e) => this.handleUpdate(e, 'value', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.value} id={'value' + (key)} />
                                                                            }
                                                                            {list.column.type === 'Date' &&
                                                                                <input type='date' onChange={(e) => this.handleUpdate(e, 'value', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.value} id={'value' + (key)} />
                                                                            }
                                                                            {!list.column &&
                                                                                <input type='text' onChange={(e) => this.handleUpdate(e, 'value', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.value} id={'value' + (key)} />
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                                <tr className={'' + (list.condition.value === "BETWEEN" || list.condition.value === "NOTBETWEEN" || list.condition.value === "IN" || list.condition.value === "BETWEEN" || list.condition.value == 'NOTIN' ? '' : 'hidden')}>
                                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Value</td>
                                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl column-div condition-div'>
                                                                        <span className={' bg-bluetablebg ' + (list.values)}>
                                                                            {list.column.type === 'Number' &&
                                                                                <input type='number' onChange={(e) => this.handleUpdate(e, 'values', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.values} id={'values' + (key)} />
                                                                            }
                                                                            {list.column.type === 'String' &&
                                                                                <input type='text' onChange={(e) => this.handleUpdate(e, 'values', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.values} id={'values' + (key)} />
                                                                            }
                                                                            {list.column.type === 'Date' &&
                                                                                <input type='date' onChange={(e) => this.handleUpdate(e, 'values', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.values} id={'values' + (key)} />
                                                                            }
                                                                            {!list.column &&
                                                                                <input type='text' onChange={(e) => this.handleUpdate(e, 'values', key)} className=' border rounded-md bg-bluetablebg  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' defaultValue={list.values} id={'values' + (key)} />
                                                                            }
                                                                        </span>

                                                                    </td>
                                                                </tr>
                                                                <tr className='bg-white tr-background-delete'>
                                                                    <td className='m-auto' > <span className='py-2 rounded-md cursor-pointer' onClick={() => this.HandleRemove(key)}><img className='w-4 ml-3 mt-1' src='/static/Icons/delete.svg' alt='delete' /></span> </td>
                                                                    <td> </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}

                                            {/* <div className='flex w-full'>
                                                <div className='column-div condition-div w-60'>
                                                    <Select
                                                        onChange={(e) => this.handleChange(e, 'Columns')}
                                                        options={this.state.Column}
                                                        placeholder='Column'
                                                        value={this.state.SelectColumn}
                                                    />
                                                </div>
                                                <div className='column-div condition-div w-60 xl:ml-10 ml-3'>
                                                    <Select
                                                        onChange={(e) => this.handleChange(e, 'Condition')}
                                                        options={this.state.Condition}
                                                        placeholder='Condition'
                                                        value={this.state.SelectCondition}
                                                    />
                                                </div>
                                                <div className='column-div condition-div w-60  xl:ml-10 ml-3'>
                                                    <Select
                                                        onChange={(e) => this.handleChange(e, 'Value')}
                                                        options={this.state.Value}
                                                        placeholder='Value'
                                                        value={this.state.SelectValue}
                                                    />
                                                </div>
                                                <div className={'column-div condition-div w-60  xl:ml-10 ml-3 ' + (this.state.Between == true ? '' : 'hidden')}>
                                                    <Select
                                                        onChange={(e) => this.handleChange(e, 'Values')}
                                                        options={this.state.Value}
                                                        placeholder='Value'
                                                        value={this.state.SelectValues}
                                                    />
                                                </div>
                                                <div className='delete-div xl:ml-10 ml-3 xl:w-8 w-10 border border-bluecolor rounded-lg cursor-pointer' onClick={this.HandleClear}>
                                                    <div className='m-auto  py-1.5'>
                                                        <img src='/static/Icons/delete.svg' alt='' className='w-3 m-auto' />
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                        <hr ></hr>
                                        <h2 className='text-bluecolor font-NotoSans font-semibold  py-1 text-sm py-2 mt-5 px-3'>Add New Criteria</h2>
                                        <div className='main-conditions grid xl:grid-cols-2 grid-cols-1 px-3   gap-3'>
                                            <div className='order-border-table rounded-xl px-4 py-3 my-4 '>

                                                <table className="w-full table-order-mobile" >
                                                    <tbody>
                                                        <tr className=''>
                                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 '>Column</td>
                                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl condition-div no-condition w-8/12'> <Select
                                                                onChange={(e) => this.handleChange(e, 'Columns')}
                                                                options={this.state.ConditionsList}
                                                                placeholder='--Choose Column--'
                                                                value={this.state.SelectColumn}
                                                            /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Condition</td>
                                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl condition-div no-condition w-8/12'> <Select
                                                                onChange={(e) => this.handleChange(e, 'Condition')}
                                                                options={this.state.SelectColumn.values}
                                                                placeholder='--Choose Condition--'
                                                                value={this.state.SelectCondition}
                                                            /></td>
                                                        </tr>
                                                        <tr className={'' + (this.state.SelectCondition?.value === 'ISTRUE' || this.state.SelectCondition?.value === 'ISNOTTRUE' || this.state.SelectCondition?.value === 'ISEMPTY' || this.state.SelectCondition?.value === 'ISNOTEMPTY' ? 'hidden' : '')}>
                                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Value</td>
                                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-1 rounded-r-xl condition-div no-condition w-8/12'>
                                                                <span className={' bg-bluetablebg '}>
                                                                    {this.state.SelectColumn.type === 'Number' &&
                                                                        <input type='number' onChange={(e) => this.handleChange(e, 'Value')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' pattern="\s*\S+.*" title="space only is not allowed" />
                                                                    }
                                                                    {this.state.SelectColumn.type === 'String' &&
                                                                        <input type='text' onChange={(e) => this.handleChange(e, 'Value')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' pattern="[a-zA-Z]+" title="letter only allowed" />
                                                                    }
                                                                    {this.state.SelectColumn.type === 'Date' &&
                                                                        <input type='date' onChange={(e) => this.handleChange(e, 'Value')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' />
                                                                    }
                                                                    {!this.state.SelectColumn &&
                                                                        <input type='text' onChange={(e) => this.handleChange(e, 'Value')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' pattern="\s*\S+.*" title="space only is not allowed" />
                                                                    }
                                                                </span>

                                                                {/* <Select
                                                                    onChange={(e) => this.handleChange(e, 'Value')}
                                                                    options={this.state.Value}
                                                                    placeholder='Value'
                                                                    value={this.state.SelectValue}
                                                                /> */}
                                                            </td>
                                                        </tr>

                                                        <tr className={'' + (this.state.SelectCondition?.value === "BETWEEN" || this.state.SelectCondition?.value === "NOTBETWEEN" || this.state.SelectCondition?.value === "IN" || this.state.SelectCondition?.value === "BETWEEN" || this.state.SelectCondition?.value == 'NOTIN' ? '' : 'hidden')}>
                                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Value</td>
                                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl condition-div no-condition'>
                                                                <span className={' bg-bluetablebg '}>
                                                                    {this.state.SelectColumn.type === 'Number' &&
                                                                        <input type='number' onChange={(e) => this.handleChange(e, 'Values')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' pattern="\s*\S+.*" title="space only is not allowed" />
                                                                    }
                                                                    {this.state.SelectColumn.type === 'String' &&
                                                                        <input type='text' onChange={(e) => this.handleChange(e, 'Values')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' pattern="[a-zA-Z]+" title="letter only allowed" />
                                                                    }
                                                                    {this.state.SelectColumn.type === 'Date' &&
                                                                        <input type='date' onChange={(e) => this.handleChange(e, 'Values')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' />
                                                                    }
                                                                    {!this.state.SelectColumn &&
                                                                        <input type='text' onChange={(e) => this.handleChange(e, 'Values')} className=' border rounded-md  font-OpenSans font-normal h-8 placeholder-bluecolor text-sm text-bluecolor w-full pl-2 font-semibold' placeholder='--Value--' pattern="\s*\S+.*" title="space only is not allowed" />
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='add-new-condition flex px-3 my-5 ml-3 border  rounded-lg cursor-pointer w-48 bg-lightblue' onClick={() => this.AddnewCondition()}>
                                            <span className='font-OpenSans font-semibold text-sm text-bluecolor'>+ Add new condition</span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                </div>

            </div >
        )
    }
};

export default AddCategory;