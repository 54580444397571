import moment from 'moment';
import React, { Component } from 'react';
import local from '../../global/js/locale';
import { convertDate, convertDatetime } from './../../global/js/browserfunction'
import { CurrencyFormat, capitalizeFirstLetter } from './../../global/js/browserfunction'
import { Link } from 'react-router-dom';
class PaymentDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payment: props.Payment,
            paymentdetails: props.paymentdetails,
            PaymentData: '',
            CurrentOrder: '',
            id: props.Id,
            FORMAT: local.locale.DATE.FORMAT,
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.paymentdetails !== this.state.paymentdetails) {
            this.setState({ paymentdetails: nextProps.paymentdetails })
            console.log(nextProps.paymentdetails)
        }

    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)

    }
    render() {
        return (
            < div className='paymentDetail-No h-full py-5 px-6 bg-white shadow-shadowblack' >
                <div className='w-full flex'>
                    <div className='w-8/12 flex'>
                        <img src='/static/Icons/payment.svg' alt='' className='w-10' />
                        <span className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 mt-3'>Payment  {this.state.paymentdetails.transaction_number}</span>
                    </div>
                    <div className='w-4/12 '>
                        {/* <div className={'ml-auto my-auto  w-max ' + (this.state.paymentdetails.refund_number === null && this.state.paymentdetails.status === 'PARTIALLY_BILLED' ? '' : 'hidden')}>
                            <Link to={'/payment/paymentdetails/' + (this.state.id + '/refund')} >
                                <span className='font-OpenSans font-normal  text-white xl:text-base text-base my-auto bg-bluecolor px-4 py-1 rounded-lg cursor-pointer '> Refund</span></Link>
                        </div> */}
                    </div>
                </div>
                <hr className='sortfilterborder my-4' />
                <div className='flex px-4 pb-2'>
                    <div className='xl:w-5/12 w-1/2 '>
                        <div className='mb-4'>
                            <span className='font-OpenSans font-semibold text-sm text-bluecolor'>Transaction No &nbsp;&nbsp; <span className='font-normal xl:ml-2 ml-3'>{this.state.paymentdetails.transaction_number}</span></span>
                        </div>
                        {/* The Mobile view */}
                        <div className='xl:hidden  '>
                            <div className=' flex pb-1 mb-3'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-28 w-32 '>Order&nbsp;&nbsp;</span>
                                <span className='font-normal font-OpenSans  text-sm text-redcolor xl:ml-1'> <Link to={'/order/orderdetail/' + (this.state.paymentdetails.order_header_id)} >{this.state.paymentdetails.order_transaction_number}</Link></span>
                            </div>
                            <div className=' pb-1'>
                                <div className='xl:w-1/2 xl:my-auto mb-3'>
                                    <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max xl:mr-auto '>Transaction Date &nbsp;&nbsp; <span className='font-normal ' > {this.state.paymentdetails.transaction_date !== '' && this.state.paymentdetails.transaction_date !== '' ? convertDatetime(this.state.paymentdetails.transaction_date) : ''}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='xl:flex xl:w-7/12 w-1/2'>
                        <div className='xl:w-1/2 xl:my-auto mb-4'>
                            <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max xl:mr-auto '>Transaction Date &nbsp;&nbsp; <span className='font-normal'> {this.state.paymentdetails.transaction_date !== '' && this.state.paymentdetails.transaction_date !== '' ? convertDatetime(this.state.paymentdetails.transaction_date) : ''}</span></span>
                        </div>
                        <div className='xl:w-1/2 xl:my-auto mb-4 flex'>
                            <span className='font-OpenSans font-semibold text-sm text-bluecolor  xl:block w-32 '>Date Modified &nbsp;&nbsp;  </span>  <span className='font-normal font-OpenSans  text-sm text-bluecolor'> {this.state.paymentdetails.date_modified !== null && this.state.paymentdetails.date_modified !== '' ? convertDatetime(this.state.paymentdetails.date_modified) : ''}</span>
                        </div>
                    </div>
                </div>
                {/* The desktop view */}
                <div className='xl:flex hidden  px-4'>
                    <div className='xl:w-5/12 w-1/2 flex pb-1'>
                        <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-28 w-32 '>Status&nbsp;&nbsp;</span>
                        <span className='font-normal font-OpenSans  text-sm text-bluecolor xl:ml-3 capitalize'> {capitalizeFirstLetter(this.state.paymentdetails.status)}</span>
                    </div>
                    <div className='xl:flex xl:w-7/12 w-1/2 pb-1'>
                        <div className='xl:w-1/2 xl:my-auto mb-4'>
                            <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-28 w-32 '>Order&nbsp;&nbsp;</span>
                            <span className='font-normal font-OpenSans  text-sm text-redcolor ml-20'> <Link className='font-semibold underline' to={'/order/orderdetail/' + (this.state.paymentdetails.order_header_id)} >{this.state.paymentdetails.order_transaction_number}</Link></span>
                        </div>
                        <div className='xl:w-1/2 xl:my-auto mb-4 flex'>
                            <span className='font-OpenSans font-semibold text-sm text-bluecolor  w-32'>Date Created &nbsp;&nbsp;</span> <span className='font-normal font-OpenSans  text-sm text-bluecolor '> {this.state.paymentdetails.date_created !== null && this.state.paymentdetails.date_created !== '' ? convertDatetime(this.state.paymentdetails.date_created) : ''}</span>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};

export default PaymentDetails;