import React, { Component } from 'react';
import local from '../../../global/js/locale';
import Breadthcrumbs from '../../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../../Store/Store'
import { getCookie } from './../../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import '../PaymentDetail.scss'
import './Refund.scss'
import Loader from './../../../components/Loader/FullLoader'
class RefundDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            level: props.level
        }

    }

    /**
* @description The function executed for before the component call
*/
    componentDidMount() {


    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.level !== this.state.level) {
            console.log(this.props.level)
            this.setState({ level: nextProps.level })
        }
    }


    render() {
        return (
            < div className='  ' >
                <div className=" ">
                    <div className="progress-steps xl:ml-10 ml-5 w-max my-5">
                        <div className={" pb-10 " + (this.state.level === 1 || this.state.level === 2 || this.state.level === 3 ? 'complete' : '') + (this.state.level === 0 ? 'actives' : ' Not') + (this.state.level === 3 || this.state.level === 2 ? ' non_active' : ' ')}>
                            <h2 className='text-redcolor'>
                                <span className={"number   " + (this.state.level === 0 || this.state.level === 1 || this.state.level === 2 || this.state.level === 3 ? ' bg-redcolor' : 'bg-darkgrey') + (this.state.level === 0 ? ' num_icons active one' : '')}>
                                    <img src='/static/Icons/refund.svg' alt='' className=' w-6 m-auto mt-1' />
                                </span>
                                <span className={'text-redcolor pl-4  font-OpenSans bottom-3 relative ' + (this.state.level === 0 ? 'font-semibold' : 'font-normal')}> Refund Method</span>
                            </h2>

                        </div>
                        <div className={" pb-10 " + (this.state.level === 2 || this.state.level === 3 ? 'complete' : '') + (this.state.level === 1 ? 'actives' : ' Not') + (this.state.level === 3 ? ' non_active' : ' ')}>
                            <h2 className='text-redcolor'>
                                <span className={"number   " + (this.state.level === 1 || this.state.level === 2 || this.state.level === 3 ? ' bg-redcolor' : 'bg-darkgrey') + (this.state.level === 1 ? ' num_icons active' : '')}>
                                    <img src='/static/Icons/enter.svg' alt='' className=' w-5  mt-1.5 ml-1  ' />
                                </span>
                                <span className={'text-redcolor pl-4  font-OpenSans bottom-3 relative ' + (this.state.level === 1 ? 'font-semibold' : 'font-normal')}> Enter Amount</span>
                            </h2>

                        </div>
                        <div className={" pb-10 " + (this.state.level === 3 ? 'complete' : '') + (this.state.level === 2 ? 'actives' : ' Not')}>
                            <h2 className='text-redcolor'>
                                <span className={"number   " + (this.state.level === 2 || this.state.level === 3 ? 'bg-redcolor' : 'bg-darkgrey') + (this.state.level === 2 ? ' num_icons active' : '')}>
                                    <img src='/static/Icons/confirm.svg' alt='' className=' w-6 m-auto mt-1 ' />
                                </span>
                                <span className={'text-redcolor pl-4  font-OpenSans bottom-3 relative ' + (this.state.level === 2 ? 'font-semibold' : 'font-normal')}> Confirm</span>
                            </h2>

                        </div>
                        <div className={" pb-10 " + (this.state.level === 3 ? 'actives' : ' Not')}>
                            <h2 className='text-redcolor'>
                                <span className={"number   " + (this.state.level === 3 ? ' bg-redcolor' : 'bg-darkgrey') + (this.state.level === 3 ? ' num_icons active' : '')}>
                                    <img src='/static/Icons/payment_status.svg' alt='' className=' w-6 m-auto mt-1 ' />
                                </span>
                                <span className={'text-redcolor pl-4  font-OpenSans bottom-3 relative ' + (this.state.level === 3 ? 'font-semibold' : 'font-normal')}> Success / Fail</span>
                            </h2>

                        </div>

                    </div>
                </div>
            </div >
        )
    }
};

export default RefundDetails;