import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './User.scss'
import cc from '../../data/countrycode.json'
import Select from 'react-select'
import { withRouter } from 'react-router-dom';
import { emailregex } from './../../global/js/validator'
import { newUserAPI } from '../../API/Users';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import Loader from './../../components/Loader/FullLoader'
class NewUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varchar: local.VARCHAR,
            Int: local.INT,
            isLoading: false,
            loading: false,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            country: '',
            phone: '',
            access: false,
            inActive: false,
            valid: false,
            emailvalid: true
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.handleinactiveToggle = this.handleinactiveToggle.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.emailvalidator = this.emailvalidator.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    /**
   * @description The function executed for before the component call
   */
    componentDidMount() {
        window.scrollTo(0, 0)
        var countrycode = cc.CountryCode
        var selectedNumber = countrycode.find((x) => x.code === "+44");
        var code = selectedNumber.code
        this.setState({ country: selectedNumber })
        this._isMounted = true;
    }

    /**
    * @description The function executed for exists the components
    */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
        * @description  Function for update Country code
        * @returns {e}, entered details
        * @returns Display updated Countrycode
    */
    handleCountry(e) {
        if (e.code !== " " || e.code !== undefined) {
            var code = e.code
            this.setState({ country: e })
        }
    }

    /**
        * @description  Function to store values of each fields
        * @param {e} changedvalues
        * @returns entered Values
    */
    handleChange = (e) => {
        if (e.target.id === 'firstName') {
            this.setState({ firstName: e.target.value })
        }
        else if (e.target.id === 'lastName') {
            this.setState({ lastName: e.target.value })
        }
        else if (e.target.id === 'email') {
            this.setState({ email: e.target.value })
        }
        else if (e.target.id === 'password') {
            this.setState({ password: e.target.value })
        }
        else if (e.target.id === 'phone') {
            this.setState({ phone: e.target.value })
        }
        else {
            console.log("ERROR")
        }
    };

    /**
        * @description  Function to toggle values of give Acess field
        * @param {e} changedvalues
        * @returns entered Values
    */
    handleToggle(e) {
        if (e.target.id === 'Acess') { //checking id of acess field checkbox
            var elm = document.getElementById('Acess');
            elm.checked = !elm.checked; // handling toggle actions
            var val = elm.checked
            if (val === true) {
                elm.value = true
            }
            else {
                elm.value = false
            }
            var a = elm.value
        }
        this.setState({ access: a })//setting value to the state 'access'
    }

    /**
        * @description  Function to toggle values of inActive field
        * @param {e} changedvalues
        * @returns entered Values
    */
    handleinactiveToggle(e) {//checking id of inActive field checkbox
        if (e.target.id === 'inActive') {
            var act = document.getElementById('inActive');
            act.checked = !act.checked;// handling toggle actions
            var v = act.checked
            if (v === true) {
                act.value = true
            }
            else {
                act.value = false
            }
            var ab = act.value
            this.setState({ inActive: ab })//setting value to the state 'inActive'
        }

    }

    /**
        * @description  Function to valiadte Email
        * @returns validation message
    */

    emailvalidator(val) {
        if (emailregex.test(val)) {
            this.setState({ emailvalid: true })
            // showNotification("Email Validated", "success");
        } else {
            this.setState({ emailvalid: false })
            // showNotification("Email Invalid", "warning");
        }
    }

    /**
        * @description The function executed for check the valid data
        * @param {token,useremail,firstname,lastname,email,phone,country,access,isinactive,graphQLParam,details},
        *           token,useremail,firstname,lastname,email,phone,country,access,isinactive,graphQLParam,details
        * @returns validation message if the values aren't in correct format, if validates true, calls API for storing details of new user
    */
    handleValidation(token, useremail, firstname, lastname, email, phone, country, access, isinactive, graphQLParam, details) {
        var res = true
        var self = this

        //validating first name
        if (firstname === '') {
            showNotification("Please Provide a First Name", "warning");
        }

        //validating last name
        else if (lastname === '') {
            showNotification("Please Provide a Last Name", "warning");
        }

        //validating email
        else if (email === '' || email === undefined) {
            if (email === undefined || email === '') {
                showNotification("Email must not be empty", "warning")
            }



        }


        //validating phonenumber with countrycode
        else if (phone === '' || country === '') {
            if (country === '' || country === undefined) {
                showNotification("Please Choose a Country code", "warning");
            }
            else {
                showNotification("Please Provide a Phone Number", "warning");
            }
        }

        //validating give access
        else if (access === '') {
            showNotification("Please Select Valid Access Option", "warning");
        }

        //validating inactive
        else if (isinactive === '') {
            showNotification("Please Select Valid  Active Status", "warning");
        }

        else {
            if (this.state.emailvalid === true) {
                newUserAPI(graphQLParam, details)//API for adding new user
                    .then((res) => res.json())
                    .then(function (res) {
                        let reason = res.data.createAdmin.summary.reason
                        let status = res.data.createAdmin.summary.status

                        if (status === 'SUCCESS' && reason === 'RECORD_CREATED') {
                            self.setState({ loading: true })
                            setTimeout(
                                function () {
                                    self.props.history.push('/User')
                                    self.setState({ loading: false })
                                }
                                    .bind(this),
                                1000
                            );

                            showNotification("Success", "success")
                        }
                        else if (status === 'SUCCESS' && reason === 'USER_EXIST') {
                            showNotification("User Exist", "danger");
                        }
                        else {
                            showNotification("Failed", "danger");
                        }
                    })
                    .catch((err) => {
                        showNotification("Something went wrong", "danger")
                    })
            }

        }
    }

    /**
        * @description  Function to set values for adding values of newuser
    */
    handleSave(e) {
        e.preventDefault();
        var self = this
        var obj = JSON.parse(localStorage.getItem("wn_log"))
        var token = obj.access_token
        var useremail = obj.email
        var firstname = self.state.firstName
        var lastname = self.state.lastName
        var email = self.state.email
        var password = self.state.password
        var country = self.state.country !== null && self.state.country.code
        var phone = self.state.phone
        var access = self.state.access
        var isinactive = self.state.inActive

        var qry = {}
        this.emailvalidator(self.state.email)
        qry["EMAIL"] = useremail
        qry["TOKEN"] = token
        qry["USER_EMAIL"] = self.state.email
        qry["FIRST_NAME"] = self.state.firstName
        qry["LAST_NAME"] = self.state.lastName
        qry["PHONE"] = country + " " + self.state.phone
        qry["GIVE_ACCESS"] = self.state.access
        qry["IS_INACTIVE"] = self.state.inActive

        qry = Object.keys(qry)
            .filter((k) => qry[k] !== '')
            .reduce((a, k) => ({ ...a, [k]: qry[k] }), {});

        let stringFields = {//setting string fields
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            USER_EMAIL: "USER_EMAIL",
            FIRST_NAME: "FIRST_NAME",
            LAST_NAME: "LAST_NAME",
            PHONE: "PHONE"
        };
        let booleanFields = { GIVE_ACCESS: "GIVE_ACCESS", IS_INACTIVE: "IS_INACTIVE" };//setting boolean fields

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(qry)) {// converting values to string
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else graphQLParam += `${key}:${Number.isNaN(Number(value)) ? '"' + value + '"' : Number(value)},`;
        }
        let details = [
            "first_name",
            "last_name",
            "email",
            "access_token",
        ];
        self.handleValidation(token, useremail, firstname, lastname, email, phone, country, access, isinactive, graphQLParam, details)  //function call for   
    }

    /**
        * @description  To go back to User page
        * @returns Navigate to User listing page
    */
    handleCancel() {
        this.props.history.push('/User')
    }

    render() {
        return (
            <div className='Category-Add h-screen xl:h-full'>
                < Breadthcrumbs Breadthcrumb={'Add New User'} Count={2} level={'User'} />
                <ReactNotification />
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'category-content mx-5 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                    <form onSubmit={this.handleSave}>
                        <div className='bg-white py-6 px-3 shadow-shadowblack '>
                            <div className="flex">
                                <div className="xl:5/6 w-2/6 flex my-auto">
                                    <h1 className='font-OpenSans  font-semibold text-bluecolor text-2xl px-2 ml-4'>Add New User</h1>
                                </div>
                                <div className='action flex w-max ml-auto my-auto '>
                                    <button type='submit' className='bg-gray-500 text-white font-OpenSans rounded-lg xl:px-1 pl-2 py-1 cursor-pointer W-1/6 flex' >
                                        <img src='/static/Icons/save1.svg' alt='' className='xl:w-4 xl:ml-2 w-4 mt-1 mb-1' />
                                        <span className="xl:w-1/2 w-full text-white text-md ml-2 pr-2">Save</span>
                                    </button>
                                    <span className='bg-none border-none  text-bluecolor font-OpenSans font-normal xl:ml-3 xl:px-2 py-1 ml-2 cursor-pointer xl:text-md text-base' onClick={() => this.handleCancel()}>Cancel</span>
                                </div>
                            </div>
                            <hr className='sortfilterborder my-4' />
                            <div className='mt-5 px-5 m-auto'>
                                <table className="xl:w-1/2 w-9/12">
                                    <tbody>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-right'><span>First Name</span></td>
                                            <td className=' pl-20'>  <input type='text' id='firstName' name='Name' defaultValue={this.state.firstName} onChange={this.handleChange} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor w-64 px-2' maxLength={this.state.varchar['MD']} required /></td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-right'><span>Last Name</span></td>
                                            <td className=' pl-20'>  <input type='text' id='lastName' name='Name' defaultValue={this.state.lastName} onChange={this.handleChange} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor w-64 px-2' maxLength={this.state.varchar['MD']} /></td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-right '><span>Email</span></td>
                                            <td className=' pl-20'>
                                                <input type='text' id='email' name='Name' defaultValue={this.state.email} onChange={this.handleChange} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor w-64   px-2' maxLength={this.state.varchar['MD']} required />
                                                <span className={'font-OpenSans  font-normal  text-redcolor text-sm block py-1 ' + (this.state.emailvalid === false ? '' : 'hidden')}>Invalid Email</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-right'><span>Phone</span></td>
                                            <td className=' pl-20'>
                                                <div className="2xl:flex flex w-48">
                                                    <span className='user-select order-page adduser  my-auto'>
                                                        <Select
                                                            value={this.state.country}
                                                            onChange={this.handleCountry}
                                                            options={cc.CountryCode} isSearchable={true}
                                                        />
                                                    </span>
                                                    <input type='text' id='phone' name='Name' defaultValue={this.state.phone} onChange={this.handleChange} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor w-36  px-2 ml-1' pattern="^(?!0*$)[0-9-]+" required minLength='8' maxLength='15' />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-right'><span>Access</span></td>
                                            <td className=' pl-20'>
                                                <span id="Acess" onClick={this.handleToggle} defaultValue={this.state.access} className='cursor-pointer'>
                                                    <input type="checkbox" id="Access" name='Access' className="form-checkbox sync-netsuite  h-4 w-4  text-white " checked={this.state.access} readOnly={!this._isMounted} />
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 text-right'><span>Inactive</span></td>
                                            <td className=' pl-20'>
                                                <span id="inActive" onClick={this.handleinactiveToggle} defaultValue={this.state.inActive} className='cursor-pointer'>
                                                    <input type="checkbox" id="inAct" name='inAct' className="form-checkbox sync-netsuite  h-4 w-4  text-white " checked={this.state.inActive} readOnly={!this._isMounted} />
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
        )
    }
};

export default withRouter(NewUser);