import { createStore } from 'redux';
var str = window.location.pathname
var urls = "/" + window.location.pathname.split('/')[1];
const initState = {
    todos: false,
    urlchange: urls,
    edit: false,
    update: {},
    Header: false,
    Sidebar: false,
    orderupdate: {
    },
    urlappend: {},
    editUser: false,
    userUpdate: {},
    userData: [],
    AllUpdates: false
}

function reducer(state = initState, action) {
    if (action.type == 'ADD_TODO') {
        return {
            ...state,
            todos: action.todo
        }
    }
    if (action.type == 'URL') {
        return {
            ...state,
            urlchange: action.url
        }
    }
    if (action.type == 'EditOrder') {
        return {
            ...state,
            edit: action.edits
        }
    }
    if (action.type == 'UpdateOrder') {
        return {
            ...state,
            orderupdate: action.UpdateOrder
        }
    }
    if (action.type == 'Update') {
        return {
            ...state,
            update: action.Update
        }
    }
    if (action.type == 'Header') {
        return {
            ...state,
            Header: action.header
        }
    }
    if (action.type == 'Sidebar') {
        return {
            ...state,
            Sidebar: action.sidebar
        }
    }
    if (action.type == 'urlappend') {
        return {
            ...state,
            urlappend: action.Urlappend
        }
    }
    if (action.type == 'userData') {
        return {
            ...state,
            userData: action.Userdata
        }

    }
    if (action.type == 'EditUser') {
        return {
            ...state,
            editUser: action.useredit
        }
    }
    if (action.type == 'UpdateUser') {
        return {
            ...state,
            userUpdate: action.UpdateUser
        }
    }
    if (action.type == 'UpdatesAll') {
        return {
            ...state,
            AllUpdates: action.UpdatesAll
        }
    }
    if (action.type == 'UpdateCharge') {
        return {
            ...state,
            chargeUpdate: action.UpdateCharge
        }
    }
}
const store = createStore(reducer);
export default store;


