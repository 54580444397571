import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import './ProductCategory.scss'
import Progress from '../../components/ProgressBar/Progress';
import Select from 'react-select'
import ImportProduct from '../../data/ImportProduct.json'
import { withRouter } from 'react-router-dom';
import ImportHeader from './ImportHeader';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from '../../components/CircleProgressBar/ChangingProgressProvider';
import ProgressProvider from '../../components/CircleProgressBar/ProgressProvider';
import Export from './Export';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import CSV from './../../Store/Csv'

class Importing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            varchar: local.VARCHAR,
            Int: local.INT,
            isLoading: false,
            Category: props.Category,
            Homepage: props.Homepage,
            Status: 'Home',
            delimiter: '',
            count: 0,
            width: 0


        }

        this.handleStatus = this.handleStatus.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.Handlemove = this.Handlemove.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

    }
    async handleCheck() {
        var File_column = CSV.getState()?.CSVkeys;
        if (File_column === undefined) {
            this.props.history.push('/importproducts')
        } else {
            var tag = document.querySelector("html");
            tag.classList.add("import_page");
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.Handlemove()
        this._isMounted && this.handleCheck()
    }
    componentWillUnmount() {
        this._isMounted = false;
        var Keylist = []
        CSV.dispatch({ type: 'CSV', Key: Keylist })

    }
    /**
        * @description The function executed for change the status
    */
    handleStatus(id) {
        if (this.state.Status === 'Menu') {
            this.setState({ Status: id });
        }
        if (id === 'Home') {
            CSV.dispatch({ type: 'CSV', Key: undefined })

        }
        if (id === 'Menu') {
            if (CSV.getState()?.CSVkeys === undefined) {
                this.props.history.push('/importproducts')
            }
        }
    }

    Handlemove() {
        if (this.state.count == 0) {
            this.setState({ count: 1 })
            var self = this
            var elem = document.getElementById("myBar");
            var width = 0;
            self.setState({ width: width })
            var id = setInterval(frame, 1000);
            function frame() {
                if (width >= 100) {
                    var tag = document.querySelector("html");
                    tag.classList.remove("import_page");
                    self.props.history.push('/importproducts/mapping/importing/done')
                    clearInterval(id);
                    self.setState({ count: 0 })
                } else {
                    width += 20;
                    self.setState({ width: width })
                    // elem.style.width = width + "%";
                }
            }
        }
    }
    /**
        * @description The function executed for navigating to next page 
        * ( Its working with timer now.. after API integration, it will be controlled upon file uploading progress)
    */
    handleContinue() {
        var self = this
        if (this.state.Status === 'Menu') {
            setTimeout(function () {
            }, 6000);

        }
    }

    render() {
        return (
            <div className='Categorys-main  h-screen'>
                <div className='importing_progress'></div>
                < Breadthcrumbs Breadthcrumb={'Products'} Count={2} level={'Import'} />
                <div className='py-1 px-4'>
                    {/* Name of the Page Header */}
                    <ImportHeader />

                    {/* Rest of the portion with step progress bar */}
                    <div className='Categorys-details  py-5 px-6 bg-white shadow-shadowblack mt-8'>
                        <div className='xl:grid xl:grid-cols-2 xl:gap-2 w-3/4'>
                            <div className='categorys grid grid-cols-2 '>

                                {/* Import Header */}
                                {/* <div className={'category-list '}>
                                    <div className={' px-2 border py-2 ' + (this.state.Status === 'Menu' ? 'bg-bluecolor' : ' ')}>
                                        <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Menu')}>
                                            <h2 className={' font-OpenSans text-base font-normal my-auto text-center ' + (this.state.Status === 'Menu' ? 'text-white' : 'text-bluecolor')}  >IMPORT</h2>
                                        </div>

                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 ml-auto relative bottom-0.5 ' + (this.state.Status === 'Menu' ? 'block' : 'hidden')} />
                                </div> */}

                                {/* Export Header */}
                                <div className={'category-list    '}>
                                    <div className={' px-2 border py-2 px-2 ' + (this.state.Status === 'Home' ? 'bg-bluecolor' : ' ')}>
                                        <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Home')}>
                                            <h2 className={' font-OpenSans text-base font-normal my-auto text-center ' + (this.state.Status === 'Home' ? 'text-white' : 'text-bluecolor')}>EXPORT</h2>
                                        </div>
                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 relative bottom-0.5 ml-auto ' + (this.state.Status === 'Home' ? 'block' : 'hidden')} />
                                </div>

                            </div>
                        </div>

                        <div className={'category-down pt-6 ' + (this.state.Status === 'Menu' ? '' : 'hidden')}>
                            <div className='categorys-div '>

                                {/* Step Progress Indicator */}
                                <div className='level0  w-full'>
                                    <Progress level1={"level1"} level2={"level2"} level3={"level3"} />
                                </div>

                                <div className='mt-12 mb-2'>
                                    <span className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl mt-4'>Importing</span>
                                </div>

                                <div className='border-solid border-2 border-gray-200 my-auto border-b mt-2'>
                                    <div className="mb-2 ml-4 justify-center items-center">

                                        <div className="w-full flex">
                                            <div className="w-full xl:mt-8 xl:mb-4 mt-8 ">
                                                <div className=" w-max m-auto">
                                                    <span className='font-OpenSans font-normal text-center text-bluecolor xl:text-md text-md mt-4 '>Your Products are now being imported.....</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full ">
                                            <div className="w-32 m-auto ">
                                                <div className="" onClick={this.handleContinue()}>

                                                    {/* Circle ProgressBar Change automatically from 0-100 */}
                                                    <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                                                        {percentage => (
                                                            <CircularProgressbar
                                                                value={percentage}
                                                                text={`${percentage}%`}
                                                                strokeWidth={2}
                                                                styles={buildStyles({
                                                                    textSize: '30px',
                                                                    textColor: "#6e768e",
                                                                    pathColor: "#bd515f",
                                                                    trailColor: "ACACAC",
                                                                    pathTransitionDuration: 0.5,// How long animation takes to go from one percentage to another, in seconds
                                                                })}
                                                            />
                                                        )}
                                                    </ChangingProgressProvider>


                                                    {/* Circle ProgressBar Change upon component Did Mount */}

                                                    {/* <ProgressProvider valueStart={0} valueEnd={66}>
                                                {(value) => <CircularProgressbar value={value} />}
                                                </ProgressProvider> */}


                                                </div>

                                            </div>
                                            <div id="myProgress" className=' bg- bg_progress xl:w-6/12 w-10/12 m-auto my-6'>
                                                <div id="myBar" className='bg-redcolor h-6' style={{ width: this.state.width + '%' }}></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.Status === 'Home' && <Export />}
                    </div >
                </div>
            </div >
        )
    }
};

export default withRouter(Importing);