import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import paymentmethod from './../../data/PaymentMethod.json'
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './../PaymentMethod/PaymentMethod.scss'
import store from './../../Store/Store'
class PaymentmethodDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Edit: store.getState().edit,
            varchar: local.VARCHAR,
            Int: local.INT,
            methodlist: props.MethodList,
            id: props.Id,
            Currentmethod: ''
        }
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    /**
        * @description The function executed for get the value from store
    */
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }

    /**
        * @description The function executed for when exists component
    */
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }

    /**
        * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var id = this.state.id;
    }

    /**
        * @description The function executed for when update from inputbox store the value as object based on id
        * @param {e}, entered Data
    */
    handleUpdate(e) {
        var key = e.target.name;
        var value = e.target.value;
        var update = store.getState().update
        update[key] = value;
        store.dispatch({ type: 'Update', Update: update })
    }

    render() {
        return (
            <div className='ProductInfo  py-4 mx-5 px-5 my-8 bg-white shadow-shadowblack'>

                <div className='flex'>
                    <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 mt-2 '}>Information</span>
                </div>

                <hr className='sortfilterborder my-4' />

                <div className='flex px-5'>
                    {/* Product Details */}
                    <table className="xl:w-1/2 w-3/4">
                        <tbody>

                            <tr>
                                <td className='py-2 xl:w-40 w-40 '> <span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor '>Image Icon</span></td>
                                <td className='py-2 '> <img src={this.state.methodlist && this.state.methodlist.image_icon} alt='' className={'w-24 pl-3 ' + (this.state.Edit === true ? 'hidden' : 'block')} />
                                    <input type='text' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md w-full  pl-2 ' + (this.state.Edit === true ? 'block' : 'hidden')} name='image_1' defaultValue={this.state.methodlist.image_icon} required onChange={this.handleUpdate} pattern="\s*\S+.*" title="space only is not allowed" />

                                </td>
                            </tr>

                            <tr>
                                <td className='py-2 xl:w-40 w-40'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>API Key</span></td>
                                <td className='py-2 '>
                                    <span className={'font-OpenSans font-normal  text-bluecolor text-sm my-auto ml-3 break-all mt-2 secret-key ' + (this.state.Edit === true ? 'hidden' : 'block')} data-protected-text="*****************************">{this.state.methodlist.token_key ? this.state.methodlist.token_key.slice(0, 50) : ''}</span>
                                    {/* <input type='text' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md w-full  pl-2 ' + (this.state.Edit === true ? 'block' : 'hidden')} name='key' defaultValue={this.state.methodlist.token_key} required onChange={this.handleUpdate} pattern="\s*\S+.*" title="space only is not allowed" /> */}
                                    <input type='text' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md w-full  pl-2 ' + (this.state.Edit === true ? 'block' : 'hidden')} name='key' required onChange={this.handleUpdate} pattern="\s*\S+.*" title="space only is not allowed" />
                                </td>
                            </tr>

                            <tr>
                                <td className='py-2 xl:w-40 w-40 '><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor '>API Secret</span></td>
                                {/* <td className='py-2 '><span className={'font-OpenSans font-normal  text-bluecolor text-sm my-auto ml-3 break-all mt-2 secret-key ' + (this.state.Edit === true ? 'hidden' : 'block')} data-protected-text="*****************************">{this.state.methodlist.token_secret ? this.state.methodlist.token_secret.slice(0, 50) : ''}</span><input type='text' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md w-full  pl-2 ' + (this.state.Edit === true ? 'block' : 'hidden')} name='secret' defaultValue={this.state.methodlist.token_secret} required onChange={this.handleUpdate} pattern="\s*\S+.*" title="space only is not allowed" /></td> */}
                                <td className='py-2 '><span className={'font-OpenSans font-normal  text-bluecolor text-sm my-auto ml-3 break-all mt-2 secret-key ' + (this.state.Edit === true ? 'hidden' : 'block')} data-protected-text="*****************************">{this.state.methodlist.token_secret ? this.state.methodlist.token_secret.slice(0, 50) : ''}</span><input type='text' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md w-full  pl-2 ' + (this.state.Edit === true ? 'block' : 'hidden')} name='secret' required onChange={this.handleUpdate} pattern="\s*\S+.*" title="space only is not allowed" /></td>
                            </tr>

                            <tr>
                                <td className='py-2 xl:w-40  w-40'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>API Url</span></td>
                                <td className='py-2 '>
                                    <span className={'font-OpenSans font-normal  text-bluecolor text-sm my-auto ml-3 mt-2 break-all ' + (this.state.Edit === true ? 'hidden' : 'block')}>{this.state.methodlist.api_url}</span><input type='text' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md w-full  pl-2 ' + (this.state.Edit === true ? 'block' : 'hidden')} name='url' defaultValue={this.state.methodlist.api_url} required onChange={this.handleUpdate} pattern="\s*\S+.*" title="space only is not allowed" /></td>
                            </tr>

                        </tbody>

                    </table>

                </div>

            </div>
        )
    }
};

export default PaymentmethodDetails;