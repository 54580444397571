import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './Customer.scss'
import Filter from './CustomerFilter';
import Loader from '../../components/Loader/Loader'
import { Link } from 'react-router-dom';
import store from './../../Store/Store'
import Update from './../../Store/Update'
import { convertDate } from './../../global/js/browserfunction'
class CustomerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            listcustomer: props.listcustomer,
            CustomerLength: '',
            pageNumbers: [],
            Count: '',
            counts: 1,
            pageid: 1,
            PageStart: 0,
            PageEnd: 20,
            loading: props.loading,
            PageMin: 0,
            PageMax: 5,
            TableView: 'grid',
            sort: false,
            sortdropdown: false,
            SortbyFilter: props.SortByFilter,
            SortValue: "Name - A To Z",
            sorthover: "",
            filtervalue: props.Filterval,
            Cursur: 'none',
            Update: {},
            updates: props.Update,
            List: props.listOrders,
            Total: props.Total,
            updateall: props.updateall,
            popstate: false
        }
        this.handlePage = this.handlePage.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleView = this.handleView.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSortFilter = this.handleSortFilter.bind(this);
        this.handlesorthover = this.handlesorthover.bind(this);
        this.HandleLoad = this.HandleLoad.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.hideOnClickOutside = this.hideOnClickOutside.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }
    /**
  * @description The function executed for props updates
  */
    componentDidUpdate(prevProps) {
        if (prevProps.Filterval !== this.props.Filterval) {
            if (this.props.Filterval === false) {
                this.setState({ sortdropdown: false });
                this.setState({ sort: false });
            }

        }


    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        // if (nextProps.updates !== this.state.updates) {
        //     if (window.location.search === '') {
        //         var update = {
        //             orderno: '',
        //             status: '',
        //             fromdate: '',
        //             todate: '',
        //             sync: '',
        //             name: '',
        //             email: '',
        //             sort: '',
        //             page: 1
        //         }
        //         this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
        //         document.getElementById("orderno").value = "";
        //         document.getElementById("fromdate").value = "";
        //         document.getElementById("todate").value = "";
        //         document.getElementById("name").value = "";
        //         document.getElementById("email").value = "";
        //         console.log('first')
        //         this.handlePage(1)
        //         var order = [];
        //         this.setState({ Orderlist: order });
        //         this.setState({ PageMin: 0, PageMax: 5 });
        //         this.setState({ PageMin: 0, PageMax: 5 });
        //     }
        // }
        if (nextProps.loading !== this.state.loading) {
            this.setState({ loading: nextProps.loading });
        }
        if (nextProps.updateall !== this.state.updateall) {
            console.log(nextProps.updateall)
            if (nextProps.updateall == true) {
                var update = {
                    orderno: '',
                    fromdate: '',
                    todate: '',
                    sync: '',
                    name: '',
                    email: '',
                    sort: '',
                    page: 1
                }
                this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
                this._isMounted && Update.dispatch({ type: 'UpdatesAll', UpdatesAll: false })
                document.getElementById("fromdate").value = "";
                document.getElementById("todate").value = "";
                document.getElementById("name").value = "";
                document.getElementById("email").value = "";
                document.getElementsByName("email")[1].value = "";
                document.getElementsByName("email")[0].value = "";
                this.clearFilters()
                console.log('first')
                this._isMounted && this.handlePage(1)
                var order = [];
                this.setState({ Orderlist: order });
                this.setState({ PageMin: 0, PageMax: 5 });
                this.setState({ PageMin: 0, PageMax: 5 });
            }

        }
        if (nextProps.listcustomer !== this.state.listcustomer) {
            this.setState({ listcustomer: nextProps.listcustomer });
            var page = store.getState().urlappend.page
            this.setState({ PageStart: ((page - 1) * 20) });
            this.setState({ PageEnd: (20 * page) });
            var Count = Math.ceil(page / 5)
            this.setState({ counts: Count });
            var pagemax = 5 * Count
            var pagemin = pagemax - 5
            this.setState({ PageMin: pagemin, PageMax: pagemax });
            var length = nextProps.listcustomer.length;
            this.setState({ PageMin: pagemin, PageMax: pagemax });
            if (length < 20) {
                this.setState({ PageEnd: this.state.PageStart + length });
                this.setState({ Cursur: 'block' });
            }
        }
        if (nextProps.Total !== this.state.Total) {
            this.setState({ Total: nextProps.Total });
            this.setState({ OrderLength: nextProps.Total });
            var length = nextProps.Total;
            var pagenum = [];
            for (let i = 1; i <= Math.ceil(length / 20); i++) {
                pagenum.push(i);
            }
            this.setState({ pageNumbers: pagenum });
            this.setState({ Count: Math.ceil(pagenum.length / 5) });
            // if (length < 10) {
            //     this.setState({ PageEnd: this.state.Total });
            //     this.setState({ Cursur: 'block' });
            // }

        }
    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Update: store.getState().urlappend })
        })

    }
    /**
  * @description The function executed for before the component call
  */
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.HandleLoad();
        window.onpopstate = e => {
            this._isMounted && this.setState({ popstate: true });
            this._isMounted && this.HandleLoad();
            this.props.passToFilter(true)
            this.props.passToFilter(false)


        }

    }
    /**
* @description The function executed for clear the filters
*/
    clearFilters() {
        let target = document.getElementsByName("fromdate")[0];
        let targets = document.getElementsByName("fromdate")[1];
        document.getElementById("fromdate").classList.remove("active");
        document.getElementById("fromdate").classList.add("opacity-60");
        target.setAttribute(
            "data-date", "MM/DD/YYYY"

        );
        targets.setAttribute(
            "data-date", "MM/DD/YYYY"

        );
        let targetto = document.getElementsByName("todate")[0];
        let targettoto = document.getElementsByName("todate")[1];
        document.getElementById("todate").classList.remove("active");
        document.getElementById("todate").classList.add("opacity-60");
        targetto.setAttribute(
            "data-date", "MM/DD/YYYY"

        );
        targettoto.setAttribute(
            "data-date", "MM/DD/YYYY"

        );
    }
    /**
 * @description The function executed for convert url to object and list order
 */
    HandleLoad() {
        window.addEventListener('click', this.hideOnClickOutside)
        //check the parameters and if the value of the sort is exists applay as sort value
        let params = new URLSearchParams(document.location.search)
        if (params.get("sort")) {
            var sort = this.state.SortbyFilter
            var obj = sort.find(item => item.value === params.get("sort"))
            this.setState({ SortValue: obj.label });
        } else {
            var sort = this.state.SortbyFilter
            var obj = sort.find(item => item.value === 'Name_Asc')
            this.setState({ SortValue: obj.label });
        }
        //conver the url parameters as object
        var str = window.location.search;
        if (str != '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            var page = parseInt(update.page)
            if (page != null) {
                //check the url and show the corresponding page
                var popstate = this.state.popstate
                if (popstate == false) {
                    this._isMounted && this.handlePage(page)
                } else {
                    this._isMounted && this.handlePagecheck(page)
                }
                var Count = Math.ceil(page / 5)
                this.setState({ counts: Count });
                var pagemin = this.state.PageMin + (5 * (Count - 1))
                var pagemax = this.state.PageMax + (5 * (Count - 1))
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                console.log(update)
                if (update.name == undefined) {
                    document.getElementById("name").value = "";
                } else {
                    document.getElementById("name").value = update.name;
                }
                if (update.fromdate == undefined) {
                    document.getElementById("fromdate").value = "";
                    let target = document.getElementById("fromdate");
                    document.getElementById("fromdate").classList.remove("active");
                    document.getElementById("fromdate").classList.add("opacity-60");
                    target.setAttribute(
                        "data-date", "MM/DD/YYYY"

                    );

                } else {
                    document.getElementById("fromdate").value = update.fromdate;
                }
                if (update.todate == undefined) {
                    document.getElementById("todate").value = "";
                    let targets = document.getElementById("todate")
                    document.getElementById("todate").classList.remove("active");
                    document.getElementById("todate").classList.add("opacity-60");
                    targets.setAttribute(
                        "data-date", "MM/DD/YYYY"

                    );
                } else {
                    document.getElementById("todate").value = update.todate;
                }
                if (update.email == undefined) {
                    document.getElementsByName("email")[1].value = "";
                    document.getElementsByName("email")[0].value = "";
                } else {
                    document.getElementsByName("email")[1].value = update.email;
                    document.getElementsByName("email")[0].value = update.email;
                }
            }
        } else {
            var update = {}
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            document.getElementById("fromdate").value = "";
            document.getElementById("todate").value = "";
            document.getElementById("name").value = "";
            document.getElementsByName("email")[0].value = "";
            document.getElementsByName("email")[1].value = "";
            console.log(document.getElementsByName("email")[0])
            this.clearFilters()
        }
        // if the page in url null execute the page as one
        if (params.get("page") == null) {
            this._isMounted && this.handlePagecheck(1)
        }
        this._isMounted && this.setState({ popstate: false });

    }
    /**
 * @description The function executed for pagination when click the pagenumber
 */
    handlePagecheck(id) {
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        pageId = id;
        var pageStart = (pageId - 1) * 20;
        var pageEnd = pageId * 20;
        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        //pass the value to parent 
        this.props.passToParent(true)
        this.props.passToParent(false)
        this.setState({ loading: false });



    }
    /**
  * @description The function executed when the pagenumber changes
  * @param {object} id-The pagenumber
  */
    handlePage(id) {
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.history.push({
            pathname: '/customer',
            search: "?" + str.toString()
        })
        pageId = id;
        var pageStart = (pageId - 1) * 20;
        var pageEnd = pageId * 20;
        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        //pass the value to parent 
        this.props.passToParent(true)
        this.props.passToParent(false)
        this.setState({ loading: false });

    }
    /**
  * @description The function executed when the next or prev page has click
  * @param id-the prev or next option
  */
    handlePagination(id) {
        var self = this.state;
        var count = this.state.counts;
        var pagemin = this.state.PageMin;
        var pagemax = this.state.PageMax;
        if (id == 'next') {
            if (self.counts < self.Count) {
                pagemin = pagemin + 5
                pagemax = pagemin + 5
                this.setState({ counts: count + 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)
            }
        }
        if (id == 'prev') {
            if (1 < self.counts) {
                pagemin = pagemin - 5
                pagemax = pagemax - 5
                this.setState({ counts: count - 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)

            }
        }

    }
    /**
  * @description The function executed for the grid view in mobile
  */
    handleView(view) {
        this.setState({ TableView: view });
    }
    /**
  * @description The function executed for the sort dropdown
  */
    handleSort() {
        console.log(this.state.popstate)
        if (this.state.popstate == false) {
            this.setState({ sort: !this.state.sort });
            if (this.state.sortdropdown === false) {
                this.setState({ sortdropdown: !this.state.sortdropdown });
            } else {
                this.setState({ sortdropdown: false });
            }
        }

    }
    /**
* @description The function executed for sort the customer list
*/
    handleSortFilter(value) {
        var sort = this.state.SortbyFilter
        var obj = sort.find(item => item.value === value)
        var update
        this.setState({ SortValue: obj.label }, function () {
            update = store.getState().urlappend;
            update['sort'] = obj.value;
            update = Object.keys(update)
                .filter((k) => update[k] != '')
                .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
            var str = "";
            for (var key in update) {
                if (str != "") {
                    str += "&";
                }
                str += key + "=" + encodeURIComponent(update[key]);
            }
            //dipatch the updated value to store
            store.dispatch({ type: 'urlappend', Urlappend: update })
            this.props.history.push({
                pathname: '/customer',
                search: "?" + str.toString()
            })
            this.props.passToParent(true)
            this.props.passToParent(false)
            setTimeout(
                function () {

                    this.setState({ popstate: false });
                    this.handleSort();
                    this.setState({ sortdropdown: false });
                }
                    .bind(this),
                1000
            );

        });

        this.setState({ sortdropdown: !this.state.sortdropdown });
    }
    /**
* @description The function executes outside click
*/
    hideOnClickOutside() {
        if (this.state.sortdropdown == true) {
            this.setState({ popstate: !this.state.popstate });
            this.handleSort();
        }
    }
    /**
* @description The function executed for change the color of the sort dropdown
*/
    handlesorthover(id) {
        this.setState({ sorthover: id });
    }
    render() {
        return (

            <div className={'Order-Main' + (this.state.filtervalue)}>
                < div className='Order-List h-full shadow-shadowblack mt-7 bg-white px-5' >
                    <div className='items count flex'>
                        <div className={'item-show mt-3 w-5/12 ' + (this.state.listcustomer.length === 0 ? 'hidden' : '')}>
                            <span className='font-OpenSans font-bold text-base text-bluecolor'>Showing {this.state.PageStart + 1} - {this.state.PageEnd} of {this.state.Total} Results</span>
                        </div>
                        <div className={'item-show mt-3 w-5/12 ' + (this.state.listcustomer.length === 0 ? '' : 'hidden')}>
                            <span className='font-OpenSans font-bold text-base text-bluecolor'> {this.state.Total} Results</span>
                        </div>
                        <div className='item-filter  my-auto mt-3 flex w-7/12'>
                            {/* The grid and list view button */}
                            <div className='w-7/12 xl:w-full '>
                                <div className='w-max flex my-auto bg-bluetablebg px-3 py-1 rounded-lg ml-auto'>
                                    <span className='font-OpenSans font-semibold text-base text-bluecolor'>Sort By</span>
                                    <span className={this.state.sort === true ? 'block' : 'hidden'}><input type='text' className='border-none text-sm font-OpenSans font-normal text-sortblue bg-bluetablebg pl-2' readOnly value={this.state.SortValue} /></span>
                                    <span onClick={() => this.handleSort()}><img src='/static/Icons/sort-by.svg' alt='' className='ml-2 mt-1 cursor-pointer ' /> </span>
                                    <div className={'absolute mt-8 bg-bluetablebg rounded-lg z-20 ' + (this.state.sortdropdown === true && this.state.sort === true ? 'block ' : 'hidden ')}>
                                        <div className='block w-full py-2'>
                                            {this.state.SortbyFilter.map(list =>
                                                <div key={list.id} onMouseMove={() => this.handlesorthover(list.id)} onMouseLeave={() => this.handlesorthover(list)}>
                                                    <div className={' font-OpenSans font-semibold text-sm  py-2 px-4 pr-9 w-64 rounded-lg cursor-pointer  ' + (this.state.sorthover === list.id ? 'text-white sort-by ' : 'text-bluecolor ') + (this.state.SortValue === list.label ? 'sort-by-text' : '')} onClick={() => this.handleSortFilter(list.value)}>{list.label}</div>
                                                    <hr className={'sortfilterborder mx-4 ' + (this.state.sorthover === list.id ? 'hidden ' : 'block ') + ((this.state.SortValue === list.label ? 'hidden' : ''))} ></hr>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-3/12 ml-auto my-auto border-l  xl:hidden'>
                                <div className='w-max ml-auto flex'>
                                    <span className='text-xs font-OpenSans text-bluecolor opacity-80 my-auto'>View :</span>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/grid.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}> <img src='/static/Icons/gridred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}><img src='/static/Icons/list.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/listred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                        <Loader loading={this.state.loading} />
                    </div>
                    {/* The orderlist view Desktop */}
                    <div className={'items-text mt-12   hidden ' + (this.state.loading === true ? 'hidden ' : 'xl:block ') + (this.state.listcustomer.length === 0 ? '' : 'pb-6')}>
                        <table className="table-auto w-full mt-3 ">
                            <thead >
                                <tr className='order-table'>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans  py-3'>
                                    </th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Date</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Customer Name</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Email</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Last Login Date</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Access</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Inactive</th>
                                </tr>
                            </thead>
                            <tbody className='mt-2 order-table-body '>
                                {/* The Foreach of the added products */}
                                {this.state.listcustomer.map(list =>
                                    <tr key={list.record_row_id}>
                                        <td className='table-order font-normal text-sm text-bluecolor font-OpenSans  py-3'>
                                            <Link to={'/customer/customerdetails/' + (list.record_row_id)} >  <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' /></Link>
                                        </td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{convertDate(list.date_created)}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.first_name + ' ' + list.last_name}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.email}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{convertDate(list.last_login_date)}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.have_access === true ? 'Yes' : 'No'}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.is_inactive === true ? 'Yes' : 'No'}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* The orderlist view Mobile */}
                    <div className={'items-text mt-16 pb-6  gap-2 xl:hidden  ' + (this.state.loading === true ? 'hidden ' : 'block ') + (this.state.TableView === 'list' ? ' grid grid-cols-1' : 'grid grid-cols-2')}>
                        {this.state.listcustomer.map(list =>
                            <div key={list.record_row_id} className='order-border-table rounded-xl px-4 py-3 mb-4 break-all'>
                                <div className='grid grid-cols-2 gap-2 my-1'>
                                    <div className='w-max mr-auto pl-2 my-auto'>
                                        <span className='text-bluecolor font-NotoSans font-normal opacity-50 text-xs'>{convertDate(list.date_created)}</span>
                                    </div>
                                    <div className='w-max mr-3 pl-2 my-auto ml-auto'>
                                        <Link to={'/customer/customerdetails/' + (list.record_row_id)} > <img src='/static/Icons/view.svg' alt='' className='w-max ml-auto' /></Link>
                                    </div>
                                </div>
                                <hr className='order-view-table'></hr>
                                <h2 className='text-redcolor  text-sm font-OpenSans font-semibold mt-3 py-2'> {list.first_name + ' ' + list.last_name}</h2>
                                <table className="w-full table-order-mobile">
                                    <tbody>
                                        <tr className=''>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 '>Customer Name</td>
                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.first_name + ' ' + list.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Email</td>
                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.email}</td>
                                        </tr>
                                        <tr>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Access</td>
                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.have_access === true ? 'Yes' : 'No'}</td>
                                        </tr>
                                        <tr>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Inactive</td>
                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.is_inactive === true ? 'Yes' : 'No'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className={'w-max m-auto py-3 ' + (this.state.listcustomer.length === 0 ? '' : 'hidden')}>
                        <span className=' font-OpenSans text-bluecolor   text-sm font-semibold'>-- No Result Found --</span>
                    </div>
                </div >
                <div className={'w-max mt-5 m-auto px-5 flex ' + (this.state.listcustomer.length === 0 ? 'hidden' : '')}>
                    <span className={'font-OpenSans text-xs font-regular border border-bluecolor  px-1   flex cursor-pointer ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('prev')}> <img src='/static/Icons/arrowleft.svg' alt='' className='w-5' /> </span>
                    {this.state.pageNumbers.slice(this.state.PageMin, this.state.PageMax).map(list =>
                        <span key={list} className={'font-OpenSans text-xs font-regular border border-bluecolor  px-3 py-1  ' + (this.state.pageid === list ? 'text-white active-page ' : 'text-bluecolor ') + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePage(list)}> {list}</span>
                    )}
                    <span className={'font-OpenSans flex text-xs font-regular border border-bluecolor  px-1 cursor-pointer   ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('next')}> <img src='/static/Icons/arrowright.svg' alt='' className='w-5 flex' /> </span>
                </div>
            </div >
        )
    }
};

export default CustomerList;