import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/FullLoader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './ProductCategory.scss'
import Select from 'react-select'
import store from './../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { listCategoryAPI } from './../../API/Category'
import Condition from './../../data/Condition.json';
class ViewCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Id: props.match.params.id,
            varchar: local.VARCHAR,
            Int: local.INT,
            menubar: true,
            homepage: false,
            Between: false,
            conditions: '',
            ConditionList: [],
            category: {},
            column: '',
            condition: '',
            value: '',
            Conditions: Condition.operator,
            url: '',
            levels: '',
            loading: false,
        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.Handlechange = this.Handlechange.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.Handleconvertcondition = this.Handleconvertcondition.bind(this);
        this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        let params = new URLSearchParams(document.location.search)
        let customer = params.get("is_homepage");
        if (customer === "false") {
            this.Handlechange('Menu')
        }
        else if (customer === 'true') {
            this.Handlechange('Home')
        }
        if (params.get("level1")) {
            this.setState({ levels: params.get("level0") });
        } else if (params.get("level1") && !params.get("level2")) {
            this.setState({ levels: params.get("level1") });
        }

    }
    /**
* @description The function executed captilize string
*/
    capitalizeFirstLetter(string) {
        if (string != undefined) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }


    }

    /**
    * @description The function executed convert the condition
    */
    Handleconvertcondition(category) {
        console.log(category)
        var inactive = category.is_inactive
        this.setState({ inactive: inactive });
        if (category.sql_products_on_category != null && category.sql_products_on_category != '') {
            let getcondition = JSON.parse(category.sql_products_on_category)
            console.log(getcondition)
            let condition_and = (getcondition.indexOf("AND") > -1);
            let condition_OR = (getcondition.indexOf("OR") > -1);
            if (condition_and == true) {
                this.setState({ conditions: 'AND' });
                getcondition = getcondition.filter(e => e !== 'AND')
            } else if (condition_OR == true) {
                this.setState({ conditions: 'OR' });
                getcondition = getcondition.filter(e => e !== 'OR')
            }
            else {
                this.setState({ conditions: 'AND' });
            }
            var result = getcondition.map(function (item) {
                return { column: item[0], condition: item[1], value: item[2], values: item[3] };
            });
            result.forEach(function (arrayItem) {
                if (arrayItem.values == undefined || arrayItem.values == null) {
                    delete arrayItem.values;
                }
            });
            var operator = Condition.operator
            operator.forEach((value, index, self) => {
                if (value.type == "Number") {
                    value['values'] = Condition.number_operation
                }
                if (value.type == "String") {
                    value['values'] = Condition.string_operation
                }
                if (value.type == "Boolean") {
                    value['values'] = Condition.boolean_operation
                }
                if (value.type == "Date") {
                    value['values'] = Condition.date_operation
                }
            })
            result.forEach((value, index, self) => {
                var obj = operator.find(item => item.value === result[index].column.toLowerCase());
                if (obj != undefined) {
                    result[index].column = obj.label
                    if (obj.type === "Number") {
                        var obj1 = Condition.number_operation.find(item => item.value === result[index].condition);
                        console.log(Condition.number_operation)
                        if (obj1 != undefined) {
                            result[index].condition = obj1.label

                        }
                    }
                    if (obj.type === "String") {
                        console.log(result[index].condition)
                        var obj1 = Condition.string_operation.find(item => item.value === result[index].condition);
                        if (obj1 != undefined) {
                            result[index].condition = obj1.label
                        }
                    }
                    if (obj.type === "Boolean") {
                        var obj1 = Condition.boolean_operation.find(item => item.value === result[index].condition);
                        if (obj1 != undefined) {
                            result[index].condition = obj1.label
                        }
                    }
                    if (obj.type === "Date") {
                        var obj1 = Condition.date_operation.find(item => item.value === result[index].condition);
                        if (obj1 != undefined) {
                            result[index].condition = obj1.label
                        }
                    }
                }

            })
            console.log(result)
            this.setState({ ConditionList: result });
        }


    }
    /**
    * @description The function executed change in home and menu bar category
    */
    Handlechange(value) {
        console.log(value)
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            if (value === "Menu") {
                newupdate['MENU_BAR'] = "true"
                newupdate['HOME_PAGE'] = "false"
            }
            else if (value === "Home") {
                newupdate['HOME_PAGE'] = "true"
                newupdate['MENU_BAR'] = "false"
            }
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        console.log(this.Handleconvert(newupdate))
        var self = this;
        self.setState({ loading: true })
        var details = [
            "id",
            "url",
            "label",
            "date_created",
            "date_modified",
            "is_inactive",
            "name",
            "show_in_menubar",
            "order_precedence",
            "is_homepage_category",
            "level",
            "sql_products_on_category",
            "parent_category_id",
        ];
        //api for list orders
        listCategoryAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.listCategory?.summary?.reason;
                let status = res?.data?.listCategory?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listCategory.details;
                    self.setState({ loading: false })
                    // self.setState({ category: data });
                    let params = new URLSearchParams(document.location.search)
                    var id = parseInt(self.state.Id)
                    if (!params.get("level1")) {
                        var category = data.find(item => item.id === id);
                        var url = '&level0=' + (params.get("level0"))
                        self.setState({ url: url })
                    } else if (params.get("level1") && !params.get("level2")) {
                        var level0 = data.find(item => item.label === params.get("level0"));
                        var category = level0.child.find(item => item.id === id);
                        var url = '&level0=' + (params.get("level0")) + '&level1=' + (params.get("level1"))
                        self.setState({ url: url })

                    } else if (params.get("level1") && params.get("level2")) {
                        var level0 = data.find(item => item.label === params.get("level0"));
                        var level1 = level0.child.find(item => item.label === params.get("level1"));
                        var category = level1.child.find(item => item.id === id);
                        var url = '&level0=' + (params.get("level0")) + '&level1=' + (params.get("level1")) + '&level2=' + (params.get("level2"))
                        self.setState({ url: url })
                    }
                    self.setState({ category: category })
                    self.setState({ menubar: category.show_in_menubar })
                    self.setState({ homepage: category.is_homepage_category })
                    self.Handleconvertcondition(category)

                } else {
                    self.setState({ loading: false })
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
    * @description The function executed for conavert the object to string
    */
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { MENU_BAR: "MENU_BAR", HOME_PAGE: "HOME_PAGE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
    * @description The function executed for redirect the page when the cookie are not available
    */
    GoToPage(path) {
        this.props.history.push(path);
    }
    render() {
        return (
            <div className='Category-Add  h-screen xl:h-full '>
                <div className='flex'>
                    < Breadthcrumbs Breadthcrumb={'Details'} Count={4} />
                    <Link to='/category' className='flex bg-white w-64 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/categories_page.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Category page</span>
                    </Link>
                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'category-content mx-5 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                    <div className='bg-white py-6 px-7 shadow-shadowblack mb-10'>
                        <div className='grid grid-cols-2'>
                            <h1 className='font-OpenSans  font-semibold text-bluecolor xl:text-2xl text-xl'>{this.state.category.label}</h1>
                            <Link to={'/category/editcategory/' + (this.state.Id) + '?is_homepage=' + (this.state.category.is_homepage_category) + this.state.url}>
                                <span className='ml-auto w-max block ml-auto mr-4 cursor-pointer'><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>
                            </Link>
                        </div>

                        <hr className='my-5' />
                        <div className='mt-5 px-5 m-auto'>
                            <table className="xl:w-1/2 w-11/12">
                                <tbody>

                                    <tr>
                                        <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 '><span>Name</span></td>
                                        <td className=' pl-20 font-OpenSans font-semibold text-sm text-bluecolor'>{this.state.category.label}</td>
                                    </tr>
                                    <tr className={'' + (this.state.menubar === true ? '' : 'hidden')}>
                                        <td className={'font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 ' + (this.state.menubar === true ? '' : 'hidden')}><span>Order Precedence</span></td>
                                        <td className=' pl-20 font-OpenSans  font-semibold text-bluecolor text-sm'>  {this.state.category.order_precedence}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 '><span>Level</span></td>
                                        <td className=' pl-20 font-OpenSans  font-semibold text-bluecolor text-sm'>   {this.state.category.level}</td>
                                    </tr>
                                    <tr className={this.state.category.parent_category_id === null || this.state.category.parent_category_id === '' ? 'hidden' : ''}>
                                        <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4  '><span>Parent Category</span></td>
                                        <td className=' pl-20 font-OpenSans  font-semibold text-bluecolor text-sm'>{this.state.levels} (ID:{this.state.category.parent_category_id})</td>
                                    </tr>

                                    <tr>
                                        <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 '><span>Show in</span><br></br><span className='font-normal '>(Menubar)</span></td>
                                        <td className=' pl-20'><span className='' >
                                            <input type="checkbox" className={"form-checkbox     h-4 w-4  text-white cursor-pointer " + (this.state.menubar === true ? 'order-sync-netsuite' : 'order-sync')} readOnly />
                                            {/* <input type="checkbox" className={"form-checkbox category-check  h-4 w-4  text-white cursor-pointer"} checked={this.state.menubar} readOnly /> */}
                                            <label className='font-OpenSans font-normal text-sm text-bluecolor ml-2'>Menubar</label>
                                        </span>
                                            <span className='  ml-3' >
                                                <input type="checkbox" className={"form-checkbox    h-4 w-4  text-white cursor-pointer " + (this.state.homepage === true ? 'order-sync-netsuite' : 'order-sync')} readOnly />
                                                {/* <input type="checkbox" className={"form-checkbox category-check  h-4 w-4  text-white cursor-pointer   ml-3"} checked={this.state.homepage} readOnly /> */}
                                                <label className='font-OpenSans font-normal text-sm text-bluecolor ml-2 '>Home Page</label></span> </td>
                                    </tr>
                                    <tr>
                                        <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-4 '><span>Inactive</span></td>
                                        <td className=' pl-20'><span className=''>
                                            <input type="checkbox" className={"form-checkbox    h-4 w-4  text-white cursor-pointer " + (this.state.inactive === true ? 'order-sync-netsuite' : 'order-sync')} />
                                            {/* <input type="checkbox" className={"form-checkbox category-check  h-4 w-4  text-white cursor-pointer " + (this.state.inactive === true ? 'checked' : '')} /> */}
                                        </span></td>
                                    </tr>

                                </tbody>
                            </table>
                            <div className={'conditions-div border mt-5 ' + (this.state.ConditionList.length === 0 ? 'hidden' : '')}>
                                <div className='condition-content px-4 py-2'>
                                    <h2 className='font-OpenSans  font-semibold text-bluecolor text-xl'>Conditions</h2>
                                    <hr className='border-t  my-2'></hr>
                                    <div className='px-3'>
                                        <p className='font-OpenSans  font-normal text-bluecolor text-sm pb-2' >Automatically select products based on conditions</p>
                                    </div>
                                    <div className='conditions-column xl:w-1/2 w-full flex px-3 mt-3'>
                                        <div className='w-max pr-10'>
                                            <p className='font-OpenSans  font-semibold text-bluecolor text-sm ' >Product must match</p>
                                        </div>
                                        <div className='w-1/2 flex'>
                                            <div className='flex cursor-pointer' >
                                                <img src='/static/Icons/radio1.svg ' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'AND' ? 'hidden' : 'block')} />
                                                <img src='/static/Icons/radio2.svg' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'AND' ? 'block' : 'hidden')} />
                                                <span className='font-OpenSans font-semibold text-sm text-bluecolor'>All Conditions</span>
                                            </div>
                                            <div className='flex ml-2 cursor-pointer' >
                                                <img src='/static/Icons/radio1.svg ' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'OR' ? 'hidden' : 'block')} />
                                                <img src='/static/Icons/radio2.svg' alt='' className={'w-3 mr-3 ' + (this.state.conditions == 'OR' ? 'block' : 'hidden')} />
                                                <span className='font-OpenSans font-semibold text-sm text-bluecolor'>Any Conditions</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid xl:grid-cols-2 grid-cols-1  gap-3' >
                                        {
                                            this.state.ConditionList.length != 0 &&
                                            this.state.ConditionList.map((list, key) =>
                                                // <div className='main-conditions flex px-3 mt-5 mb-3 ' key={key}>
                                                //     <div className='flex w-full '>
                                                //         <div className='column-div condition-div w-60 border bg-bluetablebg rounded-md px-3'>
                                                //             <span className='font-OpenSans font-normal text-sm text-bluecolor '>{list.column}</span>
                                                //         </div>
                                                //         <div className='column-div condition-div w-60 xl:ml-10 ml-3 border bg-bluetablebg rounded-md px-2 '>
                                                //             <span className='font-OpenSans font-normal text-sm text-bluecolor '>{list.condition}</span>
                                                //         </div>
                                                //         <div className='column-div condition-div w-60  xl:ml-10 ml-3 border bg-bluetablebg rounded-md px-2 '>
                                                //             <span className='font-OpenSans font-normal text-sm text-bluecolor '>{list.value}</span>
                                                //         </div>
                                                //         {list.values &&
                                                //             <div className={'column-div condition-div w-60  xl:ml-10 ml-3 border bg-bluetablebg rounded-md px-2 '}>
                                                //                 <span className='font-OpenSans font-normal text-sm text-bluecolor '>{list.values}</span>
                                                //             </div>
                                                //         }
                                                //     </div>

                                                // </div>
                                                <div key={key} className='order-border-table rounded-xl px-4 py-3 my-4'>
                                                    <table className="w-full table-order-mobile" key={key}>
                                                        <tbody>
                                                            <tr className=''>
                                                                <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 '>Column</td>
                                                                <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2'>
                                                                    <div className='column-div condition-div w-60 border bg-bluetablebg rounded-md px-3 ml-auto'>
                                                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-max mr-auto block'>{list.column}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Condition</td>
                                                                <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>
                                                                    <div className='column-div condition-div w-60 border bg-bluetablebg rounded-md px-3 ml-auto'>
                                                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-max mr-auto block'>{list.condition}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr className={'' + (list.value === '' || list.value === undefined ? 'hidden' : '')}>
                                                                <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 '>Value</td>
                                                                <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl '>
                                                                    <div className={'column-div column-div-values condition-div w-max  border bg-bluetablebg rounded-md px-3 ml-auto ' + (list.value === '' || list.value === undefined ? 'hidden' : '')} >
                                                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-max mr-auto block'>{this.capitalizeFirstLetter(list.value)}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {list.values &&
                                                                <tr className={'' + (list.values === '' || list.values === undefined ? 'hidden' : '')}>
                                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Value</td>
                                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>
                                                                        <div className={'column-div condition-div column-div-values w-max border bg-bluetablebg rounded-md px-3 ml-auto ' + (list.values === '' || list.values === undefined ? 'hidden' : '')}>
                                                                            <span className='font-OpenSans font-normal text-sm text-bluecolor w-max mr-auto block'>{this.capitalizeFirstLetter(list.values)}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
};

export default ViewCategory;