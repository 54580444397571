import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import store from '../../Store/Store'
import Loader from '../../components/Loader/Loader'
import { getCookie } from './../../global/js/browserfunction'
import { updateWebsiteCacheAPI } from '../../API/Delivery';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
class DeliveryFilter extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            varchar: local.VARCHAR,

        }
    }

    /**
        * @description The function executed for when component changed
    */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
        * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
    }


    render() {
        return (
            < div className='CategorysInformation   bg-white shadow-shadowblack py-5 px-6' >
                <div className='w-full flex'>
                    <div className='flex w-1/2'>
                        <img src='/static/Icons/orderdetail.svg' alt='' className='xl:w-8 w-5' />
                        <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto'>Delivery Charge</h1>
                    </div>
                    <div className='w-1/2 text-right my-auto '>
                        <span className='font-OpenSans font-normal  text-white xl:text-base text-base my-auto bg-bluecolor px-4 py-1 rounded-lg cursor-pointer ' onClick={this.props.handleCache}>Update Website Cache</span>
                    </div>
                </div>
            </div >

        )
    }
};

export default DeliveryFilter;