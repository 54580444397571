import React, { Component } from 'react'
import local from '../../global/js/locale';
import './Dashboard.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { convertDate } from './../../global/js/browserfunction'
import { CurrencyFormatSpace } from './../../global/js/browserfunction'
class CreatedOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: props.CreatedOrders,
            Currency: local.locale.CURRENCY.SYMBOL,
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.CreatedOrders !== this.state.orders) {
            this.setState({ orders: nextProps.CreatedOrders })
        }

    }
    render() {
        return (
            <div className=''>
                <h3 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>Recently Created Orders</h3>
                <div className='added-products relative px-4  '>
                    <table className="table-auto w-full mt-3 ">
                        <thead >
                            <tr>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans bg-bluetablebg py-1 w-14'>
                                    {/* <Link to='/order'>
                                        <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' />
                                    </Link> */}
                                </th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1'>Date</th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1'>Order No</th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1'>Customer Name</th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* The Foreach of the created orders */}
                            {this.state.orders.map(list =>
                                <tr key={list.record_row_id}>
                                    <td className='table-border font-normal text-sm text-bluecolor font-OpenSans bg-bluetablebg py-1'>
                                        <Link to={'/order/orderdetail/' + (list.record_row_id)}>
                                            <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' />
                                        </Link>
                                    </td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1'>{convertDate(list.transaction_date)}</td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1'>{list.transaction_number}</td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1'>{list.customer_name}</td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1'>{CurrencyFormatSpace(list.transaction_total)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className='relative  w-full'>
                        <Link to='/order' className='text-redcolor  font-normal text-xs font-OpenSans underline mt-4 ml-auto mr-5 block w-max'> View all
                        </Link>
                    </div>
                </div>
            </div >
        )
    }
};

export default CreatedOrders;