import React, { Component } from 'react';

function Progress(props) {

    return (

        /* Progress Indicator Section */
        <div className="w-full lg:full mx-auto my-auto pt-8">

            {/* Heading section */}
            <div className="xl:w-9/10 w-full m-auto flex">
                <div className="xl:w-1/4 w-full items-center justify-between mb-5 mx-auto">
                    <h2 className='text-redcolor xl:text-base text-md font-OpenSans font-bold font-normal justify-center mx-auto text-center'>Upload CSV File</h2>
                </div>
                <div className="xl:w-1/4 w-full items-center justify-between mb-5 mx-auto">
                    <h2 className={props.level1 && props.level2 ? 'text-redcolor xl:text-base text-md font-OpenSans font-bold font-normal justify-center mx-auto text-center' : 'text-gray-200 xl:text-base text-md font-OpenSans font-bold font-normal justify-center mx-auto text-center'}>Column Mapping</h2>
                </div>
                <div className="xl:w-1/4 w-full items-center justify-between mb-5 mx-auto">
                    <h2 className={props.level1 && props.level2 && props.level3 ? 'text-redcolor xl:text-base text-md font-OpenSans font-bold font-normal justify-center mx-auto text-center' : 'text-gray-200 xl:text-base text-md font-OpenSans font-bold font-normal justify-center mx-auto text-center'}>Import</h2>
                </div>
                <div className="xl:w-1/4 w-full items-center justify-between mb-5 mx-auto">
                    <h2 className={props.level1 && props.level2 && props.level3 && props.level4 ? 'text-redcolor xl:text-base text-md font-OpenSans font-bold font-normal justify-center mx-auto text-center' : 'text-gray-200 xl:text-base text-md font-OpenSans font-bold font-normal justify-center mx-auto text-center'}>Done!</h2>
                </div>
            </div>

            {/* Progress Indicator Section */}
            <div className="bg-gray-200 h-1 flex items-center border-rounded-lg justify-between xl:w-9/10 w-full m-auto">

                {props.level1 &&
                    <div className="w-1/4 bg-redcolor h-1 rounded-lg flex items-center">
                        <div className="bg-white h-4 w-4 rounded-full bg-redcolor shadow flex items-center justify-center  mx-auto relative">
                            <div className={props.level1 && props.level2 ? "h-2 w-2 bg-redcolor rounded-full" : "h-2 w-2 bg-white rounded-full"} />
                        </div>
                    </div>
                }

                {/* <div className={props.level1 && props.level2 ? "w-1/12 flex justify-between bg-redcolor h-1 items-center relative" : "w-1/12 flex justify-between bg-gray-200 h-1 items-center relative"} /> */}

                <div className={props.level1 && props.level2 ? "w-1/4 bg-redcolor h-1 rounded-lg flex items-center" : "w-1/4 bg-gray-200 h-1 rounded-lg flex items-center"}>
                    <div className={props.level1 && props.level2 ? "bg-white h-4 w-4 rounded-full bg-redcolor shadow flex items-center justify-center mx-auto relative" : "bg-white h-4 w-4 rounded-full bg-gray-200 shadow flex items-center justify-center mx-auto relative"}>
                        <div className={props.level1 && props.level2 && props.level3 ? "h-2 w-2 bg-redcolor rounded-full" : "h-2 w-2 bg-white rounded-full"} />
                    </div>
                </div>

                {/* <div className={props.level1 && props.level2 && props.level3 ? "w-1/12 flex justify-between bg-redcolor h-1 items-center relative" : "w-1/12 flex justify-between bg-gray-200 h-1 items-center relative"} /> */}

                <div className={props.level1 && props.level2 && props.level3 ? "w-1/4 bg-redcolor h-1 rounded-lg flex items-center" : "w-1/4 bg-gray-200 h-1 rounded-lg flex items-center"}>
                    <div className={props.level1 && props.level2 && props.level3 ? "bg-white h-4 w-4 rounded-full bg-redcolor shadow flex items-center justify-center mx-auto relative" : "bg-white h-4 w-4 rounded-full bg-gray-200 shadow flex items-center justify-center mx-auto relative"}>
                        <div className={props.level1 && props.level2 && props.level3 && props.level4 ? "h-2 w-2 bg-redcolor rounded-full" : "h-2 w-2 bg-white rounded-full"} />
                    </div>
                </div>

                {/* <div className={props.level1 && props.level2 && props.level3 && props.level4 ? "w-1/12 flex justify-between bg-redcolor h-1 items-center relative" : "w-1/12 flex justify-between bg-gray-200 h-1 items-center relative"} /> */}

                <div className={props.level1 && props.level2 && props.level3 && props.level4 ? "w-1/4 flex h-1 rounded-lg flex bg-redcolor items-center justify-end" : "w-1/4 flex rounded-lg justify-end"}>
                    <div className={props.level1 && props.level2 && props.level3 && props.level4 ? "bg-white h-4 w-4 rounded-full bg-redcolor shadow flex items-center justify-center mx-auto relative" : "bg-white h-4 w-4 rounded-full bg-gray-200 shadow flex items-center justify-center mx-auto relative"}>
                        <div className="h-2 w-2 bg-white rounded-full" />
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Progress
