
import { BASEURL } from './Baseurl'
/**
     * @description  The function for list the all products
     * @returns all the products
     */
function listPaymentsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listPayments(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       list{
                         ${detail}
                         
                       }
                         totalCount
                       }
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list the payment details
     */
function listPaymentDetailsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchPaymentDetails(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                     
                         ${detail}
                      
                       }
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list the confirmation of the payment refund
     */
function refundMethodAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    refundMethod(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                     
                        old_summary{
                            ${detail}
                            
                          }
                         new_summary{
                            ${detail}
                         }
                      
                       }
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list the refund the amount to customer
     */
function confirmRefundAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    confirmRefund(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                     
                       ${detail}
                      
                       }
                         
                
                }
                }`,
            }
        ),
    })
}
export {
    listPaymentsAPI,
    listPaymentDetailsAPI,
    refundMethodAPI,
    confirmRefundAPI


}