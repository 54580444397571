import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import PaymentDetails from "./PaymentDetails"
import PaymentInformation from './PaymentInformation'
import './PaymentDetail.scss'
import order from '../../data/Order.json'
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import { listPaymentDetailsAPI } from './../../API/Payment'
import Loader from './../../components/Loader/FullLoader'
class PaymentDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paynum: '',
            Id: props.match.params.id,
            paymentdetails: ''
        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleListPayment = this.handleListPayment.bind(this);
        this.HandleString = this.HandleString.bind(this);
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this.setState({ paynum: parseInt(this.state.id) });
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        //dispatch the current url to store
        store.dispatch({ type: 'URL', url: urls })
        var id = this.state.Id
        this.handleListPayment(id)

    }

    /**
* @description The function executed get the orderdetails based on the orderid
*@param id-order id
*/
    handleListPayment(id) {
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "RECORD_ROW_ID": id
            }
            query = this.HandleString(query)
            var details = [
                "record_row_id",
                "transaction_number",
                "date_created",
                "date_modified",
                "is_inactive",
                "order_header_id",
                "payment_method_id",
                "customer_id",
                "transaction_date",
                "payment_number",
                "payment_token",
                "status",
                "currency_id",
                "amount",
                "order_transaction_number",
                "customer_name",
                "customer_email",
                "payment_method_name",
                "currency_name",
                "payment_method_id ",
                "refund_number",
                "refund_amount",
                "transaction_amount",
                "order_header_subtotal",
                "order_header_tax_total",
                "order_header_duty_total",
                "order_header_weight_total",
                "order_header_shipping_total",
                "order_header_transaction_total",
                "order_header_shipping_tax",
                "order_header_delivery_option"

            ];
            console.log(query)
            // api for payment information
            self.setState({ loading: true })
            listPaymentDetailsAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.fetchPaymentDetails?.summary?.reason;
                    let status = res?.data?.fetchPaymentDetails?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var paymentdetails = res.data.fetchPaymentDetails.details;
                        console.log(paymentdetails)
                        self.setState({ paymentdetails: paymentdetails })
                        self.setState({ loading: false })
                    } else {
                        self.setState({ loading: false })
                        self.props.history.push("/payment?page=1");
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            this.GoToPage("/");
        }

    }
    /**
    * @description The function executed for cancel the edit
    */
    HandleString(newupdate) {
        var newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            BILLING_PHONE: "BILLING_PHONE",
            BILLING_PHONE: "SHIPPING_PHONE",
        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", ORDER_HEADER_ID: "ORDER_HEADER_ID" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    render() {
        return (
            < div className='paymentDetail-main xl:h-full h-screen  ' >
                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    < Breadthcrumbs Breadthcrumb={'Payment Details'} Count={2} level={'Payment'} />
                    <Link to='/payment' className='flex bg-white w-52 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/income.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Payment</span>
                    </Link>
                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'payment-content px-5 py-2 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                    <PaymentDetails Payment={this.state.paynum} paymentdetails={this.state.paymentdetails} Id={parseInt(this.state.Id)} />
                    <PaymentInformation Payment={this.state.paynum} paymentdetails={this.state.paymentdetails} Id={parseInt(this.state.Id)} />
                </div>
            </div >
        )
    }
};

export default PaymentDetail;