import React, { Component } from 'react';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import local from '../../global/js/locale';
import store from './../../Store/Store'
import { Link } from 'react-router-dom';
import { generateHeaderTreeAPI, updateHomePageCasheAPI } from './../../API/Category'
import { TestServerAPI, WebstoreServerAPI, LivexserverAPI, CronjobServerAPI } from './../../API/TestServer'
import { fetchLeadTimeInformationAPI, updateLeadTimeInformationAPI, fetchDutyDetailsAPI, updateDutyDetailsInformationAPI } from './../../API/Leadtime'
import { updateWebsiteCacheAPI } from '../../API/Delivery';
import Loader from './../../components/Loader/FullLoader'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { emailregex } from './../../global/js/validator'
import moment from 'moment';
import './setting.scss'
class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Weight: local.locale.WEIGHT.SYMBOL,
            Percent: local.locale.PERCENT.SYMBOL,
            Id: props.match.params.id,
            Edit: false,
            productdetails: {},
            loading: false,
            Livex: '',
            Server: '',
            Web_Server: '',
            Livex_Server: '',
            Cron_Server: '',
            State: '',
            // Uk: '',
            // International: '',
            Markup: '',
            BufferValue: '',
            Update: [],
            vaidateEmail: '',
            EmailList: [],
            DutyList: [],
            DutyUpdate: []

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.HandleupdateCategory = this.HandleupdateCategory.bind(this);
        this.HandleupdateCustom = this.HandleupdateCustom.bind(this);
        this.HandleupdateDelivery = this.HandleupdateDelivery.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.HandleServer = this.HandleServer.bind(this);
        this.HandleEdit = this.HandleEdit.bind(this);
        this.HandleCancel = this.HandleCancel.bind(this);
        this.LeadTimeInformation = this.LeadTimeInformation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleEmailsubmit = this.handleEmailsubmit.bind(this);
        this.handleEmailRemove = this.handleEmailRemove.bind(this);
        this.handleChangeDuty = this.handleChangeDuty.bind(this);


    }
    /**
* @description The function executed for get the updated value from store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
            console.log(store.getState().edit)
        })
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for exists the component
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        window.scrollTo(0, 0)
        this.LeadTimeInformation()
        // this.HandleupdateCategory()
        // this.HandleupdateCustom()
        // this.HandleupdateDelivery()
        this.HandleServer()

    }
    /**
      * @description The function executed cancel the edit option
  */
    HandleCancel() {
        store.dispatch({ type: 'EditOrder', edits: false })
        this.LeadTimeInformation()
    }
    /**
       * @description The function executed open the edit option
   */
    HandleEdit(id) {

        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
        if (!this.state.Edit === false) {
            this.LeadTimeInformation()
        }

    }

    /**
 * @description The function executed for conavert the object to string
 */
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { RECORD_ID: "RECORD_ID", MARKUP: "MARKUP" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function for fetch the leadtime and markup percent
*/
    LeadTimeInformation() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        self.setState({ loading: true })
        var details = [
            "Minimum",
            "Maximum",
            "COUNTRY",
            "TYPE",
            "ID"


        ];
        var dutydetails = [
            "record_row_id",
            "duty_rate",
            "wine_type",
            "wine_volume",

        ]
        //api for list orders
        fetchLeadTimeInformationAPI(query, details, dutydetails)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.fetchLeadTimeInformation?.summary?.reason;
                let status = res?.data?.fetchLeadTimeInformation?.summary?.status;
                console.log(reason, status)
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    self.setState({ loading: false })
                    var Leaddetails = res.data.fetchLeadTimeInformation.details.leadtime;
                    var emaillist = res.data.fetchLeadTimeInformation.details.forwarded_to;
                    self.setState({ EmailList: emaillist })
                    var markup = res.data.fetchLeadTimeInformation.details.markup_percentage;
                    var BufferValue = res.data.fetchLeadTimeInformation.details.buffer_item_value;
                    let uk = Leaddetails.find(o => o.COUNTRY === 'UK');
                    let outside = Leaddetails.find(o => o.COUNTRY === 'International');
                    let Leadtime = Leaddetails.filter(o => o.TYPE !== '');
                    // self.setState({ Uk: uk })
                    // self.setState({ International: outside })
                    console.log(Leadtime, 'Leadtime')
                    self.setState({ Markup: markup })
                    self.setState({ BufferValue: BufferValue })
                    var Dutylist = res.data.fetchLeadTimeInformation.details.duty_details;
                    self.setState({ Dutylist: Dutylist })
                    self.setState({ DutyUpdate: Dutylist })
                    var update = self.state.Update
                    update = Leadtime
                    console.log(uk, outside, markup)
                    console.log(update)
                    self.setState({ Update: update })
                } else {
                  
                    self.setState({ loading: false })
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**

    /**
  * @description The function for update the leadtime and markup
  */
    handleChangeDuty(list, feild, value, id) {
        console.log(list, value, feild, id)
        var DutyUpdate = this.state.DutyUpdate;
        console.log(DutyUpdate, 'dutyUpdate')
        if (DutyUpdate.length > 0) {
            var index = DutyUpdate.findIndex(x => x.record_row_id === feild);
            if (index > -1) {
                DutyUpdate[index][id] = value
            } else {
                list[id] = value
                DutyUpdate.push(list)
            }


            console.log(index);
        }
        else {
            list[id] = value
            DutyUpdate.push(list)

        }
        this.setState({ DutyUpdate: DutyUpdate })

    }
    /**
    * @description The function for update the leadtime and markup
    */
    handleChange(e, keyvalue, type) {
        var update = this.state.Update
        var key = e.target.id;
        var value = e.target.value;
        console.log(key,'key',value)
        console.log('e', e, key, type)
        if (key === 'min') {
            var index = update.findIndex(x => x.TYPE === type);
            update[index]['Minimum'] = parseInt(value)
        }
        else if (key === 'max') {
            var index = update.findIndex(x => x.TYPE === type);
            update[index]['Maximum'] = parseInt(value)
        }
        else if (key === 'markup') {
            this.setState({ Markup: parseFloat(value).toFixed(2) })
        } else if (key === 'BufferValue') {
            this.setState({ BufferValue: parseFloat(value) })
        }

        console.log(update)
        console.log(value, key)
        this.setState({ Update: update })
    }
    /**
    * @description The function for store email for testing
    */
    handleEmail(e) {
        this.setState({ vaidateEmail: e.target.value })
    }
    /**
    * @description The function for remove email from email list
    */
    handleEmailRemove(index) {
        var emaillist = this.state.EmailList
        emaillist.splice(index, 1);
        this.setState({ EmailList: emaillist })
        this.setState({ vaidateEmail: '' })
    }
    /**
    * @description The function for store email and test email is valid and store to array
    */
    handleEmailsubmit() {

        var email = this.state.vaidateEmail
        var emaillist = this.state.EmailList
        if (emaillist.length !== 5) {
            if (emailregex.test(email)) {
                document.getElementById('Email').value = "";
                if (emaillist.includes(email) === true) {
                    const index = emaillist.indexOf(email);
                    if (index > -1) {
                        emaillist.splice(index, 1);
                        emaillist.push(email)
                    }
                    showNotification("already exist", "danger");
                } else {
                    emaillist.push(email)

                }
                this.setState({ EmailList: emaillist })
            } else {
                showNotification("Invalid Email", "danger");
            }
        } else {
            showNotification("Exceed Limit", "danger");
        }
    }
    /**
    * @description The function for update the cache of category
    */
    HandleupdateCategory() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        self.setState({ loading: true })
        var details = [
            "url",

        ];
        //api for list orders
        generateHeaderTreeAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.generateHeaderTree?.summary?.reason;
                let status = res?.data?.generateHeaderTree?.summary?.status;
                if (reason == "RECORD_UPDATED" && status == "SUCCESS") {


                } else {
                    showNotification("Sorry Update Fail", "danger");
                }
            });
    }
    /**
    * @description The function for update the cache of customization
    */
    HandleupdateCustom() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        var details = [
            "category",

        ];
        //api for list orders
        updateHomePageCasheAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.updateHomePageCashe?.summary?.reason;
                let status = res?.data?.updateHomePageCashe?.summary?.status;
                if (reason == "RECORD_UPDATED" && status == "SUCCESS") {


                } else {
                    showNotification("Sorry Update Fail", "danger");
                }
            });
    }
    /**
    * @description The function for check server status
    */
    HandleServer() {
        var self = this;
        //adminserver testing
        TestServerAPI()
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.summary?.reason;
                let status = res?.summary?.status;
                if (reason == "SERVER_RUNNING" && status == "SUCCESS") {
                    self.setState({ Server: true })
                } else {
                    self.setState({ Server: false })
                }
            });
        //webstore testing
        WebstoreServerAPI()
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.summary?.reason;
                let status = res?.summary?.status;
                if (reason == "SERVER_RUNNING" && status == "SUCCESS") {
                    self.setState({ Web_Server: true })
                } else {
                    self.setState({ Web_Server: false })
                }
            });
        CronjobServerAPI()
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.summary?.reason;
                let status = res?.summary?.status;
                if (reason == "SERVER_RUNNING" && status == "SUCCESS") {
                    self.setState({ Cron_Server: true })
                } else {
                    self.setState({ Cron_Server: false })
                }
            });
        //livex testing
        LivexserverAPI()
            .then((res) => res.json())
            .then(function (res) {
                console.log(res)
                let reason = res?.summary?.reason;
                let status = res?.summary?.status;
                if (reason == "LIVEX_RUNNING" && status == "SUCCESS") {
                    self.setState({ Livex_Server: true })
                } else {
                    self.setState({ Livex_Server: false })
                }
            });
    }

    /**
    * @description The function for update the cache of Delivery
    */
    HandleupdateDelivery() {
        var self = this;
        var token;
        var email;
        var newupdate = {};
        if (!getCookie("wn_log")) {//checking for empty cookies
            self.props.history.push("/delivery");
        }
        if (localStorage.getItem("wn_log") !== null) {//checking  for empty token
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token

            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                TOKEN: "TOKEN",
            };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
                var details = [
                    "country"
                ];
            }
            if (!self.state.Edit) {
                updateWebsiteCacheAPI(graphQLParam, details)//API call for updating Cache
                    .then((res) => res.json())
                    .then(function (res) {
                        let reason = res.data.updateDeliveryCashe.summary.reason;
                        let status = res.data.updateDeliveryCashe.summary.status;
                        if (reason == "RECORD_UPDATED" && status == "SUCCESS") {

                        }

                    })
            }
            self.setState({ loading: false })
        } else {
            self.props.history.push("/delivery");
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        var newarray = []
        var self = this;
        var email;
        var token;
        var query
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newarray['EMAIL'] = email
            newarray['TOKEN'] = token
            newarray['MARKUP'] = self.state.Markup
            newarray['BUFFER_ITEM_VALUE'] = self.state.BufferValue
            query = self.Handleconvert(newarray)
            var details = [
                "markup_percentage",

            ];
            var leadlist = self.state.Update
            var emaillist = self.state.EmailList
            leadlist = JSON.stringify(leadlist)
            emaillist = JSON.stringify(emaillist)
            leadlist = leadlist.replace(/"/g, "'");
            emaillist = emaillist.replace(/"/g, "'");
            //api for order information
            self.setState({ loading: true })
            var DutyUpdate = this.state.DutyUpdate
            var Dutystring = JSON.stringify(DutyUpdate)?.replace(/"/g, "'")

            updateLeadTimeInformationAPI(query, leadlist, emaillist, details, Dutystring)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.updateLeadTimeInformation?.summary?.reason;
                    let status = res?.data?.updateLeadTimeInformation?.summary?.status;
                    if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                        self.LeadTimeInformation()
                        self.setState({ loading: false })
                        showNotification("Updated Successfully", "success")

                    } else if (reason == "TOKEN_INVALID" || reason == "TOKEN_EXPIRED") {
                        self.setState({ loading: false })
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                        showNotification("Token Invalid", "danger");
                    }
                    else {
                        self.setState({ loading: false })
                        self.LeadTimeInformation()
                        showNotification("Cannot Update", "danger");

                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })

        } else {
            this.GoToPage("/");
        }
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    render() {
        return (
            < div className='Product-Detail h-full  ' >

                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    <ReactNotification />
                    < Breadthcrumbs Breadthcrumb={'Settings'} />

                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'Product-Details px-5 py-2 ' + (this.state.loading === true ? 'hidden' : 'bg-white mx-5 my-2 shadow-shadowblack')}>
                    <h1 className='font-OpenSans font-semibold xl:text-2xl text-xl text-bluecolor py-1 px-5'>Server status</h1>
                    <hr className="my-5 border "></hr>
                    <div className='px-5'>
                        {this.state.Server === '' ?
                            <div className='flex my-1'>
                                <div className="blob yellow"></div>
                                <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Admin Dashboard Loading.</h2>
                            </div> :
                            this.state.Server === true ?
                                <div className='flex my-1'>
                                    <div className="blob green"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Admin Dashboard Working Fine.</h2>
                                </div>
                                :
                                <div className='flex my-1'>
                                    <div className="blob red"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Admin Dashboard Server Not Working </h2>
                                </div>

                        }
                        {this.state.Web_Server === '' ?
                            <div className='flex my-1'>
                                <div className="blob yellow"></div>
                                <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Webstore Loading.</h2>
                            </div> :
                            this.state.Web_Server === true ?
                                <div className='flex my-1'>
                                    <div className="blob green"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Webstore Working Fine.</h2>
                                </div>
                                :
                                <div className='flex my-1'>
                                    <div className="blob red"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Webstore Server Not Working </h2>
                                </div>
                        }
                        {this.state.Cron_Server === '' ?
                            <div className='flex my-1'>
                                <div className="blob yellow"></div>
                                <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Cronjob Server Loading.</h2>
                            </div> :
                            this.state.Cron_Server === true ?
                                <div className='flex my-1'>
                                    <div className="blob green"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Cronjob Server Working Fine.</h2>
                                </div>
                                :
                                <div className='flex my-1'>
                                    <div className="blob red"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Cronjob Server Not Working </h2>
                                </div>
                        }
                        {this.state.Livex_Server === '' ?
                            <div className='flex my-1'>
                                <div className="blob yellow"></div>
                                <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Livex Server Loading.</h2>
                            </div> :
                            this.state.Livex_Server === true ?
                                <div className='flex my-1'>
                                    <div className="blob green"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Livex Working Fine.</h2>
                                </div>
                                :
                                <div className='flex my-1'>
                                    <div className="blob red"></div>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 my-auto ml-4'>Livex Server Not Working </h2>
                                </div>
                        }
                    </div>
                </div>
                <div className={'Product-Details px-5 py-2 mt-5 ' + (this.state.loading === true ? 'hidden' : 'bg-white mx-5 my-2 shadow-shadowblack')}>
                    <form className='px-5' onSubmit={this.handleSubmit}>
                        <div className='py-4 '>
                            <div className='flex w-full flex '>
                                <div className='w-8/12 flex'>
                                    <h2 className='font-OpenSans font-semibold xl:text-2xl text-xl text-bluecolor py-1'>Global Preferences</h2>
                                </div>
                                <div className={'w-4/12 my-auto ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                    <span className='ml-auto w-max block ml-auto mr-4 cursor-pointer' onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>
                                </div>
                                <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                                    <div className='buttons w-max flex ml-auto h-8'>
                                        <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                            <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                                <div className='flex'>
                                                    <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                                    <span className='ml-2 mx-auto'>Save</span>
                                                </div>
                                            </button>
                                        </div>

                                        <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => this.HandleEdit()}>Cancel</span>
                                    </div>
                                </div>
                            </div>

                            <hr className="my-5 border "></hr>
                            <div className='py-2 outside_uk-lead '>
                                <div className='py-2 outside_uk-lead'>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 pl-2'>Duty</h2>
                                    <div className='xl:w-3/4 w-full pl-2'>
                                        <table className="table-auto w-max ">
                                            <thead>
                                                <tr>
                                                    <th className='text-left text-base   text-bluecolor font-OpenSans font-semibold py-3  w-56'>Wine Type</th>
                                                    <th className='text-left text-base   text-bluecolor font-OpenSans font-semibold py-3  w-56'>Duty</th>
                                                    <th className='text-left text-base   text-bluecolor font-OpenSans font-semibold py-3  w-56'>Measurement (CL)</th>
                                                </tr>
                                            </thead>
                                            <tbody className={'mt-2  '}>
                                                {this.state.Dutylist && this.state.Dutylist.map(list =>
                                                    <tr className={''} key={list.record_row_id}>
                                                        <td className={' text-left text-base   text-bluecolor font-OpenSans font-normal py-3  w-56 '}>{list.wine_type}
                                                        </td>
                                                        <td className={'text-left text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 '}>
                                                            <span className={'w-48 relative ' + (this.state.Edit === true ? 'hidden' : '')}>{list.duty_rate} </span>
                                                            <input max="2000" min='1' type='number' id="Dutyprice" step="any" onInput={(e, i) => this.handleChangeDuty(list, list.record_row_id, parseFloat(e.target.value).toFixed(2), 'duty_rate')} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-44 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={list.duty_rate} />
                                                        </td>
                                                        <td className={'text-left text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 '}>
                                                            <span className={'w-48 relative ' + (this.state.Edit === true ? 'hidden' : '')}>{list.wine_volume} </span>
                                                            <input max="2000" min={Math.ceil(list.duty_rate)} type='number' id="Dutymesure" onInput={(e, i) => this.handleChangeDuty(list, list.record_row_id, parseInt(e.target.value), 'wine_volume')} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-44 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={list.wine_volume} />
                                                        </td>

                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className='py-2 outside_uk-lead '>
                                <div className='py-2 outside_uk-lead'>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 pl-2'>Time</h2>
                                    <div className='xl:w-3/4 w-full pl-2'>
                                        <table className="table-auto w-max ">
                                            <tbody className={'mt-2  '}>
                                                <tr className={''}>
                                                    <td className={' text-left text-base   text-bluecolor font-OpenSans font-semibold py-3  w-56 '}>Time Zone

                                                    </td>
                                                    <td className={'text-left text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 '}>
                                                        <span className={'w-48 relative '}>{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
                                                    </td>

                                                </tr>
                                                <tr className={''}>
                                                    <td className={' text-left text-base   text-bluecolor font-OpenSans font-semibold py-3  w-56 '}>Current Time

                                                    </td>
                                                    <td className={'text-left text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 '}>
                                                        <span className={'w-48 relative '}>{moment().format('DD/MM/YYYY hh:mm A')}</span>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>

                            {/* <div className='py-2 uk-lead'>
                                <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 pl-2'>Uk</h2>
                                <div className='xl:w-3/4 w-full pl-4'>

                                    <table className="table-auto w-max ">
                                        <tbody className={'mt-2 payment-table-body '}>

                                            <tr className={''}>
                                                <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3 '}>Minimum

                                                </td>
                                                <td className='text-center text-base  text-bluecolor font-NotoSans font-normal py-3 w-48'>
                                                    <span className={'w-48 relative ' + (this.state.Edit === true ? 'hidden' : '')}>10</span>
                                                    <input max="100" type='number' id="uk-min" name='Name' className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={'10'} />
                                                </td>
                                                <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3 pl-4'}><span className={''}>Maximum</span>

                                                </td>
                                                <td className='text-center text-base  text-bluecolor font-NotoSans font-normal py-3 w-48'>
                                                    <span className={'w-48 ' + (this.state.Edit === true ? 'hidden' : '')}>10</span>
                                                    <input max="100" type='number' id="uk-max" name='Name' className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={'10'} />
                                                </td>
                                                <td className={' text-left text-sm  text-bluecolor font-NotoSans font-normal py-3   '}>
                                                    <div className='flex'>
                                                        <span className={' px-3 cursor-pointer my-auto ' + (this.state.Edit === true ? 'hidden' : '')}><img src='/static/Icons/edit.svg' alt='' className='w-5 cursor-pointer' onClick={() => this.HandleEdit()} /></span>
                                                        <span className={'px-3 cursor-pointer my-auto bg-bluecolor text-white px-1 ml-3 rounded-lg ' + (this.state.Edit === true ? '' : 'hidden')} onClick={() => this.HandleCancel()}>Save</span>
                                                        <span className={'px-3 cursor-pointer my-auto ' + (this.state.Edit === true ? '' : 'hidden')} onClick={() => this.HandleCancel()}>Cancel</span>
                                                    </div>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div> */}
                            <div className='py-2 outside_uk-lead '>
                                <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 pl-2'>Lead Time</h2>
                                {/* <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 pl-2'>Outside Uk</h2>
                                <hr className="my-2 border "></hr> */}
                                <div className='xl:w-3/4 w-full pl-4'>

                                    <table className="table-auto w-max ">

                                        <tbody className={'mt-2 '}>
                                            {this.state.Update && this.state.Update.map(list =>
                                                <tr className={''} key={list.TYPE}>
                                                    <td>
                                                        <h3 className={'font-OpenSans font-semibold xl:text-lg text-sm text-bluecolor py-1 pl-2' + (list.TYPE === '' ? 'hidden' : '')}>{list.TYPE === 'AVAILABLE_NOW' ? 'SEP' : list.TYPE === 'SIB' ? 'SIB' : list.TYPE === 'DUTY_PAID' ? 'Duty Paid' : ''}</h3>
                                                    </td>
                                                    <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3 w-28 '}>Minimum

                                                    </td>

                                                    <td className={'text-center text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 ' + (this.state.Edit === true ? 'flex' : '')}>
                                                        <span className={'w-48 relative ' + (this.state.Edit === true ? 'hidden' : '')}>{list.Minimum} </span>
                                                        <input max="100" min='0' type='number' id="min" onInput={(e) => this.handleChange(e, 'min', list.TYPE)} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-44 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={list.Minimum} />&nbsp;Days
                                                    </td>
                                                    <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3 pl-4 w-28'}><span className={''}>Maximum</span>

                                                    </td>
                                                    <td className={'text-center text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52  ' + (this.state.Edit === true ? 'flex' : '')}>
                                                        <span className={'w-48 ' + (this.state.Edit === true ? 'hidden' : '')}>{list.Maximum}</span>
                                                        <input max="100" min='0' type='number' id="max" onInput={this.handleChange} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-40 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={list.Maximum} />&nbsp;Days
                                                    </td>

                                                </tr>
                                            )}
                                            {/* <tr className={''}>
                                                <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3 w-28 '}>Minimum

                                                </td>
                                                <td className={'text-center text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 ' + (this.state.Edit === true ? 'flex' : '')}>
                                                    <span className={'w-48 relative ' + (this.state.Edit === true ? 'hidden' : '')}>{this.state.Uk.Minimum} </span>
                                                    <input max="100" min='0' type='number' id="min" onInput={this.handleChange} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-44 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={this.state.Uk.Minimum} />&nbsp;Days
                                                </td>
                                                <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3 pl-4 w-28'}><span className={''}>Maximum</span>

                                                </td>
                                                <td className={'text-center text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52  ' + (this.state.Edit === true ? 'flex' : '')}>
                                                    <span className={'w-48 ' + (this.state.Edit === true ? 'hidden' : '')}>{this.state.Uk.Maximum}</span>
                                                    <input max="100" min='0' type='number' id="max" onInput={this.handleChange} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-40 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={this.state.Uk.Maximum} />&nbsp;Days
                                                </td>


                                            </tr> */}

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                            <div className='py-2 outside_uk-lead'>
                                <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 pl-2'>Markup</h2>

                                <div className='xl:w-3/4 w-full pl-4'>
                                    <table className="table-auto w-max ">
                                        <tbody className={'mt-2  '}>

                                            <tr className={''}>
                                                <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3  w-56 '}>Item Markup Percentage

                                                </td>
                                                <td className={'text-center text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 ' + (this.state.Edit === true ? 'flex' : '')}>
                                                    <span className={'w-48 relative ' + (this.state.Edit === true ? 'hidden' : '')}>{this.state.Markup}</span>
                                                    <input max="100" type='number' id="markup" onInput={this.handleChange} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-40 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={this.state.Markup} /> &nbsp;%
                                                </td>

                                            </tr>
                                            <tr className={''}>
                                                <td className={' text-center text-base  text-bluecolor font-NotoSans font-normal py-3  w-56 '}>Item Buffer Value

                                                </td>
                                                <td className={'text-center text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 ' + (this.state.Edit === true ? 'flex' : '')}>
                                                    <span className={'w-48 relative ' + (this.state.Edit === true ? 'hidden' : '')}>{this.state.BufferValue}</span>
                                                    <input max="100" type='number' id="BufferValue" onInput={this.handleChange} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 w-40 xl:ml-2  ' + (this.state.Edit === true ? '' : 'hidden')} required defaultValue={this.state.BufferValue} />
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className='py-2 outside_uk-lead '>
                                <div className='py-2 outside_uk-lead'>
                                    <h2 className='font-OpenSans font-semibold xl:text-xl text-lg text-bluecolor py-1 pl-2'>Email</h2>
                                    <div className='xl:w-3/4 w-full pl-2'>
                                        <table className="table-auto w-max ">
                                            <tbody className={'mt-2  '}>
                                                <tr className={''}>
                                                    <td className={' text-left text-base   text-bluecolor font-OpenSans font-semibold py-3  w-52 '}>Forward To

                                                    </td>
                                                    <td className={'text-left text-base  text-bluecolor font-NotoSans font-normal py-3 xl:w-60 w-52 ' + (this.state.Edit === true ? '' : 'hidden')}>
                                                        <input maxLength={'50'} type='text' id="Email" onInput={this.handleEmail} className={'font-OpenSans font-normal text-sm  text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-60 w-60 xl:ml-2  '} disabled={this.state.EmailList.length === 5} />
                                                    </td>
                                                    <td className={" text-left text-sm  text-bluecolor font-NotoSans font-normal py-3   " + (this.state.Edit === true ? '' : 'hidden')}>
                                                        <div className="flex"><span className="px-3 cursor-pointer my-auto"><img src="/static/Icons/plus.svg" alt="plus.svg" className="w-5 cursor-pointer" onClick={this.handleEmailsubmit} /></span><span className="px-3 cursor-pointer my-auto"></span></div></td>

                                                </tr>


                                            </tbody>
                                        </table>
                                        <table className={"table-auto w-max " + (this.state.Edit === true ? '' : ' relative bottom-7')}>
                                            <tbody className={'  '}>
                                                {
                                                    this.state.EmailList.length !== 0 &&
                                                    this.state.EmailList.map((list, key) =>
                                                        <tr key={key}>
                                                            <td className={' text-left text-base   text-bluecolor font-OpenSans font-semibold py-3  w-56 mb-2'}>
                                                            </td>
                                                            <td className={' text-left text-base  bg-bluecolor text-white   font-OpenSans font-normal py-1  mb-2 rounded-md  w-max px-3 flex'}>
                                                                {list} <img src="/static/Icons/closebar.svg" alt="plus.svg" className={"w-3 cursor-pointer ml-2 " + (this.state.Edit === true ? '' : 'hidden')} onClick={() => this.handleEmailRemove(key)} />
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div >
        )
    }
};

export default Settings;