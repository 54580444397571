import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './HomePageCustomization.scss'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { deleteHomePageItemAPI, updateHomePageCasheAPI } from './../../API/Category'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
import { convertDate } from './../../global/js/browserfunction'
class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            dropdown: '',
            Banner: [],
            Category: [],
            Products: [],
            Bottom_banner: [],
            parent: 0,
            Banner_section: 1,
            Feature_Category_section: 2,
            Feature_Product_section: 3,
            Bottom_Banner_section: 4,
            Category: props.Category,
            Categorylist: [],
            loading: props.loading
        }

        this.handleDropdown = this.handleDropdown.bind(this);
        this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.handleUpdateCache = this.handleUpdateCache.bind(this);
    }
    componentDidMount() {
        this.props.passToParent(true)
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Category !== this.state.Category) {
            this.setState({ Categorylist: nextProps.Category })
            this.setState({ Banner: nextProps.Category.bannerHeader })
            this.setState({ Category: nextProps.Category.featureCategory })
            this.setState({ Products: nextProps.Category.featureProducts })
            this.setState({ Bottom_banner: nextProps.Category.bottomHeader })
            console.log(nextProps.Category)
        }
        if (nextProps.loading !== this.state.loading) {
            this.setState({ loading: nextProps.loading })
            console.log(nextProps.loading)
        }

    }
    /**
* @description The function executed for dropdown of the cateogies
*/
    handleDropdown(id) {
        if (this.state.dropdown == id) {
            this.setState({ dropdown: null })
        } else {
            this.setState({ dropdown: id })
        }

    }
    handleDeleteCategory(id) {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['RECORD_ID'] = id
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        var details = [
            "category",
        ];
        console.log(query)
        //api for list orders
        deleteHomePageItemAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.deleteHomePageItem?.summary?.reason;
                let status = res?.data?.deleteHomePageItem?.summary?.status;
                if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                    self.props.passToParent(true)
                    showNotification("Removed", "success");

                } else {
                    showNotification("Sorry Category Not Removed", "danger");
                }
            });
    }
    /**
   * @description The function executed for conavert the object to string
   */
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { RECORD_ID: "RECORD_ID" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
  * @description The function executed for update the cache in category
  */
    handleUpdateCache() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        var details = [
            "category",

        ];
        //api for list orders
        updateHomePageCasheAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.updateHomePageCashe?.summary?.reason;
                let status = res?.data?.updateHomePageCashe?.summary?.status;
                if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                    self.props.passToParent(true)
                    showNotification("Updated Record", "success");

                } else {
                    showNotification("Sorry Update Fail", "danger");
                }
            });
    }
    render() {
        return (
            <div className='HomePageCustomization-content px-6'>
                <ReactNotification />
                < div className='CategorysInformation   mt-2' >
                    <div className='w-full flex  bg-white shadow-shadowblack py-5 px-6'>
                        <div className='flex w-1/2'>
                            <img src='/static/Icons/surface.svg' alt='' className='xl:w-8 w-5' />
                            <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto'>Home Page Customization</h1>
                        </div>
                        <div className='w-1/2 text-right my-auto '>
                            <span className='font-OpenSans font-normal  text-white xl:text-base text-base my-auto bg-bluecolor px-4 py-1 rounded-lg cursor-pointer' onClick={() => this.handleUpdateCache()}>Update Website Cache</span>
                        </div>
                    </div>
                    <div className='Customization mt-10 bg-white shadow-shadowblack py-5 px-6'>
                        <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                            <Loader loading={this.state.loading} />
                        </div>
                        <div className={'' + (this.state.loading === true ? 'hidden' : '')}>
                            <div className='home_banner dropdown-main my-1 mb-4'>
                                <div className=' py-2 '>
                                    <div className='cursor-pointer flex w-max' onClick={() => this.handleDropdown(this.state.Banner_section)}>
                                        <div className='my-auto'>
                                            <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Banner_section ? '' : 'hidden')} src='/static/Icons/bold_minus_icon.svg' alt='' />
                                            <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Banner_section ? 'hidden' : '')} src='/static/Icons/bold_plus_icon.svg' alt='' />
                                        </div>
                                        <div className='my-auto'>
                                            <span className='font-OpenSans text-base font-semibold my-auto text-bluecolor'>Banner Header Section</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={' px-4 py-4 pl-10 ' + (this.state.dropdown === this.state.Banner_section ? ' ' : 'hidden')}>
                                    <table className="w-full">
                                        <thead>
                                            <tr>
                                                <th className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto '>Name</th>
                                                <th className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto '>Description</th>
                                                <th className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto '>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Banner.length != 0 &&
                                                this.state.Banner.map((list, index) =>
                                                    <tr className='border border-lightblue bg-white' key={list.record_id}>
                                                        <td className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto text-center border-r py-1 px-2 w-56 xl:w-1/4 w-3/12'>Top Banner</td>
                                                        <td className='font-OpenSans  font-semibold text-bluecolor  text-sm my-auto text-center border-r py-1 px-2 xl:w-1/2 w-5/12 word-breaks-list'>{list?.title}</td>
                                                        <td className='font-OpenSans  font-semibold text-bluecolor text-sm my-auto text-center  py-1'>{convertDate(list?.date)}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-10 flex">
                                                                    <div className='view-action my-auto px-2'>
                                                                        <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=false&Type=Banner&level0=Banner Header Section'}>
                                                                            <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                                                        </Link>
                                                                    </div>
                                                                    <div className='view-action my-auto px-2'>
                                                                        <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=true&Type=Banner&level0=Banner Header Section'}>
                                                                            <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                    {/* <hr className='my-5'></hr> */}
                                    {/* <div className='flex my-2'>
                                    <Link to={'/homepage/customization/' + (this.state.Banner_section) + '?Edit=true&Type=Banner&level0=Banner Header Section'} className='flex'>
                                        <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                        <span className='font-OpenSans  font-semibold text-redcolor text-sm ml-2'>Add New Banner</span></Link>
                                </div> */}
                                </div>

                            </div>
                            <div className='Featured_Category_Section dropdown-main my-1 mb-4'>
                                <div className=''>
                                    <div className=' py-2 '>
                                        <div className='cursor-pointer flex w-max ' onClick={() => this.handleDropdown(this.state.Feature_Category_section)}>
                                            <div className='my-auto'>
                                                <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Feature_Category_section ? '' : 'hidden')} src='/static/Icons/bold_minus_icon.svg' alt='' />
                                                <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Feature_Category_section ? 'hidden' : '')} src='/static/Icons/bold_plus_icon.svg' alt='' />
                                            </div>
                                            <div className='my-auto'>
                                                <span className='font-OpenSans text-base font-semibold my-auto text-bluecolor'>Featured Category Section</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={' px-4 py-4 ml-10 pl-0  ' + (this.state.dropdown === this.state.Feature_Category_section ? '' : 'hidden')}>
                                        {this.state.Category.length != 0 &&
                                            this.state.Category.map((list, index) =>
                                                <div className={'  flex ' + (index != 0 && (this.state.Category.length - 1) != index ? 'border-hr' : '')} key={list.record_id}>
                                                    <div className={' my-auto  ' + (index == 0 ? 'border-testtop' : '') + ((this.state.Category.length - 1) == index ? 'border-testbottom' : '') + (index != 0 && (this.state.Category.length - 1) != index ? 'hidden' : '')}>

                                                    </div>
                                                    <div className='flex bg-white border   border-lightblue py-2 w-full my-1 ' >
                                                        <div className='w-max'>
                                                            <span className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto text-center py-1 px-4'>{list.title}</span>
                                                        </div>
                                                        <div className='flex  w-max ml-auto mr-10'>
                                                            <div className='view-action my-auto px-2'>
                                                                <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=false&Type=Category&level0=Featured Category Section'}>
                                                                    <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                                                </Link>
                                                            </div>
                                                            <div className='view-action my-auto px-2'>
                                                                <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=true&Type=Category&level0=Featured Category Section'}>
                                                                    <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {/* <hr className='my-5'></hr> */}
                                        {/* <div className='flex my-2'>
                                        <Link to={'/homepage/customization/' + (this.state.Feature_Category_section) + '?Edit=true&Type=Category&level0=Featured Category Section'} className='flex'>
                                            <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                            <span className='font-OpenSans  font-semibold text-redcolor text-sm ml-2'>Add New Category</span></Link>
                                    </div> */}
                                    </div>

                                </div>
                            </div>
                            <div className='Featured Product Section dropdown-main my-1 mb-4'>
                                <div className=''>
                                    <div className=' py-2 '>
                                        <div className='cursor-pointer flex w-max' onClick={() => this.handleDropdown(this.state.Feature_Product_section)}>
                                            <div className='my-auto'>
                                                <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Feature_Product_section ? '' : 'hidden')} src='/static/Icons/bold_minus_icon.svg' alt='' />
                                                <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Feature_Product_section ? 'hidden' : '')} src='/static/Icons/bold_plus_icon.svg' alt='' />
                                            </div>
                                            <div className='my-auto'>
                                                <span className='font-OpenSans text-base font-semibold my-auto text-bluecolor'>Featured Product Section</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={' px-4 py-4 ml-10 pl-0   ' + (this.state.dropdown === this.state.Feature_Product_section ? '' : 'hidden')}>
                                        {this.state.Products.length != 0 &&
                                            this.state.Products.map((list, index) =>
                                                <div className={'  flex ' + (index != 0 && (this.state.Products.length - 1) != index ? 'border-hr' : '')} key={list.record_id}>
                                                    <div className={' my-auto  ' + (index == 0 ? 'border-testtop' : '') + ((this.state.Products.length - 1) == index ? 'border-testbottom' : '') + (index != 0 && (this.state.Products.length - 1) != index ? 'hidden' : '')}>
                                                    </div>
                                                    <div className='flex bg-white border border-lightblue py-2 w-full my-1' >
                                                        <div className='w-max'>
                                                            <span className='font-OpenSans  text-sm font-semibold text-bluecolor  my-auto text-center py-1 px-4'>{list.title}</span>
                                                        </div>
                                                        <div className='flex  w-max ml-auto mr-10'>
                                                            <div className='view-action my-auto px-2'>
                                                                <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=false&Type=Product&level0=Featured Product Section'}>
                                                                    <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                                                </Link>
                                                            </div>
                                                            <div className='view-action my-auto px-2'>
                                                                <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=true&Type=Product&level0=Featured Product Section'}>
                                                                    <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                                </Link>
                                                            </div>
                                                            <div className='view-action my-auto px-2'>
                                                                <img src='/static/Icons/delete.svg' alt='' className='w-4 my-auto cursor-pointer' onClick={() => this.handleDeleteCategory(list.record_id)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        <hr className='my-5'></hr>
                                        <div className='flex my-2'>
                                            <Link to={'/homepage/customization/' + (this.state.Feature_Product_section) + '?Edit=true&Type=Category&level0=Featured Product Section'} className='flex'>
                                                <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                <span className='font-OpenSans  font-semibold text-redcolor text-sm ml-2'>Add New Category</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bottom_banner dropdown-main my-1 mb-4'>
                                <div className=' py-2 '>
                                    <div className='cursor-pointer flex w-max' onClick={() => this.handleDropdown(this.state.Bottom_Banner_section)}>
                                        <div className='my-auto'>
                                            <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Bottom_Banner_section ? '' : 'hidden')} src='/static/Icons/bold_minus_icon.svg' alt='' />
                                            <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.dropdown === this.state.Bottom_Banner_section ? 'hidden' : '')} src='/static/Icons/bold_plus_icon.svg' alt='' />
                                        </div>
                                        <div className='my-auto'>
                                            <span className='font-OpenSans text-base font-semibold my-auto text-bluecolor'>Bottom Banner Section</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={' px-4 py-4 pl-10 ' + (this.state.dropdown === this.state.Bottom_Banner_section ? ' ' : 'hidden')}>
                                    <table className="w-full">
                                        <thead>
                                            <tr>
                                                <th className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto '>Name</th>
                                                <th className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto '>Description</th>
                                                <th className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto '>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Bottom_banner.length != 0 &&
                                                this.state.Bottom_banner.map(list =>
                                                    <tr className='border border-lightblue bg-white' key={list.record_id}>
                                                        <td className='font-OpenSans  font-semibold text-bluecolor text-sm  my-auto text-center border-r py-1 px-2 w-56 xl:w-1/4 w-3/12'>Bottom Banner</td>
                                                        <td className='font-OpenSans  font-semibold text-bluecolor  text-sm my-auto text-center border-r py-1 px-2 xl:w-1/2 w-5/12 word-breaks-list'>{list.title}</td>
                                                        <td className='font-OpenSans  font-semibold text-bluecolor text-sm my-auto text-center  py-1'>{convertDate(list?.date)}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 flex h-8">
                                                                    <div className='view-action my-auto  px-2'>
                                                                        <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=false&Type=Bottombanner&level0=Bottom Banner Section'}>
                                                                            <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                                                        </Link>
                                                                    </div>
                                                                    <div className='view-action my-auto px-2'>
                                                                        <Link to={'/homepage/customize/' + (list.record_id) + '?Edit=true&Type=Bottombanner&level0=Bottom Banner Section'}>
                                                                            <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                    {/* <hr className='my-5'></hr> */}
                                    {/* <div className='flex my-2'>
                                    <Link to={'/homepage/customization/' + (this.state.Bottom_Banner_section) + '?Edit=true&Type=Banner&level0=Bottom Banner Section'} className='flex'>
                                        <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                        <span className='font-OpenSans  font-semibold text-redcolor text-sm ml-2'>Add New Banner</span></Link>
                                </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </div >

        )
    }
};

export default HomePage;