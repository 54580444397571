import React, { Component } from 'react';
import local from '../../../global/js/locale';
import { Link } from 'react-router-dom';
import '../PaymentDetail.scss'
import locale from '../../../global/js/locale';
import { refundMethodAPI, confirmRefundAPI } from './../../../API/Payment'
import Loader from './../../../components/Loader/FullLoader'
import { CurrencyFormat, capitalizeFirstLetter } from './../../../global/js/browserfunction'
class RefundConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Countinue: "Confirm >>",
            Back: "<< Back",
            Int: locale.INT,
            Status: props.Option,
            Amounts: props.Amount,
            Id: props.Id,
            Head_Id: props.Head_Id,
            paymentdetails: {},
            old_summary: {},
            new_summary: {},
            RefundStatus: ''


        }
        this.handleContinue = this.handleContinue.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.HandleString = this.HandleString.bind(this);
        this.HandleRefundDetails = this.HandleRefundDetails.bind(this);
        this.HandleConfirmRefund = this.HandleConfirmRefund.bind(this);
    }
    /**
   * @description The function executed for redirect the page
   */
    GoToPage(path) {
        this.props.history.push(path);
    }

    /**
    * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
        if (this.state.Status === 'MANUAL') {
            if (Object.entries(this.state.Amounts).length === 0) {
                this.props.history.push({
                    pathname: '/payment/paymentdetails/' + this.state.Id
                })
            }
            else {

                this._isMounted && this.HandleRefundDetails()
            }


        } else {
            this._isMounted && this.HandleRefundDetails()
        }
    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    /**
   * @description The function executed for fetch the refund details
   */
    HandleRefundDetails() {
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "ORDER_HEADER_ID": this.state.Head_Id,
                "REFUND_METHOD": self.state.Status
            }
            var Amount = self.state.Amounts;
            if (this.state.Status === 'MANUAL') {
                query = { ...query, ...Amount };
            }

            console.log(query)
            query = this.HandleString(query)
            var details = [
                "subtotal",
                "tax_total",
                "duty_total",
                "weight_total",
                "shipping_total",
                "transaction_total",
                "refund_amount"

            ];
            console.log(query)
            // api for payment information
            self.setState({ loading: true })
            refundMethodAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.refundMethod?.summary?.reason;
                    let status = res?.data?.refundMethod?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var paymentdetails = res.data.refundMethod.details;
                        console.log(paymentdetails)
                        self.setState({ paymentdetails: paymentdetails })
                        self.setState({ old_summary: paymentdetails.old_summary })
                        self.setState({ new_summary: paymentdetails.new_summary })
                        self.setState({ loading: false })
                    } else {
                        self.setState({ loading: false })
                        self.props.history.push("/payment/paymentdetails/" + self.state.Id);
                    }
                });

        } else {
            this.GoToPage("/");
        }
    }
    /**
  * @description The function executed refund the amount to customer.
  */
    HandleConfirmRefund() {
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "ORDER_HEADER_ID": this.state.Head_Id,
                "REFUND_METHOD": self.state.Status,
                "CONFIRM_REFUND": true,
            }
            query = this.HandleString(query)
            var details = [
                "message",

            ];
            console.log(query)
            // api for payment information
            self.setState({ loading: true })
            confirmRefundAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.confirmRefund?.summary?.reason;
                    let status = res?.data?.confirmRefund?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var RefundStatus = res.data.confirmRefund.details.message;
                        self.setState({ RefundStatus: RefundStatus })
                        self.props.passToSucess(RefundStatus)
                        self.setState({ loading: false })
                        self.props.history.push({
                            pathname: window.location.pathname,
                            search: "?status=Refund"
                        })
                        self.props.passToRefund()
                    } else {
                        self.setState({ RefundStatus: 'STRIPE_REFUND_NOT_SUCCEEDED' })
                        self.props.passToSucess(RefundStatus)
                        self.setState({ loading: false })
                        self.props.history.push({
                            pathname: window.location.pathname,
                            search: "?status=Refund"
                        })
                        self.props.passToRefund()
                        self.props.history.push("/payment/paymentdetails/" + self.state.Id);
                    }
                });

        } else {
            this.GoToPage("/");
        }
    }
    handleContinue(e) {
        e.preventDefault();
        var self = this
        let params = new URLSearchParams(document.location.search)
        if (params.has("status")) {
            let status = params.get("status")
            this.HandleConfirmRefund()




        }
    }
    handleBack() {
        var self = this;
        if (this.state.Status === 'MANUAL') {
            self.props.history.push({
                pathname: window.location.pathname,
                search: "?status=Amount"
            })
        } else {
            self.props.history.push({
                pathname: window.location.pathname
            })
        }

        this.props.passToRefund()
    }
    /**
  * @description The function executed for cancel the edit
  */
    HandleString(newupdate) {
        var newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            BILLING_PHONE: "BILLING_PHONE",
            BILLING_PHONE: "SHIPPING_PHONE",
            REFUND_METHOD: "REFUND_METHOD"
        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", ORDER_HEADER_ID: "ORDER_HEADER_ID", CONFIRM_REFUND: "CONFIRM_REFUND", SHIPPING_TOTAL: "SHIPPING_TOTAL", TRANSACTION_TOTAL: "TRANSACTION_TOTAL", };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    render() {
        return (
            <div>
                <div
                    className=" grid xl:grid-cols-1 md:grid-cols-1 "
                >
                    <h2 className='font-OpenSans font-semibold  text-redcolor xl:text-base text-base my-2 w-11/12 m-auto opacity-90'>Note that refund can only be processed once. </h2>

                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className=" grid xl:grid-cols-2 gap-5 md:grid-cols-1 w-11/12 m-auto mb-10">
                    <div className={'order-summary order-sums mt-3 '}>
                        <div className=' px-5  ml-auto py-3'>
                            <h2 className='font-OpenSans font-semibold  text-orderred  text-sm text-xs pb-3'>Old  Summary</h2>
                            <table className="w-full">
                                <tbody className=''>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm '>Sub Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm w-10'>{CurrencyFormat(this.state.old_summary.subtotal)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Tax Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.old_summary.tax_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className='py-1 font-OpenSans font-normal  text-orderred  text-sm'>Duty Paid</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.old_summary.duty_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Weight Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.old_summary.weight_total == null ? 0 : (this.state.old_summary.weight_total / 50).toFixed(2)}Kg</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Shipping Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.old_summary.shipping_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-bold pt-2 text-orderred  text-sm'>Transaction Total</td>
                                        <td className='text-left py-1 font-OpenSans  pt-2 font-bold  text-orderred  text-sm'>{CurrencyFormat(this.state.old_summary.transaction_total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={'order-summary order-sum xl:mt-3'}>
                        <div className=' px-5  ml-auto py-3'>
                            <h2 className='font-OpenSans font-semibold  text-orderred  text-sm text-xs pb-3'>New  Summary</h2>
                            <table className="w-full">
                                <tbody className=''>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm '>Sub Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm w-10'>{CurrencyFormat(this.state.new_summary.subtotal)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Tax Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.new_summary.tax_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className='py-1 font-OpenSans font-normal  text-orderred  text-sm'>Duty Paid</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.new_summary.duty_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Weight Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.new_summary.weight_total == null ? 0 : (this.state.new_summary.weight_total / 50).toFixed(2)}Kg</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Shipping Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.new_summary.shipping_total)}</td>
                                    </tr>
                                    <tr className={'' + (this.state.Status === 'MANUAL' ? '' : 'hidden')}>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Refund Amount</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.new_summary.refund_amount)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-bold pt-2 text-orderred  text-sm'>Transaction Total</td>
                                        <td className='text-left py-1 font-OpenSans  pt-2 font-bold  text-orderred  text-sm'>{CurrencyFormat(this.state.new_summary.transaction_total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='w-full flex my-10'>
                    <div className=" my-auto">
                        <div className='w-max  ml-5'>
                            <div className='flex items-end'>
                                <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer W-1/6 flex items-end' onClick={this.handleBack}>
                                    <span className="xl:w-full w-full text-white text-md mx-4 whitespace-nowrap"> {this.state.Back} </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='w-max ml-auto mr-5'>
                        <div className='flex items-end'>
                            <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer W-1/6 flex items-end' onClick={this.handleContinue}>
                                <span className="xl:w-full w-full text-white text-md mx-4 whitespace-nowrap"> {this.state.Countinue} </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
};

export default RefundConfirm;