import { store } from 'react-notifications-component';

const showNotification = function (title, type, message) {
    store.addNotification({
        title: title,
        message: ' ',
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 3000,
            onScreen: true
        }
    });
}


const noDismissNotification = function (title, type, message) {
    store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-full",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"]
    });
}


export { showNotification, noDismissNotification };
