import moment from 'moment';
import local from '../../global/js/locale';
var FORMAT = local.locale.DATE.FORMAT;
const setCookie = function (name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const setCookieOneHr = function (name, value, hr) {
    var expires = "";
    if (hr) {
        var date = new Date();
        date.setTime(date.getTime() + (hr * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

const eraseCookie = function (name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
/**
   * @description The function executed for convert the date with timezone to date only
   */
function convertDate(inputFormat) {

    if (inputFormat != null && inputFormat != undefined) {


        var zone = moment(inputFormat).format(FORMAT)
        return zone
    }

}
function convertDatetime(inputFormat) {

    if (inputFormat != null && inputFormat != undefined) {


        var zone = moment(inputFormat).format(FORMAT + ' hh:mm A')
        return zone
    }

}
function convertDatetimesecond(inputFormat) {

    if (inputFormat != null && inputFormat != undefined) {


        var zone = moment(inputFormat).format(FORMAT + ' hh:mm:ss A')
        return zone
    }

}
/**
   * @description The function executed for convert the integer to currency format
   */
function CurrencyFormat(inputFormat) {
    if (inputFormat != null && inputFormat != undefined) {
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }, { maximumSignificantDigits: 2 }).format(inputFormat)
    }
}
function CurrencyFormatSpace(inputFormat) {
    if (inputFormat != null && inputFormat != undefined) {
        var x = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }, { maximumSignificantDigits: 2 }).format(inputFormat)
        var y = x.charAt(0) + " " + x.substr(1)
        return y
    }
}
/**
    * @description The function for capitalize the string.
    *
    */
function capitalizeFirstLetter(string) {
    if (string != undefined) {
        var strings = string.toLowerCase().replace(/_/g, " ");
        return strings.charAt(0).toUpperCase() + strings.slice(1);
    }
}
function convertFilename(string) {
    if (string != undefined) {
        var filename = string.replace(/^.*[\\\/]/, '')
        return filename;
    }
}
export { setCookie, setCookieOneHr, getCookie, eraseCookie, convertDate, CurrencyFormat, CurrencyFormatSpace, convertDatetime, capitalizeFirstLetter, convertFilename, convertDatetimesecond }