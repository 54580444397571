import React, { Component } from 'react';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import ProductDetails from './AddProductDetails';
import ProductInfo from './AddProductInfo';
import product from '../../data/Product.json'
import local from '../../global/js/locale';
import Country from '../../data/Country.json'
import ProductImage from './AddProductImage';
import store from '../../Store/Store'
import { getCookie } from '../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import { fetchProductDetailsAPI, insertProductAPI } from '../../API/Product'
import Loader from '../../components/Loader/FullLoader'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from '../../global/js/notification'
import { emailregex } from './../../global/js/validator'
class AddProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Weight: local.locale.WEIGHT.SYMBOL,
            Percent: local.locale.PERCENT.SYMBOL,
            Edit: '',
            product: product.ProductList,
            productdetails: {},
            loading: false,
            emailvalid: true,

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Hanldeconditionsubmit = this.Hanldeconditionsubmit.bind(this);
        this.cleanobject = this.cleanobject.bind(this);
    }
    /**
* @description The function executed for get the updated value from store
*/
    async handleCheck() {
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for exists the component
*/
    componentWillUnmount() {
        this._isMounted = false;
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        var id = this.state.Id
        // this._isMounted && this.handleListorder(id)
    }
    /**
* @description The function executed for cancel the edit
*/
    HandleString(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            BILLING_PHONE: "BILLING_PHONE",
            BILLING_PHONE: "SHIPPING_PHONE",
            CONDITION_IMAGE_1: "CONDITION_IMAGE_1",
            CONDITION_IMAGE_2: "CONDITION_IMAGE_2",
            CONDITION_IMAGE_3: "CONDITION_IMAGE_3",
            CONDITION_IMAGE_4: "CONDITION_IMAGE_4",
            CONDITION_IMAGE_5: "CONDITION_IMAGE_5",
            IMAGE_1: "IMAGE_1",
            IMAGE_2: "IMAGE_2",
            IMAGE_3: "IMAGE_3",
            SALES_DESCRIPTION: "SALES_DESCRIPTION",
            NAME: "NAME",
            TITLE: "TITLE",
            SKU: "SKU",
            WINE_COLOR: "WINE_COLOR",
            WINE_PRODUCER: "WINE_PRODUCER",
            WINE_COUNTRY: "WINE_COUNTRY",
            WINE_REGION: "WINE_REGION",
            WINE_SUBREGION: "WINE_SUBREGION",
            WINE_SITE: "WINE_SITE",
            WINE_PARCEL: "WINE_PARCEL",
            WINE_TYPE: "WINE_TYPE",
            WINE_SUBTYPE: "WINE_SUBTYPE",
            WINE_PRODUCER: "WINE_PRODUCER",
            PRICE_LAST_TRADE_DATE: "PRICE_LAST_TRADE_DATE",
            PRODUCT_CONTRACT_TYPE: "PRODUCT_CONTRACT_TYPE",
            PRODUCT_CONDITION: "PRODUCT_CONDITION",
            LWIN_18: "LWIN_18",


        };
        let booleanFields = { WINE_VINTAGE: "WINE_VINTAGE", TIGHTEST_SPREAD: "TIGHTEST_SPREAD", PRODUCT_ID: "PRODUCT_ID", ORDER_HEADER_ID: "ORDER_HEADER_ID", PRODUCT_DUTY_PAID: "PRODUCT_DUTY_PAID", IS_INACTIVE: "IS_INACTIVE", DISPLAY_IN_WEBSITE: "DISPLAY_IN_WEBSITE", ABV_IS_VERIFIED: "ABV_IS_VERIFIED", WINE_BOTTLESIZE: "WINE_BOTTLESIZE", WINE_PACKSIZE: "WINE_PACKSIZE", PRICE_MARKET: "PRICE_MARKET", PRICE_OFFER: "PRICE_OFFER", PRICE_LAST_TRADE: "PRICE_LAST_TRADE", PRICE_BID: "PRICE_BID", PRICE_MARKUP_PERCENTAGE: "PRICE_MARKUP_PERCENTAGE", PRICE_DISCOUNT_PERCENTAGE: "PRICE_DISCOUNT_PERCENTAGE", PRICE_ONLINE: "PRICE_ONLINE", PRODUCT_DELIVERY_PERIOD: "PRODUCT_DELIVERY_PERIOD", QUANTITY_AVAILABLE: "QUANTITY_AVAILABLE", ABV_PERCENTAGE: "ABV_PERCENTAGE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    /**
* @description The function clear the object
*/
    cleanobject(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "NaN") {
                delete obj[propName];
            }
        }
        return obj
    }
    /**
* @description The function executed for cancel the edit
*/
    handleCancel() {

        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for submit the form
*/
    handleSubmit(e) {
        e.preventDefault();
        var update = store.getState().update;
        update = this.cleanobject(update);
        var query = this.HandleString(update)
        console.log(update)
        console.log(query)
        if (localStorage.getItem("wn_log") !== null) {
            if ((update['SUPPLIER_EMAIL'] !== undefined && update['SUPPLIER_EMAIL'] !== '') && (update['NAME'] !== undefined && update['NAME'] !== '') && (update['LWIN_18'] !== undefined && update['LWIN_18'] !== '') && (update['WINE_COLOR'] !== undefined && update['WINE_COLOR'] !== '') && (update['WINE_VINTAGE'] !== undefined && update['WINE_VINTAGE'] !== '') && (update['WINE_PRODUCER'] !== undefined && update['WINE_PRODUCER'] !== '') && (update['WINE_TYPE'] !== undefined && update['WINE_TYPE'] !== '') && (update['SUPPLIER_NAME'] !== undefined && update['SUPPLIER_NAME'] !== '') && (update['WINE_PACKSIZE'] !== undefined && update['WINE_PACKSIZE'] !== '') && (update['WINE_BOTTLESIZE'] !== undefined && update['WINE_BOTTLESIZE'] !== '') && (update['WINE_BOTTLESIZE'] !== undefined && update['WINE_BOTTLESIZE'] !== '') && (update['QUANTITY_AVAILABLE'] !== undefined && update['QUANTITY_AVAILABLE'] !== '') && (update['PRICE_OFFER'] !== undefined && update['PRICE_OFFER'] !== '') && (update['PRICE_ONLINE'] !== undefined && update['PRICE_ONLINE'] !== '') && (update['PRICE_MARKUP_PERCENTAGE'] !== undefined && update['PRICE_MARKUP_PERCENTAGE'] !== '') && (update['LEAD_TIME_MIN'] !== undefined && update['LEAD_TIME_MIN'] !== '') && (update['LEAD_TIME_MAX'] !== undefined && update['LEAD_TIME_MAX'] !== '')) {
                if (emailregex.test(update['SUPPLIER_EMAIL'])) {
                    this.Hanldeconditionsubmit()
                } else {
                    showNotification("invalid Supplier Email", "danger");
                }
            }
            else {
                showNotification("Must Fill Mandatory Fields", "danger");
            }


        } else {
            this.GoToPage("/");
        }

        // cancel edit when submit form
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for submit the form
*/
    Hanldeconditionsubmit() {
        var update = store.getState().update;
        var self = this;
        var email;
        var token;
        var query
        let login = JSON.parse(localStorage.getItem("wn_log"));
        token = login.access_token;
        email = login.email;
        update['EMAIL'] = email
        update['TOKEN'] = token
        update = this.cleanobject(update);
        query = this.HandleString(update)
        var details = [
            "title",

        ];
        console.log(query)
        //api for order information
        self.setState({ loading: true })

        insertProductAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.insertProduct?.summary?.reason;
                let status = res?.data?.insertProduct?.summary?.status;
                if (reason == "RECORD_CREATED" && status == "SUCCESS") {
                    document.getElementById("create-product").reset();
                    showNotification("Added Product", "success");
                    setTimeout(
                        function () {

                            self.props.history.push("/product?page=1");
                        }
                            .bind(this),
                        1000
                    );
                    self.setState({ loading: false })
                } else {
                    showNotification("Cannot Add Product", "danger");
                    // self.props.history.push("/product?page=1");
                    self.setState({ loading: false })
                }
            });
        store.dispatch({ type: 'EditOrder', edits: false })
        // cancel edit when submit form
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    render() {
        return (
            < div className='Product-Detail h-full  ' >

                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    <ReactNotification />
                    < Breadthcrumbs Breadthcrumb={'Add Product'} Count={2} level={'Product'} />
                    <Link to='/product' className='flex bg-white w-48 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/packing.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Product</span>
                    </Link>
                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'Product-Details px-5 py-2 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>

                    <form onSubmit={(e) => this.handleSubmit(e)} id='create-product'>
                        <ProductInfo />
                        <ProductImage />
                        <ProductDetails Countrylist={Country.CountryList} ProductInfo={product.ProductInfo} currency={this.state.Currency} weight={this.state.Weight} Percent={this.state.Percent} ContractType={product.ContractType} DutyPaid={product.DutyPaid} Types={product.Type} Subtypes={product.Subtype} history={this.props.history} />

                    </form>
                </div>
            </div >
        )
    }
};

export default AddProductDetail;