import { BASEURL } from './Baseurl'
/**
     * @description  The function for list the all orders
     * @returns all the orders
     */
function listCronjobsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/cronjob', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listCronjobs(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       list{
                        ${detail}
                       }
                         totalCount
                       }
                  
                      }
                  }`,
            }
        ),
    })
}
/**
     * @description  The function for list import table column
     * @returns all the columns 
     */
function mappingProductTableAPI(email, token, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/cronjob', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    mappingProductTable(EMAIL:"${email}",TOKEN:"${token}")
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                     ${detail}
                       }
                  
                      }
                  }`,
            }
        ),
    })
}
/**
     * @description  The function for import the csv file
     * @returns update the products
     */
function importProductsAPI(email, token, mapping, file) {
    //The details are convert to string
    let o = {
        query: `
        mutation($EMAIL:String,$TOKEN:String,$FILE: Upload,$MAPPING:String)
        {
            importProducts(EMAIL:$EMAIL,TOKEN:$TOKEN,FILE:$FILE,MAPPING:$MAPPING){
                summary{
                    status
                    ,reason
                },
                details
                {
                    file_import
                }
            }
        }`,
        variables: {
            EMAIL: email, TOKEN: token,
            FILE: null,
            MAPPING: mapping
        }
    }
    let map = {
        '0': ['variables.FILE']
    }
    var formdata = new FormData();
    formdata.append('operations', JSON.stringify(o))
    formdata.append('map', JSON.stringify(map))
    formdata.append("0", file, "Level 1.csv");
    return fetch(BASEURL + 'graphql/cronjob', {
        method: 'POST',
        redirect: 'follow',
        body: formdata
    })
}
/**
     * @description  The function for list import table column
     * @returns all the columns 
     */
function exportProductsAPI(email, token, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/cronjob', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    exportProducts(EMAIL:"${email}",TOKEN:"${token}")
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                     ${detail}
                       }
                  
                      }
                  }`,
            }
        ),
    })
}
/**
     * @description  The function for find the cronjob status 
     */
function cronJobStatusAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/cronjob', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    cronJobStatus(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                       }
                 
                }
                }`,
            }
        ),
    })
}
export {
    listCronjobsAPI,
    mappingProductTableAPI,
    importProductsAPI,
    exportProductsAPI,
    cronJobStatusAPI



}