
import { BASEURL } from './Baseurl'
/**
     * @description  The function for API of the admin login
     * @returns login success or fails
     */
function adminLoginAPI(email, password, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    adminLogin(EMAIL:"${email}",PASSWORD:"${password}")
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        email
                         first_name
                         last_name
                         access_token
               
                       }
               
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for API of the admin forgot password
     */
function forgotPasswordAdminAPI(email, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    forgotPassword(EMAIL:"${email}")
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       ${detail}
               
                       }
               
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for API of the admin reset password
     */
function resetPasswordAdminAPI(email, password, passcode, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    resetPassword(EMAIL:"${email}",TOKEN:"${passcode}",PASSWORD:"${password}")
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
               
                       }
               
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for API of the admin Login validate
     */
function adminLoginValidateAPI(email, passcode, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    adminLoginValidate(EMAIL:"${email}",TOKEN:"${passcode}",)
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
               
                       }
               
                }
                }`,
            }
        ),
    })
}
export {
    adminLoginAPI,
    forgotPasswordAdminAPI,
    resetPasswordAdminAPI,
    adminLoginValidateAPI

}