import React, { Component } from 'react'
import local from './../../global/js/locale';
import './Dashboard.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { convertDate } from './../../global/js/browserfunction'
import { CurrencyFormatSpace } from './../../global/js/browserfunction'
class AddedProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: props.AddedProducts,
            Currency: local.locale.CURRENCY.SYMBOL,
        }

    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.AddedProducts !== this.state.products) {
            this.setState({ products: nextProps.AddedProducts })
        }

    }
    render() {
        return (
            <div className=''>
                <h3 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>Recently Modified Products</h3>
                <div className='added-products relative px-4  '>
                    <table className="table-auto w-full mt-3 ">
                        <thead >
                            <tr>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans bg-bluetablebg py-1 w-14'>
                                    {/* <Link to='/product'>
                                        <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' />
                                    </Link> */}
                                </th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1'>Date</th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1'>SKU</th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1'>Item Name</th>
                                <th className='table-border font-normal text-sm text-bluecolor font-OpenSans py-1 w-36'>Item Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* The Foreach of the added products */}
                            {this.state.products.map(list =>
                                <tr key={list.record_row_id}>
                                    <td className='table-border font-normaltext-sm text-bluecolor font-OpenSans bg-bluetablebg py-1'>
                                        <Link to={'/product/productdetails/' + (list.record_row_id)}>
                                            <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' />
                                        </Link>
                                    </td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1 px-1'>{convertDate(list.date_modified)}</td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1 px-1'>{list.sku}</td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1  px-1'>{list.name}</td>
                                    <td className='table-border text-center text-sm  text-bluecolor font-OpenSans py-1 px-1'>{CurrencyFormatSpace(list.price_online)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className='relative  w-full'>
                        <Link to='/product' className='text-redcolor  font-normal text-xs font-OpenSans underline mt-4 ml-auto mr-5 block w-max'> View all
                        </Link>

                    </div>
                </div>
            </div >
        )
    }
};

export default AddedProducts;