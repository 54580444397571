import React, { Component } from 'react';
import store from '../../Store/Store'
import local from '../../global/js/locale';
class AddProductImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Title: 1,
            Edit: true,
            currentProduct: '',
            Products: props.Products,
            varchar: local.VARCHAR,
            Int: local.INT,
            Float: local.FLOAT,
            Livex: '',
            update: {
                IMAGE_1: '',
                IMAGE_2: '',
                IMAGE_3: '',
            },
            viewimage: false,

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleImage = this.handleImage.bind(this);
    }
    /**
* @description The function executed for get he edit value from store
*/
    async handleCheck() {

    }

    /**
* @description The function executed for exists the component the edit as false
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
    }
    handleImage() {
        this.setState({ viewimage: !this.state.viewimage })
    }

    /**
* @description The function executed for updated value stored as object.the id as key
*/
    handleChange(e) {
        var key = e.target.id;
        var value = e.target.value;
        var update = store.getState().update
        update[key] = value;
        this.setState({ update: update })
        store.dispatch({ type: 'Update', Update: update })
    }
    render() {
        return (
            <div className='Product-image h-full  bg-white py-4 px-5 mt-6 ' >
                <div className='mr-2 grid grid-cols-2'>
                    <div>
                        <span className='OrderItem font-OpenSans font-semibold   text-base my-auto cursor-pointer text-bluecolor ' >Images &nbsp;</span>
                    </div>
                    <div className={'ml-auto ' + ((this.state.update.IMAGE_1 === '' || this.state.update.IMAGE_1 === null) && (this.state.update.IMAGE_2 === '' || this.state.update.IMAGE_2 === null) && (this.state.update.IMAGE_3 === '' || this.state.update.IMAGE_3 === null) ? 'hidden' : '')}>
                        <span className={'OrderItem font-OpenSans font-semibold   text-sm my-auto cursor-pointer mr-4 text-white px-3 rounded-xl py-1 bg-redcolor '} onClick={() => this.handleImage()}>{this.state.viewimage === true ? 'Close' : 'View'} Image</span>
                    </div>
                </div>
                <hr className='sortfilterborder mb-4 mt-4' />
                <div className='product-head flex px-5'>
                    <div className='mr-2 flex'>
                        <span className={'OrderItem font-OpenSans font-semibold   text-base my-auto cursor-pointer mr-4 ' + (this.state.Title === 1 ? 'text-redcolor ' : 'text-bluecolor ')} >Stock  &nbsp;</span>
                    </div>
                </div>
                <div className={'stock-image ' + (this.state.Title === 1 ? 'block' : 'hidden')}>
                    <div className={'flex mt-6 px-3 ' + (this.state.viewimage === true ? 'block' : 'hidden')}>
                        <div className={'flex '} >
                            {this.state.update.IMAGE_1 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.update.IMAGE_1 + ")" }}></div>
                            }
                            {this.state.update.IMAGE_2 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.update.IMAGE_2 + ")" }}></div>
                            }
                            {this.state.update.IMAGE_3 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.IMAGE_3 + ")" }}></div>
                            }
                        </div>
                    </div>
                    <div className={'edit-stock px-5 mt-5 ' + (this.state.viewimage === true ? ' hidden' : 'block')}>
                        <table className="">
                            <tbody>
                                <tr>
                                    <td className='py-2 w-36'> <span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor '>Images_1</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='IMAGE_1' onChange={this.handleChange} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_2</span></td>
                                    <td className='py-2 w-full'> <input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='IMAGE_2' onChange={this.handleChange} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_3</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='IMAGE_3' onChange={this.handleChange} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        )
    }
};

export default AddProductImage;