import React, { Component } from 'react';

class ImportHeader extends Component {
    render() {
        return (
            <div className='CategorysInformation   bg-white shadow-shadowblack py-5 px-6 ' >
                <div className='w-full flex'>
                    <div className='flex w-1/2'>
                        <img src='/static/Icons/products.svg' alt='' className='xl:w-8 w-5' />
                        <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-2xl text-xl my-auto ml-3 my-auto ml-3 '>Products</h1>
                    </div>
                </div>
            </div >
        );
    }
}

export default ImportHeader