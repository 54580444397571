import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import user from '../../data/User.json'
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './../PaymentMethod/PaymentMethod.scss'
import store from '../../Store/Store'
import { userListAPI, sendPasswordAPI } from '../../API/Users';
import { getCookie } from './../../global/js/browserfunction'
import moment from 'moment'
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import Modal from '../../components/Popup/Modal'
import { convertDatetime } from '../../global/js/browserfunction'
class UserDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Edit: store.getState().edit,
            varchar: local.VARCHAR,
            Int: local.INT,
            Display_web: false,
            giveAccess: props.MethodList.have_access,
            isInactive: props.MethodList.is_inactive,
            methodlist: props.MethodList,
            id: props.Id,
            showModal: false,
        }
        this.HandleEdit = this.HandleEdit.bind(this);
        this.HandleCancel = this.HandleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleinactiveToggle = this.handleinactiveToggle.bind(this);
        this.handleModal = this.handleModal.bind(this)
        // this.handlePopup = this.handlePopup.bind(this);

    }


    /**
        * @description  To check mounting to toggle edit state
        * @returns Display data as editable and non-editable format according to the toggled state
    */
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }

    componentWillUnmount() {
        window.scrollTo(0, 0)
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }

    componentDidMount() {
        this.setState({ giveAccess: this.state.methodlist.have_access });
        var update = store.getState().update
        update['Acess'] = JSON.stringify(this.state.methodlist.have_access);
        this.setState({ methodlist: this.state.methodlist });
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && store.dispatch({ type: 'UpdateUser', UpdateUser: update })
    }


    handleModal(value) {
        this.setState({ showModal: value })
    }


    /**
        * @description  Function for setting editable state
        * @returns Display data as editable format
    */
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
    }

    /**
        * @description  Function for setting non-editable state
        * @returns Display data as non-editable format
    */
    HandleCancel() {
        store.dispatch({ type: 'EditOrder', edits: false })
        this.props.passToParent(true)
    }

    /**
        * @description  Function for update state
        * @param {e}, entered data
        * @returns Display updated data 
    */
    handleUpdate(e) {
        var key = e.target.name;
        var value = e.target.value;
        var update = store.getState().update
        update[key] = value;
        store.dispatch({ type: 'Update', Update: update })
    }

    /**
        * @description  Function for toggling giveAccess data
        * @param {e}, entered data
        * @returns Display updated state of giveAccess 
    */
    handleToggle(e) {
        e.preventDefault();
        var self = this
        var key = e.target.id

        if (key === 'Acess' && self.state.Edit === true) {
            var elm = document.getElementById('Acess');
            var v = !this.state.giveAccess
            var value = v
            var update = store.getState().userUpdate
            update[key] = JSON.stringify(v);
            store.dispatch({ type: 'UpdateUser', UpdateUser: update })
            self.setState({ giveAccess: v })
            console.log(store.getState().userUpdate)
        }
    }

    /**
        * @description  Function for toggling inactive data
        * @param {e}, entered data
        * @returns Display updated state of inactive 
    */
    handleinactiveToggle(e) {
        e.preventDefault();
        var self = this
        var key = e.target.id

        if (e.target.id === 'inActive' && self.state.Edit === true) {
            var act = document.getElementById('inActive');
            var v = !this.state.isInactive
            var value = v
            var update = store.getState().userUpdate
            update[key] = v;
            store.dispatch({ type: 'UpdateUser', UpdateUser: update })
            self.setState({ isInactive: v })
        }
    }

    render() {
        return (
            <div className='payment-content mx-5 py-4 pt-6 px-6 bg-white shadow-shadowblack mt-2'>
                <div className='w-full flex  px-2 pr-6 '>
                    <div className='xl:w-1/4 w-full flex my-auto'>
                        <img src='/static/Icons/user.svg' alt='' className='w-10' />
                        <span className='font-OpenSans font-semibold  text-bluecolor xl:text-2xl text-xl my-auto ml-3 '>{this.state?.methodlist?.first_name && this.state.methodlist.first_name + " "}{this.state?.methodlist?.last_name && this.state.methodlist.last_name}  </span>
                    </div>
                    {this.state.Edit !== true &&
                        <div className='xl: w-full  my-auto ml-full'>
                            <span className='ml-full ml-auto w-max block cursor-pointer'><button className="bg-bluecolor text-white text-md font-OpenSans rounded-lg px-4 py-1 cursor-pointer W-1/6 flex" onClick={() => this.handleModal(true)}>{this.state?.methodlist?.reset_token !== null ? 'Reset Password' : 'Set Password'}</button></span>
                        </div>
                    }
                    <div className={'xl:w-1/12 4/12 my-auto ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                        <span className='ml-10 w-max block cursor-pointer' onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max pl-6' /></span>
                    </div>
                    <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                        <div className='buttons w-max flex ml-auto'>
                            <div className='flex bg-bluecolor rounded-xl px-3 py-1'>
                                <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                    <div className='flex'>
                                        <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                        <span className='ml-2'>Save</span>
                                    </div>
                                </button>
                            </div>

                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => this.HandleCancel()}>Cancel</span>
                        </div>
                    </div>
                    {this.state.showModal && <Modal val={this.state.showModal} resetPassword={this.props.resetPassword} handleModal={this.handleModal} title={this.state?.methodlist?.reset_token !== null ? 'Reset Password' : 'Set Password'} msg={this.state?.methodlist?.reset_token !== null ? 'Password Reset' : 'New Password creation'} />}
                </div>
                <hr className='sortfilterborder my-4 ' />
                <div className='xl:w-full px-4 xl:flex'>
                    <div className='flex xl:w-1/2'>
                        <table className=' w-1/2'>
                            <tbody>
                                <tr>
                                    <td className='xl:w-14 w-28 font-OpenSans font-semibold text-sm text-bluecolor py-2 '>Date Modified </td>
                                    <td className={'xl:w-32 w-36  font-OpenSans font-normal text-sm text-bluecolor py-2'}>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor'>{this.state.methodlist.date_modified && convertDatetime(this.state.methodlist.date_modified)}</span>

                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className=' w-1/2'>
                            <tbody>
                                <tr>
                                    <td className='xl:w-14 w-28 font-OpenSans font-semibold text-sm text-bluecolor py-2 '>Date Created</td>
                                    <td className='xl:w-32 w-36 font-OpenSans font-normal text-sm text-bluecolor py-2'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor'>{this.state.methodlist.date_created && convertDatetime(this.state.methodlist.date_created)}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='flex xl:w-1/2'>
                        <table className=' w-1/2'>
                            <tbody>
                                <tr>
                                    <td className='xl:w-14 w-28 font-OpenSans font-semibold text-sm text-bluecolor py-2 '>Give Access</td>
                                    <td className='xl:w-32 w-36 font-OpenSans font-normal text-sm text-bluecolor py-2'>
                                        <span id="Acess" onClick={this.handleToggle} defaultValue={this.state.giveAccess} className='cursor-pointer'>
                                            <input type="checkbox" name={(!this.state.Edit ? 'inactive' : '')}
                                                className={"form-checkbox sync-netsuite  h-4 w-4  text-white " + (this.state.Edit === true ? 'cursor-pointer' : '')}
                                                checked={this.state.giveAccess} readOnly={!this.state.Edit}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className=' w-1/2'>
                            <tbody>
                                <tr>
                                    <td className='xl:w-14 w-28  font-OpenSans font-semibold text-sm text-bluecolor py-2 '>Is Inactive</td>
                                    <td className='xl:w-32 w-36 font-OpenSans font-normal text-sm text-bluecolor py-2'>
                                        <span id="inActive" onClick={this.handleinactiveToggle} defaultValue={this.state.isInactive} className='cursor-pointer'>
                                            <input type="checkbox" name={(this.state.Edit === true ? 'inactive' : '')}
                                                className={"form-checkbox sync-netsuite  h-4 w-4  text-white " + (this.state.Edit === true ? 'cursor-pointer' : '')}
                                                checked={this.state.isInactive} readOnly={!this.state.Edit}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
};

export default UserDetails;