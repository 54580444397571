import React, { Component } from 'react';
import local from '../../global/js/locale';
import store from './../../Store/Store'
import order from './../../data/Order.json'
import { Link } from 'react-router-dom';
import { CurrencyFormat, capitalizeFirstLetter } from './../../global/js/browserfunction'

class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Edit: '',
            Currency: local.locale.CURRENCY.SYMBOL,
            symbolweight: local.locale.WEIGHT.SYMBOL,
            Item: false,
            Items: [],
            Payments: [],
            orderlist: {},
            itemlist: [],
            paymentlist: [],
            CurrentList: '',
            vat: 20 / 100,
            SubTotal: '',
            Tax: '',
            Weight: '',
            Country: props.Countrylist,
            delivery: '',
            Duty: '',
            Shipping: '',
            TotalAmount: '',
            id: props.Id,
            Emptyobj: false

        }
        this.handleItem = this.handleItem.bind(this);
        this.handleWeight = this.handleWeight.bind(this);
        this.handleDuty = this.handleDuty.bind(this);
        this.handleShipping = this.handleShipping.bind(this);
        this.handleTotal = this.handleTotal.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.orderlist !== this.state.orderlist) {
            this.setState({ orderlist: nextProps.orderlist })
            console.log(nextProps.orderlist)
        }
        if (nextProps.itemlist !== this.state.itemlist) {
            if (nextProps.itemlist === null) {
                var array = [];
                this.setState({ itemlist: array })
            } else {
                this.setState({ itemlist: nextProps.itemlist })
            }

        }
        if (nextProps.paymentlist !== this.state.paymentlist) {
            this.setState({ paymentlist: nextProps.paymentlist })
            console.log(nextProps.paymentlist)
            let length = nextProps.paymentlist.length === 0;
            this.setState({ Emptyobj: length })
        }

    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        var id = this.state.id;
        // var obj = this.state.Orderlist.findIndex((el) => el.id === id);
        // var items = this.state.Orderlist[obj].OrderItem;
        // var payments = this.state.Orderlist[obj].Payments;
        // var Orderlist = this.state.Orderlist[obj];
        // this.setState({ CurrentList: Orderlist });
        // this.setState({ Items: [...this.state.Items, ...items] });
        // this.setState({ Payments: [...this.state.Payments, ...payments] });
        // var subtotal = [];
        // var tax = [];
        // //get the shipping total and weight
        // items.forEach((value) => {
        //     if (value['Dutypaid'] != true) {
        //         tax.push((value['Price'] * value['Quantity']) * this.state.vat)
        //     }
        //     subtotal.push(value['Price'] * value['Quantity'])

        // });
        // subtotal = subtotal.reduce((a, b) => a + b)
        // if (this.state.delivery == 'United Kingdom') {
        //     tax = tax.reduce((a, b) => a + b)
        // }
        // else {
        //     tax = 0;
        // }

        // // this.setState({ SubTotal: subtotal });
        // this.setState({ SubTotal: subtotal }, function () {
        //     this.handleTotal();
        // });
        // this.setState({ Tax: tax });
        // this.handleWeight(items)
        // this.handleDuty(items, obj)
        // this.handleShipping(obj, items)
        // this.handleTotal(subtotal)
    }
    handleItem() {
        this.setState({ Item: !this.state.Item })
    }
    /**
* @description The function executed for get and update the weight
*/
    handleWeight(items) {
        var weight = [];
        items.forEach((value) => {
            weight.push((value['BottleSize'] * value['PackSize']))
        });
        weight = weight.reduce((a, b) => a + b)
        if (weight < 900) {
            weight = Math.ceil((weight) / 10) / 45;
        }
        else {
            weight = Math.ceil((weight) / 10) / 50;
        }
        this.setState({ Weight: weight });
    }
    /**
* @description The function executed for calculate the duty of the products
*/
    handleDuty(items, obj) {
        // var duty = []
        // items.forEach((value, i) => {
        //     if (value['Dutypaid'] != true) {
        //         if (value['Type'] == "Fortified Wines") {
        //             var Duty = Math.ceil(((value['BottleSize'] * value['PackSize']) * value['Quantity']) / 900) * 35.76;
        //             duty.push(Duty)
        //             items[i]['Duty'] = Duty
        //         }
        //         if (value['Type'] == "Still Wine") {
        //             var Duty = Math.ceil(((value['BottleSize'] * value['PackSize']) * value['Quantity']) / 900) * 27.68;
        //             duty.push(Duty)
        //             items[i]['Duty'] = Duty
        //         }
        //         else if (value['Type'] == "Sparkling Wine") {
        //             var Duty = Math.ceil(((value['BottleSize'] * value['PackSize']) * value['Quantity']) / 900) * 32.32;
        //             duty.push(Duty)
        //             items[i]['Duty'] = Duty
        //         }
        //     }
        // });
        // duty = duty.reduce((a, b) => a + b)
        // this.setState({ Duty: duty });
        // this.state.Orderlist[obj]['OrderItem'] = items
    }
    /**
* @description The function executed for calculate the shipping total
*/
    handleShipping(obj, items) {
        // var country = this.state.Orderlist[obj].Country;
        // this.setState({ delivery: country });
        // var bottlecount = [];
        // if (country == 'United Kingdom') {
        //     this.setState({ Shipping: 15 });
        // }
        // else {
        //     var shipping = this.state.Country[country].shippingTable;
        //     items.forEach((value) => {
        //         bottlecount.push(parseInt(value['BottleSize']) * 1.75)
        //     });
        //     bottlecount = bottlecount.reduce((a, b) => a + b)
        //     shipping.forEach((value) => {
        //         if (value.weight != bottlecount) {
        //             if (value.weight < bottlecount) {
        //                 this.setState({ Shipping: value.price });
        //             }
        //         }
        //         else {
        //             this.setState({ Shipping: value.price });
        //         }
        //     });
        // }
    }
    /**
* @description The function executed for calculate the total amount
*/
    handleTotal() {
        var total;
        // var subtotal = this.state.SubTotal;
        // var tax = this.state.Tax;
        // var duty = this.state.Duty;
        // var shipping = parseInt(this.state.Shipping);
        // var total = subtotal + tax + duty + shipping;
        // this.setState({ TotalAmount: total });
    }
    render() {
        return (
            < div className='Order-items  bg-white pt-8 px-8'>
                <div className='item-subsection flex'>
                    <span className={'OrderItem font-OpenSans font-semibold   text-xl my-auto cursor-pointer ' + (this.state.Item === true ? 'text-bluecolor' : 'text-redcolor')} onClick={() => this.handleItem()}>Order Item  &nbsp; &nbsp;</span>
                    <img src='/static/Icons/Line.svg' alt='' className='h-8 w-2' />

                    <span className={'OrderPayment font-OpenSans font-semibold  text-bluecolor text-xl  my-auto cursor-pointer ' + (this.state.Item === true ? 'text-redcolor' : 'text-bluecolor')} onClick={() => this.handleItem()}> &nbsp; &nbsp;&nbsp;Payment</span>
                </div>
                <div className={'Order-Item mt-3 pb-6 ' + (this.state.Item === true ? 'hidden' : 'block')}>
                    <table className="w-full">
                        <thead>
                            <tr className='order-table border-t border-b'>
                                <th className='text-left py-3 pl-2 font-OpenSans font-semibold  text-bluecolor xl:text-lg text-sm w-44 xl:w-max'>Item</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Status</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Supplier</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Price Offer</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Rate</th>
                                {/* <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Duty paid</th> */}

                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>VAT</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Duty</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Qty</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Total</th>
                            </tr>
                        </thead>
                        <tbody className={'order-table-body ' + (this.state.Items)}>
                            {this.state.itemlist.map(item =>
                                <tr key={item.product_row_id}>
                                    <td className='text-left py-2 pl-2 font-OpenSans font-semibold text-sm'><Link to={'/product/productdetails/' + item.product_row_id} className='text-redcolor underline'>

                                        <span className={'' + (item.product_contract_type === 'SEP' ? '' : 'hidden')}> {item.product_name === undefined ? "" : item.product_name} (Case -{item.wine_packsize}/{item.wine_bottlesize /
                                            10 +
                                            this.state.symbolweight})</span>
                                        <span className={'' + (item.product_contract_type === 'SIB' || item.product_contract_type === 'X' ? '' : 'hidden')}> {item.product_name === undefined ? "" : item.product_name} (Bottle -{item.wine_packsize}/{item.wine_bottlesize /
                                            10 +
                                            this.state.symbolweight})</span>

                                    </Link>  <span className='text-bluecolor block'>Contract :
                                            {item.product_contract_type === 'X' ?
                                                item.product_contract_type === 'X' && (item.cart_duty === 0) ?
                                                    item.product_contract_type + '(DP)' : item.product_contract_type + '(SIB)'
                                                :
                                                item.product_contract_type}</span></td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{capitalizeFirstLetter(item.status)}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{item.supplier_name}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{CurrencyFormat(item.price_offer)}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{this.state.Currency}{(item.product_online_price)?.toFixed(2)}</td>
                                    {/* <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{item.product_duty_paid === true ? 'YES' : 'NO'}</td> */}

                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{this.state.Currency}{(item.cart_tax)?.toFixed(2)}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{this.state.Currency} {(item.cart_duty)?.toFixed(2)}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{item.cart_quantity}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{this.state.Currency}{(item.cart_total)?.toFixed(2)}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                    <div className='order-summary mt-16'>
                        <div className='xl:w-2/7 w-3/7 px-5 order-sum ml-auto py-3'>
                            <h2 className='font-OpenSans font-semibold  text-orderred  text-sm text-xs pb-3'>Order Summary</h2>
                            <table className="w-full">
                                <tbody className=''>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm '>Sub Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm w-10'>{CurrencyFormat(this.state.orderlist.subtotal)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Tax Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.orderlist.tax_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className='py-1 font-OpenSans font-normal  text-orderred  text-sm'>Duty Paid</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.orderlist.duty_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Weight Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.orderlist.weight_total == null ? 0 : (this.state.orderlist.weight_total)?.toFixed(2)}Kg</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.orderlist.delivery_option === 'DELIVERY' ? "Shipping" : 'Transfer'} Total</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.orderlist.shipping_total)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.orderlist.delivery_option === 'DELIVERY' ? "Shipping" : 'Transfer'} Tax</td>
                                        <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.orderlist.shipping_tax)}</td>
                                    </tr>
                                    <tr>
                                        <td className=' py-1 font-OpenSans font-bold pt-2 text-orderred  text-sm'>Transaction Total</td>
                                        <td className='text-left py-1 font-OpenSans  pt-2 font-bold  text-orderred  text-sm'>{CurrencyFormat(this.state.orderlist.transaction_total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={'Payment mt-3 pb-6 ' + (this.state.Item === true ? 'block' : 'hidden')}>
                    <table className={"w-full " + (this.state.Emptyobj === false ? '' : 'hidden')}>
                        <thead>
                            <tr className='order-table border-t border-b'>
                                <th className='text-center py-3 pl-2 font-OpenSans font-semibold  text-bluecolor  text-sm w-40'>Id</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Type</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Status</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Amount</th>
                                <th className='py-3 font-OpenSans font-semibold  text-bluecolor  text-sm'>Refund</th>
                            </tr>
                        </thead>
                        <tbody className='order-table-body'>
                            {this.state.paymentlist.map(item =>
                                <tr key={item.payment_record_row_id}>
                                    <td className='text-center py-2 font-OpenSans font-semibold  text-bluecolor text-sm'><Link to={'/payment/paymentdetails/' + (item.payment_record_row_id)} className='text-redcolor underline'>{item.payment_transaction_number}</Link></td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{item.payment_name}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{item.payment_status}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{CurrencyFormat(item.payment_amount)}</td>
                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{CurrencyFormat(item.payment_refund_amount === null ? 0 : item.payment_refund_amount)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className={'' + (this.state.Emptyobj === true ? '' : 'hidden')}>
                        <p className='font-OpenSans font-normal  text-bluecolor text-sm text-left'>No Payment History</p>
                    </div>
                </div>
            </div >
        )
    }
};

export default Order;