import React, { Component } from 'react';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import store from '../../Store/Store'
import Select from 'react-select'
import JoditEditor from "jodit-react";
import local from '../../global/js/locale';
import { showNotification } from '../../global/js/notification'
import { convertDate, convertDatetime } from '../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import { fetchLeadTimeInformationAPI } from './../../API/Leadtime'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import moment from 'moment';
import { emailregex } from './../../global/js/validator'
class AddProductDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Productinfo: props.ProductInfo,
            Tabs: 1,
            Products: props.Products,
            id: props.Id,
            currentProduct: '',
            Edit: true,
            currency: props.currency,
            weight: props.weight,
            Percent: props.Percent,
            ContractType: { label: "In Bond", id: 1, value: "SIB" },
            Contracttype: props.ContractType,
            DutyPaid: { value: false, id: 2, label: "No" },
            Dutypaid: props.DutyPaid,
            CountryList: props.Countrylist,
            Countrylist: [],
            Title: 1,
            Description: '',
            CountryOption: { value: "United Kingdom", label: "United Kingdom", id: "United Kingdom" },
            lasttradedate: '',
            varchar: local.VARCHAR,
            Int: local.INT,
            Float: local.FLOAT,
            ShortLimit: false,
            ShortLimitnumber: '',
            Descriptionlimit: false,
            Descriptionlimitnumber: '',
            ABV: '',
            Livex: '',
            Quantity: 1,
            FORMAT: local.locale.DATE.FORMAT,
            update: store.getState().update,
            emailvalid: true,
            Type: '',
            SubType: '',
            Types: props.Types,
            Subtypes: props.Subtypes,
            SubTypelist: false,
            Leadmin: 0,
            Leadmax: 0,
            Leaddetails: [],
            Tightest: false

        }
        this.Increment = this.Increment.bind(this);
        this.Decrement = this.Decrement.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.convertDateYear = this.convertDateYear.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.emailvalidator = this.emailvalidator.bind(this);
        this.LeadTimeInformation = this.LeadTimeInformation.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }
    /**
* @description The function executed for get the update value from store
*/
    async handleCheck() {
        // shortdesc.innerHTML = short;
        var countrylist = this.state.CountryList
        var array = [];
        //format the country list 
        Object.keys(countrylist).forEach(function (key) {
            array.push({
                value: countrylist[key].country,
                label: countrylist[key].country,
                id: countrylist[key].itemid,
            });
            array.sort((a, b) =>
                a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0
            );
        });
        this.setState({ Countrylist: [...this.state.Countrylist, ...array] })
        this.forceUpdate();
        var contract = this.state.Contracttype
        var duty = this.state.Dutypaid
        contract.forEach(e => {
            if (e.value === 'SIB') {
                let obj = duty.filter(x => x.value === false);
                e['Duty'] = obj
            } else if (e.value === 'X') {
                e['Duty'] = duty
            } else {
                let obj = duty.filter(x => x.value === false);
                e['Duty'] = obj
            }
        });

        this.setState({ Contracttype: contract })
        let obj = contract.find(o => o.value === 'SIB');
        var update = {};
        update['QUANTITY_AVAILABLE'] = this.state.Quantity;
        update['WINE_COUNTRY'] = this.state.CountryOption.value;
        update['PRODUCT_CONTRACT_TYPE'] = obj.value;
        update['PRODUCT_DUTY_PAID'] = obj['Duty'][0].value;

        update['PRICE_DISCOUNT_PERCENTAGE'] = 0
        store.dispatch({ type: 'Update', Update: update })

    }
    /**
* @description The function executed for conavert the object to string
*/
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { RECORD_ID: "RECORD_ID", MARKUP: "MARKUP" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function executed for change the state of active and inactive
*/
    handleToggle(e) {
        var self = this;
        var update = store.getState().update
        if (e.target.children.Tightest != undefined) {
            var Tightest = !e.target.children.Tightest.checked
            self.setState({ Tightest: Tightest })
            update['TIGHTEST_SPREAD'] = !e.target.children.Tightest.checked;
            console.log(update)
            store.dispatch({ type: 'Update', Update: update })
        }


    }
    /**
* @description The function for fetch the leadtime and markup percent
*/
    LeadTimeInformation() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        self.setState({ loading: true })
        var details = [
            "Minimum",
            "Maximum",
            "COUNTRY"

        ];
        //api for list orders
        fetchLeadTimeInformationAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.fetchLeadTimeInformation?.summary?.reason;
                let status = res?.data?.fetchLeadTimeInformation?.summary?.status;
                console.log(reason, status)
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    var update = store.getState().update;
                    self.setState({ loading: false })
                    var Leaddetails = res.data.fetchLeadTimeInformation.details.leadtime;
                    let markup = res.data.fetchLeadTimeInformation.details.markup_percentage;
                    self.setState({ Leaddetails: Leaddetails })
                    let uk = Leaddetails.find(o => o.COUNTRY === 'UK');
                    let outside = Leaddetails.find(o => o.COUNTRY === 'International');
                    document.getElementById('PRICE_MARKUP_PERCENTAGE').value = markup
                    update['PRICE_MARKUP_PERCENTAGE'] = markup
                    console.log(self.state.CountryOption)
                    if (self.state.CountryOption.value === "United Kingdom") {
                        self.setState({ Leadmin: uk.Minimum })
                        self.setState({ Leadmax: uk.Maximum })
                        document.getElementById('LEAD_TIME_MIN').value = uk.Minimum
                        document.getElementById('LEAD_TIME_MAX').value = uk.Maximum

                        update['LEAD_TIME_MIN'] = uk.Minimum;
                        update['LEAD_TIME_MAX'] = uk.Maximum;
                        store.dispatch({ type: 'Update', Update: update })
                    } else {
                        self.setState({ Leadmin: outside.Minimum })
                        self.setState({ Leadmax: outside.Maximum })
                        update['LEAD_TIME_MIN'] = outside.Minimum;
                        update['LEAD_TIME_MAX'] = outside.Maximum;
                        document.getElementById('LEAD_TIME_MIN').value = outside.Minimum
                        document.getElementById('LEAD_TIME_MAX').value = outside.Maximum
                        store.dispatch({ type: 'Update', Update: update })
                    }

                } else {
                    self.setState({ loading: false })
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
* @description The function executed for when the compoenent exists
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.LeadTimeInformation();



    }
    /**
* @description The function executed for check the valid email
*/
    emailvalidator() {
        var update = store.getState().update
        var email = update['SUPPLIER_EMAIL']
        console.log(update['SUPPLIER_EMAIL'], emailregex.test(email))
        if (emailregex.test(email)) {
            this.setState({ emailvalid: true })
        } else {
            this.setState({ emailvalid: false })
        }
    }

    /**
   * @description The function executed for convert the date with timezone to date only with dd/mm/yyyy fromat
   */
    convertDateYear(inputFormat) {

        if (inputFormat != undefined) {
            let input = inputFormat.split("T")[0];
            function pad(s) {
                return s < 10 ? "0" + s : s;
            }
            var d = new Date(input);
            return [pad(d.getFullYear()), pad(d.getMonth() + 1), d.getDate()].join(
                "-"
            );
        }
    }
    /**
* @description The function executed for update the contract type of the product
*/
    handleContract = selectedOption => {
        this.setState({ ContractType: selectedOption });
        console.log(selectedOption)
        var update = store.getState().update;
        update['PRODUCT_CONTRACT_TYPE'] = selectedOption.value;
        update['PRODUCT_DUTY_PAID'] = selectedOption['Duty'][0].value;
        this.setState({ DutyPaid: selectedOption['Duty'][0] });
        store.dispatch({ type: 'Update', Update: update })
    };
    /**
* @description The function executed for update the Type of the product
*/
    handleType = selectedOption => {
        console.log(store.getState().update)
        this.setState({ Type: selectedOption });
        console.log(selectedOption)
        var update = store.getState().update;
        update['WINE_TYPE'] = selectedOption.value;
        update['WINE_SUBTYPE'] = '';
        this.setState({ SubType: {} });
        if (selectedOption?.SubType?.length === 0) {
            this.setState({ SubTypelist: true })
        }
        else {
            document.getElementById('WINE_SUBTYPE').value = ''
            this.setState({ SubTypelist: false })
        }
        store.dispatch({ type: 'Update', Update: update })

    };
    /**
  * @description The function executed for update the Sub type of the product
  */
    handleSubype = selectedOption => {
        this.setState({ SubType: selectedOption });
        console.log(selectedOption)
        var update = store.getState().update;
        update['WINE_SUBTYPE'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
        console.log(update)
    };
    /**
* @description The function executed for update the ABV of the product
*/
    handleABV = selectedOption => {
        this.setState({ ABV: selectedOption });
        var update = store.getState().update;
        update['ABV_IS_VERIFIED'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
    };
    /**
* @description The function executed for update the dutypaid
*/
    handleDutypaid = selectedOption => {
        this.setState({ DutyPaid: selectedOption });
        console.log(selectedOption)
        var update = store.getState().update;
        update['PRODUCT_DUTY_PAID'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
    };
    /**
* @description The function executed for increment the count
*/
    Increment(e) {
        var Quantity = e.target.parentNode.parentNode.parentElement.querySelector('#QUANTITY_AVAILABLE')
        var val = Quantity.value;
        val++
        Quantity.value = val;
        var update = store.getState().update;
        update['QUANTITY_AVAILABLE'] = val;
        store.dispatch({ type: 'Update', Update: update })

    }
    /**
* @description The function executed for decrement the count
*/
    Decrement(e) {
        var Quantity = e.target.parentNode.parentNode.parentElement.querySelector('#QUANTITY_AVAILABLE')
        var val = Quantity.value;
        if (val > 1) {
            val--
        }
        Quantity.value = val;
        var update = store.getState().update;
        update['QUANTITY_AVAILABLE'] = val;
        store.dispatch({ type: 'Update', Update: update })
    }
    /**
* @description The function executed for set the title of the product
*/
    handleTitle(id) {
        this.setState({ Title: id });
    }
    /**
* @description The function executed for update the country
*/
    handleCountry = selectedOption => {
        this.setState({ CountryOption: selectedOption });
        var update = store.getState().update;
        update['WINE_COUNTRY'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
        // var Leaddetails = this.state.Leaddetails
        // let uk = Leaddetails.find(o => o.COUNTRY === 'UK');
        // let outside = Leaddetails.find(o => o.COUNTRY === 'International');
        // if (selectedOption.value === "United Kingdom") {
        //     this.setState({ Leadmin: uk.Minimum })
        //     this.setState({ Leadmax: uk.Maximum })
        //     document.getElementById('LEAD_TIME_MIN').value = uk.Minimum
        //     document.getElementById('LEAD_TIME_MAX').value = uk.Maximum
        //     var update = store.getState().update;
        //     update['LEAD_TIME_MIN'] = uk.Minimum;
        //     update['LEAD_TIME_MAX'] = uk.Maximum;
        //     store.dispatch({ type: 'Update', Update: update })
        // } else {
        //     this.setState({ Leadmin: outside.Minimum })
        //     this.setState({ Leadmax: outside.Maximum })
        //     update['LEAD_TIME_MIN'] = outside.Minimum;
        //     update['LEAD_TIME_MAX'] = outside.Maximum;
        //     document.getElementById('LEAD_TIME_MIN').value = outside.Minimum
        //     document.getElementById('LEAD_TIME_MAX').value = outside.Maximum
        // }
    };
    /**
* @description The function executed for update the sort
*/
    updateshort = (value) => {
        // console.log(this.state.varchar['2X'])
        var maxlength = this.state.varchar['2X'];
        var length = value.length;
        console.log(value.length)
        if (maxlength >= length) {
            this.setState({ ShortLimit: false });
            var update = store.getState().update
            update['SHORT_DESCRIPTION'] = value;
            store.dispatch({ type: 'Update', Update: update })
        } else {
            console.log(length - maxlength)
            this.setState({ ShortLimitnumber: (length - maxlength) })
            this.setState({ ShortLimit: true });
        }

    }
    setRef = jodit => this.jodit = jodit;
    config = {
        readonly: false,
    }
    /**
* @description The function executed for update the description
*/
    updateDescription = (value) => {
        var maxlength = this.state.varchar['4X'];
        var length = value.length;
        if (maxlength > length) {
            this.setState({ Descriptionlimit: false });
            var update = store.getState().update
            update['SALES_DESCRIPTION'] = value;
            store.dispatch({ type: 'Update', Update: update })
        } else {
            console.log(length - maxlength)
            this.setState({ Descriptionlimitnumber: (length - maxlength) })
            this.setState({ Descriptionlimit: true });
        }
    }
    setRef = jodit => this.jodit = jodit;
    config = {
        readonly: false,
    }
    /**
* @description The function executed for updated values are converted to object the id as key
*/
    handleUpdate(e) {
        var self = this;
        var key = e.target.id;
        var value = e.target.value;
        var update = store.getState().update
        var price_offer = document.getElementById('PRICE_OFFER').value
        var priceonline;
        if (key === 'SUPPLIER_EMAIL') {
            update[key] = value;
            self.setState({ update: update }, function () {
                this.emailvalidator();
            })
        }
        if (key === 'PRICE_LAST_TRADE_DATE') {
            e.target.setAttribute(
                "data-date",
                moment(e.target.value, "YYYY-MM-DD")
                    .format(e.target.getAttribute("data-date-format"))
            )
        }
        if (key === 'PRICE_MARKUP_PERCENTAGE') {
            if (price_offer != '' && price_offer != null && price_offer != undefined) {

                if (value === '') {
                    priceonline = parseInt(price_offer)
                    console.log(priceonline, price_offer, value)
                    document.getElementById('PRICE_ONLINE').value = priceonline
                } else if (parseFloat(value) < 99) {
                    priceonline = (parseInt(price_offer) * parseInt(value)) / 100 + parseInt(price_offer);
                    console.log(priceonline, price_offer, value)
                    document.getElementById('PRICE_ONLINE').value = priceonline
                }
                update['PRICE_ONLINE'] = parseFloat(priceonline).toFixed(2);
            }

            update[key] = value;
        } else if (key === 'PRICE_DISCOUNT_PERCENTAGE') {
            if (price_offer != '' && price_offer != null && price_offer != undefined) {
                if (value === '') {
                    priceonline = parseInt(price_offer)
                    update['PRICE_ONLINE'] = parseFloat(priceonline).toFixed(2);
                    document.getElementById('PRICE_ONLINE').value = priceonline
                } else if (value < 100) {
                    priceonline = parseInt(price_offer) - (parseInt(price_offer) * parseInt(value)) / 100;
                    console.log(priceonline)
                    update['PRICE_ONLINE'] = parseFloat(priceonline).toFixed(2);
                    document.getElementById('PRICE_ONLINE').value = priceonline
                }
            }
            update[key] = value;
        } else if (key === 'PRICE_ONLINE') {
            document.getElementById('PRICE_MARKUP_PERCENTAGE').value = 0
            update['PRICE_MARKUP_PERCENTAGE'] = 0
            update['PRICE_ONLINE'] = value;
        }
        else if (key === 'PRICE_OFFER') {
            var markup = document.getElementById('PRICE_MARKUP_PERCENTAGE').value
            if (markup != '' && markup != null && value != '') {
                let priceoffer = parseFloat(value)
                let price_offer = parseFloat(value) + parseFloat(priceoffer * parseInt(markup) / 100);
                update['PRICE_ONLINE'] = parseFloat(price_offer).toFixed(2)
                document.getElementById('PRICE_ONLINE').value = parseFloat(price_offer).toFixed(2)
            } else {
                update['PRICE_ONLINE'] = 0;
                document.getElementById('PRICE_ONLINE').value = 0

            }
            update['PRICE_OFFER'] = parseFloat(value).toFixed(2);
        }
        else if (key === 'PRICE_MARKET') {
            update['PRICE_MARKET'] = parseFloat(value).toFixed(2);
        }
        else if (key === 'PRICE_LAST_TRADE') {
            update['PRICE_LAST_TRADE'] = parseFloat(value).toFixed(2);
        }
        else if (key === 'PRICE_BID') {
            update['PRICE_BID'] = parseFloat(value).toFixed(2);
        }
        else if (key === 'PRICE_MARKUP_PERCENTAGE') {
            update['PRICE_MARKUP_PERCENTAGE'] = parseFloat(value).toFixed(2);
        }
        else if (key === 'PRICE_DISCOUNT_PERCENTAGE') {
            update['PRICE_DISCOUNT_PERCENTAGE'] = parseFloat(value).toFixed(2);
        }
        else if (key === 'ABV_PERCENTAGE') {
            update['ABV_PERCENTAGE'] = parseFloat(value).toFixed(2);
        }
        else if (key === 'PRODUCT_DELIVERY_PERIOD' && value !== '') {
            update['LEAD_TIME_MIN'] = parseInt(value) + this.state.Leadmin
            update['LEAD_TIME_MAX'] = parseInt(value) + this.state.Leadmax
            document.getElementById('LEAD_TIME_MIN').value = parseInt(value) + this.state.Leadmin
            document.getElementById('LEAD_TIME_MAX').value = parseInt(value) + this.state.Leadmax
            update[key] = value;
        }
        else {
            update[key] = value;
        }

        console.log(update)

        store.dispatch({ type: 'Update', Update: update })

    }
    render() {
        return (
            < div className={'Product-information h-full pt-4 py-8 px-5 bg-white mt-6 shadow-shadowblack mb-10'}>
                <div className='product-head flex'>
                    {this.state.Productinfo.map(list =>
                        <div className='mr-2 flex' key={list.id}>
                            <span className={'OrderItem font-OpenSans font-semibold   text-base my-auto cursor-pointer ' + (this.state.Title !== list.id ? 'text-bluecolor ' : 'text-redcolor ') + (list.id !== 1 ? 'xl:ml-3' : '')} onClick={() => this.handleTitle(list.id)}>{list.name}  &nbsp;</span>
                            <img src='/static/Icons/Line.svg' alt='' className={'h-8 w-2 ' + (list.id === 4 ? 'hidden' : '')} />
                        </div>
                    )}
                </div>
                <hr className='sortfilterborder mb-8 mt-3' />
                <div className={'mt-10 px-10 ' + (this.state.Title === 1 ? 'block' : 'hidden')}>
                    <div className='title-description'>
                        <table className="w-full">
                            <tbody>

                                <tr>
                                    <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>LWIN_18 *</span></td>
                                    <td className='py-2'>
                                        <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='LWIN_18' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-60' onChange={this.handleUpdate} maxLength='18' required pattern="\s*\S+.*" title="space only is not allowed" /></span></td>
                                </tr>
                                {/* <tr>
                                    <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>SKU *</span></td>
                                    <td className='py-2'>
                                        <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='SKU' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-60' onChange={this.handleUpdate} maxLength={this.state.varchar['SM']} /></span></td>
                                </tr> */}
                                <tr >
                                    <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Short <br></br>description</span></td>
                                    <td className='py-2'>
                                        <span className={this.state.Edit === true ? ' w-1/2 ' : 'hidden'}>
                                            <JoditEditor
                                                editorRef={this.setRef}
                                                config={this.config}
                                                tabIndex={1}
                                                maxLength="10"
                                                onChange={this.updateshort}
                                            />
                                        </span>
                                        <span className={'font-OpenSans font-semibold  text-sm text-redcolor ' + (this.state.ShortLimit === true ? '' : 'hidden')}>Limit exceeded Chars {this.state.ShortLimitnumber}</span>
                                    </td>
                                </tr>
                                <tr >
                                    <td className='w-48 py-2 align-top'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Description</span></td>
                                    <td className='py-2'>
                                        <span className={this.state.Edit === true ? ' w-10/12' : 'hidden'}>
                                            <JoditEditor
                                                editorRef={this.setRef}
                                                config={this.config}
                                                tabIndex={1}
                                                onChange={this.updateDescription}
                                            />
                                        </span>
                                        <span className={'font-OpenSans font-semibold  text-sm text-redcolor ' + (this.state.Descriptionlimit === true ? '' : 'hidden')}>Limit exceeded Chars {this.state.Descriptionlimitnumber}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={'wine-deatils xl:w-11/12 w-10/12 m-auto  ' + (this.state.Title === 2 ? 'block' : 'hidden')}>
                    <div className='grid xl:grid-cols-3 grid-cols-1 xl:gap-4'>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className='w-48 py-2'>  <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Color *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_COLOR' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['SM']} required={this.state.Title === 2} pattern="[a-zA-Z]+" title="Character only  allowed" /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Vintage *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='number' id='WINE_VINTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['XS']} required={this.state.Title === 2} pattern="\s*\S+.*" title="space only is not allowed" /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Grower *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_PRODUCER' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['SM']} required={this.state.Title === 2} pattern="\s*\S+.*" title="space only is not allowed" /></span></td>
                                    </tr>
                                    <tr >
                                        <td className={'w-48 py-2 ' + (this.state.Livex)}><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Grower Title</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='TITLE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Country *</span></td>
                                        <td className='py-2'>
                                            <span className={' shipping-country w-52 wine-country status-select shipping-font  Shipping-size ' + (this.state.Edit === true ? ' ' : 'hidden')}><Select
                                                value={this.state.CountryOption}
                                                onChange={this.handleCountry}
                                                options={this.state.Countrylist} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'>  <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Region </span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_REGION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['MD']} pattern="\s*\S+.*" title="space only is not allowed" /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>SubRegion </span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_SUBREGION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['MD']} pattern="\s*\S+.*" title="space only is not allowed" /></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr >
                                        <td className='w-48 py-2'>   <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Site</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_SITE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Parcel</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_PARCEL' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Type *</span></td>
                                        <td className='py-2'>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font  Shipping-size  ' + (this.state.Edit === true ? 'block' : 'hidden')}> <Select
                                                value={this.state.Type}
                                                onChange={this.handleType}
                                                options={this.state.Types} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Sub Type</span></td>
                                        <td className='py-2'>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font Shipping-size   ' + (this.state.Edit === true && this.state.SubTypelist === false ? 'block' : 'hidden')}> <Select
                                                value={this.state.SubType}
                                                onChange={this.handleSubype}
                                                options={this.state.Type?.SubType} isSearchable={false}
                                            /></span><span className={this.state.Edit === true && this.state.SubTypelist === true ? ' w-full' : 'hidden'}><input type='text' id='WINE_SUBTYPE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['LG']} /></span></td>
                                    </tr>
                                    <tr >
                                        <td className='w-48 py-2'>   <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Supplier Name *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='text' id='SUPPLIER_NAME' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['MD']} required={this.state.Title === 2} pattern="\s*\S+.*" title="space only is not allowed" /></span>


                                        </td>
                                    </tr>

                                    <tr >
                                        <td className='w-48 py-2'> <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Supplier Email *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full block' : 'hidden'}><input type='text' id='SUPPLIER_EMAIL' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['MD']} pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}' /></span>
                                            <span className={'font-OpenSans font-normal text-redcolor text-sm  ' + (this.state.emailvalid === false ? 'mb-6' : 'hidden')}>Invalid Email</span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Pack Size *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='number' id='WINE_PACKSIZE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} max='1000' /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Bottle Size *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full font-OpenSans font-normal text-sm text-bluecolor ' : 'hidden'}><input type='number' id='WINE_BOTTLESIZE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} max='65535' /> ml</span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Alcohol Value</span></td>
                                        <td className='py-2 font-OpenSans font-semibold  text-sm text-bluecolor'>
                                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}><input type='number' id='ABV_PERCENTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.Int['SM-US']} /> %</span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Alcohol Value Verified </span></td>
                                        <td className='py-2'>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font  Shipping-size  ' + (this.state.Edit === true ? 'block' : 'hidden')}><Select
                                                value={this.state.ABV}
                                                onChange={this.handleABV}
                                                options={this.state.Dutypaid} isSearchable={false}
                                            /></span></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={'Price_detail w-11/12 m-auto  ' + (this.state.Title === 4 ? 'flex' : 'hidden')}>
                    <div className='w-1/2 '>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Market price</span>

                            <span className={this.state.Edit === true ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_MARKET' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' onChange={this.handleUpdate} maxLength={this.state.Float['SM-US']} /></span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Offer price *</span>

                            <span className={this.state.Edit === true ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_OFFER' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' onChange={this.handleUpdate} maxLength={this.state.Float['SM-US']} /></span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Price Online *</span>

                            <span className={this.state.Edit === true ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_ONLINE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 border-transparent border w-40' onChange={this.handleUpdate} maxLength={this.state.Float['SM-US']} disabled /></span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Last trade price</span>

                            <span className={this.state.Edit === true ? ' w-full  text-bluecolor' : 'hidden'}>{this.state.currency}&nbsp; &nbsp;<input type='number' id='PRICE_LAST_TRADE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' onChange={this.handleUpdate} maxLength={this.state.Float['SM-US']} /></span>
                        </div>
                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Last trade date</span>

                            <span className={this.state.Edit === true ? ' w-full' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='date' id='PRICE_LAST_TRADE_DATE' data-date="mm/dd/yyyy" data-date-format={this.state.FORMAT} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40 active' onChange={this.handleUpdate} /></span>
                        </div>

                    </div>
                    <div className='w-1/2'>

                        <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Markup *</span>

                            <span className={this.state.Edit === true ? ' w-full text-bluecolor' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='number' id='PRICE_MARKUP_PERCENTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' onChange={this.handleUpdate} min='1' max='100' pattern='[+-]?([0-9]*[.])?[0-9]+' /> &nbsp; &nbsp;{this.state.Percent}</span>
                        </div>
                        {/* <div className='title flex py-3 w-full'>
                            <span className='font-OpenSans font-bold  text-sm text-bluecolor w-40'>Discount</span>

                            <span className={this.state.Edit === true ? ' w-full text-bluecolor' : 'hidden'}>&nbsp; &nbsp; &nbsp;<input type='number' id='PRICE_DISCOUNT_PERCENTAGE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-40' onChange={this.handleUpdate} maxLength={this.state.Float['XS-US']}  /> &nbsp; &nbsp;{this.state.Percent}</span>
                        </div> */}
                    </div>
                </div>
                <div className={'Product-condition ' + (this.state.Title === 3 ? 'block' : 'hidden')}>
                    <div className='grid xl:grid-cols-2 grid-cols-1 xl:gap-4 w-11/12 m-auto'>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor  '>Contract Type *</span></td>
                                        <td className='py-2'>

                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font   ' + (this.state.Edit === true ? 'block' : 'hidden')}> <Select
                                                value={this.state.ContractType}
                                                onChange={this.handleContract}
                                                options={this.state.Contracttype} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor '>Duty Paid *</span></td>
                                        <td className='py-2'>
                                            <span className={' w-52  block shipping-country  wine-country status-select shipping-font  Shipping-size  ' + (this.state.Edit === true ? 'block' : 'hidden')}><Select
                                                value={this.state.DutyPaid}
                                                onChange={this.handleDutypaid}
                                                options={this.state.ContractType?.Duty} isSearchable={false}
                                            /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor  '>Available Quantity *</span></td>
                                        <td className='py-2 '>
                                            <div className='flex right-2 relative'>
                                                <span className='my-auto relative left-4 cursor-pointer ' onClick={this.Decrement}>  <img src='/static/Icons/minus.svg' alt='' className={(this.state.Edit === true ? 'block ' : 'hidden')} /></span>
                                                <span className={this.state.Edit === true ? ' w-max ' : 'hidden'}><input type='number' id='QUANTITY_AVAILABLE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md text-center focus:border-redcolor w-16 px-4' onChange={this.handleUpdate} defaultValue={this.state.Quantity} maxLength={this.state.Int['SM-US']} /></span>
                                                <span className='my-auto relative right-4 cursor-pointer' onClick={this.Increment}><img src='/static/Icons/plus.svg' alt='' className={(this.state.Edit === true ? 'block ' : 'hidden')} /></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'>Product Condition</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full relative  ' : 'hidden'}><input type='text' id='PRODUCT_CONDITION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} maxLength={this.state.varchar['2X']} /></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-full">
                                <tbody>
                                    <tr >
                                        <td className='w-48 py-2'>   <span className='font-OpenSans font-bold  text-sm text-bluecolor w-32'>Tightest Spread</span></td>
                                        <td className='py-2'> <span className='cursor-pointer ' onClick={(e) => this.handleToggle(e)} ><input type="checkbox" id={(this.state.Edit === true ? 'Tightest' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white  " + (this.state.Edit === true ? 'cursor-pointer' : '')} checked={this.state.Tightest} readOnly={this.state.Edit} /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'>  <span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'>Delivery Period *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full font-OpenSans   text-bluecolor  ' : 'hidden'}><input type='number' id='PRODUCT_DELIVERY_PERIOD' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-52' onChange={this.handleUpdate} required={this.state.Title === 3 ? true : false} maxLength={this.state.Int['XS-US']} />&nbsp;&nbsp;days</span></td>

                                    </tr>

                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'> Lead Time Minimum *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full relative  ' : 'hidden'}><input type='number' id='LEAD_TIME_MIN' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 border-transparent w-52' required={this.state.Title === 3 ? true : false} onChange={this.handleUpdate} max={store.getState().update["LEAD_TIME_MAX"]} disabled /></span></td>
                                    </tr>
                                    <tr>
                                        <td className='w-48 py-2'><span className='font-OpenSans font-bold  text-sm text-bluecolor w-52'> Lead Time Maximum *</span></td>
                                        <td className='py-2'>
                                            <span className={this.state.Edit === true ? ' w-full relative  ' : 'hidden'}><input type='number' id='LEAD_TIME_MAX' className='font-OpenSans font-normal text-sm text-bluecolor  rounded-md pl-3   border-transparent border  w-52' required={this.state.Title === 3 ? true : false} onChange={this.handleUpdate} disabled /></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
};

export default AddProductDetails;