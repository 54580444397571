import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import './ProductCategory.scss'
import Progress from '../../components/ProgressBar/Progress';
import Select from 'react-select'
import ImportProduct from '../../data/ImportProduct.json'
import { withRouter } from 'react-router-dom';
import ImportHeader from './ImportHeader';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from '../../components/CircleProgressBar/ChangingProgressProvider';
import ProgressProvider from '../../components/CircleProgressBar/ProgressProvider';
import Export from './Export';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import CSV from './../../Store/Csv'


class ImportDone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            varchar: local.VARCHAR,
            Int: local.INT,
            isLoading: false,
            Category: props.Category,
            Homepage: props.Homepage,
            Status: 'Home',


        }

        this.handleStatus = this.handleStatus.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }
    async handleCheck() {
        var File_column = CSV.getState()?.CSVkeys;
        console.log(File_column)
        if (File_column === undefined) {
            this.props.history.push('/importproducts')
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck()
    }
    componentWillUnmount() {
        this._isMounted = false;
        var Keylist = undefined
        CSV.dispatch({ type: 'CSV', Key: Keylist })

    }
    /**
* @description The function executed for change the status
*/
    handleStatus(id) {
        this.setState({ Status: id });
        if (id === 'Home') {
            CSV.dispatch({ type: 'CSV', Key: undefined })

        }
        if (id === 'Menu') {
            if (CSV.getState()?.CSVkeys === undefined) {
                this.props.history.push('/importproducts')
            }
        }
    }



    render() {
        return (
            <div className='Categorys-main h-screen'>
                < Breadthcrumbs Breadthcrumb={'Products'} Count={2} level={'Import'} />
                <div className='py-1 mx-4'>
                    {/* Name of the Page Header */}
                    <ImportHeader />

                    {/* Rest of the portion with step progress bar */}
                    <div className='Categorys-details  py-5 px-6 bg-white shadow-shadowblack mt-8'>
                        <div className='xl:grid xl:grid-cols-2 xl:gap-2 w-3/4'>
                            <div className='categorys grid grid-cols-2 '>

                                {/* Import Header */}
                                {/* <div className={'category-list '}>
                                    <div className={' px-2 border py-2 ' + (this.state.Status === 'Menu' ? 'bg-bluecolor' : ' ')}>
                                        <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Menu')}>
                                            <h2 className={' font-OpenSans text-base font-normal my-auto text-center ' + (this.state.Status === 'Menu' ? 'text-white' : 'text-bluecolor')}  >IMPORT</h2>
                                        </div>

                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 ml-auto relative bottom-0.5 ' + (this.state.Status === 'Menu' ? 'block' : 'hidden')} />
                                </div> */}

                                {/* Export Header */}
                                <div className={'category-list    '}>
                                    <div className={' px-2 border py-2 px-2 ' + (this.state.Status === 'Home' ? 'bg-bluecolor' : ' ')}>
                                        <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Home')}>
                                            <h2 className={' font-OpenSans text-base font-normal my-auto text-center ' + (this.state.Status === 'Home' ? 'text-white' : 'text-bluecolor')}>EXPORT</h2>
                                        </div>
                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 relative bottom-0.5 ml-auto ' + (this.state.Status === 'Home' ? 'block' : 'hidden')} />
                                </div>

                            </div>
                        </div>
                        <div className={'category-down pt-6 ' + (this.state.Status === 'Menu' ? '' : 'hidden')}>
                            <div className='categorys-div '>

                                {/* Step Progress Indicator */}
                                <div className='level0  w-full'>
                                    <Progress level1={"level1"} level2={"level2"} level3={"level3"} level4={"level4"} />
                                </div>

                                <div className='mt-12 mb-2' />

                                {/* Circle ProgressBar Completed Section */}
                                <div className='border-solid border-2 border-gray-200 my-auto border-b mt-2'>
                                    <div className="mb-2 ml-4 justify-center items-center">

                                        <div className="w-full flex">
                                            <div className="w-full my-8 flex" />
                                        </div>

                                        <div className="w-full ">
                                            <div className=" ">
                                                <div className="w-max m-auto">
                                                    <img src='/static/Icons/tick123.svg' alt='' className='w-20' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full flex">
                                            <div className="w-full xl:my-4 my-8 w-max m-auto">
                                                <div className="">
                                                    <span className='font-OpenSans font-semibold text-center text-redcolor xl:text-xl text-md '>Import Completed !</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {this.state.Status === 'Home' && <Export />}
                    </div >
                </div>
            </div >
        )
    }
};

export default withRouter(ImportDone);